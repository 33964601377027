import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Box } from '@mui/material'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useRefreshHighSecSession from 'hooks/useRefreshHighSecSession'
import useRolesViews from 'hooks/useRolesViews'
import useScrolledState from 'hooks/useScrolledState'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { AvatarSidebarStateProvider } from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import { FilterContext } from 'pages/AbsencePlanner/contexts/FilterContext'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { useAbsencePlannerData } from 'pages/AbsencePlanner/hooks/useAbsencePlannerData'
import { useCalendarActions } from 'pages/AbsencePlanner/hooks/useCalendarActions'
import { useFilter } from 'pages/AbsencePlanner/hooks/useFilter'
import RedesignThemeWrapper from 'redesign/components/atoms/RedesignThemeWrapper'
import MonthSwitcher from 'redesign/components/organisms/MonthSwitcher'
import SideMenu from 'redesign/components/templates/SideMenu'
import AvatarSideBar from '../AvatarSideBar'
import ToggleSideBarButton from '../AvatarSideBar/ToggleSideBarButton'
import MonthSwitcherBar from '../MonthSwitcherBar'
import TaskBar from '../TaskBar'
import { taskBarStates } from '../TaskBar/types'
import AbsencePlannerGridBody from './Body'
import CalendarRow from './CalendarRow'
import SmallScreenGradient from './CalendarRow/SmallScreenGradient'
import MonthScrollerContainer from './MonthScrollerContainer'
import GridBodyContainer from './MonthScrollerContainer/GridBodyContainer'
import TableBackgroundContainer from './TableBackgroundContainer/TableBackgroundContainer'
import TeamNameRows from './TeamNameRows'
import TopBoxBlock from './TopBoxBlock'
import { gridClasses } from './styles'
import { AbsencePlannerGridProps } from './types'

export const scrollerDivId = 'absence_planner_scroll_container'

const AbsencePlannerGrid: React.FC<AbsencePlannerGridProps> = ({ setTaskCount }) => {
  const { throttledRefreshHighSecSession } = useRefreshHighSecSession()
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { personIds } = useRolesViews()
  const filters = useFilter()
  const { shownTypes, allTeams, shownTeams, isMultiTenant, persons, loading: filtersLoading } = filters

  const scrollRef = useRef<HTMLDivElement>(null)
  const { checkIsScrolled, isScrolled, isAtBottom, hasOverflow } = useScrolledState(scrollRef)

  const {
    shiftsDateRange,
    loading,
    personData,
    userPerson,
    taskCountTotal,
    taskCounts,
    updateDateRange,
    reloadAbsenceRequest,
    renewPersonData,
  } = useAbsencePlannerData({ shownTypes, shownTeams })

  useEffect(() => {
    if (!setTaskCount) return

    setTaskCount(taskCountTotal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskCountTotal])

  const {
    baseDate,
    shownMonthsIndexes,
    shownMonthIndex,
    onNextMonthClick,
    onPreviousMonthClick,
    onTodayClick,
    onMonthScrollerScroll,
    onMonthSelect,
  } = useCalendarActions({
    filtersLoading,
    badgesLoading: loading,
    scrollRef,
    checkIsScrolled,
    updateDateRange,
  })
  const redesignFF = useFeatureFlag(FF_FAVUR_REDESIGN)
  const setTaskBarState = useTaskBarStateDispatchContext()
  const onMenuClick = useCallback(() => setTaskBarState(taskBarStates.filters), [setTaskBarState])
  const teams = useMemo(() => allTeams.filter((team) => shownTeams.includes(team.id)), [allTeams, shownTeams])

  return (
    <Box
      sx={gridClasses.gridContainer}
      onMouseOver={throttledRefreshHighSecSession()}
      onTouchStart={throttledRefreshHighSecSession()}
    >
      <FilterContext.Provider value={filters}>
        <AvatarSidebarStateProvider>
          <SmallScreenGradient />
          <TopBoxBlock isScrolled={isScrolled} />
          <TableBackgroundContainer>
            {redesignFF ? (
              <MonthSwitcher
                handleToday={onTodayClick}
                baseDate={baseDate}
                monthIndex={shownMonthsIndexes[shownMonthIndex]}
                onChange={onMonthSelect}
              />
            ) : (
              <MonthSwitcherBar
                baseDate={baseDate}
                shownMonthIndex={shownMonthsIndexes[shownMonthIndex]}
                toNextMonth={onNextMonthClick}
                toPrevMonth={onPreviousMonthClick}
                toToday={onTodayClick}
                isSmallScreen={isSmallScreen}
                onMonthSelect={onMonthSelect}
                onMenuClick={onMenuClick}
              />
            )}
          </TableBackgroundContainer>

          <MonthScrollerContainer ref={scrollRef} onScroll={onMonthScrollerScroll}>
            <AvatarSideBar
              teams={teams}
              taskCounts={taskCounts}
              loading={filtersLoading}
              persons={persons}
              firstPersonIds={personIds}
            />
            <TeamNameRows
              teams={teams}
              teamsLoading={filtersLoading}
              personIds={personIds}
              isMultiTenant={isMultiTenant}
            />
            <GridBodyContainer>
              <CalendarRow baseDate={baseDate} shownMonthsIndexes={shownMonthsIndexes} />
              <AbsencePlannerGridBody
                persons={persons}
                teams={teams}
                personData={personData}
                userPerson={userPerson}
                shiftsDateRange={shiftsDateRange}
                loading={loading}
                isSmallScreen={isSmallScreen}
                firstPersonIds={personIds}
                scrollContainerRef={scrollRef}
              />
            </GridBodyContainer>
          </MonthScrollerContainer>
          <Box sx={gridClasses.toggleBarButtonContainer}>
            <ToggleSideBarButton isSmallScreen={isSmallScreen} hasOverflow={hasOverflow} isAtBottom={isAtBottom} />
          </Box>
          {redesignFF ? (
            <RedesignThemeWrapper>
              <SideMenu taskCount={taskCountTotal} onClose={() => setTaskBarState(null)} />
            </RedesignThemeWrapper>
          ) : (
            <TaskBar
              taskCountTotal={taskCountTotal}
              refetchAll={reloadAbsenceRequest}
              refetchTask={(personId: string, dateFrom: string, dateTo: string) =>
                renewPersonData(personId, dateFrom, dateTo)
              }
            />
          )}
        </AvatarSidebarStateProvider>
      </FilterContext.Provider>
    </Box>
  )
}

export default AbsencePlannerGrid
