import React from 'react'
import { IconButton, useTheme } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import { FilterIcon } from '../../Icon'
import { HeaderBadge } from './styles'
import type { FilterButtonProps } from './types'

const FilterButton: React.FC<FilterButtonProps> = ({ isPrimaryButton = true, onClick }) => {
  const theme = useTheme()
  const { getChips } = useFilterContext()
  const chips = getChips()

  return (
    <HeaderBadge variant="dot" invisible={!chips.length} color="primary">
      <IconButton onClick={onClick} size="medium">
        <FilterIcon color={isPrimaryButton ? theme.palette.primary.main : theme.palette.text.primary} />
      </IconButton>
    </HeaderBadge>
  )
}

export default FilterButton
