import routes from 'services/RoutesProvider/routes'
import { CmsContentT, CmsFolderEntryT, CmsFolderT } from 'types'
import { FOLDER_ENTRY_TYPE } from 'types/cms'

export const getFolderLink = (folder: CmsFolderT, ownerId?: number) => {
  if (!ownerId) return `${routes.cmsContentIndex}?folderUuid=${folder.uuid}`
  return `${routes.cmsContentIndexOwner(ownerId)}?folderUuid=${folder.uuid}`
}

export const getFolderUuid = () => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const folderUuid = urlParams.get('folderUuid')
  return folderUuid ?? undefined
}

export const isContent = (entry: CmsFolderEntryT | CmsContentT) =>
  (entry as CmsFolderEntryT)?.type === FOLDER_ENTRY_TYPE.content || (entry as CmsContentT).contentType !== undefined
