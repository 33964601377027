const BASE_WAIT = 250
// Wait time with 8 call retries
// 125 + 250 + 500 + 1000 + 2000 + 4000 + 8000  + 16000 = 31_875ms
const MAX_CALL_RETRIES = 8
export const MAX_WAIT = 30_000

const waitForAttempt = (attempt: number) =>
  new Promise((resolve) => {
    const waitTime = Math.min(MAX_WAIT, BASE_WAIT * 2 ** (attempt - 1))
    setTimeout(resolve, waitTime)
  })

type RetryOperationT = {
  onError?: (err: unknown) => void
  onRetry?: (numRetry: number) => void
  onSuccess?: () => void
  maxRetries?: number
  retriesLeft?: number
}

export const retryOperation = async (operation: () => Promise<void>, options?: RetryOperationT): Promise<void> => {
  const maxRetries = options?.maxRetries ?? MAX_CALL_RETRIES
  const retriesLeft = options?.retriesLeft ?? maxRetries
  const onError = options?.onError ?? ((_error) => {})
  const onRetry = options?.onRetry ?? ((_num) => {})
  const onSuccess = options?.onSuccess ?? (() => {})

  try {
    await operation()
    onSuccess()
  } catch (err) {
    if (retriesLeft > 0) {
      onRetry(maxRetries - retriesLeft)
      await waitForAttempt(maxRetries - retriesLeft)
      await retryOperation(operation, {
        onRetry,
        onError,
        onSuccess,
        maxRetries: maxRetries,
        retriesLeft: retriesLeft - 1,
      })
    } else {
      onError(err)
    }
  }
}
