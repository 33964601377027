import React, { useCallback, useMemo, useState } from 'react'
import AppUnavailablePage from './Page'
import AppUnavailableContext, { AppUnavailableStatus } from './context'

const AppUnavailable: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [status, setStatus] = useState<AppUnavailableStatus>(AppUnavailableStatus.HIDE)

  const onRetry = useCallback(() => {
    setStatus(AppUnavailableStatus.HIDE)
    window.location.href = '/'
  }, [])

  const showPage = useMemo(() => status === AppUnavailableStatus.LOADING || status === AppUnavailableStatus.RETRY, [
    status,
  ])

  return (
    <AppUnavailableContext.Provider value={{ status, setStatus }}>
      {showPage ? <AppUnavailablePage onRetry={onRetry} loading={status === AppUnavailableStatus.LOADING} /> : children}
    </AppUnavailableContext.Provider>
  )
}

export default AppUnavailable
