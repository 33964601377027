import type { ShiftsByDateT } from 'components/ShiftsList/types'
import { shiftTypes } from 'constants/shift'
import { groupBy, sortBy } from 'lodash/fp'
import type { PlannedShiftT } from 'types'
import { dateToISOStringWithoutTime } from 'utils/date'

export const getShiftsByDay = (shifts: ShiftsByDateT[]): { [key: string]: PlannedShiftT[] } =>
  Object.entries(groupBy('date', shifts)).reduce((acc, [key, value]) => {
    const mapped = value.flatMap((date) => date.tenants.flatMap((tenant) => tenant.shifts)) as PlannedShiftT[]

    return { ...acc, [key]: mapped }
  }, {})

export const getWeekKey = (week: Date[]) =>
  `${dateToISOStringWithoutTime(week[0])}-${dateToISOStringWithoutTime(week[week.length - 1])}`

const prepareShifts = (shiftList: PlannedShiftT[]) => {
  const groupedByTenantAndDescription = Object.values(groupBy('personId', shiftList))
    .map((list) => Object.values(groupBy('shortDescription', list)).map((byDesc) => byDesc[0]))
    .flat()

  return sortBy(['personId', 'from', 'until'], groupedByTenantAndDescription)
}

const prepareAbsences = (absenceList: PlannedShiftT[]) => {
  const groupedByTenant = Object.values(groupBy('personId', absenceList)).map(
    (absenceGroup) => sortBy(['percent', 'from', 'until'], absenceGroup)[0],
  )

  return sortBy(['percent', 'from', 'until'], groupedByTenant)
}

export const prepareShowingShifts = (shiftList: PlannedShiftT[]) => {
  const { absences, shifts } = shiftList.reduce(
    (acc, shift) => {
      if (shift.type === shiftTypes.absence) {
        return { absences: [...acc.absences, shift], shifts: acc.shifts }
      }

      return { absences: acc.absences, shifts: [...acc.shifts, shift] }
    },
    { absences: [] as PlannedShiftT[], shifts: [] as PlannedShiftT[] },
  )

  return [...prepareShifts(shifts), ...prepareAbsences(absences)]
}
