// Deprecated - still used in old design
export enum DocumentStatuses {
  seen = 'seen',
  received = 'received',
  accepted = 'accepted',
  failed = 'failed',
  noFavurAccount = 'no_favur_account',
  claimInProcess = 'claim_in_process',
  claimAcceptedThisMonth = 'claim_accepted_this_month',
  claimAcceptedNextMonth = 'claim_accepted_next_month',
}

export enum DocumentTypes {
  monthlySheet = 'monthly_sheet',
  payslip = 'payslip',
  salaryStatement = 'salary_statement',
}

export const DOCUMENT_STATUS = {
  seen: 'seen',
  received: 'received',
  accepted: 'accepted',
  failed: 'failed',
  noFavurAccount: 'no_favur_account',
  claimInProcess: 'claim_in_process',
  claimAcceptedThisMonth: 'claim_accepted_this_month',
  claimAcceptedNextMonth: 'claim_accepted_next_month',
} as const

export const DOCUMENT_TYPES = {
  monthlySheet: 'monthly_sheet',
  payslip: 'payslip',
  salaryStatement: 'salary_statement',
} as const
