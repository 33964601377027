import React from 'react'
import {
  ACTION,
  ActionDispatchT,
  ClearPersonListActionT,
  ClearPersonListElementActionT,
  ConfirmPersonsListActionT,
  FilterPageStateT,
  OpenPersonsListActionT,
  ReducerActionT,
  SetPersonsListSelectedActionT,
} from 'components/Filter/types'
import { changeValue, clearValue } from 'components/Filter/utils'

export const actions = (filterPageState: FilterPageStateT, dispatch: React.Dispatch<ReducerActionT>) => ({
  getPersonsListSelected: () => filterPageState.personsListSelected,
  getCurrentSelectedPersons: () => filterPageState.currentSelectedPersons || [],
  openPersonsList: (filterName: string) => {
    dispatch({ type: ACTION.OPEN_PERSONS_LIST, value: { filterName } })
  },
  closePersonsList: () => {
    dispatch({ type: ACTION.CLOSE_PERSONS_LIST })
  },
  confirmPersonsList: (filterName: string, selectedPersons: number[]) => {
    dispatch({ type: ACTION.CONFIRM_PERSONS_LIST, value: { filterName, selectedPersons } })
    dispatch({ type: ACTION.SET_SCROLL_TO, value: { destination: filterName } })
  },
  cancelPersonsList: (filterName: string) => {
    dispatch({ type: ACTION.CANCEL_PERSONS_LIST })
    dispatch({ type: ACTION.SET_SCROLL_TO, value: { destination: filterName } })
  },
  clearPersonList: (filterName: string) => {
    dispatch({ type: ACTION.CLEAR_PERSON_LIST, value: { filterName } })
  },
  clearPersonListElement: (filterName: string, personId: number) => {
    dispatch({ type: ACTION.CLEAR_PERSON_LIST_ELEMENT, value: { filterName, personId } })
  },
  setCurrentSelectedPersons: (selectedPersons: number[]) => {
    dispatch({ type: ACTION.SET_CURRENT_SELECTED_PERSONS, value: { selectedPersons } })
  },
})

export const reducers: ActionDispatchT = {
  [ACTION.OPEN_PERSONS_LIST]: (state, action) => {
    const actionValue = action.value as OpenPersonsListActionT
    const { filterName } = actionValue
    return {
      ...state,
      personsListSelected: filterName,
    }
  },
  [ACTION.CLOSE_PERSONS_LIST]: (state) => {
    return {
      ...state,
      personsListSelected: undefined,
    }
  },
  [ACTION.CONFIRM_PERSONS_LIST]: (state, action) => {
    const actionValue = action.value as ConfirmPersonsListActionT
    const { filterName, selectedPersons } = actionValue

    // You can only confirm if there are persons selected, so "selectedPersons"
    // will never contain 0 elements.
    const updatedFilters = {
      ...state.dirtyFilters,
      [filterName]: selectedPersons,
    }
    return {
      ...state,
      personsListSelected: undefined,
      dirtyFilters: updatedFilters,
    }
  },
  [ACTION.CANCEL_PERSONS_LIST]: (state, _action) => {
    return {
      ...state,
      personsListSelected: undefined,
    }
  },
  [ACTION.CLEAR_PERSON_LIST]: (state, action) => {
    const actionValue = action.value as ClearPersonListActionT
    const { filterName } = actionValue

    return {
      ...state,
      dirtyFilters: clearValue(state.dirtyFilters, filterName),
      currentSelectedPersons: [],
    }
  },
  [ACTION.CLEAR_PERSON_LIST_ELEMENT]: (state, action) => {
    const actionValue = action.value as ClearPersonListElementActionT
    const { filterName, personId } = actionValue
    const currentSelectedPersons = state.currentSelectedPersons
    const actualList = state.dirtyFilters[filterName] as number[]
    const updatedList = actualList.filter((pId) => pId !== personId)
    const updatedFilters =
      updatedList.length === 0
        ? clearValue(state.dirtyFilters, filterName)
        : changeValue(state.dirtyFilters, filterName, updatedList)
    const updatedCurrentSelectedPersons = currentSelectedPersons?.filter((id) => id !== personId)

    return {
      ...state,
      dirtyFilters: updatedFilters,
      currentSelectedPersons: updatedCurrentSelectedPersons,
    }
  },
  [ACTION.SET_CURRENT_SELECTED_PERSONS]: (state, action) => {
    const actionValue = action.value as SetPersonsListSelectedActionT
    const { selectedPersons } = actionValue

    return {
      ...state,
      currentSelectedPersons: selectedPersons,
    }
  },
}
