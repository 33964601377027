import React, { useEffect, useRef, useState } from 'react'
import { Button, Typography, IconButton, TextField, Box } from '@mui/material'
import Checkbox from 'components/Checkbox'
import Timer from 'components/Timer'
import { FF_SECURITY_TIMEOUT_FE__BE__ } from 'constants/featureFlags'
import { SMS_TIMEOUT, SMS_CODE_LENGTH } from 'constants/sms'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { ArrowLeftM } from 'icons'
import { IValidateComponentProps } from 'pages/EntryPoint/LoginPage/types'
import { classes as entryPointClasses } from 'pages/EntryPoint/styles'
import { isNative } from 'utils/platform'
import { escapeNonNumericalKeys } from 'utils/string'
import { classes } from '../../../LoginPage/styles'
import ResendSMSDialog from '../ResendSMSDialog'

export const testIds = {
  backArrow: 'back-arrow',
  form: 'autofill-sms-form',
  input: 'autofill-sms-input',
  noSms: 'no-sms-received',
  submitButton: 'submit-phone',
}

const ValidateAutofillSmsCode: React.FC<IValidateComponentProps> = ({
  backButton,
  description,
  onConfirm,
  title,
  withRememberMe = false,
}) => {
  const { t } = useFavurTranslation()
  const timeout = useFeatureFlag(FF_SECURITY_TIMEOUT_FE__BE__) ? SMS_TIMEOUT.short : SMS_TIMEOUT.long
  const [timerReset, resetTimer] = React.useReducer((reset) => !reset, false)
  const [validationCode, setValidationCode] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [rememberMe, setRememberMe] = useState(isNative())
  const showRememberMe = withRememberMe && !isNative()

  const smsCodeInputRef = useRef<HTMLInputElement>()
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCode = event.target.value

    if (newCode && newCode.length <= SMS_CODE_LENGTH) {
      setValidationCode(newCode)
    }
  }

  useEffect(() => {
    if (validationCode.length === SMS_CODE_LENGTH) {
      onConfirm(validationCode, rememberMe)
      setValidationCode('')
    }
  }, [onConfirm, validationCode, rememberMe])

  return (
    <Box
      sx={entryPointClasses.autoFillForm}
      component="form"
      data-testid={testIds.form}
      onSubmit={(formEvent) => {
        formEvent.preventDefault()
        onConfirm(validationCode, rememberMe)
        setValidationCode('')
      }}
    >
      <Box sx={classes.fullPageContainer}>
        <Box>
          <Box sx={classes.heading}>
            <Box sx={classes.pinConfirmTitleContainer}>
              <Box sx={classes.titleGutter}>
                <IconButton
                  aria-label={testIds.backArrow}
                  sx={classes.backButton}
                  data-testid={testIds.backArrow}
                  onClick={backButton}
                  size="large"
                >
                  <ArrowLeftM />
                </IconButton>
              </Box>
              <Typography variant="h2" color="primary" align="center">
                {title || t('login.start.content.title')}
              </Typography>
              <Box sx={classes.titleGutter} />
            </Box>
            <Typography component="p" align="center">
              {description || t('login.validate.content.description')}
            </Typography>
          </Box>
          <Box sx={classes.pinDisplayContainer}>
            <TextField
              variant="standard"
              autoComplete="one-time-code"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              sx={classes.smsCodeInput}
              color="primary"
              inputRef={smsCodeInputRef}
              inputMode="numeric"
              label={t('login.validate.content.smsCode')}
              name={t('login.validate.content.smsCode')}
              onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
              onKeyPress={escapeNonNumericalKeys}
              slotProps={{
                htmlInput: { 'data-testid': testIds.input, inputMode: 'numeric' },
              }}
            />
            <Timer timeout={timeout} timerReset={timerReset} />
            <ResendSMSDialog
              closeDialog={() => {
                setDialogOpen(false)
                smsCodeInputRef.current?.focus()
              }}
              goBackAStep={backButton}
              isOpen={dialogOpen}
              resetTimer={resetTimer}
            />
            <Button
              sx={classes.resendSMSDialogButton}
              data-testid={testIds.noSms}
              onClick={() => setDialogOpen(true)}
              size="small"
            >
              {t('login.validate.button.noCode')}
            </Button>
          </Box>
        </Box>
        <Box sx={classes.numpadContainer}>
          {showRememberMe && (
            <Checkbox
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
              label={t('login.validate.content.rememberMe')}
            />
          )}
          <Button
            sx={classes.validationButton}
            color="secondary"
            data-testid={testIds.submitButton}
            size="large"
            type="submit"
            variant="contained"
          >
            {t('login.validate.content.callToAction')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ValidateAutofillSmsCode
