import React from 'react'
import { ICON_SIZE, ICON_VIEWBOX } from '../Icon/constants'
import SvgWrapper from '../SvgWrapper'
import type { IllustrationProps } from './types'

const ErrorIllustration: React.FC<IllustrationProps> = ({
  viewBox = ICON_VIEWBOX.large,
  height = ICON_SIZE.large,
  width = ICON_SIZE.large,
}) => (
  <SvgWrapper viewBox={viewBox} height={height} width={width}>
    <path
      fill="#FF79AC"
      d="m28.75 19.375-.625-6.875c0-.516-1.886-.084-2.319-.449-.433-.364-1.02-.57-1.632-.57H13.79c-.612 0-1.2.206-1.632.57-.433.365-.676.86-.676 1.375v6.806h4.615l7.028-3.357 5.625 2.5Z"
    />
    <path
      fill="#fff"
      d="M28.558 30.232v1.875c-.002.497-.246.973-.678 1.324-.433.351-1.018.55-1.63.55H13.79c-.612 0-1.198-.199-1.63-.55-.433-.351-.676-.827-.678-1.324v-1.875h17.076Z"
    />
    <path fill="#FF79AC" d="M28.75 23.75V30h-17.5v-5.75h5.385l7.115-3 5 2.5Z" />
    <path
      fill="#FFF2F6"
      d="m28.75 6.018-.64 8.567L25 17.5l-1.966-.39-6.937 3.122h-4.615V6.335c.002-.52.245-1.017.678-1.384.432-.367 1.018-.574 1.63-.576l12.705-.183c.612.002 1.198.209 1.63.576.432.367.623.731.625 1.25ZM22.5 22.5l-10.625 8.125-.393-6.29h4.545l5.564-2.215.909.38Z"
    />
    <path
      fill="#28324D"
      fillRule="evenodd"
      d="M11.56 4.01a2.828 2.828 0 0 1 1.94-.76h12.985c.722 0 1.42.27 1.939.76.52.49.818 1.163.818 1.87v13.588a.5.5 0 0 1-.713.452l-5.439-2.566-7.133 3.105a.5.5 0 0 1-.2.041h-4.515a.5.5 0 0 1-.5-.5V5.88c0-.707.298-1.38.818-1.87Zm1.94.24c-.476 0-.926.178-1.254.487a1.57 1.57 0 0 0-.504 1.143V19.5h3.911l7.246-3.153a.5.5 0 0 1 .413.006l4.93 2.326v-12.8a1.57 1.57 0 0 0-.504-1.142 1.828 1.828 0 0 0-1.253-.487H13.5Zm4 8.75a2.681 2.681 0 0 1 2.23 1.192.5.5 0 0 0 .831-.555A3.68 3.68 0 0 0 17.501 12a.5.5 0 1 0 0 1Zm5.938-3.625a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.875Zm-5.313-.938a.937.937 0 1 1-1.875 0 .937.937 0 0 1 1.875 0ZM6.604 15.89a.5.5 0 1 0-.708.707l1.997 1.997a.5.5 0 1 0 .707-.708l-1.996-1.997Zm-.354 4.845a.5.5 0 0 0 0 1h2.496a.5.5 0 1 0 0-1H6.25Zm2.35 3.142a.5.5 0 0 1 0 .707L6.604 26.58a.5.5 0 0 1-.708-.707l1.997-1.997a.5.5 0 0 1 .707 0Zm22.65-2.142h2.496a.5.5 0 1 0 0-1H31.25a.5.5 0 0 0 0 1Zm2.35-5.846a.5.5 0 0 1 0 .708l-1.996 1.997a.5.5 0 0 1-.708-.708l1.997-1.997a.5.5 0 0 1 .707 0Zm0 9.985-1.996-1.997a.5.5 0 0 0-.708.707l1.997 1.997a.5.5 0 0 0 .707-.707Zm-9.854-5.075a.5.5 0 0 0-.39-.015L15.672 23.7H11.25a.5.5 0 0 0-.5.5v10.083c0 .678.31 1.312.833 1.768.52.454 1.214.699 1.925.699h12.985c.71 0 1.404-.245 1.924-.7.523-.455.833-1.089.833-1.767V23.709a.5.5 0 0 0-.287-.453l-5.217-2.458ZM28.25 29.5v-5.474l-4.738-2.233-7.57 2.875a.5.5 0 0 1-.177.032H11.75v4.8h16.5Zm-16.5 1h16.5v3.783c0 .365-.166.732-.49 1.014a1.938 1.938 0 0 1-1.268.453H13.508c-.486 0-.941-.17-1.267-.453-.324-.282-.49-.649-.49-1.014V30.5Z"
      clipRule="evenodd"
    />
  </SvgWrapper>
)

export default ErrorIllustration
