import React from 'react'
import { DialogActions, DialogTitle, Typography } from '@mui/material'
import { DIALOG_SIZES } from './constants'
import { MuiDialog, MuiDialogContent } from './styles'
import type { IDialogProps } from './types'

const Dialog: React.FC<IDialogProps> = ({
  children,
  title,
  actions,
  testId,
  onClose,
  noPadding = false,
  size = DIALOG_SIZES.large,
  addMinWidth,
  ...props
}) => (
  <MuiDialog
    addMinWidth={addMinWidth}
    width={size.width}
    height={size.height}
    disableEscapeKeyDown
    onClose={(event, reason) => {
      if (onClose && reason !== 'backdropClick') {
        onClose(event, reason)
      }
    }}
    data-testid={testId ?? 'dialog'}
    {...props}
  >
    {title && (
      <DialogTitle>
        <Typography variant="subtitle1" role="heading" data-testid="dialog-title-text" component="span">
          {title}
        </Typography>
      </DialogTitle>
    )}
    <MuiDialogContent noPadding={noPadding}>{children}</MuiDialogContent>
    {actions && <DialogActions>{actions}</DialogActions>}
  </MuiDialog>
)

export default Dialog
