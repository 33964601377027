import React, { memo } from 'react'
import { Box } from '@mui/material'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { CellBox } from 'redesign/components/atoms/Cell/styles'
import RedesignThemeWrapper from 'redesign/components/atoms/RedesignThemeWrapper'
import { TeamRow as RedesignTeamRow } from 'redesign/components/atoms/TeamRow'
import { gridClasses } from '../styles'
import { teamNameRowClasses } from './styles'

interface IPlaceHolderRowProps {
  shownDays: string[]
  teamId: string
}

const PlaceHolderRow: React.FC<IPlaceHolderRowProps> = ({ shownDays, teamId }) => {
  const redesignFF = useFeatureFlag(FF_FAVUR_REDESIGN)

  if (redesignFF) {
    return (
      <RedesignThemeWrapper>
        <RedesignTeamRow isPlaceHolder>
          {shownDays.map((day) => (
            <CellBox key={`${teamId}_placeholder_${day}`} />
          ))}
        </RedesignTeamRow>
      </RedesignThemeWrapper>
    )
  }

  return (
    <Box sx={[gridClasses.stickyLeft, gridClasses.backgroundTeamName]}>
      {shownDays.map((day) => (
        <Box key={`${teamId}_placeholder_${day}`} sx={teamNameRowClasses.placeholderRowCell} />
      ))}
    </Box>
  )
}

export default memo(PlaceHolderRow)
