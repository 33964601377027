import React from 'react'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import AnnouncementList from './AnnouncementsList'
import PageHeader from './PageHeader'

const AnnouncementsPage: React.FC = () => {
  const { t } = useFavurTranslation()

  return (
    <Page header={<PageHeader title={t('page.announcements.header.title')} />} showHeader={false}>
      <AnnouncementList />
    </Page>
  )
}
export default AnnouncementsPage
