import React from 'react'
import ShiftUpdateDetailDay from 'redesign/components/organisms/ShiftUpdateDetailDay'
import { Container } from './styles'
import type { ShiftUpdateDetailsProps } from './types'

const ShiftUpdateDetailsTemplate: React.FC<ShiftUpdateDetailsProps> = ({
  updatedDays,
  shiftsUpdatedByDate,
  shiftsUpdatedHistoryByDate,
}) => (
  <Container>
    {updatedDays?.map((updatedDay) => (
      <ShiftUpdateDetailDay
        key={`updated-day-${updatedDay}`}
        day={updatedDay}
        shiftsUpdated={shiftsUpdatedByDate[updatedDay] ?? []}
        shiftsUpdatedHistory={shiftsUpdatedHistoryByDate[updatedDay] ?? []}
      />
    ))}
  </Container>
)

export default ShiftUpdateDetailsTemplate
