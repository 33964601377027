import { css, styled, Typography } from '@mui/material'

export const Body = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasTopPaddingBody' && prop !== 'hasBody',
})<{ hasTopPaddingBody?: boolean; hasBody?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme, hasTopPaddingBody }) => theme.spacing(hasTopPaddingBody ? 2 : 0, 2, 2, 2)};
  gap: ${({ theme }) => theme.spacing(1)};
`

export const EmptyBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasTopPaddingBody' && prop !== 'hasBody',
})<{ hasTopPaddingBody?: boolean; hasBody?: boolean }>`
  height: 0;
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`

export const Header = styled('div')`
  padding: ${({ theme }) => theme.spacing(1, 1, 0, 1)}; // Note: right padding is less to accomodate the alert dot
`
export const BodyText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'overflowBodyText',
})<{ overflowBodyText?: boolean }>`
  ${({ overflowBodyText }) =>
    overflowBodyText &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      justify-content: start;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      line-clamp: 1;
      -webkit-line-clamp: 1;
    `}
`
