import React, { memo, useMemo } from 'react'
import { Box } from '@mui/material'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import { addMonths, eachDayOfInterval, endOfMonth, startOfMonth } from 'date-fns'
import useFeatureFlag from 'hooks/useFeatureFlag'
import RedesignThemeWrapper from 'redesign/components/atoms/RedesignThemeWrapper'
import RedesignMonthlyBar from 'redesign/components/organisms/MonthlyBar'
import { getFormattedFromDate } from 'utils/date'
import MonthlyBarDay from './MonthlyBarDay'
import { monthlyBarClasses } from './styles'

interface IMonthlyBarProps {
  baseDate: Date
  index: number
  selectedTaskUuid: string | null
  selectedDays: Date[]
}

const MonthlyBar: React.FC<IMonthlyBarProps> = ({ baseDate, index, selectedDays, selectedTaskUuid }) => {
  const redesignFF = useFeatureFlag(FF_FAVUR_REDESIGN)
  const monthDates = useMemo(() => {
    const integerIndex = Math.round(index)
    const start = startOfMonth(addMonths(baseDate, integerIndex))
    const end = endOfMonth(start)
    return eachDayOfInterval({ start, end })
  }, [baseDate, index])

  if (redesignFF) {
    return (
      <RedesignThemeWrapper>
        <RedesignMonthlyBar monthDates={monthDates} selectedTaskUuid={selectedTaskUuid} selectedDays={selectedDays} />
      </RedesignThemeWrapper>
    )
  }

  return (
    <Box sx={[monthlyBarClasses.container]}>
      {monthDates.map((date) => (
        <MonthlyBarDay
          date={date}
          selectedTaskUuid={selectedTaskUuid}
          selectedDays={selectedDays}
          key={getFormattedFromDate(date)}
        />
      ))}
    </Box>
  )
}

export default memo(MonthlyBar)
