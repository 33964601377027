import React, { useEffect, useState } from 'react'
import { TextField, Typography, Box, Button } from '@mui/material'
import AddressForm from 'components/Forms/components/AddressBlockForm'
import FormsButtons from 'components/Forms/components/Buttons'
import PhoneNumberInput from 'components/Forms/components/PhoneNumberInput'
import { classes, addressClasses } from 'components/Forms/styles'
import type { FormHandlersT } from 'components/Forms/types'
import Grid from 'components/Grid'
import { SimpleLoading } from 'components/LoadingIcon'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePhoneNumber from 'hooks/usePhoneNumber'
import useSessionContext from 'hooks/useSessionContext'
import { PlusS } from 'icons'
import { isEqual } from 'lodash/fp'
import { useFieldArray } from 'react-hook-form'
import SendShareUserDataCard from '../components/SendShareUserDataCard'
import { useFormsContext } from '../context'
import type { DataFormT, IDefaultFormProps } from '../types'
import { addressInputKeys } from './constants'
import { addAddress, hasAddressType } from './helpers'
import { ContactFormValuesT, addressTypes } from './types'

const addressRenderingOrder = [addressTypes.main, addressTypes.foreign, addressTypes.emergency]

const ContactData: React.FC<IDefaultFormProps> = ({
  changeTabTrigger,
  onCancel,
  setChangeTabTrigger,
  setIsFormDirty,
  accessPoint,
  tabChangeCallback,
}) => {
  const { t } = useFavurTranslation()
  const { phone: phoneFromSession } = useSessionContext()
  const [hasForeignAddress, setHasForeignAddress] = useState(false)
  const [hasEmergencyAddress, setHasEmergencyAddress] = useState(false)
  const { phone, setPhone } = usePhoneNumber(phoneFromSession ?? '')
  const { getContactDataForm } = useFormsContext()

  const { favurForm, formHandlers, formReady, defaultValues } = getContactDataForm() as DataFormT<
    ContactFormValuesT,
    ContactFormValuesT
  >
  const { fields: addressArray, append, remove } = useFieldArray({
    control: favurForm.control,
    name: 'addresses',
  })

  useEffect(() => {
    if (favurForm.formState.isSubmitted && isEqual(favurForm.formState.errors, {})) {
      tabChangeCallback && tabChangeCallback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favurForm.formState.isSubmitted])

  useEffect(() => {
    if (changeTabTrigger && setChangeTabTrigger) {
      favurForm.handleSubmit()
      setChangeTabTrigger(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTabTrigger])

  useEffect(() => {
    if (defaultValues) {
      if (defaultValues?.addresses.length === 0) {
        addAddress(addressTypes.main, append)
      } else {
        setHasForeignAddress(hasAddressType(defaultValues.addresses, addressTypes.foreign))
        setHasEmergencyAddress(hasAddressType(defaultValues.addresses, addressTypes.emergency))
      }
    }
  }, [defaultValues, setHasForeignAddress, setHasEmergencyAddress, append])

  useEffect(() => {
    if (setIsFormDirty) {
      setIsFormDirty(favurForm.formState.isDirty)
    }
  }, [favurForm.formState.isDirty, setIsFormDirty])

  useEffect(() => {
    favurForm.reset({}, { keepValues: true, keepDirty: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!formReady) {
    return <SimpleLoading />
  }

  return (
    <>
      <Grid container>
        <Grid sx={classes.gridForFormField} col={4}>
          <TextField
            variant="standard"
            fullWidth
            {...formHandlers('email')}
            slotProps={{
              htmlInput: { 'data-testid': 'contact-address_email' },
            }}
          />
        </Grid>
        <Grid sx={classes.gridForFormField} col={4}>
          <PhoneNumberInput
            {...formHandlers('phoneNumber')}
            disabled
            phone={phone}
            setPhone={setPhone}
            fullWidthNoMargin
          />
        </Grid>
        {addressRenderingOrder.map((addressType) => {
          const address = addressArray.find((currentAddress) => currentAddress.type === addressType)
          return (
            address && (
              <AddressForm
                key={address.type}
                formHandlers={formHandlers as FormHandlersT}
                inputKeys={addressInputKeys}
                addressArray={addressArray}
                addressType={address.type}
                setHasForeignAddress={setHasForeignAddress}
                setHasEmergencyAddress={setHasEmergencyAddress}
                remove={remove}
                setValue={favurForm.setValue}
              />
            )
          )
        })}
      </Grid>
      {(!hasForeignAddress || !hasEmergencyAddress) && (
        <Box sx={classes.additionalAddressesBox}>
          <Box mb={0.75}>
            <Typography variant="body1">{t('personalData.additionalAddresses.title')}</Typography>
          </Box>
          {!hasForeignAddress && (
            <Button
              sx={classes.pinkDeleteButton}
              onClick={() => {
                addAddress(addressTypes.foreign, append)
                setHasForeignAddress(true)
              }}
              startIcon={<PlusS />}
              data-testid="contact_add-foreign-address"
            >
              {t('personalData.foreignAddress.add')}
            </Button>
          )}
          {!hasEmergencyAddress && (
            <Button
              sx={classes.pinkAddButton}
              onClick={() => {
                addAddress(addressTypes.emergency, append)
                setHasEmergencyAddress(true)
              }}
              startIcon={<PlusS />}
              data-testid="contact_add-emergency-address"
            >
              {t('personalData.emergencyContact.add')}
            </Button>
          )}
        </Box>
      )}
      {accessPoint === 'userSettings' && <SendShareUserDataCard />}
      <Box sx={[...(hasForeignAddress && hasEmergencyAddress ? [addressClasses.formButtonsSpacing] : [])]}>
        {accessPoint !== 'shareUserDataWorkflow' && (
          <FormsButtons onCancel={onCancel} onSubmit={favurForm.handleSubmit} accessPoint={accessPoint} />
        )}
      </Box>
    </>
  )
}

export default ContactData
