import { ROLES_MANAGE_TEAMS, ROLES_TENANT_SETTINGS, ROLE_VIEWS } from 'constants/roles'
import { modules } from 'shared/constants'
import { AuthPersonIdAndPermissions, PersonRole, ROLES, RoleT, TenantNamesT } from 'types'

export const hasNoOfficeRoles = (tenantsAndPermissions: TenantNamesT[]): boolean => {
  return tenantsAndPermissions.every((tp) => !tp.permissions.officeView)
}

export const hasOfficeView = (
  personIdsAndPermissions: AuthPersonIdAndPermissions[],
  roleViewFF?: string | boolean | void,
): boolean => {
  if (roleViewFF === ROLE_VIEWS.office) {
    return true
  }
  return personIdsAndPermissions.some((tp) => tp.permissions.officeView)
}

export const hasFrontlinerView = (
  personIdsAndPermissions: AuthPersonIdAndPermissions[],
  roleViewFF?: string | boolean | void,
): boolean => {
  if (roleViewFF === ROLE_VIEWS.frontliner) {
    return true
  }

  return personIdsAndPermissions.length > 0
}

export const personRolesCanManageTeams = (personRoles: PersonRole[] | undefined) =>
  Boolean(personRoles && personRoles.some((role) => ROLES_MANAGE_TEAMS.includes(role.role as RoleT)))

export const personRolesCanManageTenants = (personRoles: PersonRole[] | undefined) =>
  Boolean(personRoles && personRoles.some((role) => ROLES_TENANT_SETTINGS.includes(role.role as RoleT)))

export const userCanManageTeams = (
  personIdsAndPermissions: AuthPersonIdAndPermissions[] | undefined,
  hasAccessToModule: (module: string) => boolean,
): boolean =>
  Boolean(
    personIdsAndPermissions &&
      personIdsAndPermissions.some(
        (person) => personRolesCanManageTeams(person.roles) && hasAccessToModule(modules.teams),
      ),
  )

export const userCanManageTenants = (
  personIdsAndPermissions: AuthPersonIdAndPermissions[] | undefined,
  hasAccessToModule: (module: string) => boolean,
): boolean =>
  Boolean(
    personIdsAndPermissions &&
      personIdsAndPermissions.some(
        (person) => personRolesCanManageTenants(person.roles) && hasAccessToModule(modules.businessCustomizations),
      ),
  )

export const userCanManageGlobalTeams = (persons: AuthPersonIdAndPermissions[] | undefined): boolean => {
  if (!persons) {
    return false
  }

  return persons.some((person) => person.roles?.some((role) => role.role === ROLES.manageGlobalTeams))
}

export const userCanManageAbsences = (personIdsAndPermissions: AuthPersonIdAndPermissions[] | undefined) =>
  Boolean(personIdsAndPermissions && personIdsAndPermissions.some((person) => person.permissions.workflowAbsences))

export const userCanManageTenantTeams = (
  persons: AuthPersonIdAndPermissions[] | undefined,
  personIds: number[],
): boolean => {
  if (!persons) {
    return false
  }

  const filteredPersons = persons.filter((person) => personIds.includes(person.id))

  return filteredPersons.some((person) => person.roles?.some((role) => role.role === ROLES.manageTeams))
}
