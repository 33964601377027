import React from 'react'
import ContentFooter from 'redesign/components/molecules/ContentFooter'
import HeaderBlock from 'redesign/components/molecules/HeaderBlock'
import { Container } from './styles'
import type { TaskReferenceProps } from './types'

const TaskReference: React.FC<TaskReferenceProps> = ({ testId, header, footerProps, onTaskClick }) => {
  return (
    <Container data-testId={testId} onClick={onTaskClick}>
      <HeaderBlock showChevron {...header} />
      <ContentFooter {...footerProps} />
    </Container>
  )
}

export default TaskReference
