import React from 'react'
import { VirtualizeSwipeableViews } from 'redesign/services/SwipeableView/VirtualizeSwipeableViews'
import CalendarRowWeekDay from './CalendarRowWeekDay'
import type { SwipeableCalendarRowWeekProps } from './types'
import { calculateDifferentWeekMonday } from './utils/calculateDifferentWeekMonday'

const SwipeableCalendarRowWeek: React.FC<SwipeableCalendarRowWeekProps> = ({
  selectedDay,
  handleDayClick,
  handleChangeWeek,
  weekIndex,
  weeklyShifts,
  showTopCalendarShifts = true,
  showDayNumbers,
  isNative,
  canShowShifts,
}) => (
  <VirtualizeSwipeableViews
    index={weekIndex}
    onChangeIndex={(newIndex, indexLatest) => {
      handleChangeWeek(newIndex < indexLatest ? -1 : 1)
    }}
    overscanSlideAfter={1}
    overscanSlideBefore={1}
    enableMouseEvents={isNative}
    slideRenderer={({ index }) => (
      <CalendarRowWeekDay
        key={index}
        selectedDay={calculateDifferentWeekMonday({
          selectedDay,
          slideWeekIndex: index,
          currentWeekIndex: weekIndex,
        })}
        showSelectedDay={index === weekIndex}
        weeklyShifts={weeklyShifts}
        handleDayClick={handleDayClick}
        showTopCalendarShifts={index === weekIndex && showTopCalendarShifts}
        showDayNumbers={showDayNumbers}
        canShowShifts={canShowShifts}
      />
    )}
  />
)

export default SwipeableCalendarRowWeek
