import { Button, css, styled } from '@mui/material'

export const CalendarDayButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: ${({ theme }) => theme.spacing(1, 0)};
  gap: ${({ theme }) => theme.spacing(0.5)};
  box-sizing: border-box;

  :hover {
    background-color: ${({ theme }) => theme.palette.common.hoverCard};
  }
`

export const DayFrame = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0.25)};
  box-sizing: border-box;
`

export const CalendarDayContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCurrentMonth',
})<{
  isCurrentMonth: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  ${({ isCurrentMonth }) =>
    !isCurrentMonth &&
    css`
      opacity: 0.5;
    `}
`
