import { useCallback, useMemo } from 'react'
import { getAreThereShifts, getCanShowEffectiveTimes } from 'components/ShiftsList/components/ShiftCard/utils'
import { findEffectiveShiftListByTenant, getCurrentTeamPlan } from 'components/ShiftsList/components/utils'
import { ShiftUpdateStatus } from 'components/ShiftsList/types'
import { groupBy } from 'lodash/fp'
import type { PlannedShiftT } from 'types'
import { groupShiftsByShortDescription } from 'utils/shifts'
import type { UseShiftPlanContent, UseShiftPlanContentProps } from './types'

const useShiftPlanContent = ({
  sortedTenantIds,
  tenantList,
  groupedTenants,
  getAbsenceRequestsByTenantId,
  effectiveShifts,
  effectiveShiftsLoading,
  shiftUpdates = [],
  getEventsForTenantShiftCard,
  isPast,
  teamPlanData,
  date,
}: UseShiftPlanContentProps) => {
  const getUpdateTask = useCallback(
    (shiftGroup: PlannedShiftT[]) => {
      const taskIds = shiftGroup.map((shift) => `${shift.updateTaskId}`)
      return shiftUpdates.find((task) => task.id && taskIds.includes(`${task.id}`))
    },
    [shiftUpdates],
  )

  return useMemo(
    () =>
      sortedTenantIds.reduce((acc, tenantId) => {
        const tenant = tenantList[tenantId]
        const shiftData = groupedTenants.get(tenantId) ?? []
        const absenceRequests = getAbsenceRequestsByTenantId(tenantId)
        const groupedShifts = shiftData.map((costcenter) => ({
          costCenterName: costcenter.costCenterName,
          costCenterUuid: costcenter.costCenterUuid,
          shifts: groupShiftsByShortDescription(costcenter.shifts as PlannedShiftT[]),
        }))
        const events = getEventsForTenantShiftCard({ tenantShifts: shiftData })
        const showEvents = Boolean(events.length)
        const teamPlan = getCurrentTeamPlan(false, teamPlanData, date, tenantId)
        const areThereShifts = getAreThereShifts(groupedShifts)
        const areThereEffectiveShifts = effectiveShifts !== undefined && effectiveShifts.length > 0
        const allShifts = shiftData.flatMap((costcenter) => costcenter.shifts as PlannedShiftT[])
        const updateTask = getUpdateTask(allShifts)
        const showShiftUpdateAlert = Boolean(!isPast && updateTask && !updateTask.taskData.acknowledgedAt)
        const shiftUpdateDaysCount = updateTask
          ? Object.keys(groupBy('date', updateTask.shiftsUpdatedHistory ?? [])).length
          : 0

        const hasAcknowledgedShiftUpdate =
          updateTask === undefined || updateTask.status === ShiftUpdateStatus.reviewFrontlineSeen

        const canShowEffectiveTimes = getCanShowEffectiveTimes(
          isPast,
          areThereShifts,
          areThereEffectiveShifts,
          hasAcknowledgedShiftUpdate,
          false,
        )
        const filteredEffectiveShifts = findEffectiveShiftListByTenant(
          effectiveShifts,
          effectiveShiftsLoading,
          tenantId,
        )

        if (shiftData.length || absenceRequests.length || teamPlan?.length) {
          return [
            ...acc,
            {
              tenant,
              shiftData,
              absenceRequests,
              groupedShifts,
              filteredEffectiveShifts,
              canShowEffectiveTimes,
              events,
              showEvents,
              showShiftUpdateAlert,
              updateTask,
              shiftUpdateDaysCount,
              teamPlan: teamPlan,
            },
          ]
        }

        return acc
      }, [] as UseShiftPlanContent[]),
    [
      sortedTenantIds,
      tenantList,
      groupedTenants,
      getAbsenceRequestsByTenantId,
      getEventsForTenantShiftCard,
      teamPlanData,
      date,
      effectiveShifts,
      getUpdateTask,
      isPast,
      effectiveShiftsLoading,
    ],
  )
}

export default useShiftPlanContent
