export const MENU_CONTAINER_MOTION = {
  hidden: {
    opacity: 0,
    x: -100,
    transition: {
      staggerChildren: 0,
      when: 'beforeChildren',
      ease: 'easeOut',
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { staggerChildren: 0.3, ease: 'easeOut' },
  },
}

export const GROUP_HIDDEN_MOTION = { opacity: 0, x: -100 }

export const PRIMARY_GROUP_MOTION = {
  hidden: GROUP_HIDDEN_MOTION,
  visible: { opacity: 1, x: 0 },
}

export const DIVIDER_1_MOTION = {
  hidden: { opacity: 0, x: -200 },
  visible: { opacity: 1, x: 0, transition: { delay: 0.4 } },
}

export const SECONDARY_GROUP_MOTION = {
  hidden: GROUP_HIDDEN_MOTION,
  visible: { opacity: 1, x: 0, transition: { delay: 0.5 } },
}
export const DIVIDER_2_MOTION = {
  hidden: { opacity: 0, x: -200 },
  visible: { opacity: 1, x: 0, transition: { delay: 0.525 } },
}

export const TERTIARY_GROUP_MOTION = {
  hidden: GROUP_HIDDEN_MOTION,
  visible: { opacity: 1, x: 0, transition: { delay: 0.55 } },
}
