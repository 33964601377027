import { shiftTimesOfDay } from 'types'
import type { PlannedShiftT } from 'types'
import { ABSENCE_TYPE } from '../AbsenceTag/constants'

export const getAbsenceType = ({ shift }: { shift: PlannedShiftT }) => {
  if (shift.percent && shift.percent < 100) {
    if (shift.timeOfDay === shiftTimesOfDay.pm) return ABSENCE_TYPE.halfDayEvening

    return ABSENCE_TYPE.halfDayMorning
  }

  return ABSENCE_TYPE.fullDay
}
