import { styled } from '@mui/material'

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bottomSpacing' && prop !== 'horizontalSpacing',
})<{ bottomSpacing: number; horizontalSpacing: number }>`
  margin: ${({ theme, bottomSpacing, horizontalSpacing }) =>
    theme.spacing(0, horizontalSpacing, bottomSpacing, horizontalSpacing)};
  display: flex;
  justify-content: space-between;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
