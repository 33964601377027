import React, { useMemo } from 'react'
import { useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getMetadata } from 'pages/Cms/helper'
import FramedIcon from 'redesign/components/atoms/FramedIcon'
import { EventIcon } from 'redesign/components/atoms/Icon'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDateRange } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import ContentCard from '../ContentCard'
import type { EventCardProps } from './types'

const EventCard: React.FC<EventCardProps> = ({ event, date }) => {
  const { t, language, locale } = useFavurTranslation()
  const { palette } = useTheme()
  const { startDate, endDate } = event
  const metadata = useMemo(() => getMetadata(event, language), [event, language])
  const bodyText = useMemo(
    () =>
      formatDateRange({
        dateFormat: DATE_FORMAT_VARIANTS.shortWithWeekdayAndTime,
        dates: { startDate, endDate },
        locale,
        t,
      }),
    [startDate, endDate, locale, t],
  )

  if (!metadata) return null

  return (
    <ContentCard
      dataTestId="event-card"
      url={`${routes.cmsContentView}/${event.uuid}${date ? `?dateref=${date}` : ''}`}
      header={{
        icon: <FramedIcon color={palette.primary.main} icon={EventIcon} size={ICON_SIZE.small} />,
        title: metadata.title,
        showChevron: true,
        overflowSingleLine: true,
      }}
      onClick={() => {}}
      footerProps={{
        mainTextVariant: 'body2',
        startText: bodyText,
      }}
    />
  )
}

export default EventCard
