import { getLuminance } from '@mui/material'
import { TGetTagTextColour } from './types'

export const getTagTextColour = ({ isManual, hexTextColor, theme }: TGetTagTextColour) => {
  if (!hexTextColor || !isManual) return hexTextColor
  const { palette } = theme
  const isTextLight = getLuminance(hexTextColor) > 0.5

  return isTextLight ? palette.text.primary : hexTextColor
}
