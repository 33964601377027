import React from 'react'
import { useTheme } from '@mui/material'
import DocumentsHeader from 'redesign/components/organisms/Headers/DocumentsHeader'
import { DocumentsList } from 'redesign/components/templates/ContentLists'
import FilterContent from 'redesign/components/templates/Filters'
import { FilterStateListener } from 'redesign/components/templates/Filters/FilterStateListener'
import Page from 'redesign/components/templates/Page'
import { DOCUMENTS_ROOT_ID } from './constants'
import { useDocumentsList } from './useDocumentsList'

const DocumentsPageContent: React.FC = () => {
  const { documents, loading, loadMore, showLoadMoreButton, updateFilters } = useDocumentsList()
  const theme = useTheme()

  return (
    <FilterStateListener onFilterChange={updateFilters}>
      <Page
        rootId={DOCUMENTS_ROOT_ID}
        header={<DocumentsHeader />}
        backgroundColor={theme.palette.common.backgroundApp}
        paddingX={0}
      >
        <DocumentsList
          documents={documents}
          isLoading={loading}
          loadMore={loadMore}
          showLoadMoreButton={showLoadMoreButton}
        />
      </Page>
      <FilterContent rootId={DOCUMENTS_ROOT_ID} />
    </FilterStateListener>
  )
}

export default DocumentsPageContent
