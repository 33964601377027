import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getShiftKey } from 'types/utils'
import ContentCard from '../ContentCard'
import ShiftPlanCard from '../ShiftPlanCard'
import { ShiftsContainer } from './styles'
import type { TeamShiftsListProps } from './types'

const TeamShiftsList: React.FC<TeamShiftsListProps> = ({ showCostCenter, date, teamPersons }) => {
  const { t } = useFavurTranslation()

  if (teamPersons.length === 0) {
    return <ContentCard hasTopPaddingBody bodyText={t('component.teamplanDetailPage.noData.label')} />
  }

  return (
    <ShiftsContainer>
      {teamPersons.map((member) => {
        const firstShift = member.shifts[0]
        const defaultExpanded = member.shifts.some((shift) => shift.isManual || Boolean(shift.note))

        return (
          <ShiftPlanCard
            testId={getShiftKey(firstShift)}
            date={date}
            user={member}
            isAccordion={true}
            shifts={member.shifts}
            showCostCenter={showCostCenter}
            key={getShiftKey(firstShift)}
            defaultExpanded={defaultExpanded}
            hideAbsenceType
          />
        )
      })}
    </ShiftsContainer>
  )
}

export default TeamShiftsList
