import { createContext, Context, useContext } from 'react'
import { UserAnnouncementT } from 'types'
import { IAnnouncementsContext } from './types'

const initialState: IAnnouncementsContext = {
  openAnnouncement: (_announcementRoute: UserAnnouncementT) => {},
  markAnnouncementSeen: (_userAnnouncement: UserAnnouncementT) => {},
}

const AnnouncementsContext: Context<IAnnouncementsContext> = createContext(initialState)

export const useAnnouncementsContext = () => {
  const context = useContext(AnnouncementsContext)
  return context
}

export default AnnouncementsContext
