import { useMemo } from 'react'
import { sortTeamPlanShifts } from 'components/ShiftsList/components/ShiftCard/TeamsSection/utils'
import { getTeamplanPersonsName as getTeamPlanPersonsName } from 'components/ShiftsList/utils'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useSessionContext from 'hooks/useSessionContext'
import sortBy from 'lodash/fp/sortBy'
import { emptyOrOnlyMeInMembers } from '../../TeamCard/emptyOrOnlyMeInMembers'
import type { TeamsToDisplay, UseTeamSection } from './types'

export const useTeamSection = ({ teamPlan }: UseTeamSection) => {
  const { t, language } = useFavurTranslation()
  const { persons } = useSessionContext()
  const personIds = useMemo(() => persons?.map((person) => `${person.id}`), [persons])
  const { attendances, vacationAbsences } = useMemo(() => sortTeamPlanShifts(teamPlan), [teamPlan])
  const teams = sortBy(
    (teamPlanPersons) => getTeamPlanPersonsName(teamPlanPersons, language),
    teamPlan === 'loading' ? [] : teamPlan,
  )
  const teamsToDisplay = useMemo(
    () =>
      teams.reduce<TeamsToDisplay[]>((acc, teamPlanPersons): TeamsToDisplay[] => {
        const teamPlanPersonsTitle = getTeamPlanPersonsName(teamPlanPersons, language)
        const showTeamplanCostCenters = teamPlanPersons.type === 'team' && teamPlanPersons.team?.showTeamplanCostCenters

        const hideAttendances = emptyOrOnlyMeInMembers({
          teamMemberList: attendances[teamPlanPersons.id] ?? [],
          personIds,
        })
        const hideAbsences = emptyOrOnlyMeInMembers({
          teamMemberList: vacationAbsences[teamPlanPersons.id] ?? [],
          personIds,
        })

        if (hideAbsences && hideAttendances) {
          return acc
        }

        const teamCardProps = {
          hideAttendances,
          hideAbsences,
          title: teamPlanPersonsTitle,
          showTeamplanCostCenters,
        }

        return [...acc, { ...teamPlanPersons, ...teamCardProps }]
      }, []),
    [attendances, language, personIds, teams, vacationAbsences],
  )

  return {
    attendances,
    personIds,
    t,
    teamsToDisplay,
    vacationAbsences,
    language,
    teams,
  }
}
