import React from 'react'
import { css, Tooltip as MuiTooltip, styled, tooltipClasses } from '@mui/material'
import { TOOLTIP_VARIANT } from './constants'
import type { ExtendedTooltipProps } from './types'

// This component is needed to pass down the styling classes to the popper component that the MUI Tooltip component generates
const ExtendedTooltip: React.FC<ExtendedTooltipProps> = ({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
)

// This styles component will be the main one that will be imported, that's why it's in this file
const Tooltip = styled(ExtendedTooltip)`
  ${({ theme, variant }) => css`
    & .${tooltipClasses.tooltip} {
      // Styles for the tooltip container
      background-color: ${theme.palette.common.greyDark};
      color: ${theme.palette.textLight.primary};
      padding: ${theme.spacing(1.5, 1)};
      max-width: ${theme.spacing(25)};
      border-radius: ${theme.spacing(1)};

      ${variant === TOOLTIP_VARIANT.light &&
      css`
        background-color: ${theme.palette.common.backgroundCard};
        color: ${theme.palette.text.primary};
        box-shadow: ${theme.shadows[2]};
      `}
    }

    & .${tooltipClasses.arrow} {
      // Styles for the tooltip arrow component
      color: ${theme.palette.common.greyDark};

      ${variant === TOOLTIP_VARIANT.light &&
      css`
        color: ${theme.palette.common.backgroundCard};
        &::before: {
          box-shadow: ${theme.shadows[2]};
        }
      `};
    }
  `}
`

export default Tooltip
