import { IconButton, styled } from '@mui/material'

export const Footer = styled('footer')`
  display: flex;
  right: auto;
  left: auto;
  max-width: ${({ theme }) => theme.spacing(75)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  margin: 0 auto;
  width: ${({ theme }) => theme.spacing(3.5)};
  width: 100%;
  justify-content: center;
  pointer-events: none;
  position: relative;
`

export const Container = styled('div')`
  display: flex;
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-bottom: env(safe-area-inset-bottom));
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
`

export const NavbarElement = styled('div')`
  display: flex;
  min-width: ${({ theme }) => theme.spacing(9)};
  max-width: ${({ theme }) => theme.spacing(21)};
  justify-content: center;
  height: ${({ theme }) => theme.spacing(7)};
  width: 100%;
  padding: ${({ theme }) => theme.spacing(0, 0.5)};
  &:empty {
    pointer-events: none;
  }
`

export const ExitButton = styled(IconButton)`
  border-radius: ${({ theme }) => theme.spacing(1)};
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  pointer-events: auto;
  &:active {
    background-color: ${({ theme }) => theme.palette.common.hoverNavItem};
  }
`
