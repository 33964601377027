import React from 'react'
import Accordion from 'redesign/components/atoms/Accordion'
import { CardContainer, HeaderContainer } from './styles'
import type { AccordionWrapperProps } from './types'

const AccordionWrapper: React.FC<AccordionWrapperProps> = ({
  children,
  isAccordion,
  header,
  testId,
  defaultExpanded = false,
}) =>
  isAccordion ? (
    <Accordion defaultExpanded={defaultExpanded} testId={`${testId}-accordion`} header={header}>
      {children}
    </Accordion>
  ) : (
    <CardContainer data-testid={testId}>
      {header ? <HeaderContainer>{header}</HeaderContainer> : null}
      {children}
    </CardContainer>
  )

export default AccordionWrapper
