import theme from 'utils/theme'

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    // @ts-ignore
    backgroundColor: theme.palette.grey[25],
  },
  loadingBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  progressBar: {
    width: '50%',
  },
  text: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    width: theme.spacing(16),
    textAlign: 'center',
  },
}
