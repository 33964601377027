import React from 'react'
import useRolesViews from 'hooks/useRolesViews'
import ShiftPlanHeader from 'redesign/components/organisms/Headers/ShiftPlanHeader'
import { useSideMenuContent } from 'redesign/components/organisms/SideMenuContent/useSideMenuContent'
import Page from 'redesign/components/templates/Page'
import ShiftPlanTemplate from 'redesign/components/templates/ShiftPlanContent/ShiftPlanTemplate'
import SideMenu from 'redesign/components/templates/SideMenu'
import { PARENT_PAGES, SIDE_MENU_OPTIONS } from 'redesign/components/templates/SideMenu/constants'
import SideMenuProvider from 'redesign/services/SideMenu/SideMenuProvider'
import SwipeableViewProvider from 'redesign/services/SwipeableView/SwipeableViewProvider'
import { isNative } from 'utils/platform'
import { SHIFT_PLAN_PAGE_ROOT_ID } from './constants'

const ShiftPlanPage: React.FC = () => {
  const isNativeView = isNative()
  const { setPage } = useSideMenuContent()
  const { persons } = useRolesViews()

  return (
    <SideMenuProvider parentPage={PARENT_PAGES.absencePlan} persons={persons}>
      <SwipeableViewProvider>
        <Page header={<ShiftPlanHeader />} paddingX={0} removeLastMargin rootId={SHIFT_PLAN_PAGE_ROOT_ID}>
          <ShiftPlanTemplate isNative={isNativeView} />
          <SideMenu
            onClose={() => {
              setPage(SIDE_MENU_OPTIONS.mainMenu)
            }}
          />
        </Page>
      </SwipeableViewProvider>
    </SideMenuProvider>
  )
}

export default ShiftPlanPage
