import { ROLE_VIEWS } from 'constants/roles'
import { shiftUpdateTypes } from 'constants/shift'
import { taskStates } from 'pages/Tasks/constants'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDateRange } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import { ShiftNotification } from 'shared/graphql/graphql'
import { newDateWithoutTime } from 'utils/date'
import { GetNotificationData } from '../types'

export const getShiftData = ({ notification, t, locale }: GetNotificationData) => {
  const { dataType, data } = notification
  const { dateTo, dateFrom, count, taskUuid } = data as ShiftNotification
  const isInserted = dataType === shiftUpdateTypes.inserted
  const dateFormat = DATE_FORMAT_VARIANTS.shortWithoutWeekday

  const dateRange = formatDateRange({
    dates: {
      startDate: newDateWithoutTime(dateFrom),
      endDate: newDateWithoutTime(dateTo),
    },
    dateFormat,
    locale,
    t,
  })

  const bodyTranslationKey = () => {
    if (!count) return ''

    if (count > 1 && dataType === shiftUpdateTypes.updated) {
      return t('shiftUpdate.date.multipleChanges', {
        dateRange,
        shiftNumber: count,
      })
    }

    return dateRange
  }

  const url = isInserted ? routes.shifts : `${routes.shiftUpdateDetail}/${taskUuid}/${taskStates.todo}`

  return {
    title: t(isInserted ? 'notification.new.newshift.title' : 'notification.new.shiftchange.title'),
    body: bodyTranslationKey(),
    route: url,
    role: ROLE_VIEWS.frontliner,
  }
}
