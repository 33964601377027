import React from 'react'
import { useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Divider from 'redesign/components/atoms/Divider'
import FramedIcon from 'redesign/components/atoms/FramedIcon'
import { PlaceholderIcon } from 'redesign/components/atoms/Icon'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import ShiftCardInfoText from 'redesign/components/atoms/ShiftCardInfoText'
import AlertTag from 'redesign/components/atoms/Tag/AlertTag'
import AccordionWrapper from 'redesign/components/molecules/AccordionWrapper'
import { normalizeTaskContents, normalizeTaskHeader } from 'redesign/components/templates/ContentLists/TaskList/utils'
import { getTaskUrl } from 'redesign/components/templates/ContentLists/TaskList/utils/getTaskUrl'
import TaskReference from '../TaskReference'
import { DataContainer } from './styles'
import type { ShiftUpdateAlertCardProps } from './types'

const ShiftUpdateAlertCard: React.FC<ShiftUpdateAlertCardProps> = ({ task, onTaskClick, daysUpdated = 1 }) => {
  const { palette } = useTheme()
  const { t, locale } = useFavurTranslation()
  const { startText, secondaryText } = normalizeTaskContents({
    t,
    task,
    locale,
  })
  const title = normalizeTaskHeader({ task, isOffice: false, t })
  const taskUrl = getTaskUrl({ task })

  return (
    <AccordionWrapper
      testId="shift-update-alert-card"
      header={<AlertTag text={t('page.shifts.shiftCard.shiftUpdate.hidden')} />}
    >
      <DataContainer>
        <ShiftCardInfoText
          title={t('page.shifts.shiftCard.shiftUpdate.unacknowledged.title')}
          subtitle={
            daysUpdated > 1
              ? t('page.shifts.shiftCard.shiftUpdate.unacknowledged.bodyMultiple', { shiftNumber: daysUpdated })
              : t('page.shifts.shiftCard.shiftUpdate.unacknowledged.bodySingle')
          }
        />
        <Divider fullSize />
        <TaskReference
          testId={`shift-update-reference-task_${task.id}`}
          onTaskClick={() => {
            onTaskClick(taskUrl)
          }}
          header={{
            icon: <FramedIcon color={palette.primary.main} icon={PlaceholderIcon} size={ICON_SIZE.small} />,
            title,
          }}
          footerProps={{ mainTextVariant: 'body2', secondaryText, startText }}
        />
      </DataContainer>
    </AccordionWrapper>
  )
}

export default ShiftUpdateAlertCard
