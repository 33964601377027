import React, { useCallback, useMemo, memo, SetStateAction, Dispatch, useState } from 'react'
import { Box, useTheme } from '@mui/material'
import GroupS from 'icons/GroupS'
import { AbsencePlannerBadgeT, AbsencePlannerBadgeTypesT, SelectedShiftDataT } from 'pages/AbsencePlanner/types'
import { PersonT, TenantT } from 'types'
import { getAvatarDataFromPerson } from 'utils/avatar'
import palette from 'utils/theme/palette'
import Badge from '../../Badge'
import { getBadgeKey } from '../../Badge/utils'
import { TaskBarStateT } from '../../TaskBar/types'
import ShiftDialog from '../ShiftDialog'
import { gridClasses } from '../styles'
import { absencePlannerCellClasses } from './styles'

const MAX_DAY_ELEMENTS = 3

interface IAbsencePlannerGridCellProps {
  badges?: AbsencePlannerBadgeT[]
  selectedRow?: boolean
  selectedColumn?: boolean
  isTaskSelected: boolean
  isGreyscale: boolean
  isLastRow?: boolean
  userRow?: boolean
  person?: PersonT
  id?: string
  shownTypes: AbsencePlannerBadgeTypesT[]
  setTaskBarState: Dispatch<SetStateAction<TaskBarStateT | null>>
  isToday: boolean
  isFirstDayOfMonth: boolean
  isMonday: boolean
  userIsAbsenceManager?: boolean
  isPreview?: boolean
  isRedesign?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSelectedAbsenceRequest = (event: any) => {
  if (!event) return false

  const target = event.target
  if (!target) return false

  if (target.id && target.id.startsWith('absenceRequest')) {
    return true
  }

  const parentNode = event.target.parentNode
  if (!parentNode) return false
  return parentNode.id && parentNode.id.startsWith('absenceRequest')
}

const canSelectCell = (event: React.MouseEvent<HTMLElement>) => {
  if (!event) return false
  const target = event.target as HTMLElement
  if (!target) return false
  if (
    target.id &&
    target.id.startsWith('cell') &&
    (target.children.length > 1 || target.children[0].id.startsWith('absenceRequest'))
  ) {
    return false
  }
  return true
}

const AbsencePlannerGridCell: React.FC<IAbsencePlannerGridCellProps> = ({
  badges = [],
  selectedRow,
  selectedColumn,
  isTaskSelected,
  isGreyscale,
  isLastRow = false,
  userRow,
  person,
  id,
  shownTypes,
  setTaskBarState,
  isToday = false,
  isFirstDayOfMonth = false,
  isMonday = false,
  userIsAbsenceManager = false,
  isPreview,
  isRedesign = false,
}) => {
  const redesignTheme = useTheme()
  const [selectedCell, setSelectedCell] = useState<boolean>(false)
  const [dialogChildNode, setDialogChildNode] = useState<HTMLElement | null>()
  const [cursorClickPosition, setCursorClickPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const [selectedShift, setSelectedShift] = useState<SelectedShiftDataT | null>(null)
  const { showingBadges: showingBadges, hasOverflow: hasOverflow } = useMemo(() => {
    if (!badges || badges.length < 0 || !badges) {
      return { hasOverflow: false, showingBadges: [] as AbsencePlannerBadgeT[] }
    }
    const hasMoreElements = badges.length > MAX_DAY_ELEMENTS
    return {
      hasOverflow: hasMoreElements,
      showingBadges: hasMoreElements ? badges.slice(0, MAX_DAY_ELEMENTS - 1) : badges,
    }
  }, [badges])

  const onClickCell = useCallback(() => {
    if (!showingBadges || showingBadges.length === 0) {
      return undefined
    }

    return (event: React.MouseEvent<HTMLElement>) => {
      if (isSelectedAbsenceRequest(event)) {
        return
      }

      if (canSelectCell(event)) {
        setCursorClickPosition({ x: event.clientX, y: event.clientY })

        const firstBadge = showingBadges[0]

        setDialogChildNode(event.currentTarget)
        setSelectedCell(true)
        setSelectedShift({
          person: person as PersonT,
          tenant: person?.tenant as TenantT,
          date: firstBadge.date,
          avatarData: getAvatarDataFromPerson(person as PersonT),
        })
      }
    }
  }, [person, setSelectedShift, showingBadges])

  return (
    <>
      <Box
        sx={[
          absencePlannerCellClasses.lastRowContainer(isLastRow, isMonday),
          ...(isToday ? [gridClasses.selectedBgToday] : []),
          ...(isFirstDayOfMonth ? [gridClasses.selectedBgFirstDayOfMonth] : []),
          ...(selectedColumn ? [gridClasses.selectedBgColumn] : []),
          ...(selectedRow ? [gridClasses.selectedBgRow] : []),
          ...(selectedCell ? [gridClasses.selectedBgCell] : []),
          ...(isRedesign ? [absencePlannerCellClasses.redesignTheme(redesignTheme)] : []),
        ]}
        onClick={onClickCell()}
        id={id}
      >
        <Box sx={[absencePlannerCellClasses.container(showingBadges.length)]} id={`cell_${id}`}>
          {showingBadges.map((badge) => {
            return (
              <Badge
                key={getBadgeKey(badge)}
                badge={badge}
                isAlone={showingBadges.length <= 1}
                userBadge={userRow}
                person={person}
                setTaskBarState={setTaskBarState}
                isTaskSelected={isTaskSelected}
                isGreyscale={isGreyscale}
                isPreview={isPreview}
                dataTestId={getBadgeKey(badge)}
              />
            )
          })}
          {hasOverflow && (
            <Box sx={absencePlannerCellClasses.overflowContainer}>
              <Box>
                <GroupS fill={palette.primary.main} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <ShiftDialog
        dialogChildNode={dialogChildNode}
        selectedShift={selectedShift}
        shownTypes={shownTypes}
        onClose={() => {
          setSelectedShift(null)
          setSelectedCell(false)
        }}
        cursorClickPosition={cursorClickPosition}
        isUser={Boolean(userRow)}
        userIsAbsenceManager={userIsAbsenceManager}
      />
    </>
  )
}

export default memo(AbsencePlannerGridCell)
