import React from 'react'
import { useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FramedIcon from 'redesign/components/atoms/FramedIcon'
import { PlaceholderIcon } from 'redesign/components/atoms/Icon'
import { normalizeTaskContents, normalizeTaskHeader } from 'redesign/components/templates/ContentLists/TaskList/utils'
import { getTaskUrl } from 'redesign/components/templates/ContentLists/TaskList/utils/getTaskUrl'
import ContentCard from '../ContentCard/ContentCard'
import type { TaskReferenceCardProps } from './types'

const TaskReferenceCard: React.FC<TaskReferenceCardProps> = ({ task, testId, onTaskClick }) => {
  const { palette } = useTheme()

  const { t, locale } = useFavurTranslation()
  const { startText, secondaryText } = normalizeTaskContents({
    t,
    task,
    locale,
  })
  const title = normalizeTaskHeader({ task, isOffice: false, t })
  const taskUrl = getTaskUrl({ task })

  return (
    <ContentCard
      dataTestId={testId}
      header={{
        icon: <FramedIcon color={palette.primary.main} icon={PlaceholderIcon} />,
        title,
        showChevron: true,
      }}
      onClick={() => {
        onTaskClick(taskUrl)
      }}
      footerProps={{
        mainTextVariant: 'body2',
        secondaryText,
        startText,
      }}
    />
  )
}

export default TaskReferenceCard
