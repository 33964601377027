import React from 'react'
import { PersonT } from 'types'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import { getUserName } from 'utils/person'
import {
  ACTION,
  ActionDispatchT,
  CheckListOptionT,
  CheckListT,
  ChipDataT,
  DateRangeT,
  FilterPageStateT,
  FilterStateT,
  FilterStateValueT,
  FilterType,
  OptionT,
  PersonsT,
  ReducerActionT,
  RemoveChipActionT,
} from '../types'

const createMultipleChoiceChip = (filterConfig: CheckListT, value: FilterStateValueT): string => {
  const { label, options } = filterConfig
  const selectedOptionsValues = value as string[]
  const numSelected = selectedOptionsValues.length
  if (numSelected === 1) {
    const selectedOptionValue = selectedOptionsValues[0]
    const selectedOption = options.find((option) => option.name === selectedOptionValue) as CheckListOptionT
    return selectedOption.label
  }
  return `${numSelected} ${label}`
}

const createPersonsListChip = (filterConfig: PersonsT, value: FilterStateValueT): string => {
  const { label, options } = filterConfig
  const selectedOptionsValues = value as number[]
  const numSelected = selectedOptionsValues.length
  if (numSelected === 1) {
    const selectedOptionValue = selectedOptionsValues[0]
    const selectedPerson = options.find((option) => option.id === selectedOptionValue) as PersonT
    return getUserName(selectedPerson)
  }
  return `${numSelected} ${label}`
}

const createDateRangeChip = (filterConfig: DateRangeT, filters: FilterStateT): string => {
  const {
    fromDate: { name: fromDateName, label: fromDateLabel },
    toDate: { name: toDateName, label: toDateLabel },
  } = filterConfig

  const fromDate = filters[fromDateName]
  const toDate = filters[toDateName]
  if (!fromDate) return `${toDateLabel} ${getFormattedFromISOString(toDate as string, uiDateFormat, undefined)}`
  if (!toDate) return `${fromDateLabel} ${getFormattedFromISOString(fromDate as string, uiDateFormat, undefined)}`
  return `${getFormattedFromISOString(fromDate as string, uiDateFormat, undefined)} - ${getFormattedFromISOString(
    toDate as string,
    uiDateFormat,
    undefined,
  )}`
}

export const findConfigurationElement = (configuration: OptionT[], filterName: string): OptionT => {
  const configurationResult = configuration.find((configurationElement) => {
    if (configurationElement.name === filterName) return true
    if (configurationElement.type === FilterType.DATE_RANGE) {
      return configurationElement.fromDate.name === filterName || configurationElement.toDate.name === filterName
    }
    return false
  })

  if (!configurationResult) {
    throw new Error(`filterName: '${filterName}' not found in configuration`)
  }

  return configurationResult as OptionT
}

export const updateChips = (filters: FilterStateT, configuration: OptionT[]): ChipDataT[] => {
  const chipList = Object.entries(filters).map(([filterName, value]) => {
    const filterConfig = findConfigurationElement(configuration, filterName)

    if (!filterConfig) {
      return undefined
    }

    switch (filterConfig.type) {
      case FilterType.MULTIPLE_CHOICE:
        return { label: createMultipleChoiceChip(filterConfig, value), filterName } as ChipDataT
      case FilterType.DATE_RANGE:
        return { label: createDateRangeChip(filterConfig, filters), filterName: filterConfig.name } as ChipDataT
      case FilterType.PERSONS:
        return { label: createPersonsListChip(filterConfig, value), filterName: filterConfig.name } as ChipDataT
      default:
        return undefined
    }
  })

  return chipList.filter(
    (chip, index) =>
      chip && chipList.findIndex((chipToFind) => chipToFind && chipToFind.filterName === chip.filterName) === index,
  ) as ChipDataT[]
}

export const actions = (filterPageState: FilterPageStateT, dispatch: React.Dispatch<ReducerActionT>) => ({
  getChips: () => {
    return filterPageState.chips
  },
  removeChip: (chip: ChipDataT) => {
    dispatch({ type: ACTION.REMOVE_CHIP, value: { filterName: chip.filterName } })
  },
})

export const reducers: ActionDispatchT = {
  [ACTION.REMOVE_CHIP]: (state, action) => {
    const actionValue = action.value as RemoveChipActionT

    const configValue = findConfigurationElement(state.configuration, actionValue.filterName)

    const updatedFilters = Object.entries(state.filters).reduce((acc, [key, value]) => {
      switch (configValue.type) {
        case FilterType.MULTIPLE_CHOICE:
          if (key === actionValue.filterName) return acc
          break
        case FilterType.DATE_RANGE:
          if (key === configValue.fromDate.name || key === configValue.toDate.name) return acc
          break
        case FilterType.PERSONS:
          if (key === actionValue.filterName) return acc
          break
        default:
          return { ...acc, [key]: value }
      }
      return { ...acc, [key]: value }
    }, {})

    return {
      ...state,
      filters: updatedFilters,
      chips: updateChips(updatedFilters, state.configuration),
    }
  },
  [ACTION.UPDATE_CHIPS]: (state, _action) => ({
    ...state,
    chips: updateChips(state.filters, state.configuration),
  }),
}
