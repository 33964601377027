import { Card, styled } from '@mui/material'

export const HeaderContainer = styled('div')`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`
export const CardContainer = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'lineThrough',
})<{
  lineThrough?: boolean
}>`
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: 0;
  text-decoration: ${({ lineThrough }) => (lineThrough ? 'line-through' : 'none')};
`
