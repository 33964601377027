import React, { useEffect } from 'react'
import useFilterContext from 'components/Filter/context'
import type { FilterStateListenerProps } from './types'

export const FilterStateListener: React.FC<FilterStateListenerProps> = ({ children, onFilterChange }) => {
  const { getFilters } = useFilterContext()
  const filters = getFilters()

  useEffect(() => {
    onFilterChange(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return <>{children}</>
}
