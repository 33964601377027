import React, { useState } from 'react'
import { Box } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePinStatus from 'hooks/usePinStatus'
import useSessionContext from 'hooks/useSessionContext'
import useStateBackLink from 'hooks/useStateBackLink'
import { headerClasses } from 'pages/UserSettings/styles'
import routes from 'services/RoutesProvider/routes'
import AvatarDeleteDialog from './components/AvatarDeleteDialog'
import CropImage from './components/CropImage'
import LoginSettings from './components/LoginSettings'
import OtherSettings from './components/OtherSettings'
import ProfileSettings from './components/ProfileSettings'
import useProfilePicture from './hooks/useProfilePicture'
import { classes } from './styles'

const ProfilePage: React.FC = () => {
  const { t } = useFavurTranslation()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const { user } = useSessionContext()

  const [avatarDeleteConfirmation, showAvatarDeleteConfirmation] = useState<boolean>(false)
  const { selectedImageBase64, setSelectedImageBase64, onFileChanged, onDeleteAvatar } = useProfilePicture()
  const [pinStatusQuery, pinStatusLoading] = usePinStatus()

  const pinStatus = pinStatusQuery?.pinStatus || ''
  return selectedImageBase64 ? (
    <CropImage
      base64Image={selectedImageBase64}
      onCancel={() => {
        setSelectedImageBase64(undefined)
      }}
    />
  ) : (
    <Page
      header={
        <BackwardLink
          onClickIcon={() => {
            pushBackLinkOrDefault(routes.userSettings)
          }}
          title={t('page.userSettings.profile.header')}
        />
      }
      sxHeader={headerClasses.subHeader}
    >
      {user && (
        <Box sx={classes.cardsContainer}>
          <ProfileSettings
            user={user}
            onFileChanged={onFileChanged}
            onClickDeleteAvatar={() => showAvatarDeleteConfirmation(true)}
          />
          <LoginSettings pinStatus={pinStatus} pinStatusLoading={pinStatusLoading} />
          <OtherSettings />
        </Box>
      )}

      {avatarDeleteConfirmation && (
        <AvatarDeleteDialog onCancel={() => showAvatarDeleteConfirmation(false)} onDelete={onDeleteAvatar} />
      )}
    </Page>
  )
}

export default ProfilePage
