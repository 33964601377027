import React from 'react'
import {
  MiddleContainer,
  HeaderContainer,
  EndContainer,
  StartContainer,
  MainContainer,
  HeaderContent,
  DividerLine,
  ContentContainer,
} from './styles'
import type { HeaderProps } from './types'

const Header: React.FC<HeaderProps> = ({
  startContent,
  middleContent,
  endContent,
  subheader,
  withoutDivider = false,
  sidePadding = false,
}) => {
  return (
    <MainContainer>
      <ContentContainer>
        <HeaderContainer sidePadding={sidePadding}>
          <HeaderContent>
            <StartContainer>{startContent}</StartContainer>
            <MiddleContainer>{middleContent}</MiddleContainer>
            <EndContainer>{endContent}</EndContainer>
          </HeaderContent>
        </HeaderContainer>
        {subheader}
      </ContentContainer>
      {!withoutDivider && <DividerLine />}
    </MainContainer>
  )
}

export default Header
