import { ROLE_VIEWS } from 'constants/roles'
import he from 'he'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDateRange } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import { absencePeriods, absencePeriodsTranslationMap } from 'shared/constants'
import { ResolvedAbsenceRequestNotification } from 'shared/graphql/graphql'
import { newDateWithoutTime } from 'utils/date'
import { getShortNameFromName } from 'utils/person'
import { ResolvedAbsenceRequestStates } from '../constants'
import { GetNotificationData } from '../types'

export const getResolvedAbsenceRequestData = ({ notification, t, locale }: GetNotificationData) => {
  const {
    managerNameShort,
    managerName,
    dateTo: notificationDateTo,
    dateFrom: notificationDateFrom,
    taskUuid,
    period,
    state,
  } = notification.data as ResolvedAbsenceRequestNotification
  const title = () => {
    const titleKey =
      state === ResolvedAbsenceRequestStates.approved
        ? 'notification.new.absence.accepted.title'
        : 'notification.new.absence.declined.title'

    return he.decode(
      t(titleKey, {
        managerNameShort: managerNameShort ?? getShortNameFromName(managerName ?? ''),
      }),
    )
  }

  const multiday = notificationDateFrom !== notificationDateTo
  const dateFormat = DATE_FORMAT_VARIANTS.shortWithWeekday
  const dateRange = formatDateRange({
    dates: {
      startDate: newDateWithoutTime(notificationDateFrom),
      endDate: multiday ? newDateWithoutTime(notificationDateTo) : undefined,
    },
    dateFormat,
    locale,
    t,
  })

  const absencePeriod = () => {
    if (multiday || !period || period.trim() === '') {
      return ''
    }

    const translatedPeriod = absencePeriodsTranslationMap[period]

    return t(absencePeriods[translatedPeriod])
  }

  return {
    title: title(),
    body: multiday ? dateRange : `${dateRange} ${absencePeriod()}`,
    route: `${routes.absenceRequestDetail}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
