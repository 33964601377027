import { styled } from '@mui/material'

export const Container = styled(`div`)`
  display: flex;
  min-height: ${({ theme }) => theme.spacing(7)};
  align-items: center;
`

export const ChipContainer = styled(`div`)`
  width: 100%;
`
