import React from 'react'
import Headline from 'redesign/components/atoms/Headline'
import CheckboxFormGroup from 'redesign/components/molecules/CheckboxFormGroup'
import { Container } from './styles'
import type { TeamSettingsBlockProps } from './types'
import { useTeamSettings } from './useTeamSettings'

const TeamSettingsBlock: React.FC<TeamSettingsBlockProps> = ({
  teams,
  selectedTeams,
  setSelectedTeams,
  filterKey,
  headlineLabel,
}) => {
  const { checkboxOptions } = useTeamSettings({
    teams,
    selectedTeams,
    setSelectedTeams,
    filterKey,
  })

  if (!teams.length) return null

  return (
    <Container>
      <Headline text={headlineLabel} />
      <CheckboxFormGroup checkboxes={checkboxOptions} />
    </Container>
  )
}

export default TeamSettingsBlock
