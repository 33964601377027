import { ComponentsOverrides, Theme } from '@mui/material'

const styleOverrides: ComponentsOverrides<Theme>['MuiDialog'] = {
  paper: {
    borderRadius: '1.5rem',
  },
}
const MuiDialog = {
  styleOverrides,
}

export default MuiDialog
