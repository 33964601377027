export const TEAM_PLAN_WITH_TEAMS_QUERY = 'teamplanWithTeams'

export const GET_ABSENCE_REQUEST_QUERY = 'getAbsenceRequests'

export const ALL_EFFECTIVE_SHIFTS_FAVUR_QUERY = 'allEffectiveShiftsFavur'

export const ALL_SHIFTS_FAVUR_QUERY = 'allShiftsFavur'

export const GET_TEAMS_QUERY = 'getTeamsQuery'

export const CMS_PAGINATED_CONTENT_LIST_QUERY = 'cmsPaginatedContentList'
