import React, { useCallback, useMemo } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { sortBy } from 'lodash/fp'
import useStoredFilters from 'pages/Shifts/useStoredFilters'
import { useSwipeableView } from 'redesign/services/SwipeableView/useSwipeableView'
import { getFullTeamLabel } from 'utils/team'
import type { CheckboxOptions, TeamSettingsBlockProps } from './types'

export const useTeamSettings = ({
  teams,
  selectedTeams,
  setSelectedTeams,
  filterKey,
}: Omit<TeamSettingsBlockProps, 'headlineLabel'>) => {
  const { t, language } = useFavurTranslation()
  const { sideMenuData } = useSwipeableView()
  const { isMultiTenant } = sideMenuData
  const { updateStoredFilter } = useStoredFilters()

  const getChangedSelectedTeams = useCallback(
    (teamId: string, checked: boolean) => {
      if (!selectedTeams) return []

      const selectedTeamsList = (() => {
        if (checked) return [...selectedTeams, teamId]

        return selectedTeams.filter((id: string) => id !== teamId)
      })()

      updateStoredFilter(filterKey, selectedTeamsList)

      return selectedTeamsList
    },
    [filterKey, selectedTeams, updateStoredFilter],
  )

  const onTeamCheckChange = useCallback(
    (teamId: string, checked: boolean) => setSelectedTeams(getChangedSelectedTeams(teamId, checked)),
    [getChangedSelectedTeams, setSelectedTeams],
  )

  const isTeamChecked = useCallback((teamId: string) => selectedTeams && selectedTeams.includes(teamId), [
    selectedTeams,
  ])

  const checkboxOptions: CheckboxOptions[] = useMemo(
    () =>
      sortBy(
        'label',
        teams.map((team) => ({
          label: getFullTeamLabel(team, language, isMultiTenant),
          value: team.id,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            const { checked } = event.target

            onTeamCheckChange(team.id, checked)
          },
          checked: isTeamChecked(team.id),
        })),
      ),
    [isMultiTenant, isTeamChecked, language, onTeamCheckChange, teams],
  )

  return {
    t,
    onTeamCheckChange,
    checkboxOptions,
    isTeamChecked,
    teams,
    selectedTeams,
  }
}
