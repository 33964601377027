import React from 'react'
import { Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import NotificationsButton from 'redesign/components/atoms/Buttons/NotificationsButton'
import { FavurLogo } from 'redesign/components/atoms/Illustrations'
import Header from '../../Header'
import { TDashboardHeader } from './types'

const DashboardHeader: React.FC<TDashboardHeader> = ({ header, isNative }) => {
  const { hasActiveAndResignedPersons, totalNotifications } = header
  const { t } = useFavurTranslation()
  const headerTitle = t('page.dashboard.title')

  return (
    <Header
      middleContent={isNative ? <FavurLogo /> : <Typography variant="subtitle1">{headerTitle}</Typography>}
      endContent={hasActiveAndResignedPersons && <NotificationsButton totalNotifications={totalNotifications} />}
    />
  )
}

export default DashboardHeader
