import React, { useCallback } from 'react'
import useFilterContext from 'components/Filter/context'
import useFilterUtils from 'components/Filter/useFilterUtils'
import useHistoryUtils from 'hooks/useHistoryUtils'
import useStateBackLink from 'hooks/useStateBackLink'
import type { TaskStateT } from 'pages/Tasks/types'
import { RouteComponentProps } from 'react-router-dom'
import TaskListHeader from 'redesign/components/organisms/Headers/TaskListHeader'
import { TaskList } from 'redesign/components/templates/ContentLists'
import Filters from 'redesign/components/templates/Filters'
import { FilterStateListener } from 'redesign/components/templates/Filters/FilterStateListener'
import Page from 'redesign/components/templates/Page'
import routes from 'services/RoutesProvider/routes'
import { TASK_LIST_ROOT_ID } from './constants'
import { useTaskList } from './useTaskList'

const TaskListPageContent: React.FC<RouteComponentProps<{ taskState?: TaskStateT }>> = () => {
  const { list: tasks, isOffice, loading, loadMore, showLoadMoreButton, handleFilterChange } = useTaskList()
  const { setLoadFilterTasks } = useFilterUtils()
  const { storeState } = useFilterContext()
  const { pushWithRole } = useHistoryUtils()
  const { setBackLink } = useStateBackLink()

  const redirect = useCallback(
    ({ taskUrl }: { taskUrl: string }) => {
      setLoadFilterTasks()
      storeState()
      pushWithRole(taskUrl)
      setBackLink(`${routes.openTasks}`)
    },
    [pushWithRole, setBackLink, setLoadFilterTasks, storeState],
  )

  return (
    <FilterStateListener onFilterChange={handleFilterChange}>
      <Page rootId={TASK_LIST_ROOT_ID} header={<TaskListHeader isOffice={isOffice} />} paddingX={0}>
        <TaskList
          isLoading={loading}
          isOffice={isOffice}
          tasks={tasks}
          redirect={redirect}
          showLoadMoreButton={showLoadMoreButton}
          loadMore={loadMore}
        />
      </Page>
      <Filters rootId={TASK_LIST_ROOT_ID} />
    </FilterStateListener>
  )
}
export default TaskListPageContent
