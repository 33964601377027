import { PersonT, TaskCountsT } from 'types'

export type ChipDataT = {
  label: string
  filterName: string
}

export type FilterStateValueT = string | number[] | number | string[]
export type FilterStateT = { [x: string]: FilterStateValueT }
export type FilterPageStateT = {
  name: string
  configuration: OptionT[]
  displayFilterPage: boolean
  personsListSelected: string | undefined
  filters: FilterStateT
  dirtyFilters: FilterStateT
  chips: ChipDataT[]
  scrollTo: string | undefined
  taskCounts: TaskCountsT | undefined
  currentSelectedPersons?: number[]
}

export type CheckListOptionT = {
  name: string
  label: string
}

export type QuickFilterT = {
  label: string
  action: () => void
}

export type CheckListT = {
  name: string
  type: 'multiple_choice'
  label: string
  options: CheckListOptionT[]
  defaultValues?: string[]
  initialValues?: string[]
}

export type DateRangePickerT = {
  name: string
  label: string
}

export type DateRangeT = {
  name: string
  type: 'date_range'
  label: string
  maxDate: string
  minDate?: string
  fromDate: DateRangePickerT
  toDate: DateRangePickerT
}

export type PersonsT = {
  name: string
  type: 'person_ids'
  label: string
  options: PersonT[]
}

export type OptionT = CheckListT | DateRangeT | PersonsT

export enum FilterType {
  MULTIPLE_CHOICE = 'multiple_choice',
  DATE_RANGE = 'date_range',
  PERSONS = 'person_ids',
}

export const enum ACTION {
  CLOSE_FILTER_PAGE,
  OPEN_FILTER_PAGE,
  REMOVE_CHIP,
  RESET_FILTER_PAGE,
  SUBMIT_FILTER_PAGE,
  TOGGLE_OPTION,
  CHANGE_VALUE,
  UPDATE_CHIPS,
  CLEAR_VALUE,
  CLOSE_PERSONS_LIST,
  OPEN_PERSONS_LIST,
  CONFIRM_PERSONS_LIST,
  CANCEL_PERSONS_LIST,
  CLEAR_PERSON_LIST,
  CLEAR_PERSON_LIST_ELEMENT,
  UPDATE_CONFIGURATION_OPTIONS,
  SET_SCROLL_TO,
  SET_CURRENT_SELECTED_PERSONS,
}

export type ToggleOptionActionT = {
  filterName: string
  optionName: string
}

export type ChangeValueActionT = {
  filterName: string
  value: string
}

export type ClearValueActionT = {
  filterName: string
}

export type RemoveChipActionT = {
  filterName: string
}

export type IsRedesignActionT = {
  isRedesign?: boolean
}

export type UpdateConfigurationOptionsActionT = {
  options: OptionT[]
}

export type OpenPersonsListActionT = {
  filterName: string
}

export type ConfirmPersonsListActionT = {
  filterName: string
  selectedPersons: number[]
}

export type ClearPersonListActionT = {
  filterName: string
}

export type ClearPersonListElementActionT = {
  filterName: string
  personId: number
}

export type SetPersonsListSelectedActionT = {
  selectedPersons: number[]
}

export type SetScrollToActionT = {
  destination: string | undefined
}

export type ReducerActionT = {
  type: ACTION
  value?:
    | ToggleOptionActionT
    | RemoveChipActionT
    | ChangeValueActionT
    | ClearValueActionT
    | UpdateConfigurationOptionsActionT
    | OpenPersonsListActionT
    | ConfirmPersonsListActionT
    | ClearPersonListActionT
    | ClearPersonListElementActionT
    | SetScrollToActionT
    | SetPersonsListSelectedActionT
    | IsRedesignActionT
}

export type ActionDispatchT = { [x: string]: (state: FilterPageStateT, _action: ReducerActionT) => FilterPageStateT }
