import React, { useState, useEffect, useCallback, SyntheticEvent } from 'react'
import { Snackbar, Button, Typography, IconButton } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { CloseThinS } from 'icons'
import theme from 'utils/theme'
import FlashMessagesContext from './context'
import { classes } from './styles'
import { ISnackbarMessageReq, ISnackbarMessage, FlashMessageType, FlashMessageCloseType } from './types'

export const flashMessagesTestIds = {
  closeButton: 'close-error-flash-message',
  snackbar: 'error-flash-message',
}

const FlashMessagesService = ({ children }: { children?: React.ReactNode }) => {
  const { t } = useFavurTranslation()
  const [snackPack, setSnackPack] = useState<ISnackbarMessageReq[]>([])
  const [open, setOpen] = useState(false)
  const [messageInfo, setMessageInfo] = useState<ISnackbarMessage | undefined>(undefined)

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ timeout: 6000, ...snackPack[0] })
      setSnackPack((prev) => prev.slice(1))
      setOpen(true)
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false)
    }
  }, [snackPack, messageInfo, open])

  const setFlashMessage = useCallback(
    (message: string, timeout?: number, type?: FlashMessageType, closeType?: FlashMessageCloseType) => {
      setSnackPack((prev) => [
        ...prev,
        {
          message,
          timeout,
          // eslint-disable-next-line
          key: new Date().getTime(),
          type: type ?? FlashMessageType.ERROR,
          closeType: closeType ?? FlashMessageCloseType.TEXT,
        },
      ])
    },
    [setSnackPack],
  )

  const handleClose = (_: Event | SyntheticEvent<Element, Event>, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleExited = () => {
    setMessageInfo(undefined)
  }

  const closeLatest = useCallback(() => {
    setOpen(false)
  }, [])

  const removeAll = useCallback(() => {
    setSnackPack((_prev) => [])
    setMessageInfo(undefined)
    setOpen(false)
  }, [])

  return (
    <FlashMessagesContext.Provider value={{ setFlashMessage, closeLatest, removeAll }}>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={messageInfo?.timeout}
        onClose={handleClose}
        data-testid={flashMessagesTestIds.snackbar}
        sx={[classes.root, ...[classes.rootAuthenticated]]}
        message={messageInfo ? <Typography variant="body2">{t(messageInfo.message)}</Typography> : undefined}
        action={
          <>
            {messageInfo?.closeType === FlashMessageCloseType.TEXT && (
              <Button
                color="secondary"
                size="small"
                onClick={handleClose}
                data-testid={flashMessagesTestIds.closeButton}
              >
                {t('common.dialog.button.close')}
              </Button>
            )}
            {messageInfo?.closeType === FlashMessageCloseType.ICON && (
              <IconButton
                sx={classes.deleteIconButton}
                onClick={handleClose}
                data-testid={flashMessagesTestIds.closeButton}
                size="large"
              >
                <CloseThinS fill={messageInfo.type === FlashMessageType.ERROR ? 'white' : theme.palette.primary.main} />
              </IconButton>
            )}
          </>
        }
        slotProps={{
          transition: {
            onExited: handleExited,
          },

          content: {
            sx: {
              '&.MuiSnackbarContent-root':
                messageInfo?.type === FlashMessageType.INFO ? classes.contentInfo : classes.content,
              '& .MuiSnackbarContent-message': classes.message,
              '& .MuiSnackbarContent-action': classes.action,
            },
          },
        }}
      />
      {children}
    </FlashMessagesContext.Provider>
  )
}

export default FlashMessagesService
