import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useModule from 'hooks/useModule'
import useSessionContext from 'hooks/useSessionContext'
import useStateBackLink from 'hooks/useStateBackLink'
import { AddAvatar, ArrowRightThinS } from 'icons'
import { useHistory } from 'react-router'
import routes from 'services/RoutesProvider/routes'
import { modules } from 'shared/constants'
import { teaserClasses } from './styles'

const TeaserToAvatar: React.FC<{ isAbsencePlanner?: boolean }> = ({ isAbsencePlanner = false }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { hasAccessToModule } = useModule()
  const { user } = useSessionContext()
  const { setBackLink } = useStateBackLink()
  const showAddAvatar = useMemo(() => !user?.avatarUrl && hasAccessToModule(modules.userAvatar), [
    hasAccessToModule,
    user?.avatarUrl,
  ])

  return showAddAvatar ? (
    <Box
      sx={teaserClasses.teaser}
      onClick={() => {
        setBackLink(isAbsencePlanner ? routes.absencePlanner : routes.shifts)
        history.push(routes.profile)
      }}
    >
      <Box sx={teaserClasses.icon}>
        <AddAvatar />
      </Box>
      <Typography variant="caption" sx={teaserClasses.text}>
        {t('page.shifts.sideMenu.teaserToAvatar')}
      </Typography>
      <Box sx={teaserClasses.arrow}>
        <ArrowRightThinS />
      </Box>
    </Box>
  ) : (
    <></>
  )
}

export default TeaserToAvatar
