import { Locale } from 'date-fns'
import { taskTypes } from 'shared/constants'
import { modules } from 'shared/constants'
import type { AuthPermissions, AuthPersonIdAndPermissions } from 'types'
import { ModuleT } from 'types'
import { getFormattedFromISOString } from 'utils/date'
import {
  TaskT,
  TaskDataT,
  AbsenceRequestTaskT,
  MonthlySheetTaskT,
  ShareUserDataTaskT,
  ShiftUpdateTaskT,
} from '../types'

const filterPersonIdsByOwnerAndPermission = (
  list: AuthPersonIdAndPermissions[] | undefined,
  ownerId: number | undefined,
  permKey: keyof AuthPermissions,
): number[] => {
  if (!list || ownerId === undefined) return []

  return list
    .filter((person) => person.ownerId === Number(ownerId) && person.permissions[permKey] === true)
    .map((item) => item.id)
}

const hasAnyPersonAccessToModule = (
  personIds: number[],
  module: string,
  hasModuleAccess: (personId: number, module: ModuleT) => boolean,
): boolean => {
  return personIds.some((id) => hasModuleAccess(id, module))
}

export function canSendCommentAndReminder(
  isOffice: boolean,
  persons: AuthPersonIdAndPermissions[] | undefined,
  task: TaskT<AbsenceRequestTaskT | MonthlySheetTaskT | ShareUserDataTaskT | ShiftUpdateTaskT>,
  requiredPermission: keyof AuthPermissions,
  hasPersonAccessToModule: (personId: number, module: ModuleT) => boolean,
) {
  const personsToCheckAccessModule = isOffice
    ? filterPersonIdsByOwnerAndPermission(persons, task.person?.owner?.id, requiredPermission)
    : [Number(task?.person?.id)].filter((id) => !isNaN(id))

  return {
    canSendComment: hasAnyPersonAccessToModule(personsToCheckAccessModule, modules.comments, hasPersonAccessToModule),
    canSendReminder: hasAnyPersonAccessToModule(personsToCheckAccessModule, modules.reminders, hasPersonAccessToModule),
  }
}

export const isAbsenceRequestTask = (task: TaskT<TaskDataT>): task is TaskT<AbsenceRequestTaskT> =>
  task.taskType === taskTypes.absenceRequest

export const isMonthlySheetTask = (task: TaskT<TaskDataT>): task is TaskT<MonthlySheetTaskT> =>
  task.taskType === taskTypes.monthlySheet

export const isShareUserDataTask = (task: TaskT<TaskDataT>): task is TaskT<ShareUserDataTaskT> =>
  task.taskType === taskTypes.shareUserData

export const getTasksByDay = (tasks: TaskT<ShiftUpdateTaskT>[], locale: Locale | undefined) =>
  tasks.reduce((acc, task) => {
    const { shiftsUpdated } = task
    if (!shiftsUpdated) return acc
    shiftsUpdated.forEach((shift) => {
      const date = getFormattedFromISOString(shift.date, 'yyyy-MM-dd', locale)
      const current = acc.get(date) ?? []
      acc.set(date, [...current, task])
    })
    return acc
  }, new Map<string, TaskT<ShiftUpdateTaskT>[]>())
