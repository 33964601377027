import React from 'react'
import { Step as MuiStep, StepLabel, Stepper as MuiStepper, StepIconProps } from '@mui/material'
import { StepT } from 'types'
import { classes } from './styles'

export interface IStepperProps {
  activeStep: number
  steps: StepT[]
}

const Stepper: React.FC<IStepperProps> = ({ activeStep, steps }) => (
  <MuiStepper activeStep={activeStep} connector={<></>} sx={classes.stepper}>
    {steps.map((step) => (
      <MuiStep key={step.index} sx={[classes.step, ...(activeStep === step.index ? [classes.active] : [])]}>
        <StepLabel
          sx={classes.stepLabel}
          slots={{
            stepIcon: step.icon as React.ElementType<StepIconProps>,
          }}
        />
      </MuiStep>
    ))}
  </MuiStepper>
)

export default Stepper
