import React from 'react'
import MonthlyBarDay from 'redesign/components/molecules/MonthlyBarDay'
import { getFormattedFromDate } from 'utils/date'
import { MonthlyBarContainer } from './styles'
import type { MonthlyBarProps } from './types'

const MonthlyBar: React.FC<MonthlyBarProps> = ({ monthDates, selectedTaskUuid, selectedDays }) => (
  <MonthlyBarContainer>
    {monthDates.map((date) => (
      <MonthlyBarDay
        date={date}
        selectedTaskUuid={selectedTaskUuid}
        selectedDays={selectedDays}
        key={getFormattedFromDate(date)}
      />
    ))}
  </MonthlyBarContainer>
)

export default MonthlyBar
