import React, { useCallback } from 'react'
import useStateBackLink from 'hooks/useStateBackLink'
import routes from 'services/RoutesProvider/routes'
import { NotificationT, NotificationTypes } from 'types'
import AbsenceRequestCommentFrontliner from './AbsenceRequestComment/Frontliner'
import AbsenceRequestCommentManager from './AbsenceRequestComment/Manager'
import AbsenceRequestRequestDeletion from './AbsenceRequestDeletion/Requested'
import ResolvedAbsenceRequestRequestDeletion from './AbsenceRequestDeletion/Resolved'
import CmsNewContentNotification from './CmsNewContentNotification'
import CmsUpdateContentNotification from './CmsUpdateContentNotification'
import DocumentNotification from './DocumentNotification'
import EmptyNotification from './EmptyNotification'
import InvitationNotification from './InvitationNotification'
import LinkMessageNotification from './LinkMessageNotification'
import MonthlySheetCommentFrontliner from './MonthlySheetComment/Frontliner'
import MonthlySheetCommentManager from './MonthlySheetComment/Manager'
import OfficeAbsenceRequest from './OfficeAbsenceRequest'
import OfficeMonthlySheetApprovedNotification from './OfficeMonthlySheetApprovedNotification'
import ReceivedUserData from './ReceivedUserData'
import ReminderNotification from './Reminders'
import AbsenceRequestManualForManagerReminder from './Reminders/AbsenceRequestManualForManager'
import MonthlySheetManualReminder from './Reminders/MonthlySheetManual'
import MonthlySheetManualForManagerReminder from './Reminders/MonthlySheetManualForManager'
import SecureConnectionReminder from './Reminders/SecureConnection'
import SecureConnectionManualReminder from './Reminders/SecureConnectionManualForFrontliner'
import ShareUserDataReminder from './Reminders/ShareUserData'
import ShareUserDataManualReminder from './Reminders/ShareUserDataManual'
import ShareUserDataManualReminderForManager from './Reminders/ShareUserDataManualForManager'
import ReminderShiftUpdate from './Reminders/ShiftUpdate'
import ShiftUpdateManualReminder from './Reminders/ShiftUpdateManual'
import ResignationInfo from './ResignationInfo'
import ResolvedAbsenceRequest from './ResolvedAbsenceRequest'
import SecureConnection from './SecureConnection'
import SecureConnectionCommentFrontliner from './SecureConnectionComment/Frontliner'
import SecureConnectionCommentManager from './SecureConnectionComment/Manager'
import SecureConnectionCompleted from './SecureConnectionCompleted'
import ShareUserData from './ShareUserData'
import ShareUserDataAccepted from './ShareUserDataAccepted'
import ShareUserDataCommentFrontliner from './ShareUserDataComment/Frontliner'
import ShareUserDataCommentManager from './ShareUserDataComment/Manager'
import ShiftNotification from './ShiftNotification'
import ShiftUpdateSeenNotification from './ShiftUpdateSeenNotification'
import type { NotificationPropsT } from './types'

// eslint-disable-next-line complexity
const Notification: React.FC<NotificationPropsT> = ({ dismiss, notification }) => {
  const { setBackLink } = useStateBackLink()

  const setBackLinkAndDismiss = useCallback(
    (funcNotification: NotificationT) => {
      setBackLink(routes.notifications)
      dismiss(funcNotification)
    },
    [dismiss, setBackLink],
  )

  switch (notification.type) {
    case NotificationTypes.document:
      return <DocumentNotification notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.invitation:
      return <InvitationNotification notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.linkMessage:
      return <LinkMessageNotification notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.officeAbsenceRequest:
      return <OfficeAbsenceRequest notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.officeMonthlySheetApproved:
      return <OfficeMonthlySheetApprovedNotification notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.receivedUserData:
      return <ReceivedUserData notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminderShiftUpdate:
      return <ReminderShiftUpdate notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminder:
      return <ReminderNotification notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.resolvedAbsenceRequest:
      return <ResolvedAbsenceRequest notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.shareUserData:
      return <ShareUserData notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.shareUserDataCommentForFrontliner:
      return <ShareUserDataCommentFrontliner notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.shareUserDataAccepted:
      return <ShareUserDataAccepted notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.shift:
      return <ShiftNotification notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.shiftUpdateSeen:
      return <ShiftUpdateSeenNotification notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminderShiftUpdateManual:
      return <ShiftUpdateManualReminder notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminderMonthlySheetManual:
      return <MonthlySheetManualReminder notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminderMonthlySheetManualForManager:
      return <MonthlySheetManualForManagerReminder notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminderAbsenceRequestManualForManager:
      return <AbsenceRequestManualForManagerReminder notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminderShareUserData:
      return <ShareUserDataReminder notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminderShareUserDataManual:
      return <ShareUserDataManualReminder notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminderShareUserDataManualForManager:
      return <ShareUserDataManualReminderForManager notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.monthlySheetCommentForFrontliner:
      return <MonthlySheetCommentFrontliner notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.monthlySheetCommentForManager:
      return <MonthlySheetCommentManager notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.absenceRequestCommentForFrontliner:
      return <AbsenceRequestCommentFrontliner notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.absenceRequestCommentForManager:
      return <AbsenceRequestCommentManager notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.absenceRequestRequestDeletion:
      return <AbsenceRequestRequestDeletion notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.resolvedAbsenceRequestRequestDeletion:
      return <ResolvedAbsenceRequestRequestDeletion notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.shareUserDataCommentForManager:
      return <ShareUserDataCommentManager notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.resignationInfo:
      return <ResignationInfo notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.cmsNewContent:
      return <CmsNewContentNotification notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.cmsUpdateContent:
      return <CmsUpdateContentNotification notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.secureConnection:
      return <SecureConnection notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminderSecureConnection:
      return <SecureConnectionReminder notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.secureConnectionCommentForFrontliner:
      return <SecureConnectionCommentFrontliner notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.secureConnectionCommentForManager:
      return <SecureConnectionCommentManager notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.reminderSecureConnectionManualForFrontliner:
      return <SecureConnectionManualReminder notification={notification} dismiss={setBackLinkAndDismiss} />

    case NotificationTypes.secureConnectionCompleted:
      return <SecureConnectionCompleted notification={notification} dismiss={setBackLinkAndDismiss} />

    default:
      return <EmptyNotification />
  }
}

export default Notification
