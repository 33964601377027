import React from 'react'
import { Button, Snackbar as MuiSnackbar, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes as flashClasses } from 'services/FlashMessages/styles'
import { classes } from './styles'

interface ISnackbarProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((val: boolean) => boolean)
  flashText: {
    title?: string
    message: string
  }
}

const Snackbar: React.FC<ISnackbarProps> = ({ open, setOpen, flashText }) => {
  const { t } = useFavurTranslation()

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      data-testid="flashtext-error-message"
      sx={[flashClasses.root, classes.snackbar]}
      message={
        <Typography variant="body2">
          {flashText.title && (
            <>
              <strong>{flashText.title}</strong>
              <br />
            </>
          )}
          {flashText.message}
        </Typography>
      }
      action={
        <>
          <Button
            color="secondary"
            data-testid="flashtext-close"
            size="small"
            onClick={() => {
              setOpen(false)
            }}
          >
            {t('common.dialog.button.close')}
          </Button>
        </>
      }
      slotProps={{
        content: {
          sx: {
            '&.MuiSnackbarContent-root': { ...flashClasses.content, ...classes.content },
            '& .MuiSnackbarContent-message': flashClasses.message,
            '& .MuiSnackbarContent-action': { ...flashClasses.action, ...classes.action },
          },
        },
      }}
    />
  )
}

export default Snackbar
