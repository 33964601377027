import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Headline from 'redesign/components/atoms/Headline'
import { HEADLINE_LEVELS } from 'redesign/components/atoms/Headline/constants'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import TeamShiftsList from './TeamShiftsList'
import { SectionContainer, TeamPlanContainer } from './styles'
import type { TeamDetailsContentProps } from './types'

const TeamDetailsContent: React.FC<TeamDetailsContentProps> = ({
  isLoading,
  selectedTeamPlanData,
  showCostCenter,
  date,
}) => {
  const { t } = useFavurTranslation()

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <TeamPlanContainer>
      <SectionContainer>
        <Headline level={HEADLINE_LEVELS.second} text={t('component.teamplanDetailPage.tab.attendant')} />
        <TeamShiftsList teamPersons={selectedTeamPlanData.attendances} showCostCenter={showCostCenter} date={date} />
      </SectionContainer>
      <SectionContainer>
        <Headline level={HEADLINE_LEVELS.second} text={t('component.teamplanDetailPage.tab.absent')} />
        <TeamShiftsList teamPersons={selectedTeamPlanData.absences} showCostCenter={showCostCenter} date={date} />
      </SectionContainer>
    </TeamPlanContainer>
  )
}

export default TeamDetailsContent
