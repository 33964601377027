import React from 'react'
import { ClickAwayListener, Typography, useTheme } from '@mui/material'
import Tooltip from '../../Tooltip/Tooltip'
import { TOOLTIP_VARIANT } from '../../Tooltip/constants'
import { TooltipContent } from './styles'
import type { AvatarTooltipProps } from './types'

const AvatarTooltip: React.FC<AvatarTooltipProps> = ({
  text,
  subtext,
  children,
  open,
  onClose,
  actionButton,
  variant = TOOLTIP_VARIANT.dark,
}) => {
  const { palette } = useTheme()

  return (
    <Tooltip
      arrow
      open={open}
      variant={variant}
      title={
        <ClickAwayListener onClickAway={onClose}>
          <TooltipContent>
            <Typography
              color={variant === TOOLTIP_VARIANT.light ? palette.text.primary : palette.textLight.primary}
              variant="body2"
              noWrap
            >
              {text}
            </Typography>
            {subtext && (
              <Typography
                color={variant === TOOLTIP_VARIANT.light ? palette.text.secondary : palette.textLight.secondary}
                variant="caption"
                noWrap
              >
                {subtext}
              </Typography>
            )}
            {actionButton && actionButton}
          </TooltipContent>
        </ClickAwayListener>
      }
      placement="right"
    >
      {children}
    </Tooltip>
  )
}

export default AvatarTooltip
