import { css, IconButton as MuiIconButton, styled } from '@mui/material'

export const SideMenuWrapper = styled(`div`)`
  height: 100%;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`

export const IconBar = styled(`div`)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(3, 1)};
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(3)};
  width: ${({ theme }) => theme.spacing(5.5)};
  align-self: stretch;
  background-color: ${({ theme }) => theme.palette.common.backgroundCard};
  border-right: ${({ theme }) => `${theme.spacing(0.125)} solid ${theme.palette.common.cardBorder}`};
  border-left: ${({ theme }) => `${theme.spacing(0.125)} solid ${theme.palette.common.cardBorder}`};
`

export const IconButton = styled(MuiIconButton)`
  height: ${({ theme }) => theme.spacing(5.5)};
  width: ${({ theme }) => theme.spacing(5.5)};
`

export const ContentContainer = styled(`div`, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen?: boolean }>`
  background: ${({ theme }) => theme.palette.common.backgroundCard};
  display: flex;
  ${({ isOpen, theme }) =>
    isOpen
      ? css`
          width: ${theme.spacing(42.25)};
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shortest,
          })};
        `
      : css`
          width: 0;
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shortest,
          })};
        `};
`
