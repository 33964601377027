import React from 'react'
import { Box } from '@mui/material'
import LoadMoreButton from 'components/LoadMoreButton'
import { SimpleLoading } from 'components/LoadingIcon'
import useListCmsPaginatedFolders from 'hooks/useListCmsPaginatedFolders'
import { CmsContentT, CmsFolderEntryT, CmsFolderT } from 'types'
import { CONTENT_TYPES } from 'types/cms'
import { styles } from '../styles'
import ContentCard from './ContentCard'
import FolderCard from './FolderCard'
import FoldersBreadcrumb from './FoldersBreadcrumb'
import NoPages from './NoPages'
import { getFolderUuid, isContent } from './utils'

interface IPageListWithFoldersProps {
  ownerId?: number
  onClickItem?: (_entry: CmsContentT | CmsFolderT) => void
}

const PageListWithFolders: React.FC<IPageListWithFoldersProps> = ({ ownerId }) => {
  const { list, loading, hasMoreElements, loadMore, currentFolder } = useListCmsPaginatedFolders({
    contentType: CONTENT_TYPES.page,
    uuid: getFolderUuid(),
    ownerId,
  })

  if (loading) {
    return <SimpleLoading />
  }

  if (!loading && list?.length === 0) {
    return <NoPages />
  }

  return (
    <>
      <Box sx={styles.cardContainer}>
        <FoldersBreadcrumb folder={currentFolder} ownerId={ownerId} />
        {list?.map((folderEntry: CmsFolderEntryT) =>
          isContent(folderEntry) ? (
            <ContentCard key={folderEntry.content?.uuid} content={folderEntry.content as CmsContentT} />
          ) : (
            <FolderCard ownerId={ownerId} key={folderEntry.content?.uuid} folder={folderEntry.folder as CmsFolderT} />
          ),
        )}
      </Box>
      {loading && <SimpleLoading />}
      {!loading && hasMoreElements && <LoadMoreButton onClick={loadMore} />}
    </>
  )
}

export default PageListWithFolders
