import React from 'react'
import { IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ChevronLeft, MoreVertical } from 'redesign/components/atoms/Icon'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import { SIDE_MENU_OPTIONS } from 'redesign/components/templates/SideMenu/constants'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'
import { useSwipeableView } from 'redesign/services/SwipeableView/useSwipeableView'
import { isNative } from 'utils/platform'
import Header from '../../Header'
import SubheaderCalendarWeek from '../../Subheaders/SubheaderCalendarWeek'

const ShiftPlanHeader: React.FC = () => {
  const { t } = useFavurTranslation()
  const {
    state,
    goToToday,
    handleIndexWeekChange,
    goToDay,
    handleIndexChange,
    toggleCalendarOpen,
    weeklyShifts,
    canShowShifts,
    onSelectTeamData,
    selectedTeamData,
  } = useSwipeableView()
  const { setOpen, setPage } = useSideMenuContext()
  const { currentDay, weekIndex } = state
  const { palette, breakpoints } = useTheme()
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'))
  const openSideMenu = () => {
    setPage(SIDE_MENU_OPTIONS.mainMenu)
    setOpen(true)
  }

  return (
    <Header
      middleContent={
        <Typography variant="subtitle1" data-testid="header__title">
          {selectedTeamData ? selectedTeamData.teamName : t('page.shifts.content.shiftsTitle')}
        </Typography>
      }
      startContent={
        selectedTeamData && (
          <IconButton onClick={() => onSelectTeamData(undefined)}>
            <ChevronLeft size={ICON_SIZE.medium} color={palette.text.secondary} />
          </IconButton>
        )
      }
      endContent={
        Boolean(isSmallScreen) && (
          <IconButton onClick={openSideMenu} size="medium">
            <MoreVertical color={palette.primary.main} />
          </IconButton>
        )
      }
      subheader={
        <SubheaderCalendarWeek
          handleToday={goToToday}
          selectedDay={currentDay}
          handleDayClick={goToDay}
          handleCalendarAction={toggleCalendarOpen}
          handleChangeWeek={handleIndexWeekChange}
          handleNextButton={() => handleIndexChange(1)}
          handlePreviousButton={() => handleIndexChange(-1)}
          weekIndex={weekIndex}
          isNative={isNative()}
          weeklyShifts={weeklyShifts}
          canShowShifts={canShowShifts}
          isSmallScreen={isSmallScreen}
        />
      }
    />
  )
}

export default ShiftPlanHeader
