import React from 'react'
import useRolesViews from 'hooks/useRolesViews'
import { AbsencePlannerStateProvider } from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { TaskBarStateProvider } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import PinLogin from 'pages/PinLogin'
import { PARENT_PAGES } from 'redesign/components/templates/SideMenu/constants'
import SideMenuProvider from 'redesign/services/SideMenu/SideMenuProvider'
import AbsencePlannerPage from './AbsencePlannerPage'

const AbsencePlannerPageWrappers: React.FC = () => {
  const { persons } = useRolesViews()

  return (
    <PinLogin>
      <TaskBarStateProvider>
        <AbsencePlannerStateProvider>
          <SideMenuProvider parentPage={PARENT_PAGES.absencePlan} persons={persons}>
            <AbsencePlannerPage />
          </SideMenuProvider>
        </AbsencePlannerStateProvider>
      </TaskBarStateProvider>
    </PinLogin>
  )
}

export default AbsencePlannerPageWrappers
