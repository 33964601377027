import { Block, Maybe, TextBlockData, UserAnnouncement } from 'shared/graphql/graphql'

const removeHtmlTags = (html: string) => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

const getTextBlocks = (announcement: UserAnnouncement, language: string) => {
  const blocks = announcement.announcement?.blocks ?? []
  const textBlocks = blocks.filter((block) => block?.type === 'text' && block.language === language)
  return textBlocks
}

const getText = (block: Maybe<Block>) => {
  const data = block?.data as TextBlockData
  const html = data?.html ?? ''
  return html === '' ? '' : removeHtmlTags(html)
}

export const getTitle = (announcement: UserAnnouncement, language: string) => {
  const blocks = getTextBlocks(announcement, language)
  if (blocks.length === 0) return ''
  return getText(blocks[0])
}

export const getBody = (announcement: UserAnnouncement, language: string) => {
  const blocks = getTextBlocks(announcement, language)
  if (blocks.length < 2) return ''
  return blocks
    .slice(1)
    .map((block) => getText(block))
    .join('. ')
}
