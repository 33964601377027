import { styled } from '@mui/material'

export const LoadingContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`
