import React from 'react'
import { FilterProvider } from 'components/Filter/context'
import useFilterConfiguration from 'pages/Tasks/hooks/useFilterConfiguration'
import type { TaskStateT } from 'pages/Tasks/types'
import { RouteComponentProps } from 'react-router-dom'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import TaskListPageContent from './TaskListPageContent'
import { getUrlTaskState, getUrlTaskType } from './utils'

const TaskListPage: React.FC<RouteComponentProps<{ taskState?: TaskStateT }>> = (props) => {
  const { match } = props

  const { taskState: taskStateUrl } = match.params

  const { configuration, loading: configLoading } = useFilterConfiguration(
    getUrlTaskState(taskStateUrl),
    getUrlTaskType(),
  )

  if (configLoading) return <LoadingSpinner />

  return (
    <FilterProvider configuration={configuration} name="tasks">
      <TaskListPageContent {...props} />
    </FilterProvider>
  )
}
export default TaskListPage
