import React from 'react'
import NotificationsContent from 'redesign/components/templates/NotificationsContent'
import SubheaderTabProvider from 'redesign/services/SubheaderTabs/SubheaderTabProvider'
import { PAGES } from './constants'

const NotificationsPage: React.FC = () => (
  <SubheaderTabProvider pages={PAGES}>
    <NotificationsContent />
  </SubheaderTabProvider>
)

export default NotificationsPage
