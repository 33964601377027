import type { ResolveDayCellColor } from './types'

export const resolveDayCellBackground = ({ isToday, isSelected, isInCalendar, theme }: ResolveDayCellColor) => {
  if (isToday && (isSelected || isInCalendar)) {
    return theme.palette.primary.main
  }

  if (isSelected) {
    return theme.palette.common.darkerGrey
  }

  return theme.palette.common.transparent
}

export const resolveDayCellTextColor = ({ isToday, isSelected, isInCalendar, theme }: ResolveDayCellColor) => {
  const useWhiteText = (isToday && isInCalendar) || isSelected

  return useWhiteText ? theme.palette.common.white : theme.palette.text.primary
}
