import type { DateFormat, DateVariant } from './types'

export const DATE_FORMAT_VARIANTS = {
  shortWithWeekday: 'shortWithWeekday',
  shortWithWeekdayAndTime: 'shortWithWeekdayAndTime',
  shortWithWeekdayAndTimeRange: 'shortWithWeekdayAndTimeRange',
  shortWithoutWeekday: 'shortWithoutWeekday',
  longWithWeekday: 'longWithWeekday',
  longWithoutWeekday: 'longWithoutWeekday',
  monthOnly: 'monthOnly',
} as const

export const DATE_FORMATS: DateFormat = {
  shortWithWeekday: { currentYear: 'd MMM', otherYear: 'd MMM yyyy', sameMonth: 'd' },
  shortWithWeekdayAndTime: {
    currentYear: 'd MMM, HH:mm',
    otherYear: 'd MMM yyyy, HH:mm',
    sameMonth: 'd, HH:mm',
  },
  shortWithWeekdayAndTimeRange: { currentYear: 'd MMM, HH:mm', otherYear: 'd MMM yyyy, HH:mm', sameMonth: 'd, HH:mm' },
  shortWithoutWeekday: { currentYear: 'd MMM', otherYear: 'd MMM yyyy', sameMonth: 'd' },
  longWithWeekday: { currentYear: 'd MMMM', otherYear: 'd MMMM yyyy', sameMonth: 'd' },
  longWithoutWeekday: { currentYear: 'd MMMM', otherYear: 'd MMMM yyyy', sameMonth: 'd' },
  monthOnly: { currentYear: 'MMMM', otherYear: 'MMMM yyyy', sameMonth: 'MMMM' },
}

export const GERMAN_DATE_FORMATS: DateFormat = {
  shortWithWeekday: { currentYear: 'd. MMM', otherYear: 'd. MMM yyyy', sameMonth: 'd.' },
  shortWithWeekdayAndTime: { currentYear: 'd. MMM, HH:mm', otherYear: 'd. MMM yyyy, HH:mm', sameMonth: 'd. HH:mm' },
  shortWithWeekdayAndTimeRange: {
    currentYear: 'd. MMM, HH:mm',
    otherYear: 'd. MMM yyyy, HH:mm',
    sameMonth: 'd. HH:mm',
  },
  shortWithoutWeekday: { currentYear: 'd. MMM', otherYear: 'd. MMM yyyy', sameMonth: 'd.' },
  longWithWeekday: { currentYear: 'd. MMMM', otherYear: 'd. MMMM yyyy', sameMonth: 'd.' },
  longWithoutWeekday: { currentYear: 'd. MMMM', otherYear: 'd. MMMM yyyy', sameMonth: 'd.' },
  monthOnly: { currentYear: 'MMMM', otherYear: 'MMMM yyyy', sameMonth: 'MMMM' },
}

export const FORMATS_WITHOUT_WEEKDAYS: DateVariant[] = [
  DATE_FORMAT_VARIANTS.shortWithoutWeekday,
  DATE_FORMAT_VARIANTS.longWithoutWeekday,
  DATE_FORMAT_VARIANTS.monthOnly,
]
