import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ShiftUpdateStatus } from 'components/ShiftsList/types'
import { DASHBOARD_NEXT_SHIFT_DATE } from 'constants/shift'
import useHistoryUtils from 'hooks/useHistoryUtils'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useRolesViews from 'hooks/useRolesViews'
import { uniq } from 'lodash/fp'
import { REF_SHIFT_LIST_DATE_PARAM_NAME } from 'pages/Tasks/ShiftUpdate/Details/constants'
import { updateShiftUpdateStatusMutationApollo } from 'pages/Tasks/ShiftUpdate/Details/mutations'
import type { ShiftUpdatesByDate } from 'pages/Tasks/ShiftUpdate/Details/types'
import { groupShiftsByDate } from 'pages/Tasks/ShiftUpdate/Details/utils'
import { taskQueryApollo } from 'pages/Tasks/queries'
import type { TaskT, ShiftUpdateTaskT } from 'pages/Tasks/types'
import { useAppStatesContext } from 'services/AppStates'
import FlashMessagesContext from 'services/FlashMessages/context'
import { FlashMessageCloseType, FlashMessageType } from 'services/FlashMessages/types'
import routes from 'services/RoutesProvider/routes'
import type { MutationResult, PlannedShiftT } from 'types'
import { useMutation } from '@apollo/client'
import type { TUseShiftUpdateDetailPage } from './types'

export const useShiftUpdateDetailPage = ({ favurUuid }: TUseShiftUpdateDetailPage) => {
  const [task, setTask] = useState<TaskT<ShiftUpdateTaskT> | null>(null)
  const { pushWithRole } = useHistoryUtils()
  const { isOffice } = useRolesViews()
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const { set } = useAppStatesContext()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const refShiftListDate = urlParams.get(REF_SHIFT_LIST_DATE_PARAM_NAME)
  const [updateShiftUpdateStatus] = useMutation<{ updateShiftUpdateStatus: MutationResult }>(
    updateShiftUpdateStatusMutationApollo,
  )

  const shiftsUpdatedByDate: ShiftUpdatesByDate = useMemo(() => {
    return task ? groupShiftsByDate(task.shiftsUpdated as PlannedShiftT[]) : {}
  }, [task])

  const shiftsUpdatedHistoryByDate: ShiftUpdatesByDate = useMemo(() => {
    return task ? groupShiftsByDate(task.shiftsUpdatedHistory as PlannedShiftT[]) : {}
  }, [task])

  const numberOfUpdatedDays = useMemo(() => Object.keys(shiftsUpdatedByDate).length, [shiftsUpdatedByDate])
  const updatedDays = useMemo(
    () =>
      // eslint-disable-next-line fp/no-mutating-methods
      uniq([...Object.keys(shiftsUpdatedByDate), ...Object.keys(shiftsUpdatedHistoryByDate)]).sort(),
    [shiftsUpdatedByDate, shiftsUpdatedHistoryByDate],
  )

  const onGoBack = useCallback(() => {
    if (refShiftListDate) {
      set(DASHBOARD_NEXT_SHIFT_DATE, refShiftListDate)
    }
  }, [refShiftListDate, set])

  const closeTask = useCallback(async () => {
    if (!task?.favurUuid) return

    try {
      removeAll()
      const result = await updateShiftUpdateStatus({
        variables: { favurUuid: task.favurUuid, status: ShiftUpdateStatus.reviewFrontlineSeen },
      })

      if (result.data?.updateShiftUpdateStatus?.success) {
        setFlashMessage(
          'page.tasks.shiftUpdate.detail.label.tasksClosed',
          2_000,
          FlashMessageType.INFO,
          FlashMessageCloseType.ICON,
        )
      } else {
        setFlashMessage('common.error.be.default')
      }
    } catch (err) {
      setFlashMessage('common.error.be.default')
    }
  }, [removeAll, setFlashMessage, task, updateShiftUpdateStatus])

  const { loading } = useJamesApolloQuery<{ task: TaskT<ShiftUpdateTaskT> }>(taskQueryApollo, {
    variables: { favurUuid, office: isOffice },
    fetchPolicy: 'no-cache',
    onCompleted: ({ task: queriedTask }) => setTask(queriedTask),
    onError: () => {
      pushWithRole(routes.dashboard)
    },
  })

  useEffect(() => {
    if (task?.favurUuid && task?.status !== ShiftUpdateStatus.reviewFrontlineUnseen) return
    closeTask()
  }, [closeTask, task])

  return { onGoBack, loading, shiftsUpdatedByDate, shiftsUpdatedHistoryByDate, numberOfUpdatedDays, updatedDays }
}
