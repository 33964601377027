import React, { useCallback } from 'react'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import { FF_GROUP_WIKI_PAGES } from 'constants/featureFlags'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useStateBackLink from 'hooks/useStateBackLink'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import palette from 'utils/theme/palette'
import { styles } from '../styles'
import PageList from './PageList'
import PageListWithFolders from './PageListWithFolders'

const ContentIndexPage: React.FC<RouteComponentProps<{ ownerId: string }>> = ({ match }) => {
  const { t } = useFavurTranslation()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const history = useHistory()
  const groupWikiPagesFF = useFeatureFlag(FF_GROUP_WIKI_PAGES) === true

  const {
    params: { ownerId },
  } = match
  const owner = parseInt(ownerId)

  const gotoBackLink = useCallback(() => {
    pushBackLinkOrDefault(() => {
      history.goBack()
    })
  }, [pushBackLinkOrDefault, history])

  return (
    <Page
      backgroundColor={palette.primary[15]}
      header={
        <WithBackwardLink
          onClick={gotoBackLink}
          title={t('page.cms.content.header')}
          iconSx={styles.subHeaderIcon}
          iconThin
        />
      }
      sxHeader={styles.subHeader}
    >
      {groupWikiPagesFF ? <PageListWithFolders ownerId={owner} /> : <PageList ownerId={owner} />}
    </Page>
  )
}

export default ContentIndexPage
