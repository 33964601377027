import React, { useMemo } from 'react'
import { Badge, IconButton, Typography, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Tooltip from 'redesign/components/organisms/Tooltip'
import { TOOLTIP_VARIANT } from 'redesign/components/organisms/Tooltip/constants'
import { SIDE_MENU_TRANSLATIONS } from '../SideMenu/constants'
import { sideMenuIcons } from './constants'
import type { SideMenuIconProps } from './types'

const SideMenuIcon: React.FC<SideMenuIconProps> = ({ toggleOpen, taskCount, page, selected }) => {
  const { palette } = useTheme()
  const { t } = useFavurTranslation()
  const Icon = useMemo(() => sideMenuIcons[page], [page])

  return (
    <Tooltip
      arrow
      placement="left"
      variant={TOOLTIP_VARIANT.dark}
      title={
        <Typography variant="body2" noWrap>
          {t(SIDE_MENU_TRANSLATIONS[page])}
        </Typography>
      }
    >
      <IconButton onClick={toggleOpen} size="large">
        <Badge badgeContent={taskCount} color="primary" variant="small">
          <Icon isFilled={selected} color={selected ? palette.primary.main : palette.text.secondary} />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

export default SideMenuIcon
