import { Box, css, styled } from '@mui/material'
import { IonContent } from '@ionic/react'
import { calculateContentPadding } from './utils'

export const HeaderPlaceholder = styled('div')`
  height: ${({ theme }) => theme.spacing(12.5)};
`

export const ContentContainer = styled(IonContent, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{
  backgroundColor?: string
}>`
  --ion-background-color: ${({ backgroundColor }) => `${backgroundColor ?? 'transparent'}`};
`

export const Content = styled(Box, {
  // eslint-disable-next-line complexity
  shouldForwardProp: (prop) =>
    prop !== 'center' &&
    prop !== 'growContent' &&
    prop !== 'isFlex' &&
    prop !== 'footer' &&
    prop !== 'columnCenter' &&
    prop !== 'fitToViewport' &&
    prop !== 'fullContentHeight' &&
    prop !== 'isOpen' &&
    prop !== 'fullContentWidth' &&
    prop !== 'removeLastMargin' &&
    prop !== 'hasFloatingButtons' &&
    prop !== 'addSafeMargin' &&
    prop !== 'noPadding' &&
    prop !== 'extraBottomMargin' &&
    prop !== 'paddingX' &&
    prop !== 'isNative',
})<{
  center?: boolean
  growContent?: boolean
  isFlex?: boolean
  footer?: boolean
  columnCenter?: boolean
  fitToViewport?: boolean
  fullContentHeight?: boolean
  isOpen?: boolean
  fullContentWidth?: boolean
  removeLastMargin?: boolean
  hasFloatingButtons?: boolean
  addSafeMargin?: boolean
  noPadding?: boolean
  extraBottomMargin?: number
  paddingX?: number
  isNative?: boolean
}>`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  ${({ isNative }) =>
    !isNative &&
    css`
      height: 100%;
    `}

  ${({
    fullContentWidth,
    noPadding,
    hasFloatingButtons,
    addSafeMargin,
    paddingX,
    extraBottomMargin,
    removeLastMargin,
    theme,
  }) => `
    max-width:  ${fullContentWidth ? '100%' : theme.spacing(75)};
    padding: ${calculateContentPadding({
      noPadding,
      hasFloatingButtons,
      addSafeMargin,
      paddingX: paddingX ?? 0,
      extraBottomMargin: extraBottomMargin ?? 0,
      theme,
    })};

      & > div > div > :last-child {
        margin-bottom: ${removeLastMargin ? 0 : theme.spacing(3)};
      }
    `};
  ${({ isFlex }) =>
    isFlex &&
    `
      display: flex;
  `};
  ${({ center }) =>
    center &&
    `
      justify-content: center;
  `};
  ${({ footer, theme }) =>
    footer &&
    `
      padding: ${theme.spacing(1.5)};
  `};
  ${({ columnCenter }) =>
    columnCenter &&
    `
    flex-direction: column;
    justify-content: center;
  `};
  ${({ fitToViewport }) =>
    fitToViewport &&
    `
    max-height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  `};
  ${({ fullContentHeight }) =>
    fullContentHeight &&
    `
    height: 100%;
  `};
`
