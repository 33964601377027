import { ROLE_VIEWS } from 'constants/roles'
import he from 'he'
import { absenceRequestStatus } from 'pages/Tasks/AbsenceRequest/constants'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDateRange } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import { absencePeriodsTranslationMap, absencePeriods } from 'shared/constants'
import { ResolvedAbsenceRequestRequestDeletionNotification } from 'shared/graphql/graphql'
import { newDateWithoutTime } from 'utils/date'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getResolveAbsenceRequestDeletionData = ({ notification, t, locale }: GetNotificationData) => {
  const {
    managerNameShort,
    state,
    taskUuid,
    period,
    dateFrom,
    dateTo,
  } = notification.data as ResolvedAbsenceRequestRequestDeletionNotification
  const { title: acceptedTitle } = notificationGlosssaryKeys.resolvedAbsenceRequestRequestDeletion
  const { title: declinedTitle } = notificationGlosssaryKeys.resolvedAbsenceRequestRequestDeletionDeclined
  const dateFormat = DATE_FORMAT_VARIANTS.shortWithWeekday

  const body = formatDateRange({
    dates: {
      startDate: newDateWithoutTime(dateFrom),
      endDate: newDateWithoutTime(dateTo),
    },
    dateFormat,
    locale,
    t,
  })

  const isMultiday = dateFrom !== dateTo
  const absencePeriod = () => {
    if (isMultiday || !period || period.trim() === '') {
      return ''
    }
    const translatedPeriod = absencePeriodsTranslationMap[period]
    return t(absencePeriods[translatedPeriod])
  }
  const titleKey = state === absenceRequestStatus.acceptedDeletionRequest ? acceptedTitle : declinedTitle

  return {
    title: he.decode(
      t(titleKey, {
        managerNameShort,
      }),
    ),
    body: isMultiday ? body : `${body} ${absencePeriod()}`,
    route: `${routes.absenceRequestDetail}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
