import { styled } from '@mui/material'

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`

export const DailyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`

export const ShiftGrupContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

export const ShiftsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`
