import React, { memo, useEffect, useState } from 'react'
import TeamplanDatePicker from 'components/DatePickers/TeamplanDatePicker'
import { getCurrentDate } from './getCurrentDate'

interface IMonthIndicatorProps {
  shownMonthIndex: number
  baseDate: Date
  onMonthSelect: (newDate: Date) => void
}

const MonthSelector: React.FC<IMonthIndicatorProps> = ({ shownMonthIndex, baseDate, onMonthSelect }) => {
  const [datePickerDate, setDatePickerDate] = useState(getCurrentDate(baseDate, shownMonthIndex))

  useEffect(() => {
    setDatePickerDate(getCurrentDate(baseDate, shownMonthIndex))
  }, [baseDate, shownMonthIndex])

  return (
    <TeamplanDatePicker
      value={datePickerDate}
      onChange={(date) => setDatePickerDate(date)}
      onAccept={(date) => onMonthSelect(date)}
      variant="month"
      dataTestId="absence-planner-shown_month"
    />
  )
}

export default memo(MonthSelector)
