import React, { useMemo, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import { FilterType, PersonsT } from 'components/Filter/types'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import FullScreenModal from 'redesign/components/organisms/FullScreenModal'
import FiltersBody from './FiltersBody'
import FiltersFooter from './FiltersFooter'
import FiltersHeaders from './FiltersHeaders'
import type { FiltersProps } from './types'

const Filters: React.FC<FiltersProps> = ({ rootId }) => {
  const {
    cancelPersonsList,
    closeFilterPage,
    getConfiguration,
    getPersonsListSelected,
    isFilterPageOpen,
    getCurrentSelectedPersons,
    confirmPersonsList,
    submitFilterPage,
    resetFilterPage,
    validateFilters,
    clearPersonList,
  } = useFilterContext()
  const theme = useTheme()
  const redesignFF = Boolean(useFeatureFlag(FF_FAVUR_REDESIGN))
  const [displayDateValue, setDisplayDateValue] = useState(false)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const configuration = useMemo(() => getConfiguration(), [getConfiguration])
  const personsListSelected = useMemo(() => getPersonsListSelected(), [getPersonsListSelected])
  const isPersonsListSelected = Boolean(personsListSelected)
  const personsOption = useMemo(
    () =>
      personsListSelected
        ? (configuration.find((option) => option.name === personsListSelected) as PersonsT)
        : undefined,
    [configuration, personsListSelected],
  )
  const personsConfiguration = useMemo(() => configuration.find((config) => config.type === FilterType.PERSONS), [
    configuration,
  ])
  const onReset = () => {
    if (personsConfiguration) {
      clearPersonList(personsConfiguration.name)
    }

    setDisplayDateValue(false)
    resetFilterPage({ isRedesign: redesignFF })
  }
  const onSubmit = () => {
    if (isPersonsListSelected && personsOption) {
      confirmPersonsList(personsOption.name, getCurrentSelectedPersons())
    } else {
      submitFilterPage()
    }
  }

  return (
    <FullScreenModal
      disableDrag
      noPadding
      isSmallScreen={isSmallScreen}
      rootId={rootId}
      onClose={closeFilterPage}
      isOpen={isFilterPageOpen()}
      header={
        <FiltersHeaders
          isPersonsFilter={isPersonsListSelected}
          onClosePersonsFilters={() => cancelPersonsList(personsOption?.name ?? '')}
          onClose={closeFilterPage}
          onReset={onReset}
          isSmallScreen={isSmallScreen}
        />
      }
      footer={
        <FiltersFooter
          isPersonsFilter={isPersonsListSelected}
          hasSelectedPersons={Boolean(getCurrentSelectedPersons().length)}
          onClick={onSubmit}
          validFilters={validateFilters()}
        />
      }
    >
      <FiltersBody displayDateValue={displayDateValue} setDisplayDateValue={setDisplayDateValue} />
    </FullScreenModal>
  )
}

export default Filters
