import React from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { NotificationTemplatePropsT } from 'components/Notification/types'
import { cardVariants } from 'constants/componentsBasics'
import { notificationStatuses } from 'types'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import { classes } from './styles'

const NotificationTemplate: React.FC<NotificationTemplatePropsT> = ({
  notification,
  titleIcon,
  title,
  body,
  details,
  testId,
}) => {
  const isNew = notification.status === notificationStatuses.new
  return (
    <Box data-testid={testId} sx={classes.root} onClick={details}>
      <Card {...(isNew ? { variant: cardVariants.highlight } : {})}>
        <Box sx={classes.container}>
          <Box sx={classes.bodyContainer}>
            <Box sx={classes.titleContainer}>
              {titleIcon ? titleIcon : null}
              <Typography variant="subtitle2" sx={classes.title}>
                {title}
              </Typography>
            </Box>
            <Typography variant="body2" sx={classes.body}>
              {body}
            </Typography>
            <Typography variant="caption" sx={classes.date}>
              {getFormattedFromISOString(notification.updatedAt, uiDateFormat, undefined)}
            </Typography>
          </Box>
          <Box sx={classes.dotContainer}>{isNew && <Box sx={classes.newDot} />}</Box>
        </Box>
      </Card>
    </Box>
  )
}

export default NotificationTemplate
