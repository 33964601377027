import React from 'react'
import CalendarActions from 'redesign/components/organisms/CalendarActions'
import SwipeableCalendarRowWeek from '../../CalendarRowWeekDay/SwipeableCalendarRowWeek'
import { Container, FlexContainer } from './styles'
import type { SubheaderCalendarWeekProps } from './types'

const SubheaderCalendarWeek: React.FC<SubheaderCalendarWeekProps> = ({
  selectedDay,
  handleDayClick,
  handleToday,
  handleCalendarAction,
  handleNextButton,
  handlePreviousButton,
  handleChangeWeek,
  weekIndex,
  weeklyShifts,
  isNative,
  showCalendarActions = true,
  showTopCalendarShifts,
  showDayNumbers,
  canShowShifts,
  isSmallScreen,
  showMonthCTA,
}) => (
  <FlexContainer>
    <Container>
      {showCalendarActions && (
        <CalendarActions
          handleNextButton={handleNextButton}
          handlePreviousButton={handlePreviousButton}
          referenceDay={selectedDay}
          handleCalendarAction={handleCalendarAction}
          handleToday={handleToday}
          isNative={isNative}
          isSmallScreen={isSmallScreen}
          showMonthCTA={showMonthCTA}
        />
      )}

      <SwipeableCalendarRowWeek
        handleChangeWeek={handleChangeWeek}
        selectedDay={selectedDay}
        weeklyShifts={weeklyShifts}
        handleDayClick={handleDayClick}
        showTopCalendarShifts={showTopCalendarShifts}
        showDayNumbers={showDayNumbers}
        weekIndex={weekIndex}
        isNative={isNative}
        canShowShifts={canShowShifts}
      />
    </Container>
  </FlexContainer>
)

export default SubheaderCalendarWeek
