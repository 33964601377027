import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import NavigationIcon from '../../atoms/NavigationIcon'
import IconAndTextBlock from '../IconAndTextBlock'
import { LIST_GROUP_MOTION, LIST_ITEM_MOTION } from './motions'
import { ListGroup, NavItem } from './styles'
import type { NavigationGroupProps } from './types'

const NavigationGroup: React.FC<NavigationGroupProps> = ({ navPoints, onNavigationGroupClick }) => {
  const { t } = useFavurTranslation()
  const theme = useTheme()
  const [hoverStates, setHoverStates] = useState(Array(navPoints.length).fill(false))

  const handleHoverStates = (index: number, state: boolean) =>
    setHoverStates((prevState) => {
      const newState = [...prevState]
      newState[index] = state

      return newState
    })

  return (
    <ListGroup initial="hidden" animate="visible" exit="hidden" variants={LIST_GROUP_MOTION}>
      {navPoints.map((navPoint, index) => {
        const { redesignIcon: Icon, label, testId } = navPoint

        return (
          <NavItem
            key={label}
            data-testid={testId}
            onClick={() => onNavigationGroupClick(navPoint)}
            tabIndex={index}
            variants={LIST_ITEM_MOTION(index)}
            onMouseEnter={() => handleHoverStates(index, true)}
            onMouseLeave={() => handleHoverStates(index, false)}
          >
            <IconAndTextBlock
              icon={
                <NavigationIcon
                  icon={Icon}
                  color={hoverStates[index] ? theme.palette.common.white : theme.palette.common.navItemBase}
                  isFilled={hoverStates[index]}
                />
              }
              title={t(label)}
              variant="body1"
              gap={2}
              color={hoverStates[index] ? theme.palette.common.white : theme.palette.common.navItemBase}
            />
          </NavItem>
        )
      })}
    </ListGroup>
  )
}

export default NavigationGroup
