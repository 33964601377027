import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getModuleAndRelatedTutorials, tutorialNames } from 'constants/tutorials'
import useModule from 'hooks/useModule'
import useSessionContext from 'hooks/useSessionContext'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import Page from '../../components/Page'
import useFavurTranslation from '../../hooks/useFavurTranslation'
import useStateBackLink from '../../hooks/useStateBackLink'
import useTutorials from '../../hooks/useTutorials'
import TutorialAbsenceRequestFrontliner from './AbsenceRequestFrontliner'
import TutorialAbsenceRequestManager from './AbsenceRequestManager'
import TutorialAbsenceplanFrontliner from './AbsenceplanFrontliner'
import TutorialAbsenceplanManager from './AbsenceplanManager'
import Resignation from './Resignation'
import TutorialSecureConnection from './SecureConnection'
import TutorialShiftplan from './Shiftplan'
import TutorialSocialEngineering from './SocialEngineering'
import TutorialWorkTimeControlFrontliner from './WorkTimeControlFrontliner'
import TutorialWorkTimeControlManager from './WorkTimeControlManager'

const Tutorial: React.FC<RouteComponentProps<{ tutorialName: string }>> = ({ match, location }) => {
  const queryParams = new URLSearchParams(location.search)
  const taskId = queryParams.get('taskUuid') ?? undefined
  const { tutorialName: tutorialNameParam } = match.params
  const { module, tutorials } = getModuleAndRelatedTutorials(tutorialNameParam)
  const [tutorialName, ...relatedTutorials] = tutorials
  const [step, setStep] = useState(0)

  const { t } = useFavurTranslation()
  const history = useHistory()
  const { hasAccessToModule } = useModule()
  const { markAsSeenList, isReady, beenShownBefore } = useTutorials()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const { user } = useSessionContext()

  const isTutorialSeen = useMemo(() => isReady && !beenShownBefore(tutorialName), [
    beenShownBefore,
    isReady,
    tutorialName,
  ])

  const goBack = useCallback(
    (route: string) => {
      pushBackLinkOrDefault(route)
    },
    [pushBackLinkOrDefault],
  )

  useEffect(() => {
    if (!tutorialName || !(module && hasAccessToModule(module))) {
      history.push(routes.dashboard)
    }
  }, [hasAccessToModule, history, module, tutorialName])

  useEffect(() => {
    if (!isTutorialSeen) return
    markAsSeenList([tutorialName, ...relatedTutorials])
  }, [isTutorialSeen, markAsSeenList, tutorialName, relatedTutorials])

  const getTutorialComponent = useCallback(() => {
    const props = { tutorialName, t, step, setStep, goBack, history }

    switch (tutorialName) {
      case tutorialNames.ABSENCE_REQUEST_FRONTLINER:
        return <TutorialAbsenceRequestFrontliner {...props} />
      case tutorialNames.ABSENCE_REQUEST_MANAGER:
        return <TutorialAbsenceRequestManager {...props} />
      case tutorialNames.ABSENCE_PLAN_FRONTLINER:
        return <TutorialAbsenceplanFrontliner {...props} />
      case tutorialNames.ABSENCE_PLAN_MANAGER:
        return <TutorialAbsenceplanManager {...props} />
      case tutorialNames.SECURE_CONNECTION:
        return <TutorialSecureConnection taskId={taskId} {...props} />
      case tutorialNames.SHIFT_PLAN:
        return <TutorialShiftplan hasUserAvatar={Boolean(user?.avatarUrl)} {...props} />
      case tutorialNames.SOCIAL_ENGINEERING:
        return <TutorialSocialEngineering {...props} />
      case tutorialNames.RESIGNATION:
        return <Resignation {...props} />
      case tutorialNames.WORK_TIME_CONTROL_FRONTLINER:
        return <TutorialWorkTimeControlFrontliner {...props} />
      case tutorialNames.WORK_TIME_CONTROL_MANAGER:
        return <TutorialWorkTimeControlManager {...props} />

      default:
        return <></>
    }
  }, [tutorialName, t, step, goBack, history, taskId, user?.avatarUrl])

  return (
    <Page hideNativeNavigation hideHamburger noPadding hideWebToolbar removeLastMargin>
      {getTutorialComponent()}
    </Page>
  )
}

export default Tutorial
