import React from 'react'
import DrawerHeaderEndContent from 'redesign/components/molecules/DrawerHeaderEndContent'
import Header from '../Header'
import type { DrawerHeaderProps } from './types'

const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  closeIconOnClick,
  startContent,
  middleContent,
  endContent,
  subheader,
  isSmallScreen,
}) => (
  <Header
    startContent={startContent}
    middleContent={middleContent}
    endContent={<DrawerHeaderEndContent closeIconOnClick={closeIconOnClick}>{endContent}</DrawerHeaderEndContent>}
    subheader={subheader}
    sidePadding={!isSmallScreen}
  />
)

export default DrawerHeader
