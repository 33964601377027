import React, { useCallback, useState } from 'react'
import { Box } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useModule from 'hooks/useModule'
import { useHistory, useLocation } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { modules } from 'shared/constants'
import { PersonT } from 'types'
import EmployeeCard from './components/EmployeeCard'
import NoAvatarDrawer from './components/NoAvatarDrawer'
import { employeeCardDataQuery } from './queries'
import { backgroundColor, classes, headerClasses } from './styles'

export const INITIAL_PERSON_PARAM_NAME = 'person'

const EmployeeCardPage: React.FC = () => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { search } = useLocation()
  const { getPersonIdsWithAccessToModule, hasPersonAccessToModule } = useModule()
  const modulePersonIds = getPersonIdsWithAccessToModule(modules.employeeCards)
  const [persons, setPersons] = useState<PersonT[]>()
  const [initialIndex, setInitialIndex] = useState(0)
  const [personInitialId, setPersonInitialId] = useState<number | undefined>(undefined)

  const { loading } = useJamesApolloQuery<{ findAllPersons: PersonT[] }>(employeeCardDataQuery, {
    onCompleted(data) {
      const modulePersons = data.findAllPersons.filter(
        (person) => person.id !== undefined && modulePersonIds.includes(Number(person.id)),
      )
      const urlParams = new URLSearchParams(search)
      const initialPerson = urlParams.get(INITIAL_PERSON_PARAM_NAME)

      if (initialPerson !== null) {
        const index = modulePersons.findIndex((person) => person.favurUuid === initialPerson)
        setInitialIndex(index === -1 ? 0 : index)
        setPersonInitialId(index !== -1 ? Number(modulePersons[index].id) : undefined)
      }
      setPersons(modulePersons)
    },
  })

  const showNoAvatarDrawer = useCallback(
    (personId: number | undefined) => {
      if (!persons?.length) return false

      const effectivePersonInitialId = personInitialId ?? personId

      // When the user access directly to the employee card url, there is not person specified on url params
      if (effectivePersonInitialId === null || effectivePersonInitialId === undefined) return false

      // For multitenant users, check if the person of the current employer has access to the module
      if (!hasPersonAccessToModule(effectivePersonInitialId, modules.userAvatar)) return false

      // using persons[0] because all the persons should have same user
      return persons[0].user?.avatarUrl === null
    },
    [hasPersonAccessToModule, personInitialId, persons],
  )

  return (
    <Page
      header={<BackwardLink onClickIcon={() => history.goBack()} title={t('employeeCard.main.title')} />}
      hideNativeNavigation
      hideHamburger
      hideBackground
      removeLastMargin
      sxHeader={headerClasses.subHeader}
      backgroundColor={backgroundColor}
      growContent={false}
      noPadding
    >
      {loading || !persons ? (
        <SimpleLoading />
      ) : (
        <>
          <SwipeableViews style={classes.slider} containerStyle={classes.slideContainer} index={initialIndex}>
            {persons.map((person) => (
              <>
                <Box key={person.id} sx={classes.slide} data-testid={`employee-card_${person.favurUuid}`}>
                  <EmployeeCard person={person} />
                </Box>
                <NoAvatarDrawer open={showNoAvatarDrawer(person.id)} />
              </>
            ))}
          </SwipeableViews>
        </>
      )}
    </Page>
  )
}

export default EmployeeCardPage
