import {
  format,
  isThisYear,
  isToday,
  isYesterday,
  isTomorrow,
  isSameDay,
  eachMonthOfInterval,
  endOfYear,
  Locale,
  startOfYear,
  eachYearOfInterval,
  isSameMonth as isSameMonthFns,
  isSameYear,
  add,
  formatISO,
} from 'date-fns'
import { de } from 'date-fns/locale'
import { newDateWithoutTime } from 'utils/date'
import { FORMATS_WITHOUT_WEEKDAYS, DATE_FORMATS, DATE_FORMAT_VARIANTS, GERMAN_DATE_FORMATS } from './constants'
import { FormatDate, FormatDateRange, GetDay, GetFormatString, GetWeekDay } from './types'

export const getWeekDay = ({ date, dateFormat = 'EEEE', locale }: GetWeekDay) => format(date, dateFormat, { locale })

export const getDay = ({ date, locale, t }: GetDay) => {
  if (isYesterday(date)) return `${t('page.date.yesterday')},`
  if (isToday(date)) return `${t('page.date.today')},`
  if (isTomorrow(date)) return `${t('page.date.tomorrow')},`

  return getWeekDay({ date, dateFormat: 'EEE', locale })
}

export const getFormatString = ({ date, dateFormat, locale, skipMonth = false }: GetFormatString) => {
  const dateFormats = locale === de ? GERMAN_DATE_FORMATS : DATE_FORMATS

  if (skipMonth) return dateFormats[dateFormat].sameMonth

  return isThisYear(date) ? dateFormats[dateFormat].currentYear : dateFormats[dateFormat].otherYear
}

export const formatDate = ({ date, dateFormat, endDate, locale, t, skipMonth }: FormatDate) => {
  if (!date || !dateFormat) return ''

  const formatStr = getFormatString({ date, dateFormat, locale, skipMonth })
  const formattedDate = format(date, formatStr, { locale })

  if (FORMATS_WITHOUT_WEEKDAYS.includes(dateFormat)) {
    return formattedDate
  }

  const formattedDateWithWeekday = `${getDay({ date, locale, t })} ${formattedDate}`
  const hasTimeRange = endDate && dateFormat === DATE_FORMAT_VARIANTS.shortWithWeekdayAndTimeRange

  return hasTimeRange ? `${formattedDateWithWeekday} – ${format(endDate, 'HH:mm')}` : formattedDateWithWeekday
}

export const formatDateRange = ({ dates, dateFormat, locale, t }: FormatDateRange) => {
  const { startDate, endDate } = dates
  if (!startDate || !dateFormat) return ''

  if (!endDate || isSameDay(startDate, endDate)) {
    return `${formatDate({ date: startDate, dateFormat, locale, t })}`
  }

  const isSameMonth = isSameMonthFns(startDate, endDate) && isSameYear(startDate, endDate)

  if (isSameMonth && dateFormat === DATE_FORMAT_VARIANTS.monthOnly) {
    return formatDate({ date: startDate, dateFormat, locale, t })
  }

  const formattedStartDate = `${formatDate({
    date: startDate,
    dateFormat,
    locale,
    t,
    skipMonth: isSameMonth,
  })}`

  return `${formattedStartDate} – ${formatDate({
    date: endDate,
    dateFormat,
    locale,
    t,
  })}`
}

export const getCalendarMonths = ({ locale }: { locale?: Locale }) =>
  eachMonthOfInterval({
    start: startOfYear(newDateWithoutTime()),
    end: endOfYear(newDateWithoutTime()),
  }).map((month, index) => {
    const label = format(month, 'MMMM', { locale })
    const shortLabel = format(month, 'MMM', { locale })
    const monthNumber = format(month, 'MM', { locale })

    return {
      label,
      shortLabel,
      value: index,
      monthNumber,
    }
  })

export const getCalendarYears = ({ startYear = 2015, endYear }: { startYear?: number; endYear?: number } = {}) =>
  eachYearOfInterval({
    start: newDateWithoutTime(`${startYear}`),
    end: endYear ? newDateWithoutTime(`${endYear}`) : newDateWithoutTime(),
  }).map((date, index) => ({ label: `${date.getFullYear()}`, value: index }))

export const getDatetimeRangeWithTimezone = ({ start, end }: { start: string; end: string }) => {
  const timezoneOffset = newDateWithoutTime(start).getTimezoneOffset()
  const startDate = add(newDateWithoutTime(start), { minutes: timezoneOffset })
  const endDate = add(newDateWithoutTime(end), { days: 1, minutes: timezoneOffset - 1 })

  return {
    startDate: formatISO(startDate),
    endDate: formatISO(endDate),
  }
}
