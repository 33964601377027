import React from 'react'
import { IconButton, Typography } from '@mui/material'
import { ChevronLeftMD, ChevronRightMD } from 'redesign/components/atoms/Icon'
import { CalendarHeaderContainer } from './styles'
import { MonthCalendarHeaderProps } from './types'

const MonthCalendarHeader: React.FC<MonthCalendarHeaderProps> = ({ year, addYear, subtractYear }) => (
  <CalendarHeaderContainer>
    <IconButton color="primary" onClick={subtractYear}>
      <ChevronLeftMD />
    </IconButton>
    <Typography variant="subtitle1">{year}</Typography>
    <IconButton color="primary" onClick={addYear}>
      <ChevronRightMD />
    </IconButton>
  </CalendarHeaderContainer>
)

export default MonthCalendarHeader
