import React from 'react'
import NavigationMenu from 'redesign/components/organisms/Navigation/NavigationMenu'
import { isNative } from 'utils/platform'
import type { TSideMenu } from './types'
import { useSideMenu } from './useSideMenu'

const SideMenuNavigation: React.FC<TSideMenu> = ({
  auth,
  navigate,
  toggleDrawer,
  hideHamburger,
  showHamburgerHeader,
  hideWebToolbar,
}) => {
  const {
    appInfoData,
    showAppInfo,
    navigationLinks,
    firstName,
    buttonCallbacks,
    handleNavigationGroupClick,
    toggleAppInfoDialog,
  } = useSideMenu({
    toggleDrawer,
  })

  if (!isNative() && hideWebToolbar) {
    return null
  }

  return (
    <NavigationMenu
      appInfoData={appInfoData}
      auth={auth}
      navigate={navigate}
      showAppInfo={showAppInfo}
      toggleDrawer={toggleDrawer}
      hideHamburger={hideHamburger}
      navigationLinks={navigationLinks}
      userFirstName={firstName}
      isNative={isNative()}
      onNavigationGroupClick={handleNavigationGroupClick}
      navigationButtonCallbacks={buttonCallbacks}
      toggleAppInfoDialog={toggleAppInfoDialog}
      showHamburgerHeader={showHamburgerHeader}
    />
  )
}

export default SideMenuNavigation
