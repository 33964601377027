import React, { useCallback, useMemo, useState } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import ControlledDatePicker from 'components/DatePickers/ControlledDatePicker'
import ControlledSelect from 'components/Forms/components/ControlledSelect'
import ToggleBar from 'components/ToggleBar'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { absencePeriods } from 'shared/constants'
import { daysIncludedInRange, newDateWithoutTime } from 'utils/date'
import { dateRangeOptionsValues, dateRangeOptionsKeys, inputDateFormat } from '../constants'
import { newAbsenceRequestClasses } from './styles'
import { FormErrorsT, FormFieldsT } from './useNewAbsenceRequestForm'

interface INewFromProps {
  formFields: FormFieldsT
  formErrors: FormErrorsT
  setValue: (field: string, value: string | (Date | null)) => void
  personIds: string[]
  getTenantOptions: () => Record<string, string>
  handlePersonUuidChange: (personUuid: string) => void
  onSideBar?: boolean
}

const MAX_LENGTH_REQUEST_COMMENT = 100

const getIfExtraBottomPadding = (errorField?: string) =>
  !errorField ? newAbsenceRequestClasses.extraBottomPadding : {}

const NewForm: React.FC<INewFromProps> = ({
  formFields,
  formErrors,
  setValue,
  personIds,
  getTenantOptions,
  handlePersonUuidChange,
  onSideBar,
}) => {
  const { t } = useFavurTranslation()
  const {
    dateRange: { value: dateRange },
    personUuid: { value: personUuid },
    absencePeriod: { value: absencePeriod },
    toDate: { value: toDate },
    fromDate: { value: fromDate },
    comment: { value: comment },
  } = formFields

  const todayDate = newDateWithoutTime()
  const isMultipleDates = useMemo(() => dateRange === dateRangeOptionsKeys.multipleDays, [dateRange])
  const [dateRangeToggle, setDateRangeToggle] = useState<number>(0)

  const numDays = useMemo(() => {
    if (!toDate || !fromDate) {
      return 0
    }

    return daysIncludedInRange(fromDate as Date, toDate as Date)
  }, [fromDate, toDate])

  const handleDateRangeChange = useCallback(
    (e: React.SyntheticEvent<Element, Event>, newIndex: number) => {
      if (newIndex === 1) {
        setValue('dateRange', dateRangeOptionsKeys.multipleDays)
        setValue('absencePeriod', 'fullDay')
      } else {
        setValue('dateRange', dateRangeOptionsKeys.singleDay)
        setValue('absencePeriod', '')
        setValue('toDate', null)
      }

      setDateRangeToggle(newIndex)
    },
    [setValue],
  )

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} alignItems="center" gap={0.5}>
      <Box width={1} textAlign="left" marginBottom={1} marginTop={0.5}>
        <Typography variant="body2">{t('absenceRequest.new.helperText')}</Typography>
      </Box>
      {personIds.length > 1 && (
        <Box width={1} sx={getIfExtraBottomPadding(formErrors.personUuid)}>
          <ControlledSelect
            name="personUuid"
            value={personUuid as string}
            options={getTenantOptions()}
            onChange={(e) => {
              handlePersonUuidChange(e.target.value)
            }}
            error={Boolean(formErrors.personUuid)}
            helperText={formErrors.personUuid}
            label={t('absenceRequest.new.personUuid.label')}
          />
        </Box>
      )}
      <Box width={1}>
        <ToggleBar
          currentToggleIndex={dateRangeToggle}
          handleChange={handleDateRangeChange}
          firstOption={t(dateRangeOptionsValues.singleDay)}
          secondOption={t(dateRangeOptionsValues.multipleDays)}
        />
      </Box>
      <Box width={1} sx={getIfExtraBottomPadding(formErrors.fromDate)}>
        <ControlledDatePicker
          dataTestId="new-absence-request_date-from"
          value={fromDate as Date | null}
          onChange={(newValue) => setValue('fromDate', newValue as Date)}
          error={Boolean(formErrors.fromDate)}
          helperText={formErrors.fromDate}
          label={isMultipleDates ? t('absenceRequest.new.multiFromDate.label') : t('absenceRequest.new.fromDate.label')}
          format={inputDateFormat}
          minDate={todayDate}
          maxDate={toDate as Date}
          dialogSx={onSideBar ? newAbsenceRequestClasses.datePickerDialog : undefined}
          useDefaultValue={false}
        />
      </Box>
      {isMultipleDates ? (
        <>
          <Box width={1} sx={getIfExtraBottomPadding(formErrors.toDate)}>
            <ControlledDatePicker
              dataTestId="new-absence-request_date-to"
              value={toDate as Date}
              onChange={(newValue) => setValue('toDate', newValue as Date)}
              error={Boolean(formErrors.toDate)}
              helperText={formErrors.toDate}
              label={t('absenceRequest.new.toDate.label')}
              format={inputDateFormat}
              minDate={(fromDate as Date) || todayDate}
              dialogSx={onSideBar ? newAbsenceRequestClasses.datePickerDialog : undefined}
              useDefaultValue={false}
            />
          </Box>
        </>
      ) : (
        <Box width={1} sx={getIfExtraBottomPadding(formErrors.absencePeriod)}>
          <ControlledSelect
            name="absencePeriod"
            value={absencePeriod as string}
            options={absencePeriods}
            onChange={(e) => setValue('absencePeriod', e.target.value)}
            error={Boolean(formErrors.absencePeriod)}
            helperText={formErrors.absencePeriod}
            label={t('absenceRequest.new.absencePeriod.label')}
          />
        </Box>
      )}
      <TextField
        variant="standard"
        fullWidth
        maxRows={7}
        multiline
        value={comment}
        onChange={(e) => setValue('comment', e.target.value)}
        label={t('absenceRequest.new.comment.label')}
        slotProps={{
          htmlInput: { maxLength: MAX_LENGTH_REQUEST_COMMENT, 'data-testid': 'message' },
        }}
      />
      <Box width={1} textAlign="left" marginTop={2} paddingLeft={1}>
        {numDays > 0 && isMultipleDates && (
          <Typography variant="body2" sx={newAbsenceRequestClasses.numDaysLabel}>
            <span>{t('absenceRequest.new.numDaysLabel')}</span>{' '}
            <span>{t('absenceRequest.new.numDaysLabelDays', { numDays })}</span>
          </Typography>
        )}
      </Box>
    </Box>
  )
}
export default NewForm
