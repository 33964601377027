import React from 'react'
import AlertTag from 'redesign/components/atoms/Tag/AlertTag'
import EventTag from 'redesign/components/atoms/Tag/EventTag'
import MoreEntriesTag from 'redesign/components/atoms/Tag/MoreEntriesTag'
import ShiftTag from 'redesign/components/atoms/Tag/ShiftTag'
import { TAG_SIZE } from 'redesign/components/atoms/Tag/constants'
import { getShiftKey } from 'types/utils'
import type { CalendarDayShiftsProps } from './types'
import { useCalendarDay } from './useCalendarDay'

const CalendarDayShifts: React.FC<CalendarDayShiftsProps> = ({
  sortedShifts,
  shiftUpdates,
  events,
  day,
  containerRef,
}) => {
  const { shiftsToShow, overflowVal, numEvents, hasAcknowledgedTask } = useCalendarDay({
    sortedShifts,
    shiftUpdates,
    events,
    containerRef,
  })

  return (
    <>
      {shiftsToShow.map((shift) =>
        hasAcknowledgedTask(shift) ? (
          <ShiftTag shift={shift} key={getShiftKey(shift)} />
        ) : (
          <AlertTag key={getShiftKey(shift)} tagSize={TAG_SIZE.small} fullWidth />
        ),
      )}
      {numEvents > 0 && (
        <EventTag
          tagSize={TAG_SIZE.small}
          data-testid={`calendar-event-badge-day-${day}`}
          numberOfEvents={numEvents}
          fullWidth
        />
      )}
      {overflowVal > 0 && <MoreEntriesTag overflowVal={overflowVal} />}
    </>
  )
}

export default CalendarDayShifts
