import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const styles = {
  title: {
    color: palette.grey[600],
    margin: '16px 0',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  contentCard: {
    display: 'flex',
    padding: '16px',
    borderColor: palette.primary[100],
    backgroundColor: 'white',
    borderRadius: '8px',
    justifyContent: 'space-between',
  },
  iconAndText: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  icon: {
    backgroundColor: palette.primary[50],
    height: theme.spacing(2.5),
    width: theme.spacing(2.875),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(0.5),
    padding: '0',
    marginRight: theme.spacing(0.75),
  },
  contentCardText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  titleText: {
    color: palette.grey[900],
  },
  subText: {
    color: palette.grey[600],
  },
  contentCardLink: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  tabLabel: {
    display: 'flex',
    gap: theme.spacing(0.375),
    alignItems: 'center',
  },
  eventGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.0),
  },
  eventDateGroup: {
    color: palette.text.secondary,
  },
  eventsGroupList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  noContentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  noContentText: {
    weight: 700,
    size: theme.spacing(1),
    color: '#141A33',
  },
  noContentIconText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  subHeader: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  subHeaderIcon: {
    color: theme.palette.primary.main,
  },
  iconText: {
    display: 'flex',
    gap: '12px',
  },
}
