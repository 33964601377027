import React from 'react'
import { useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ShiftDataProps } from 'redesign/components/organisms/ShiftPlanCard/types'
import { getTimeType, getFromUntil } from 'utils/shifts'
import { DataTypography, TextContainer } from '../styles'

const PlannedShiftData: React.FC<ShiftDataProps> = ({ firstShift, shifts, date, isOld, showCostCenter = true }) => {
  const { t } = useFavurTranslation()
  const { palette } = useTheme()

  return (
    <>
      {shifts.map((shift) => (
        <TextContainer key={`shift-${shift.id}-${date}`}>
          <DataTypography isOld={isOld} color={palette.text.secondary} variant="body1">
            {getTimeType(shift, t)}
          </DataTypography>
          <DataTypography variant="body1"> {getFromUntil(shift, t)}</DataTypography>
        </TextContainer>
      ))}
      {showCostCenter && (
        <TextContainer>
          <DataTypography isOld={isOld} color={palette.text.secondary} variant="body1">
            {t('component.shifthistory.costcenter')}
          </DataTypography>
          <DataTypography variant="body1">{firstShift.costCenterName}</DataTypography>
        </TextContainer>
      )}
    </>
  )
}

export default PlannedShiftData
