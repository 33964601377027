import React from 'react'
import { useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import type { ShiftDataProps } from 'redesign/components/organisms/ShiftPlanCard/types'
import { DataTypography, TextContainer } from '../styles'
import { getAbsencePeriodKey } from './getAbsencePeriodKey'

const AbsenceShiftData: React.FC<ShiftDataProps> = ({ firstShift, isOld }) => {
  const { palette } = useTheme()
  const { t } = useFavurTranslation()
  const { percent, timeOfDay } = firstShift

  return (
    <TextContainer data-testid="absence-shift-data">
      <DataTypography color={palette.text.secondary} variant="body1" isOld={isOld}>
        {t('absencePeriod.label')}
      </DataTypography>
      <DataTypography variant="body1">{t(getAbsencePeriodKey({ percent, timeOfDay }))}</DataTypography>
    </TextContainer>
  )
}
export default AbsenceShiftData
