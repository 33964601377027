import { styled, Typography, css } from '@mui/material'

export const TextContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const DataTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isOld',
})<{ isOld?: boolean }>`
  text-decoration: none;
  display: inline;
  ${({ isOld }) =>
    isOld &&
    css`
      text-decoration: line-through;
    `}
`
