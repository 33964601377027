import { useMemo } from 'react'
import { prepareShowingShifts } from 'components/ShiftsList/components/utils'
import { PlannedShiftT } from 'types'
import { getEventsFromTenantsAndOwners } from 'utils/cms'
import { TShifts } from './types'

export const useShiftTagList = ({ tenants, shiftUpdates, events }: TShifts) => {
  const tenantsShifts = useMemo(() => tenants.flatMap(({ shifts }) => shifts || []), [tenants])
  const pendingTaskIds = new Set(
    shiftUpdates.reduce<string[]>(
      (acc, update) => (update.taskData.acknowledgedAt === null ? [...acc, `${update.id}`] : acc),
      [],
    ),
  )
  const tenantsWithPendingTasks = tenants.filter((tenant) =>
    (tenant.shifts ?? []).some((shift) => shift?.updateTaskId && pendingTaskIds.has(`${shift.updateTaskId}`)),
  )
  const tenantsWithPendingTasksIds = useMemo(() => new Set(tenantsWithPendingTasks.map((tenant) => tenant.tenantId)), [
    tenantsWithPendingTasks,
  ])
  const tenantsWithoutPendingTasks = useMemo(
    () => tenants.filter((tenant) => !tenantsWithPendingTasksIds.has(tenant.tenantId)),
    [tenants, tenantsWithPendingTasksIds],
  )

  const filteredEvents = useMemo(() => {
    const tenantOwnerPairs = tenantsWithoutPendingTasks.reduce<{ tenantId: string; ownerId: string }[]>(
      (acc, { tenantId, ownerId }) => (tenantId && ownerId ? [...acc, { tenantId, ownerId }] : acc),
      [],
    )

    if (!tenantOwnerPairs.length) return events

    return getEventsFromTenantsAndOwners(events, tenantOwnerPairs)
  }, [events, tenantsWithoutPendingTasks])

  const filteredShifts = useMemo(() => {
    const shifts = tenantsShifts.filter((shift) =>
      tenantsWithoutPendingTasks.some((tenant) => tenant.shifts?.some((tenantShift) => tenantShift?.id === shift?.id)),
    )
    return shifts
  }, [tenantsShifts, tenantsWithoutPendingTasks])

  const hasUnacknowledgedTask = tenantsWithPendingTasksIds.size > 0

  const showFootnote = useMemo(
    () => filteredShifts.find((shift) => shift?.isManual === true) && !hasUnacknowledgedTask,
    [filteredShifts, hasUnacknowledgedTask],
  )

  const showingShifts = useMemo(() => prepareShowingShifts((filteredShifts as unknown) as PlannedShiftT[]), [
    filteredShifts,
  ])

  return {
    showFootnote,
    showingShifts,
    filteredEvents,
    hasUnacknowledgedTask,
    tenantsWithPendingTasks: tenantsWithPendingTasksIds,
  }
}
