import React from 'react'
import DotDivider from './DotDivider'
import { DIVIDER_VARIANT } from './constants'
import { DividerComponent, SeparatorDivider, SpaceDivider } from './styles'
import { DividerProps } from './types'

const Divider: React.FC<DividerProps> = ({ variant, orientation, fullSize, fullWidth }) => {
  switch (variant) {
    case DIVIDER_VARIANT.separator:
      return <SeparatorDivider />
    case DIVIDER_VARIANT.space:
      return <SpaceDivider />
    case DIVIDER_VARIANT.dot:
      return <DotDivider />
    case DIVIDER_VARIANT.line:
      return <DividerComponent orientation={orientation} fullSize={fullSize} />
    default:
      return <DividerComponent orientation={orientation} fullSize={fullSize} fullWidth={fullWidth} />
  }
}

export default Divider
