import React from 'react'
import { Typography } from '@mui/material'
import { Container } from './styles'
import type { ShiftCardInfoTextProps } from './types'

const ShiftCardInfoText: React.FC<ShiftCardInfoTextProps> = ({ title, subtitle }) => {
  return (
    <Container>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="body2">{subtitle}</Typography>
    </Container>
  )
}

export default ShiftCardInfoText
