import React from 'react'
import { PullIndicator, Refresher } from './styles'
import { PullToRefreshContentWrapperProps } from './types'

const PullToRefreshContentWrapper: React.FC<PullToRefreshContentWrapperProps> = ({
  children,
  disabled = false,
  onRefresh,
}) =>
  disabled ? (
    <>{children}</>
  ) : (
    <Refresher
      slot="fixed"
      disabled={disabled}
      onIonRefresh={async (event) => {
        try {
          await onRefresh()
        } catch {
          // eslint-disable-next-line no-console
          console.error('There was an error refreshing the content')
        } finally {
          event.detail.complete()
        }
      }}
    >
      <PullIndicator />
      {children}
    </Refresher>
  )

export default PullToRefreshContentWrapper
