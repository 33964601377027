import { TabList as MuiTabList } from '@mui/lab'
import { styled, Tab as MuiTab } from '@mui/material'

export const TabList = styled(MuiTabList)`
  width: 100%;
`

export const Tab = styled(MuiTab)`
  &.MuiBadge-root {
    gap: ${({ theme }) => theme.spacing(1)};
  }
`
