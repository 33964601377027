import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { getBadgeLabel } from 'components/Badge/helpers'
import Tag from 'redesign/components/atoms/Tag'
import AbsenceTag from 'redesign/components/atoms/Tag/AbsenceTag'
import { TAG_SIZE } from 'redesign/components/atoms/Tag/constants'
import { getTagTextColour } from '../utils'
import { getAbsenceType } from './getAbsenceType'
import type { TShiftTag } from './types'

export const ShiftTag: React.FC<TShiftTag> = ({ shift }) => {
  const { type: shiftType, hexBackgroundColor, hexTextColor, isManual } = shift
  const theme = useTheme()
  const textColor = getTagTextColour({ isManual, hexTextColor, theme })
  const borderColor = isManual ? hexBackgroundColor : undefined

  if (shiftType === 'absence') {
    const absenceType = getAbsenceType({ shift })

    return <AbsenceTag absenceType={absenceType} tagSize={TAG_SIZE.small} fullWidth />
  }

  return (
    <Tag
      backgroundColor={hexBackgroundColor}
      borderColor={borderColor}
      isTransparent={isManual}
      fullWidth
      tagSize={TAG_SIZE.small}
    >
      <Typography variant="chipSmall" color={textColor} noWrap>
        {getBadgeLabel(shift)}
      </Typography>
    </Tag>
  )
}
