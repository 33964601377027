import React, { useMemo } from 'react'
import { Box, Card, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowRightThinS } from 'icons'
import { Link } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { CmsContentT } from 'types'
import { getMetadata } from '../helper'
import { styles } from '../styles'

interface IContentCardProps {
  content: CmsContentT
  onClick?: (_content: CmsContentT) => void
}

const ContentCard: React.FC<IContentCardProps> = ({ content, onClick = (_content: CmsContentT) => {} }) => {
  const { language } = useFavurTranslation()
  const metadata = useMemo(() => getMetadata(content, language), [content, language])

  if (!metadata) return null

  return (
    // In the future we should change the uuid for slug
    <Box
      component={Link}
      to={`${routes.cmsContentView}/${content.uuid}`}
      sx={styles.contentCardLink}
      onClick={() => onClick(content)}
    >
      <Card variant="outlined" sx={styles.contentCard}>
        <Box sx={styles.contentCardText}>
          <Typography variant="body1" sx={styles.titleText}>
            {metadata.title ?? ''}
          </Typography>
          {metadata.shortDescription && (
            <Typography variant="body2" sx={styles.subText}>
              {metadata.shortDescription}
            </Typography>
          )}
        </Box>
        <ArrowRightThinS />
      </Card>
    </Box>
  )
}

export default ContentCard
