import { ROLE_VIEWS } from 'constants/roles'
import he from 'he'
import routes from 'services/RoutesProvider/routes'
import { ReminderAbsenceRequestManualForManagerNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getReminderAbsenceRequestManualManager = ({ notification, t }: GetNotificationData) => {
  const data = notification.data as ReminderAbsenceRequestManualForManagerNotification
  const { title, body } = notificationGlosssaryKeys.reminderAbsenceRequestManualForManager

  return {
    title: he.decode(t(title, data)),
    body: t(body, data),
    route: `${routes.absenceRequestDetail}/${data.taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
