import { formatDateRange } from 'redesign/shared/date/utils'
import { newDateWithoutTime } from 'utils/date'
import { DEFAULT_DATE_FORMAT } from '../../constants'
import { GetShiftUpdateDatePeriod } from '../types'

export const getShiftUpdateDatePeriod = ({ shiftsUpdated = [], locale, t }: GetShiftUpdateDatePeriod) => {
  const translationKey = 'shiftUpdate.date.multipleChanges'
  const shiftNumber = shiftsUpdated.length

  if (!shiftNumber) {
    return t(translationKey, {
      dateRange: '',
      shiftNumber,
    })
  }

  const dates = {
    startDate: newDateWithoutTime(shiftsUpdated?.[0] as string),
    endDate: newDateWithoutTime(shiftsUpdated?.[shiftNumber - 1] as string),
  }

  const dateRange = formatDateRange({ dates, dateFormat: DEFAULT_DATE_FORMAT, locale, t })

  return t(translationKey, {
    dateRange,
    shiftNumber,
  })
}
