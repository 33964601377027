import { Theme } from '@mui/material'
import theme from 'utils/theme'
import { CELL_WIDTH } from '../Grid/constants'

export const monthlyBarClasses = {
  container: {
    boxSizing: 'border-box',
    display: 'flex',
    width: 'fit-content',
    height: 'fit-content',
    position: 'sticky',
    top: 40,
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid',
    // @ts-ignore
    borderColor: theme.palette.primary[50],
  },
  redesignTheme: (redesignTheme: Theme) => ({
    borderColor: redesignTheme.palette.secondary.main,
  }),
  dayContainer: (isMonday: boolean) => ({
    width: `${CELL_WIDTH}px`,
    boxSizing: 'border-box',
    padding: '3px 0',
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    cursor: 'pointer',
    borderLeft: '1px solid',
    // @ts-ignore
    borderColor: isMonday ? theme.palette.grey[300] : theme.palette.common.white,
  }),
  selected: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[25],
  },
  isToday: {
    color: theme.palette.secondary.main,
    // @ts-ignore
    borderColor: theme.palette.primary[50],
    width: `${CELL_WIDTH}px`,
    boxSizing: 'border-box',
    // @ts-ignore
    backgroundColor: theme.palette.secondary[25],
  },
  isDayAfterToday: {
    // @ts-ignore
    borderColor: theme.palette.primary[50],
  },
  isFirstDay: {
    backgroundColor: theme.palette.grey[50],
  },
  dayOfWeek: {
    lineHeight: '18px',
    letterSpacing: 0.3,
  },
  dayOfMonth: {
    fontWeight: 600,
    lineHeight: '22px',
  },
  extraCaption: (isToday: boolean) => ({
    // @ts-ignore
    color: isToday ? theme.palette.secondary[400] : theme.palette.grey[400],
    lineHeight: '18px',
    letterSpacing: 0.3,
  }),
  monthCaption: (isToday: boolean) => ({
    color: theme.palette.common.white,
    // @ts-ignore
    backgroundColor: isToday ? theme.palette.secondary[400] : theme.palette.grey[800],
    borderRadius: '2px',
    height: '18px',
    width: '35px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  }),
  isSelected: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[25],
    borderTop: '2px solid ' + theme.palette.secondary.main,
    padding: '1px 0 3px 0',
  },
}
