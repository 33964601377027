export const LIST_GROUP_MOTION = {
  hidden: { opacity: 0, x: -100 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { staggerChildren: 0.07, delayChildren: 0.07 },
  },
}

export const LIST_ITEM_MOTION = (index: number) => ({
  hidden: { opacity: 0, x: -100, scale: 0.95 },
  visible: {
    opacity: 1,
    x: 0,
    scale: 1,
    transition: { duration: Math.max(0.3 - 0.04 * index, 0.07), ease: 'easeOut' },
  },
})
