import { useMemo } from 'react'
import { getTaskIds } from 'components/ShiftsList/components/utils'
import { addMonths } from 'date-fns'
import { useEventsDateRange } from 'hooks/useEventsDateRange'
import useSessionContext from 'hooks/useSessionContext'
import useShifts from 'hooks/useShifts'
import { chunk } from 'lodash/fp'
import useShiftUpdates from 'pages/Shifts/useShiftUpdates'
import { dateToISOStringWithoutTime, getCalendarMonthInterval, newDateWithoutTime } from 'utils/date'
import { DAYS_TO_SHOW } from './constants'
import type { TUseMonthlyCalendar } from './types'

const useMonthlyCalendar = ({ index = 0, referenceDay = newDateWithoutTime() }: TUseMonthlyCalendar) => {
  const { personIds } = useSessionContext()
  const originDay = addMonths(referenceDay, index)
  const calendarDates = getCalendarMonthInterval(originDay, DAYS_TO_SHOW)
  const weekCalendarDates = useMemo(() => chunk(7, calendarDates), [calendarDates])
  const startDate = dateToISOStringWithoutTime(calendarDates[0])
  const endDate = dateToISOStringWithoutTime(calendarDates[calendarDates.length - 1])

  const { shifts: monthShifts, loading: loadingMonthShifts } = useShifts({
    dates: { start: startDate, end: endDate },
    personIds: personIds as number[],
  })
  const { eventsByDate } = useEventsDateRange({ startDate, endDate })

  const taskIds = useMemo(() => getTaskIds(monthShifts), [monthShifts])
  const { tasks: monthTaskList, loading: tasksLoading } = useShiftUpdates({ taskIds })
  const loadingMonth = loadingMonthShifts || tasksLoading

  return {
    loadingMonth,
    monthTaskList,
    eventsByDate,
    monthShifts,
    weekCalendarDates,
  }
}

export default useMonthlyCalendar
