import { useCallback, useEffect, useState } from 'react'
import { hasAcknowledgedShiftUpdate } from 'components/ShiftsList/components/utils'
import { convertPxToMuiSpacing } from 'redesign/themes/main/utils'
import type { PlannedShiftT } from 'types'
import { DAY_CELL_HEIGHT, BOX_GAP, TAG_HEIGHT, TAG_GAP } from './constants'
import type { TUseCalendar } from './types'

export const useCalendarDay = ({ sortedShifts, shiftUpdates, events, containerRef }: TUseCalendar) => {
  const [maxChips, setMaxChips] = useState(0)
  const [overflowVal, setOverflowVal] = useState(0)
  const [shiftsToShow, setShiftsToShow] = useState<PlannedShiftT[]>([])
  const numEvents = !events?.length ? 0 : 1

  const hasAcknowledgedTask = useCallback((shift: PlannedShiftT) => hasAcknowledgedShiftUpdate(shift, shiftUpdates), [
    shiftUpdates,
  ])

  const getMaxChips = (containerHeight: number | null) => {
    if (!containerHeight) return 0
    const containerHeightInMuiSpacing = convertPxToMuiSpacing(containerHeight)

    return Math.floor((containerHeightInMuiSpacing - DAY_CELL_HEIGHT - BOX_GAP) / (TAG_HEIGHT + TAG_GAP))
  }

  useEffect(() => {
    const maxChipsNum = getMaxChips(containerRef?.current?.clientHeight ?? 0)
    setMaxChips(maxChipsNum)
  }, [containerRef])

  useEffect(() => {
    const callback = () => {
      const maxChipsNum = getMaxChips(containerRef?.current?.clientHeight ?? 0)
      setMaxChips(maxChipsNum)
    }

    window.removeEventListener('resize', callback)
    window.addEventListener('resize', callback)

    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [containerRef, sortedShifts])

  useEffect(() => {
    const overflowValue = sortedShifts.length + numEvents - maxChips
    const chipsToShow = overflowValue > 0 ? maxChips - numEvents - 1 : maxChips - numEvents

    setOverflowVal(overflowValue)
    setShiftsToShow(sortedShifts.slice(0, chipsToShow))
  }, [maxChips, numEvents, sortedShifts])

  return {
    hasAcknowledgedTask,
    numEvents,
    overflowVal,
    shiftsToShow,
  }
}
