import { ComponentsOverrides, Theme } from '@mui/material'

const styleOverrides: ComponentsOverrides<Theme>['MuiTooltip'] = {
  tooltip: ({ theme }) => {
    return {
      backgroundColor: theme.palette.common.greyDark,
      color: theme.palette.textLight.primary,
      padding: theme.spacing(1.5, 1),
      maxWidth: theme.spacing(25),
      borderRadius: theme.spacing(1),
    }
  },
  arrow: ({ theme }) => ({
    color: theme.palette.common.greyDark,
  }),
}
const MuiTooltip = {
  styleOverrides,
}

export default MuiTooltip
