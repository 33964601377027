import { useCallback, useMemo, useState } from 'react'
import type { NavPointCallbacksT, NavPointT } from 'components/Page/Header/Toolbar/Menu/types'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurNavigation from 'hooks/useFavurNavigation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useRolesViews from 'hooks/useRolesViews'
import useSessionContext from 'hooks/useSessionContext'
import { t } from 'i18next'
import { useHistory, useLocation } from 'react-router'
import routes from 'services/RoutesProvider/routes'
import { Browser } from '@capacitor/browser'
import { datadogLogs } from '@datadog/browser-logs'
import { APP_INFO_QUERY } from './queries'
import type { TUseSideMenu } from './types'

export const useSideMenu = ({ toggleDrawer }: TUseSideMenu) => {
  const [showAppInfo, setShowAppInfo] = useState(false)
  const { primaryNavigation, secondaryNavigation, tertiaryNavigation } = useFavurNavigation()
  const { user } = useSessionContext()
  const firstName = user?.firstName || ''

  const history = useHistory()
  const { pathname } = useLocation()
  const { activeView, setActiveView, clearActiveView } = useRolesViews()
  const { pushWithRole } = useHistoryUtils()
  const { logout: contextLogout } = useSessionContext()
  const { data: appInfoData } = useJamesApolloQuery<{ info: { applicationEnv: string } }>(APP_INFO_QUERY)

  /////// Navigation Group //////////

  const handleNavigationGroupClick = useCallback(
    (navPoint: NavPointT) => {
      if (navPoint.to !== pathname) {
        pushWithRole(navPoint.to, navPoint.role)
        return
      }

      toggleDrawer()
      if (activeView !== navPoint.role) {
        setActiveView(navPoint.role ?? ROLE_VIEWS.frontliner)
      }
    },
    [activeView, pathname, pushWithRole, setActiveView, toggleDrawer],
  )

  /////// Navigation Buttons /////////

  const logout = useCallback(() => {
    datadogLogs.logger.info('[Doing logout] From TertiaryNavigation')
    clearActiveView()
    contextLogout()
  }, [clearActiveView, contextLogout])

  const closeIfCurrent = useCallback(
    (to: string) => {
      to === pathname && toggleDrawer()
    },
    [pathname, toggleDrawer],
  )

  const onNavigate = useCallback(
    (to: string) => {
      closeIfCurrent(to)
      history.push(to)
    },
    [closeIfCurrent, history],
  )

  const toggleAppInfoDialog = useCallback(() => setShowAppInfo(!showAppInfo), [showAppInfo])

  const buttonCallbacks: NavPointCallbacksT = useMemo(
    () => ({
      appInfo: () => toggleAppInfoDialog(),
      contact: () => onNavigate(routes.contact),
      privacy: async () => {
        await Browser.open({ url: t('page.termsAndConditionsUpdate.url.dataProtection') })
      },
      logout,
    }),
    [onNavigate, logout, toggleAppInfoDialog],
  )

  ////////////////////////////////////

  const navigationLinks = {
    primaryNavigation,
    secondaryNavigation,
    tertiaryNavigation,
  }

  return {
    appInfoData,
    buttonCallbacks,
    firstName,
    handleNavigationGroupClick,
    navigationLinks,
    showAppInfo,
    toggleAppInfoDialog,
  }
}
