import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import AbsenceButton from 'components/AbsenceButton'
import MonthSelector from 'components/MonthSelector'
import Page from 'components/Page'
import { shiftDateFormat } from 'constants/dateFormats'
import { startOfMonth, addMonths, endOfMonth, isThisMonth } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useModule from 'hooks/useModule'
import { modules } from 'shared/constants'
import { QueryDates } from 'types'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import TimeBalance from './TimeBalance'
import { classes } from './styles'

const BalancesPage: React.FC = () => {
  const { t } = useFavurTranslation()
  const { hasAccessToModule } = useModule()
  const [swipeIndex, setSwipeIndex] = useState(0)
  const today = newDateWithoutTime()
  const [balanceStartDate, setBalanceStartDate] = useState(startOfMonth(today))
  const defaultBalanceDates: QueryDates = {
    start: getFormattedFromDate(startOfMonth(today), shiftDateFormat),
    end: getFormattedFromDate(today, shiftDateFormat),
  }
  const [balanceDates, setBalanceDates] = useState(defaultBalanceDates)

  const handleDateChange = (amount: number) => {
    setSwipeIndex(swipeIndex + amount)
    const newBalanceStartDate = addMonths(balanceStartDate, amount)
    setBalanceStartDate(newBalanceStartDate)

    if (isThisMonth(newBalanceStartDate)) {
      setBalanceDates(defaultBalanceDates)
    } else {
      setBalanceDates({
        start: getFormattedFromDate(newBalanceStartDate, shiftDateFormat),
        end: getFormattedFromDate(endOfMonth(newBalanceStartDate), shiftDateFormat),
      } as QueryDates)
    }
  }

  const resetDates = () => {
    setSwipeIndex(0)
    setBalanceDates(defaultBalanceDates)
  }

  return (
    <Page
      paddingX={0}
      growSubheader
      header={
        <>
          <Box sx={classes.titleContainer}>
            <Typography variant="h2">{t('page.shifts.content.balanceTitle')}</Typography>
          </Box>
          <MonthSelector dates={balanceDates} handleDateChange={handleDateChange} isBalances />
        </>
      }
    >
      <TimeBalance resetDates={resetDates} swipeIndex={swipeIndex} handleSwipeAction={handleDateChange} />
      {hasAccessToModule(modules.workflowAbsences) && <AbsenceButton />}
    </Page>
  )
}

export default BalancesPage
