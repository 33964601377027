import React from 'react'
import { IconButton, useTheme } from '@mui/material'
import { ChevronLeft } from '../../Icon'
import type { BackButtonProps } from './types'

const BackButton: React.FC<BackButtonProps> = ({ onGoBack }) => {
  const theme = useTheme()

  return (
    <IconButton onClick={onGoBack}>
      <ChevronLeft color={theme.palette.text.secondary} />
    </IconButton>
  )
}

export default BackButton
