import React, { useEffect } from 'react'
import { Sheet } from 'react-modal-sheet'
import { useHistory } from 'react-router'
import Dialog from 'redesign/components/organisms/Dialog'
import { isNativeNoOverride } from 'utils/platform'
import { StatusBar, Style } from '@capacitor/status-bar'
import { DIALOG_SIZES } from '../Dialog/constants'
import { ContentContainer, ContentDesktopContainer, CustomSheet, FooterContainer } from './styles'
import type { FullScreenModalProps } from './types'
import { cleanupModalSheet } from './utils'

const FullScreenModal: React.FC<FullScreenModalProps> = ({
  children,
  disableDrag,
  rootId,
  isOpen,
  onClose,
  isSmallScreen,
  header,
  footer,
  noPadding,
}) => {
  const history = useHistory()

  if (isNativeNoOverride() && isSmallScreen && isOpen) {
    // This needs to be here because
    // if the modal is open, we need the style to be re-applied
    // On close the styles are re-applied automatically
    StatusBar.setStyle({ style: Style.Default })
  }
  useEffect(() => {
    // A history listener needed to cleanup css applied by react-modal-sheet
    // if any route actions prevent the drawer to do that by closing
    return history.listen(() => {
      cleanupModalSheet(rootId)
    })
  }, [history, rootId])

  if (isSmallScreen) {
    return (
      <CustomSheet
        dragPropagation
        rootId={rootId}
        onClose={onClose}
        isOpen={isOpen}
        disableDrag={disableDrag}
        disableScrollLocking
      >
        <Sheet.Container>
          <Sheet.Header dragPropagation>{header}</Sheet.Header>
          <Sheet.Content dragPropagation>
            <ContentContainer>{children}</ContentContainer>
            {footer && <FooterContainer extraBottomPadding>{footer}</FooterContainer>}
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={onClose} />
      </CustomSheet>
    )
  }

  return (
    <Dialog addMinWidth open={isOpen} onClose={onClose} noPadding={noPadding} size={DIALOG_SIZES.large} fixedHeight>
      {header}
      <ContentDesktopContainer>{children}</ContentDesktopContainer>
      {footer && <FooterContainer extraBottomPadding>{footer}</FooterContainer>}
    </Dialog>
  )
}

export default FullScreenModal
