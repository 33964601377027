import React from 'react'
import { TextField, TextFieldProps, MenuItem } from '@mui/material'
import { DropdownDownS } from 'icons'
import { isNative, isTest } from 'utils/platform'

// DEPRECATED COMPONENT: Use mui material select
const Select: React.FC<TextFieldProps> = ({ children, ...props }) => {
  const native = isNative() || isTest()

  return (
    <TextField
      variant="standard"
      select
      {...props}
      slotProps={{
        select: {
          native,
          IconComponent: DropdownDownS,
        },
      }}
    >
      {native
        ? children
        : React.Children.map(
            children as { key: string; props: Record<string, unknown> }[],
            ({ key, props: childProps }) => {
              return <MenuItem key={key} {...childProps} />
            },
          )}
    </TextField>
  )
}

export default Select
