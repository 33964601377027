import React from 'react'
import { Box, Typography } from '@mui/material'
import { endOfMonth, isThisMonth } from 'date-fns'
import he from 'he'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { BalanceItem, BalanceObject } from 'types/balances'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { numberToStringWithDecimals } from 'utils/number'
import { classes } from './styles'

const getFormattedValue = (item?: BalanceItem) => {
  if (!item || item.value === null || item.value === undefined) return ''
  return numberToStringWithDecimals(item.value, 2)
}

interface IContentTableProps {
  balance: BalanceObject
  frontlinerFullName?: string
  tenantName?: string
}

const ContentTable: React.FC<IContentTableProps> = ({ balance, frontlinerFullName, tenantName }) => {
  const { t } = useFavurTranslation()
  const dateEndOfMonth = getFormattedFromDate(endOfMonth(newDateWithoutTime()), uiDateFormat)
  const isCurrentMonth = isThisMonth(newDateWithoutTime())
  const hasEstimations =
    Boolean(balance) && (balance?.estimations?.vacation !== null || balance?.estimations?.bankHoliday !== null)
  const showEstimations = isCurrentMonth && hasEstimations
  const dateEndOfYear = showEstimations
    ? getFormattedFromDate(newDateWithoutTime(balance.estimationDate), uiDateFormat)
    : ''

  return (
    <Box sx={classes.container}>
      <Typography variant="subtitle1" sx={classes.titleText}>
        {he.decode(
          t(
            frontlinerFullName
              ? 'absenceRequest.components.employeeSaldo.titleOffice'
              : 'absenceRequest.components.employeeSaldo.title',
            { frontlinerFullName },
          ),
        )}
        {tenantName ? ` | ${tenantName}` : ''}
      </Typography>
      {!frontlinerFullName && (
        <Typography variant="body2" sx={classes.subtitleText}>
          {t('absenceRequest.components.employeeSaldo.subtitle')}
        </Typography>
      )}
      <Box sx={classes.table}>
        <Box sx={[classes.row, classes.rowHeader]}>
          <Box sx={classes.column}>
            <Typography variant="caption">{t('absenceRequest.components.employeeSaldo.endDate')}</Typography>
          </Box>
          <Box sx={classes.column}>
            <Typography variant="caption">{dateEndOfMonth}</Typography>
          </Box>
          <Box sx={[classes.column, classes.columnEndOfYear]}>
            <Typography variant="caption">{dateEndOfYear}</Typography>
          </Box>
        </Box>
        <Box sx={classes.row}>
          <Box sx={classes.column}>
            <Typography variant="body1">{t('absenceRequest.components.employeeSaldo.bankHoliday')}</Typography>
          </Box>
          <Box sx={classes.column}>
            <Typography variant="body1">{getFormattedValue(balance?.bankHoliday)}</Typography>
          </Box>
          <Box sx={[classes.column, classes.columnEndOfYear]}>
            <Typography variant="body1">{getFormattedValue(balance?.estimations?.bankHoliday)}</Typography>
          </Box>
        </Box>
        <Box sx={classes.row}>
          <Box sx={classes.column}>
            <Typography variant="body1">{t('absenceRequest.components.employeeSaldo.vacation')}</Typography>
          </Box>
          <Box sx={classes.column}>
            <Typography variant="body1">{getFormattedValue(balance?.vacation)}</Typography>
          </Box>
          <Box sx={[classes.column, classes.columnEndOfYear]}>
            <Typography variant="body1">{getFormattedValue(balance?.estimations?.vacation)}</Typography>
          </Box>
        </Box>
        <Box sx={classes.row}>
          <Box sx={classes.column}>
            <Typography variant="body1">{t('absenceRequest.components.employeeSaldo.free')}</Typography>
          </Box>
          <Box sx={classes.column}>
            <Typography variant="body1">{getFormattedValue(balance?.free)}</Typography>
          </Box>
          <Box sx={[classes.column, classes.columnEndOfYear]}></Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ContentTable
