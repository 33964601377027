import React from 'react'
import { Typography } from '@mui/material'
import { getFullTeamLabel } from 'utils/team'
import { TeamTitlePlaceholder, TitleContainer } from './styles'
import type { TeamRowTitleProps } from './types'

const TeamRowTitle: React.FC<TeamRowTitleProps> = ({ teamsLoading, team, isMultiTenant, language }) => (
  <TitleContainer>
    {teamsLoading || !team ? (
      <TeamTitlePlaceholder />
    ) : (
      <Typography variant="subtitle1" data-testid={`title-${team.id}`}>
        {getFullTeamLabel(team, language, Boolean(isMultiTenant))}
      </Typography>
    )}
  </TitleContainer>
)

export default TeamRowTitle
