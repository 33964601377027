import React from 'react'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TFunction } from 'i18next'
import DeleteWorkflow from 'pages/Tasks/components/ActionButtons/DeleteWorkflow'
import Plus from 'pages/Tasks/components/ActionButtons/Plus'
import SendReminder from 'pages/Tasks/components/ActionButtons/SendReminder'
import { taskStates } from 'pages/Tasks/constants'
import { EventsOfTask, ShareUserDataTaskT, TaskT } from 'pages/Tasks/types'
import AddComment from './AddComment'
import FillForm from './FillForm'

const dataTestIdPlus = 'share-user-data-detail_options'
interface IActionButtonsProps {
  canSendComment: boolean
  canSendReminder: boolean
  task: TaskT<ShareUserDataTaskT>
  isOffice: boolean
  onAction?: () => void
}
type IActionButtonsPropsWithT = IActionButtonsProps & { t: TFunction }

const getButtonsFromStateFrontline = (
  canSendComment: boolean,
  task: TaskT<ShareUserDataTaskT>,
  onAction?: () => void,
) => {
  const { favurUuid } = task
  if (task.statusFe === taskStates.todo) {
    return (
      <ActionCardButtons>
        <FillForm />
        {canSendComment && (
          <Plus dataTestId={dataTestIdPlus}>
            <AddComment
              taskUuid={favurUuid}
              event={EventsOfTask.shareUserDataSendCommentToManager}
              onAction={onAction}
            />
          </Plus>
        )}
      </ActionCardButtons>
    )
  }

  if (task.statusFe === taskStates.pending && canSendComment) {
    return (
      <ActionCardButtons>
        <Plus dataTestId={dataTestIdPlus}>
          <AddComment taskUuid={favurUuid} event={EventsOfTask.shareUserDataSendCommentToManager} onAction={onAction} />
        </Plus>
      </ActionCardButtons>
    )
  }

  return <></>
}

const getButtonsFromStateOffice = (
  canSendComment: boolean,
  canSendReminder: boolean,
  task: TaskT<ShareUserDataTaskT>,
  t: TFunction,
  onAction?: () => void,
) => {
  const { favurUuid } = task
  if (task.statusFe === taskStates.todo && canSendComment) {
    return (
      <ActionCardButtons>
        <Plus dataTestId={dataTestIdPlus}>
          <AddComment
            taskUuid={favurUuid}
            event={EventsOfTask.shareUserDataSendCommentToFrontline}
            onAction={onAction}
          />
        </Plus>
      </ActionCardButtons>
    )
  }
  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons>
        <Plus dataTestId={dataTestIdPlus}>
          {canSendComment && (
            <AddComment
              taskUuid={favurUuid}
              event={EventsOfTask.shareUserDataSendCommentToFrontline}
              onAction={onAction}
            />
          )}
          {canSendReminder && (
            <SendReminder
              taskUuid={favurUuid}
              onAction={onAction}
              isOffice
              label={t('shareUserData.buttons.sendReminder')}
              dataTestId="share-user-data-detail_send_reminder"
            />
          )}
          <DeleteWorkflow task={task} label={t('shareUserData.buttons.deleteWorkflow')} />
        </Plus>
      </ActionCardButtons>
    )
  }
  return <></>
}

const getDeleteButtonStateOffice = (task: TaskT<ShareUserDataTaskT>, t: TFunction) => {
  if (task.statusFe === taskStates.closed) {
    return <></>
  }

  return (
    <ActionCardButtons>
      <Plus dataTestId={dataTestIdPlus}>
        <DeleteWorkflow task={task} label={t('shareUserData.buttons.deleteWorkflow')} />
      </Plus>
    </ActionCardButtons>
  )
}

const getButtonsFromTaskAndView = ({
  canSendComment,
  canSendReminder,
  task,
  isOffice,
  t,
  onAction,
}: IActionButtonsPropsWithT) => {
  if (task.statusFe === taskStates.closed) {
    return <></>
  }

  if (isOffice) {
    return getButtonsFromStateOffice(canSendComment, canSendReminder, task, t, onAction)
  }

  return getButtonsFromStateFrontline(canSendComment, task, onAction)
}

const ActionButtons: React.FC<IActionButtonsProps> = ({
  canSendComment,
  canSendReminder,
  task,
  isOffice,
  onAction,
}) => {
  const { t } = useFavurTranslation()

  if (task.timeline && task.timeline.length > 0) {
    return <>{getButtonsFromTaskAndView({ canSendComment, canSendReminder, task, isOffice, t, onAction })}</>
  }

  if (isOffice) {
    return <>{getDeleteButtonStateOffice(task, t)}</>
  }

  return <></>
}

export default ActionButtons
