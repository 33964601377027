import React from 'react'
import Announcement from 'components/Announcements'
import AnnouncementsContext from './context'
import { useUserAnnouncements } from './useUserAnnouncements'

interface IAnnouncementsServiceProps {
  children?: React.ReactNode
}

const AnnouncementsService: React.FC<IAnnouncementsServiceProps> = ({ children }) => {
  const {
    routeAnnouncement,
    showAnnouncement,
    setShowAnnouncement,
    fetch,
    openAnnouncement,
    markAnnouncementSeen,
  } = useUserAnnouncements()

  return (
    <AnnouncementsContext.Provider value={{ openAnnouncement, markAnnouncementSeen }}>
      <Announcement
        blocks={routeAnnouncement?.announcement?.blocks ?? []}
        open={showAnnouncement}
        onClose={() => {
          setShowAnnouncement(false)
          fetch()
        }}
      />
      {children}
    </AnnouncementsContext.Provider>
  )
}

export default AnnouncementsService
