import React from 'react'
import { Typography } from '@mui/material'
import useFilterContext from 'components/Filter/context'
import { Container, TitleContainer, BodyContainer } from './styles'
import type { FilterSectionProps } from './types'
import { getConfigurationComponent } from './utils'

const FilterSection: React.FC<FilterSectionProps> = ({ configuration, displayDateValue, setDisplayDateValue }) => {
  const { getValue } = useFilterContext()
  const value = getValue(configuration.name)
  const amountLabel = Array.isArray(value) ? `(${value.length})` : ''

  return (
    <Container>
      <TitleContainer>
        <Typography variant="overline">{`${configuration.label} ${amountLabel}`}</Typography>
      </TitleContainer>
      <BodyContainer>
        {getConfigurationComponent({ configuration, displayDateValue, setDisplayDateValue })}
      </BodyContainer>
    </Container>
  )
}

export default FilterSection
