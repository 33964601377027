import React from 'react'
import { Container } from './styles'
import type { SafeSpaceProps } from './types'

const SafeSpace: React.FC<SafeSpaceProps> = ({ children, rootId, isSideMenuOpen = false, hasNavbar = false }) => (
  <Container id={rootId} isSideMenuOpen={isSideMenuOpen} hasNavbar={hasNavbar}>
    {children}
  </Container>
)

export default SafeSpace
