import { useCallback, useEffect, useMemo, useState } from 'react'
import useFilterContext from 'components/Filter/context'
import type { PersonsT } from 'components/Filter/types'
import { sortBy } from 'lodash/fp'
import type { PersonT } from 'types'
import { getNormalizedFirstName, getNormalizedLastName, getSortingLastName } from 'utils/person'
import { normalizeText } from 'utils/string'

const usePersonsFilterSection = (persons: PersonsT) => {
  const { options } = persons
  const [searchText, setSearchText] = useState<string>('')
  const [filteredPersonsList, setFilteredPersonsList] = useState<PersonT[]>(options)
  const { setCurrentSelectedPersons, getCurrentSelectedPersons } = useFilterContext()

  const personsList = useMemo(() => {
    const sortedOptions = sortBy((p) => getSortingLastName(p), options)

    return sortedOptions.map((person) => ({
      firstName: getNormalizedFirstName(person),
      lastName: getNormalizedLastName(person),
      person,
    }))
  }, [options])

  const filterPersons = useCallback(
    (text: string) => {
      const normalizedText = normalizeText(text)
      const filteredEmployees = personsList.reduce<PersonT[]>((acc, normPerson) => {
        return normPerson.firstName.includes(normalizedText) || normPerson.lastName.includes(normalizedText)
          ? [...acc, normPerson.person]
          : acc
      }, [])
      setFilteredPersonsList(filteredEmployees)
    },
    [personsList],
  )

  useEffect(() => {
    filterPersons(searchText)
  }, [filterPersons, searchText])

  const currentSelectedPersons = useMemo(() => getCurrentSelectedPersons(), [getCurrentSelectedPersons])

  const onClick = (person: PersonT) => {
    if (currentSelectedPersons.includes(person.id as number)) {
      setCurrentSelectedPersons(currentSelectedPersons.filter((pId: number) => pId !== person.id))
    } else {
      setCurrentSelectedPersons([...currentSelectedPersons, person.id as number])
    }
  }

  return { setSearchText, filteredPersonsList, onClick, currentSelectedPersons }
}

export default usePersonsFilterSection
