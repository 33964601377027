import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { EventIcon } from '../../Icon'
import { ICON_SIZE } from '../../Icon/constants'
import Tag from '../Tag'
import { TAG_SIZE } from '../constants'
import { MAX_EVENTS } from './constants'
import { Container } from './styles'
import type { EventTagProps } from './types'

const EventTag: React.FC<EventTagProps> = ({
  tagSize = TAG_SIZE.large,
  numberOfEvents = 0,
  maxEvents = MAX_EVENTS,
  fullWidth = false,
}) => {
  const theme = useTheme()
  const events = numberOfEvents > maxEvents ? `+${maxEvents}` : numberOfEvents

  return (
    <Tag
      noPadding={tagSize === TAG_SIZE.small}
      backgroundColor={theme.palette.background.default}
      borderColor={theme.palette.common?.cardBorder}
      tagSize={tagSize}
      fullWidth={fullWidth}
    >
      <Container>
        <EventIcon color={theme.palette.secondary.main} size={ICON_SIZE.extraSmall} />
        <Typography color={theme.palette.secondary.main} variant="chipSmall" noWrap>
          {events}
        </Typography>
      </Container>
    </Tag>
  )
}

export default EventTag
