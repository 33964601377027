import { useRef, useState, useEffect, useMemo } from 'react'
import { POPPER_TOP_MARGIN, POPPER_LEFT_MARGIN } from './constants'
import { PopperComponent } from './styles'
import type { DesktopMonthPickerProps } from './types'

export const useDesktopMonthPicker = ({ datePickerDate }: Pick<DesktopMonthPickerProps, 'datePickerDate'>) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [placeholderDate, setPlaceholderDate] = useState(datePickerDate)
  const [positions, setPositions] = useState({
    top: 0,
    left: 0,
  })
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const refPosition = ref?.current?.getBoundingClientRect()

    setPositions({
      top: (refPosition?.top ?? 0) + POPPER_TOP_MARGIN,
      left: (refPosition?.left ?? 0) + POPPER_LEFT_MARGIN,
    })
  }, [open])

  const Popper = useMemo(() => PopperComponent({ top: positions.top, left: positions.left }), [
    positions.left,
    positions.top,
  ])

  return {
    ref,
    Popper,
    open,
    setOpen,
    placeholderDate,
    setPlaceholderDate,
  }
}
