export const SIDE_MENU_INITIAL_VALUES = {
  isAbsenceManager: false,
  page: '',
  setPage: () => {},
  pages: [],
  open: false,
  setOpen: () => {},
  parentPage: '',
  taskCount: undefined,
  setTaskCount: () => {},
}
