import React from 'react'
import { TAG_SIZE } from './constants'
import { MainContainer } from './styles'
import type { TagProps } from './types'

const Tag: React.FC<TagProps> = ({
  backgroundColor,
  borderColor,
  children,
  fullWidth = false,
  isTransparent = false,
  tagSize = TAG_SIZE.large,
  minWidthRem,
}) => (
  <MainContainer
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    fullWidth={fullWidth}
    isTransparent={isTransparent}
    label={children}
    minWidthRem={minWidthRem}
    tagSize={tagSize}
  />
)

export default Tag
