import { styled } from '@mui/material'

export const MonthlyBarContainer = styled('div')`
  display: flex;
  position: sticky;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  top: 40;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.common.cardBorder};
`
