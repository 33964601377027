import { modules } from 'shared/constants'

export const seenTutorialBase = 'SEEN_TUTORIAL_'

export const tutorialTypes = {
  absencePlanFrontliner: 'absenceplan-frontliner',
  absencePlanManager: 'absenceplan-manager',
  absenceRequestFrontliner: 'absence-request-frontliner',
  absenceRequestManager: 'absence-request-manager',
  resignation: 'resignation',
  secureConnection: 'secure-connection',
  shiftPlan: 'shiftplan',
  socialEngineering: 'social-engineering',
  workTimeControlFrontliner: 'work-time-control-frontliner',
  workTimeControlManager: 'work-time-control-manager',
}

export const tutorialNames = {
  ABSENCE_PLAN_FRONTLINER: 'absenceplanFrontliner',
  ABSENCE_PLAN_MANAGER: 'absenceplanManager',
  ABSENCE_REQUEST_FRONTLINER: 'absenceRequestFrontliner',
  ABSENCE_REQUEST_MANAGER: 'absenceRequestManager',
  RESIGNATION: 'resignation',
  SECURE_CONNECTION: 'secureConnection',
  SHIFT_PLAN: 'shiftplan',
  SOCIAL_ENGINEERING: 'socialEngineering',
  WORK_TIME_CONTROL_FRONTLINER: 'workTimeControlFrontliner',
  WORK_TIME_CONTROL_MANAGER: 'workTimeControlManager',
}

// Returns the module needed to display the tutorial and a list where
// the first element is the own tutorial and the rest are the related tutorials.
export const getModuleAndRelatedTutorials = (tutorialName: string) => {
  switch (tutorialName) {
    case tutorialTypes.absencePlanFrontliner:
      return {
        module: modules.absencesPlan,
        tutorials: [tutorialNames.ABSENCE_PLAN_FRONTLINER, tutorialNames.ABSENCE_PLAN_MANAGER],
      }
    case tutorialTypes.absencePlanManager:
      return {
        module: modules.absencesPlan,
        tutorials: [tutorialNames.ABSENCE_PLAN_MANAGER, tutorialNames.ABSENCE_PLAN_FRONTLINER],
      }
    case tutorialTypes.absenceRequestFrontliner:
      return {
        module: modules.workflowAbsences,
        tutorials: [tutorialNames.ABSENCE_REQUEST_FRONTLINER],
      }
    case tutorialTypes.absenceRequestManager:
      return {
        module: modules.workflowAbsences,
        tutorials: [tutorialNames.ABSENCE_REQUEST_MANAGER],
      }
    case tutorialTypes.resignation:
      return {
        module: modules.user,
        tutorials: [tutorialNames.RESIGNATION],
      }
    case tutorialTypes.secureConnection:
      return {
        module: modules.workflowSecureConnection,
        tutorials: [tutorialNames.SECURE_CONNECTION],
      }
    case tutorialTypes.socialEngineering:
      return {
        module: modules.user,
        tutorials: [tutorialNames.SOCIAL_ENGINEERING],
      }
    case tutorialTypes.shiftPlan:
      return {
        module: modules.shiftPlan,
        tutorials: [tutorialNames.SHIFT_PLAN],
      }
    case tutorialTypes.workTimeControlFrontliner:
      return {
        module: modules.workflowMonthlySheets,
        tutorials: [tutorialNames.WORK_TIME_CONTROL_FRONTLINER],
      }
    case tutorialTypes.workTimeControlManager:
      return {
        module: modules.workflowMonthlySheets,
        tutorials: [tutorialNames.WORK_TIME_CONTROL_MANAGER],
      }
    default:
      return {
        module: null,
        tutorials: [],
      }
  }
}
