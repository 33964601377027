import React, { useMemo } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { groupShiftsByCostCenter } from 'pages/Tasks/ShiftUpdate/Details/utils'
import Headline from 'redesign/components/atoms/Headline'
import { HEADLINE_LEVELS } from 'redesign/components/atoms/Headline/constants'
import AccordionWrapper from 'redesign/components/molecules/AccordionWrapper'
import { DataTypography } from 'redesign/components/molecules/ShiftData/styles'
import type { PlannedShiftT } from 'types'
import { getShiftKey } from 'types/utils'
import ShiftPlanCard from '../ShiftPlanCard'
import { ShiftsContainer } from '../ShiftPlanSections/styles'
import { ShiftGrupContainer } from './styles'
import type { ShiftUpdateGroupProps } from './types'

const ShiftUpdateGroup: React.FC<ShiftUpdateGroupProps> = ({ isOld, shifts, deleted }) => {
  const { t } = useFavurTranslation()
  const titleKey = useMemo(() => (isOld ? 'shiftUpdate.headline.oldShift' : 'shiftUpdate.headline.newShift'), [isOld])

  const shiftsByCostCenter = useMemo(() => {
    if (!shifts) return []

    return groupShiftsByCostCenter(shifts)
  }, [shifts])

  const noShiftsTitleKey = deleted ? 'component.shifthistory.deletedshifts' : 'component.shifthistory.updatedagain'

  return (
    <ShiftGrupContainer>
      <Headline level={HEADLINE_LEVELS.second} text={t(titleKey)} />
      {shiftsByCostCenter.length === 0 && (
        <AccordionWrapper>
          <DataTypography variant="body1">{t(noShiftsTitleKey)}</DataTypography>
        </AccordionWrapper>
      )}
      <ShiftsContainer>
        {!deleted &&
          shiftsByCostCenter.map((costCenterAndShifts) => {
            const { shifts: costCenterShifts, costCenter } = costCenterAndShifts

            return (
              <ShiftsContainer key={`costcenter-shifts-${costCenter.uuid}`}>
                {costCenterShifts.map((costCenterShift) => {
                  const firstShift = costCenterShift[0] as PlannedShiftT

                  return (
                    <>
                      {Boolean(firstShift) && (
                        <ShiftPlanCard
                          shifts={costCenterShift}
                          key={`${costCenter.uuid}-${getShiftKey(firstShift)}`}
                          isOld={isOld}
                          isAccordion={isOld}
                        />
                      )}
                    </>
                  )
                })}
              </ShiftsContainer>
            )
          })}
      </ShiftsContainer>
    </ShiftGrupContainer>
  )
}

export default ShiftUpdateGroup
