import { Badge, styled } from '@mui/material'

export const BadgeContainer = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'isNative',
})<{ isNative?: boolean }>`
  flex: 1;
  border: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.background.paper};
  margin-bottom: 0 !important;

  ${({ isNative, theme }) =>
    !isNative &&
    `
      :hover {
        background-color: ${theme.palette.common.hoverCard};
      }
    `}

  & .MuiBadge-standard {
    top: ${({ theme }) => theme.spacing(0.5)};
    right: ${({ theme }) => theme.spacing(0.5)};
  }
`
