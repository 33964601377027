import type { TFunction } from 'react-i18next'
import {
  ABSENCE_PLAN_CONFIGURATION,
  ADMIN_ABSENCE_PLAN_CONFIGURATION,
  ADMIN_SHIFT_PLAN_CONFIGURATION,
  PARENT_PAGES,
  SHIFT_PLAN_CONFIGURATION,
  SIDE_MENU_OPTIONS,
} from './constants'
import type { ParentPage, SideMenuConfigurationProps } from './types'

export const getMainMenuTitle = (parentPage: ParentPage, t: TFunction<'translation', undefined>): string => {
  switch (parentPage) {
    case PARENT_PAGES.shiftPlan:
      return t('page.shifts.content.shiftsTitle')
    case PARENT_PAGES.absencePlan:
      return t('page.absencePlanner.title')
    default:
      return ''
  }
}

const getAbsencePlanConfiguration = (isAbsenceManager: boolean, hasCmsAccess: boolean) => {
  const baseConfiguration = isAbsenceManager ? ADMIN_ABSENCE_PLAN_CONFIGURATION : ABSENCE_PLAN_CONFIGURATION

  return hasCmsAccess ? [SIDE_MENU_OPTIONS.events, ...baseConfiguration] : [...baseConfiguration]
}

const getShiftPlanConfiguration = (isAbsenceManager: boolean, hasCmsAccess: boolean) => {
  const baseConfiguration = isAbsenceManager ? ADMIN_SHIFT_PLAN_CONFIGURATION : SHIFT_PLAN_CONFIGURATION

  return hasCmsAccess ? [SIDE_MENU_OPTIONS.events, ...baseConfiguration] : [...baseConfiguration]
}

export const getSideMenuConfiguration = ({
  parentPage,
  isAbsenceManager,
  hasCmsAccess,
}: SideMenuConfigurationProps) => {
  switch (parentPage) {
    case PARENT_PAGES.shiftPlan:
      return getShiftPlanConfiguration(isAbsenceManager, hasCmsAccess)
    case PARENT_PAGES.absencePlan:
      return getAbsencePlanConfiguration(isAbsenceManager, hasCmsAccess)
    default:
      return []
  }
}
