import React, { useCallback } from 'react'
import { Typography } from '@mui/material'
import { addDays, format, isFirstDayOfMonth, isMonday, isToday } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { isDaySelected } from 'pages/AbsencePlanner/utils'
import { DayContainer, Text, MonthText } from './styles'
import type { MonthlyBarDayProps } from './types'

const MonthlyBarDay: React.FC<MonthlyBarDayProps> = ({ selectedDays, selectedTaskUuid, date, isPreview }) => {
  const { t, locale } = useFavurTranslation()
  const { updateSelectedDays } = useSelectedStatesActionsContext()
  const setTaskBarState = useTaskBarStateDispatchContext()

  const onClick = useCallback(() => {
    if (isPreview) return
    if (selectedTaskUuid) setTaskBarState(null)
    updateSelectedDays(date)
  }, [isPreview, selectedTaskUuid, setTaskBarState, updateSelectedDays, date])

  return (
    <DayContainer
      isMonday={isMonday(date)}
      isToday={isToday(date)}
      isDayAfterToday={isToday(addDays(date, -1))}
      isFirstDayOfMonth={isFirstDayOfMonth(date)}
      isDaySelected={isDaySelected(date, selectedDays)}
      onClick={onClick}
    >
      <Text variant="body2">{format(date, 'EEEEEE', { locale })}</Text>
      <Typography variant="body2">{date.getDate()}</Typography>
      {isFirstDayOfMonth(date) && <MonthText variant="caption">{format(date, 'MMM', { locale })}</MonthText>}
      {!isFirstDayOfMonth(date) && isMonday(date) && (
        <Text variant="caption">{`${t('page.absencePlanner.monthlyBar.week')}${format(date, 'w')}`}</Text>
      )}
    </DayContainer>
  )
}

export default MonthlyBarDay
