import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Headline from 'redesign/components/atoms/Headline'
import PlannedTimeCards from '../ShiftSection/PlannedTimeCards'
import { CardsContainer, Container } from '../styles'
import type { NoShiftTenantSectionProps } from './types'

const NoShiftTenantSection: React.FC<NoShiftTenantSectionProps> = ({
  filteredPersonsWithTenant,
  isPast,
  date,
  getAbsenceRequestsByTenantId,
  onTaskClick,
}) => {
  const { t } = useFavurTranslation()

  if (!filteredPersonsWithTenant || filteredPersonsWithTenant.length <= 0) return <></>

  return (
    <Container>
      <Headline text={t('shiftPlan.headline.shiftSection')} testId="my-shift-headline" />
      <CardsContainer>
        {filteredPersonsWithTenant.map(({ tenant }) => {
          if (!tenant) return null

          return (
            <>
              <PlannedTimeCards
                isPast={isPast}
                date={date}
                tenant={tenant}
                absenceRequests={getAbsenceRequestsByTenantId(tenant.id)}
                onTaskClick={onTaskClick}
                groupedShifts={[]}
              />
            </>
          )
        })}
      </CardsContainer>
    </Container>
  )
}

export default NoShiftTenantSection
