import React from 'react'
import { getTeamMemberKey } from 'components/ShiftsList/components/ShiftCard/TeamsSection/utils'
import Avatar from 'redesign/components/molecules/Avatar'
import { getAvatarName } from 'utils/person'
import { getAvatarShifts } from './getAvatarShifts'
import { Container } from './styles'
import type { AvatarGroupProps } from './types'
import { useAvatarGroup } from './useAvatarGroup'

const AvatarGroup: React.FC<AvatarGroupProps> = ({ avatars, showShifts, size, maxRows, displayAllAvatars }) => {
  const { containerRef, visibleAvatars, extraAvatarsCount } = useAvatarGroup({
    avatars,
    size,
    maxRows,
    displayAllAvatars,
  })
  const displayExtraAvatarCount = extraAvatarsCount > 0 && !displayAllAvatars

  return (
    <Container ref={containerRef}>
      {visibleAvatars.map((avatar) => {
        const { avatarUrl, lastName, firstName } = avatar
        const name = getAvatarName(firstName, lastName)
        const key = getTeamMemberKey(avatar)

        return (
          <Avatar
            testId={key}
            shifts={showShifts ? getAvatarShifts(avatar) : []}
            src={avatarUrl}
            text={name}
            key={key}
          />
        )
      })}
      {displayExtraAvatarCount && (
        <Avatar testId="extra-avatars-count" removeBackground text={`+${extraAvatarsCount}`} />
      )}
    </Container>
  )
}

export default AvatarGroup
