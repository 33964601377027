import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import featureFlags from 'constants/featureFlags'
import { seenTutorialBase } from 'constants/tutorials'
import useJamesMutation from 'hooks/useJamesMutation'
import info from 'info.json'
import { sideMenuFiltersStorageKey } from 'pages/Shifts/useStoredFilters'
import { AppUnavailableStatus, useAppUnavailableContext } from 'services/AppUnavailable/context'
import { initializeDataDogDatadogUser } from 'utils/datadog'
import { timeDiffExceedsThreshold } from 'utils/date'
import { isNativeNoOverride } from 'utils/platform'
import { retryOperation } from 'utils/retry'
import { useApolloClient, useMutation } from '@apollo/client'
import { Filesystem, Directory, FileInfo } from '@capacitor/filesystem'
import { datadogLogs } from '@datadog/browser-logs'
import { AuthenticationKind } from '../../constants/authentication'
import { MeObject } from '../../types'
import SessionContext, { ApiMeResponseT } from './context'
import { sessionQuery, logoutMutationParams, logoutDeviceMutationParams, RememberMeParams } from './queries'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: ApiMeResponseT = { auth: -1 as any, cmsActivated: false, personsAndPermissions: undefined }
const THRESHOLD_TIME_TO_REFRESH = 10_000 // 10 seconds

type AuthRefreshTokenResponse = {
  authRefreshToken?: MeObject
}
interface SessionProps {
  children?: React.ReactNode
}

const SessionService: React.FC<SessionProps> = ({ children }) => {
  const [session, setSession] = useState(initialState)
  const [registeredToken, setRegisteredToken] = useState<string | null>(null)
  const lastUpdatedAt = useRef<Date | null>(null)
  const [sessionReady, setSessionReady] = useState<boolean>(false)
  const fetchSessionRef = useRef(false)

  const [rememberMeMutation] = useJamesMutation(RememberMeParams)
  const client = useApolloClient()
  const { setStatus: setAppUnavailableStatus } = useAppUnavailableContext()

  const clearLocalStorage = () => {
    const ignored = [...Object.keys(featureFlags), sideMenuFiltersStorageKey]
    const ignoredByStart = [seenTutorialBase]
    Object.keys(localStorage).forEach((key) => {
      if (ignored.includes(key) || ignoredByStart.some((value) => key.startsWith(value))) return
      localStorage.removeItem(key)
    })
  }

  const clearFilesInCache = async () => {
    if (isNativeNoOverride()) {
      await Filesystem.readdir({
        path: '/',
        directory: Directory.Cache,
      }).then((readdirResult) => {
        readdirResult.files.map(async (file: FileInfo) => {
          if (file.type === 'file') {
            await Filesystem.deleteFile({
              path: file.name,
              directory: Directory.Cache,
            })
          }
        })
      })
    }
  }

  const cleanSession = useCallback(
    async (newSession?: ApiMeResponseT) => {
      datadogLogs.logger.info(`[Doing clean session] Cleaning local and session storage`)
      // Clean storages
      clearLocalStorage()
      sessionStorage.clear()

      setSession(newSession || initialState)
      setSessionReady(true)
      setRegisteredToken(null)
      lastUpdatedAt.current = null

      // Clear apollo cache
      await client.clearStore()

      // Clear direcory ~/Librabry/Cache
      await clearFilesInCache()
    },
    [client],
  )

  const logoutQuery = useMemo(() => {
    if (isNativeNoOverride() && registeredToken) {
      return logoutDeviceMutationParams(registeredToken)
    }

    return logoutMutationParams
  }, [registeredToken])

  const [doLogout] = useMutation<{ logout: ApiMeResponseT }>(logoutQuery)

  const logout = useCallback(async () => {
    return doLogout().then((res) => cleanSession(res?.data?.logout))
  }, [cleanSession, doLogout])

  const rememberMe = useCallback(async () => {
    datadogLogs.logger.info(`[Doing Remember me] Calling graphql`)
    const data: AuthRefreshTokenResponse = await rememberMeMutation()

    const sessionData: ApiMeResponseT = {
      auth: data?.authRefreshToken?.auth || AuthenticationKind.ANONYMOUS,
      cmsActivated: data?.authRefreshToken?.cmsActivated ?? false,
      phone: data?.authRefreshToken?.user?.phoneNumber,
      name: data?.authRefreshToken?.user
        ? `${data.authRefreshToken.user.firstName} ${data.authRefreshToken.user.lastName}`
        : '',
      personIds: data?.authRefreshToken?.personIds || [],
      personsAndPermissions: data?.authRefreshToken?.personsAndPermissions || [],
      user: data?.authRefreshToken?.user,
    }
    setSession(sessionData)
    if (data?.authRefreshToken?.auth === AuthenticationKind.AUTHENTICATED) {
      // eslint-disable-next-line favur/no-date
      lastUpdatedAt.current = new Date()
    }
    initializeDataDogDatadogUser(data?.authRefreshToken?.user?.userId)
  }, [rememberMeMutation])

  const handleSessionResult = useCallback(
    async (me: MeObject, tryRememberMe: boolean) => {
      if (me?.auth) {
        setSession(me)
        // eslint-disable-next-line favur/no-date
        lastUpdatedAt.current = new Date()
        return
      }

      if (tryRememberMe) {
        await rememberMe()
        return
      }

      await cleanSession()
    },
    [rememberMe, cleanSession],
  )
  const fetchSession = useCallback(
    async (options: { tryRememberMe?: boolean } = { tryRememberMe: false }) => {
      if (fetchSessionRef.current) {
        datadogLogs.logger.info('[Doing refresh session] Refresh session reference already exists')
        return
      }
      fetchSessionRef.current = true

      const { tryRememberMe = false } = options
      const operation = async () => {
        datadogLogs.logger.info(`[Doing Fetching session] Calling graphql`)
        const res = await client.query<{ me: MeObject }>({
          query: sessionQuery,
          variables: { buildVersionNumber: info.version },
          fetchPolicy: 'no-cache',
        })

        const me = res?.data?.me

        await handleSessionResult(me, tryRememberMe)
      }

      await retryOperation(operation, {
        onRetry: (numRetry) => {
          datadogLogs.logger.warn(`[Doing Fetching session] Attempt has failed... Num. Retry: ${numRetry}`)
          setAppUnavailableStatus(AppUnavailableStatus.LOADING)
        },
        onError: (error) => {
          datadogLogs.logger.error(`[Doing Fetching session] There was an unexpected error fetching session`, {
            error: error,
          })
          setAppUnavailableStatus(AppUnavailableStatus.RETRY)
        },
        onSuccess: () => {
          setAppUnavailableStatus(AppUnavailableStatus.HIDE)
        },
      }).finally(() => {
        fetchSessionRef.current = false
        setSessionReady(true)
      })
    },
    [client, handleSessionResult, setAppUnavailableStatus],
  )

  const refresh = useCallback(
    async (options: { force: boolean; onComplete?: () => void } = { force: false, onComplete: () => {} }) => {
      // If the refresh was done less than the threshold seconds ago we do not do it again.
      const exceedsTimeToRefresh = timeDiffExceedsThreshold(lastUpdatedAt.current, THRESHOLD_TIME_TO_REFRESH)
      if (!options.force && !exceedsTimeToRefresh) return

      await fetchSession()

      if (options.onComplete) {
        options.onComplete()
      }
    },
    [fetchSession, lastUpdatedAt],
  )

  // Refetch session on didmount of service provider, basically happens only on hard reload.
  useEffect(() => {
    fetchSession({ tryRememberMe: true })
  }, [fetchSession, refresh])

  return (
    <SessionContext.Provider
      value={{
        ...session,
        update: setSession,
        logout,
        cleanSession,
        refresh,
        registeredToken,
        setRegisteredToken,
        persons: session.personsAndPermissions,
      }}
    >
      {sessionReady ? children : <></>}
    </SessionContext.Provider>
  )
}

export default SessionService
