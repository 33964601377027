import { ROLE_VIEWS } from 'constants/roles'
import he from 'he'
import routes from 'services/RoutesProvider/routes'
import { CmsContentNotification } from 'shared/graphql/graphql'
import { NotificationTypes } from 'types'
import { CONTENT_TYPES } from 'types/cms'
import { GetNotificationData } from '../types'
import { contentTranslationPropsFromNotification, getTranslations } from './cmsUtils'

export const getCmsNotificationData = ({ notification, t, locale }: GetNotificationData) => {
  const data = notification.data as CmsContentNotification
  const { title, body } = getTranslations({
    contentType: data.type ?? CONTENT_TYPES.page,
    isNew: notification.type === NotificationTypes.cmsNewContent,
  })
  const translationProps = contentTranslationPropsFromNotification(data, t, locale)

  return {
    title: he.decode(t(title, { ...translationProps })),
    body: t(body, { ...translationProps }),
    route: `${routes.cmsContentView}/${data.contentUuid}`,
    role: ROLE_VIEWS.frontliner,
  }
}
