import { styled } from '@mui/material'

export const MonthContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`

export const WeekContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(7, minmax(${({ theme }) => theme.spacing(5)}, 1fr));
  height: 100%;
  overflow: hidden;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  border-top: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
`
