import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import DrawerHeaderEndContent from 'redesign/components/molecules/DrawerHeaderEndContent'
import { getNamedDaysByLanguage } from 'utils/date'
import CalendarActions from '../../CalendarActions'
import { Container, DaysContainer, HeaderContainer, Text } from './styles'
import type { SubheaderCalendarMonthProps } from './types'

const SubheaderCalendarMonth: React.FC<SubheaderCalendarMonthProps> = ({
  handleCalendarAction,
  handleNextButton,
  handlePreviousButton,
  handleToday,
  isNative,
  onClose,
  selectedDay,
  isSmallScreen,
}) => {
  const { language } = useFavurTranslation()
  const weekDays = getNamedDaysByLanguage(language)

  return (
    <Container>
      <HeaderContainer isSmallScreen={isSmallScreen}>
        <CalendarActions
          handleNextButton={handleNextButton}
          handlePreviousButton={handlePreviousButton}
          referenceDay={selectedDay}
          handleCalendarAction={handleCalendarAction}
          handleToday={handleToday}
          isNative={isNative}
          isMonthCalendarOpen
          isSmallScreen={isSmallScreen}
        />
        <DrawerHeaderEndContent closeIconOnClick={onClose} />
      </HeaderContainer>
      <DaysContainer>
        {weekDays.map((day) => (
          <Text variant="subtitle2" key={day}>
            {day}
          </Text>
        ))}
      </DaysContainer>
    </Container>
  )
}

export default SubheaderCalendarMonth
