import { styled } from '@mui/material'

export const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const Profile = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NameAndShiftContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  margin: auto 0;
`
