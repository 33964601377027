import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const ChevronRightMD: React.FC<IconProps> = ({
  color = 'currentColor',
  size = ICON_SIZE.medium,
  viewBox = ICON_VIEWBOX.medium,
}) => (
  <SvgWrapper aria-label="Chevron Right Md Icon" viewBox={viewBox} size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.293 7.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L12.586 12 9.293 8.707a1 1 0 0 1 0-1.414z"
      fill={color}
    />
  </SvgWrapper>
)

export default ChevronRightMD
