import React, { useCallback, useMemo } from 'react'
import Grid from '@mui/material/Grid2'
import CarouselCard from 'components/CardCarousel/Card'
import { Locale } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { TenantLinkT } from 'types'
import { getCardFromPersonTenant } from './EmployeeCardCarousel'
import { getCardFromTenantLink } from './TenantLinkCarousel'
import { PersonTenant } from './types'

interface IWebCardGridProps {
  personTenantList: PersonTenant[]
  tenantLinkList: TenantLinkT[]
}

const WebCardGrid: React.FC<IWebCardGridProps> = ({ personTenantList, tenantLinkList }) => {
  const history = useHistory()
  const { locale } = useFavurTranslation()

  const personTenantCardOnClick = useCallback(
    (favurUuid: string) => history.push(`${routes.employeeCard}?person=${favurUuid}`),
    [history],
  )

  const cardList = useMemo(() => {
    const personTenantCards = personTenantList.map((personTenant) =>
      getCardFromPersonTenant(personTenant, personTenantCardOnClick),
    )
    const tenantLinkCards = tenantLinkList.map((link) => getCardFromTenantLink(link, locale as Locale))
    return [...personTenantCards, ...tenantLinkCards]
  }, [locale, personTenantCardOnClick, personTenantList, tenantLinkList])

  return (
    <Grid container spacing={0.5}>
      {cardList.map((card) => (
        <Grid size={6} key={card.key}>
          <CarouselCard label={card.label} onClick={card.onClick} icon={card.icon} fullWidth />
        </Grid>
      ))}
    </Grid>
  )
}

export default WebCardGrid
