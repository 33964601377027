import { useCallback, useEffect, useState } from 'react'
import { FilterStateT } from 'components/Filter/types'
import { useJamesApolloLazyQuery } from 'hooks/useJamesApolloLazyQuery'
import { tasksPaginatedQuery, tasksPaginatedQueryMinData } from 'pages/Tasks/queries'
import type { TaskListElement, TasksObject } from 'shared/graphql/graphql'
import type { UseTasksQueryPaginated } from './types'

const useTasksQueryPaginated = ({
  initialFilters,
  initialOffice = false,
  limit,
  skip,
  reducedFields = false,
}: UseTasksQueryPaginated) => {
  const [offset, setOffset] = useState<number>(0)
  const [filters, setFilters] = useState<FilterStateT>(initialFilters)
  const [office, setOffice] = useState(initialOffice)
  const [tasks, setTasks] = useState<TaskListElement[]>([])
  const [tasksLoading, setTasksLoading] = useState(true)

  const [fetchTasks, { data, loading, refetch }] = useJamesApolloLazyQuery<{ tasksPaginated: TasksObject }>(
    reducedFields ? tasksPaginatedQueryMinData : tasksPaginatedQuery,
    {
      variables: {
        filters,
        offset,
        limit,
        office,
      },
      skip: Boolean(skip),
      fetchPolicy: 'no-cache',
      onCompleted(responseData) {
        const newTasks =
          responseData?.tasksPaginated?.list?.filter((list): list is TaskListElement => list !== null) ?? []

        setTasks(limit ? [...tasks, ...newTasks] : newTasks)
        setTasksLoading(false)
      },
    },
  )

  useEffect(() => {
    if (!tasksLoading) return

    fetchTasks()
    // Avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksLoading])

  const totalCount = data?.tasksPaginated.totalCount
  const hasMoreElements = Boolean(totalCount && tasks && totalCount > tasks?.length)

  const loadMore = useCallback(() => {
    setOffset(tasks.length)
  }, [tasks?.length])

  const updateFilters = useCallback((filtersUpdate: FilterStateT) => {
    setTasks([])
    setOffset(0)
    setTasksLoading(true)
    setFilters(filtersUpdate)
  }, [])

  const updateOffice = useCallback((officeUpdate: boolean) => {
    setTasks([])
    setOffset(0)
    setTasksLoading(true)
    setOffice(officeUpdate)
  }, [])

  const handleRefetch = () => {
    refetch()
  }

  const reload = () => {
    setTasks([])
    setOffset(0)
    setTasksLoading(true)
    refetch()
  }

  return {
    list: tasks,
    hasMoreElements,
    handleRefetch,
    loadMore,
    updateFilters,
    updateOffice,
    reload,
    loading: loading || tasksLoading,
    totalCount,
  }
}

export default useTasksQueryPaginated
