import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import WheelPicker from 'redesign/components/molecules/WheelPicker'
import Drawer from '../Drawer'
import { ButtonContainer, Container } from './styles'
import type { DatePickerDrawerProps } from './types'

const DatePickerDrawer: React.FC<DatePickerDrawerProps> = ({
  onChange,
  onClose,
  header,
  monthOptionProps,
  open,
  yearOptionProps,
}) => {
  const { t } = useFavurTranslation()

  return (
    <Drawer testId="date-picker-drawer" onClose={onClose} showPuller open={open} header={header}>
      <Container>
        <WheelPicker firstOptions={monthOptionProps} secondOptions={yearOptionProps} />
        <ButtonContainer>
          <Button fullWidth variant="contained" onClick={onChange}>
            {t('filter.button.apply_date_picker')}
          </Button>
        </ButtonContainer>
      </Container>
    </Drawer>
  )
}
export default DatePickerDrawer
