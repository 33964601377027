import React from 'react'
import { Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { Comment, Container } from './styles'
import type { ShiftCommentProps } from './types'

const ShiftComment: React.FC<ShiftCommentProps> = ({ isOld, note }) => {
  const { t } = useFavurTranslation()

  return (
    <Container data-testid="shift-comment">
      <Typography variant="subtitle2" component="span">
        {t('page.shifts.shiftCard.comment')}{' '}
        <Comment isOld={isOld} variant="body2">
          {note}
        </Comment>
      </Typography>
    </Container>
  )
}
export default ShiftComment
