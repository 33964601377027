import { styled } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
`

export const ShiftsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const CardsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`
