import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import Snackbar from 'components/Snackbar'
import Timer from 'components/Timer'
import { FF_SECURITY_TIMEOUT_FE__BE__ } from 'constants/featureFlags'
import { SMS_CODE_LENGTH, SMS_TIMEOUT } from 'constants/sms'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFeatureFlag from 'hooks/useFeatureFlag'
import usePSN from 'hooks/usePushNotifications'
import useSessionContext from 'hooks/useSessionContext'
import AssetCheckmark from 'icons/AssetCheckmarkSvg'
import ResendSMSDialog from 'pages/EntryPoint/components/ValidateComponent/ResendSMSDialog'
import { classes as entryPointClasses } from 'pages/EntryPoint/styles'
import { sendSMSPinQuery } from 'pages/ForgotPhone/queries'
import { ChangePhoneStep4Response } from 'pages/ForgotPhone/types'
import { useHistory } from 'react-router'
import routes from 'services/RoutesProvider/routes'
import { MeObject } from 'types'
import { getFirstErrorCode } from 'utils/apollo'
import { setSessionData } from 'utils/login'
import { isNativeNoOverride } from 'utils/platform'
import { escapeNonNumericalKeys } from 'utils/string'
import { useMutation } from '@apollo/client'
import { classes as forgotPhoneClasses } from '../../styles'
import { classes } from './styles'

interface ISMSPinProps {
  goToStep: (stepName: string) => void
  hideStepper: (hide: boolean) => void
}

const SMSPin: React.FC<ISMSPinProps> = ({ goToStep, hideStepper }) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { update } = useSessionContext()
  const { setPushActiveOnDevice } = usePSN({ refresh: false })
  const timeout = useFeatureFlag(FF_SECURITY_TIMEOUT_FE__BE__) ? SMS_TIMEOUT.short : SMS_TIMEOUT.long
  const [timerReset, resetTimer] = React.useReducer((reset) => !reset, false)
  const [validationCode, setValidationCode] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const smsCodeInputRef = useRef<HTMLInputElement>()
  const [serverError, setServerError] = useState(false)
  const [snackbarText, setSnackbarText] = useState({
    title: t('common.error.be.default'),
    message: t('forgotPhone.errors.generic'),
  })
  const [showSuccess, setShowSuccess] = useState(false)
  const [newSessionData, setNewSessionData] = useState<MeObject>()

  const [sendNewSimMutation] = useMutation<ChangePhoneStep4Response>(sendSMSPinQuery)

  const onConfirm = useCallback(
    () =>
      sendNewSimMutation({ variables: { validationCode, rememberMe: true } })
        .then((mutationResult) => {
          const { data } = mutationResult
          setShowSuccess(true)
          setNewSessionData(data?.changePhoneStep4)
        })
        .catch((e) => {
          const errorCode = getFirstErrorCode(e)
          setSnackbarText({
            title: t('common.error.label.errorTitle'),
            message: t(t(errorCode) ? errorCode : 'forgotPhone.errors.generic'),
          })
          setServerError(true)
        }),
    [sendNewSimMutation, t, validationCode],
  )
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCode = event.target.value

    if (newCode && newCode.length <= SMS_CODE_LENGTH) {
      setValidationCode(newCode)
    }
  }

  useEffect(() => {
    if (!showSuccess) return undefined

    hideStepper(true)
    const successTimeout = setTimeout(() => {
      setSessionData({ data: newSessionData, update, setPushActiveOnDevice })
      history.push(routes.dashboard)
    }, 2100)
    return () => {
      clearTimeout(successTimeout)
    }
  }, [hideStepper, history, newSessionData, setPushActiveOnDevice, showSuccess, update])

  return (
    <Box
      component="form"
      sx={entryPointClasses.autoFillForm}
      onSubmit={(formEvent) => {
        formEvent.preventDefault()
        onConfirm()
        setValidationCode('')
      }}
    >
      <Box sx={[forgotPhoneClasses.root, entryPointClasses.centeredBox]}>
        <Snackbar open={serverError} setOpen={setServerError} flashText={snackbarText} />
        <Box sx={[forgotPhoneClasses.textContainer, showSuccess && classes.textContainerSuccess]}>
          <Typography variant="h2" color="primary" align="center">
            {t(showSuccess ? 'forgotPhone.SMSPin.success' : 'forgotPhone.SMSPin.title')}
          </Typography>
          {!showSuccess && (
            <Typography variant="body1" align="center" sx={forgotPhoneClasses.bodyText}>
              {t('forgotPhone.SMSPin.body')}
            </Typography>
          )}
          {showSuccess && (
            <>
              <Box sx={forgotPhoneClasses.checkMark}>
                <AssetCheckmark />
              </Box>
            </>
          )}
        </Box>
        {!showSuccess && (
          <>
            <Box sx={classes.inputContainer}>
              <TextField
                variant="standard"
                sx={classes.smsCodeInput}
                autoComplete="one-time-code"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={!isNativeNoOverride()}
                color="primary"
                inputMode="numeric"
                inputRef={smsCodeInputRef}
                label={t('login.validate.content.smsCode')}
                name={t('login.validate.content.smsCode')}
                onChange={(e) => onChange(e as React.ChangeEvent<HTMLInputElement>)}
                onKeyPress={escapeNonNumericalKeys}
                slotProps={{
                  htmlInput: { 'data-testid': 'sms-pin-input' },
                }}
              />
              <Timer timeout={timeout} timerReset={timerReset} />
              <ResendSMSDialog
                closeDialog={() => {
                  setDialogOpen(false)
                  smsCodeInputRef.current?.focus()
                }}
                goBackAStep={() => goToStep('NEW_SIM')}
                isOpen={dialogOpen}
                resetTimer={resetTimer}
              />
              <Button onClick={() => setDialogOpen(true)} size="small" color="primary">
                {t('login.validate.button.noCode')}
              </Button>
            </Box>
            <Box sx={forgotPhoneClasses.buttonContainer}>
              <Button color="secondary" size="large" type="submit" variant="contained" data-testid="submit-sms-pin">
                {t('login.validate.content.callToAction')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default SMSPin
