/* eslint-disable camelcase */
export const ABSENCES_TYPES: { [key: string]: string } = {
  accident: 'absenceType.accident',
  additional_free: 'absenceType.additionalFree',
  bank_holiday: 'absenceType.bankHoliday',
  civil_service: 'absenceType.civilService',
  compensation_day: 'absenceType.compensationDay',
  education: 'absenceType.education',
  free: 'absenceType.free',
  free_compensation: 'absenceType.freeCompensation',
  military: 'absenceType.military',
  motherhood: 'absenceType.parentalLeave',
  school: 'absenceType.school',
  short_time: 'absenceType.shortTimeWork',
  sickness: 'absenceType.sickLeave',
  unpaid_vacation: 'absenceType.unpaidVacation',
  vacation: 'absenceType.vacation',
}
