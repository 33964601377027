import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Headline from 'redesign/components/atoms/Headline'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import type { TaskListElement } from 'shared/graphql/graphql'
import ShiftUpdateAlertCard from '../../ShiftUpdateAlertCard/ShiftUpdateAlertCard'
import EffectiveShiftSection from '../EffectiveShiftSection'
import { CardsContainer, Container } from '../styles'
import PlannedTimeCards from './PlannedTimeCards'
import type { ShiftSectionProps } from './types'

const ShiftSection: React.FC<ShiftSectionProps> = ({
  date,
  tenant,
  absenceRequests,
  onTaskClick,
  groupedShifts,
  effectiveShifts,
  canShowEffectiveTimes,
  effectiveShiftsError,
  updateTask,
  showShiftUpdateAlert,
  shiftsSectionLoading,
  shiftUpdateDaysCount,
  isPast,
}) => {
  const { t } = useFavurTranslation()

  if (shiftsSectionLoading) {
    return (
      <Container>
        <Headline text={t('shiftPlan.headline.shiftSection')} testId="my-shift-headline" />
        <LoadingSpinner />
      </Container>
    )
  }

  return (
    <Container>
      <Headline text={t('shiftPlan.headline.shiftSection')} testId="my-shift-headline" />
      <CardsContainer>
        {canShowEffectiveTimes && effectiveShifts ? (
          <EffectiveShiftSection
            effectiveShifts={effectiveShifts}
            effectiveShiftsError={Boolean(effectiveShiftsError)}
          />
        ) : null}

        {showShiftUpdateAlert ? (
          <ShiftUpdateAlertCard
            task={updateTask as TaskListElement}
            onTaskClick={onTaskClick}
            daysUpdated={shiftUpdateDaysCount}
          />
        ) : (
          <PlannedTimeCards
            isPast={isPast}
            date={date}
            tenant={tenant}
            absenceRequests={absenceRequests}
            onTaskClick={onTaskClick}
            groupedShifts={groupedShifts}
            updateTask={updateTask}
          />
        )}
      </CardsContainer>
    </Container>
  )
}

export default ShiftSection
