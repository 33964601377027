import { useCallback, useEffect, useState } from 'react'
import { FilterStateT } from 'components/Filter/types'
import { useJamesApolloLazyQuery } from 'hooks/useJamesApolloLazyQuery'
import { DocumentsPaginated } from 'pages/Documents/DocumentList/types'
import { DocumentT } from 'types'
import { documentsPaginatedQuery } from './queries'

const LIMIT = 20

const useDocumentsQueryPaginated = () => {
  const [offset, setOffset] = useState<number>(0)
  const [filters, setFilters] = useState<FilterStateT>({})
  const [documents, setDocuments] = useState<DocumentT[]>([])
  const [documentsLoading, setDocumentsLoading] = useState(true)

  const [fetchDocuments, { data, loading }] = useJamesApolloLazyQuery<{ documentsPaginated: DocumentsPaginated }>(
    documentsPaginatedQuery,
    {
      variables: {
        filters,
        offset,
        limit: LIMIT,
      },
      fetchPolicy: 'no-cache',
      onCompleted(responseData) {
        setDocuments([...documents, ...responseData.documentsPaginated.list])
        setDocumentsLoading(false)
      },
    },
  )

  useEffect(() => {
    if (!documentsLoading) return

    fetchDocuments()
    // Avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsLoading])

  const totalCount = data?.documentsPaginated.totalCount
  const hasMoreElements = totalCount && documents && totalCount > documents?.length

  const loadMore = useCallback(() => {
    setOffset(documents.length)
  }, [documents?.length])

  // if this executes after the first call to documents, it will set the documents to []
  const updateFilters = useCallback((filtersUpdate: FilterStateT) => {
    setDocuments([])
    setOffset(0)
    setDocumentsLoading(true)
    setFilters(filtersUpdate)
  }, [])

  return {
    list: documents ?? [],
    hasMoreElements,
    loadMore,
    updateFilters,
    loading: loading || documentsLoading,
  }
}

export default useDocumentsQueryPaginated
