import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import RemovableChipGroup from 'redesign/components/molecules/ChipGroups/RemovableChipGroup'
import type { RemovableChipGroupProps } from 'redesign/components/molecules/ChipGroups/RemovableChipGroup/types'
import { Container, FooterContainer } from './styles'
import type { PersonFilterProps } from './types'
import usePersonsFilter from './usePersonsFilter'

const PersonsFilter: React.FC<PersonFilterProps> = ({ configuration }) => {
  const { t } = useFavurTranslation()
  const { selectedPersonsForChips, openPersonsList, clearPersonList, onDelete } = usePersonsFilter(configuration)
  const hasSelectedPersons = selectedPersonsForChips.length > 0

  return (
    <Container>
      {hasSelectedPersons && (
        <RemovableChipGroup
          options={selectedPersonsForChips}
          onDelete={onDelete as RemovableChipGroupProps['onDelete']}
        />
      )}
      <FooterContainer>
        {hasSelectedPersons && (
          <Button variant="outlined" color="primary" size="medium" onClick={() => clearPersonList(configuration.name)}>
            {t('filter.persons.card.clearAll')}
          </Button>
        )}
        <Button variant="outlined" color="primary" size="medium" onClick={() => openPersonsList(configuration.name)}>
          {t('filter.persons.card.button')}
        </Button>
      </FooterContainer>
    </Container>
  )
}

export default PersonsFilter
