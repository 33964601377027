import React, { useMemo } from 'react'
import { isToday } from 'date-fns'
import CalendarDay from 'redesign/components/molecules/CalendarDay'
import { dateToISOStringWithoutTime, newDateWithoutTime } from 'utils/date'
import { MonthContainer, WeekContainer } from './styles'
import type { MonthlyCalendarGridProps } from './types'
import { getShiftsByDay, getWeekKey, prepareShowingShifts } from './utils'

const MonthlyCalendarGrid: React.FC<MonthlyCalendarGridProps> = ({
  onClickDay,
  onClose,
  shifts,
  weekCalendarDates,
  eventsByDate,
  tasks,
  loading,
  referenceDay,
}) => {
  const groupedShiftsByDate = useMemo(() => getShiftsByDay(shifts), [shifts])

  return (
    <MonthContainer>
      {weekCalendarDates.map((week) => (
        <WeekContainer key={getWeekKey(week)}>
          {week.map((day) => {
            const isoDate = dateToISOStringWithoutTime(day).toString()
            const events = eventsByDate.get(isoDate)
            const groupedShifts = loading ? [] : groupedShiftsByDate[isoDate] ?? []
            const sortedShifts = prepareShowingShifts(groupedShifts)

            return (
              <CalendarDay
                day={day.getDate().toString()}
                key={isoDate}
                isToday={isToday(day)}
                isCurrentMonth={referenceDay.getMonth() === day.getMonth()}
                sortedShifts={sortedShifts}
                shiftUpdates={tasks ?? []}
                events={events}
                onClick={() => {
                  onClickDay(newDateWithoutTime(isoDate))
                  onClose()
                }}
              />
            )
          })}
        </WeekContainer>
      ))}
    </MonthContainer>
  )
}

export default MonthlyCalendarGrid
