import React, { useMemo, JSX } from 'react'
import { Box } from '@mui/material'
import TeaserToAvatar from 'components/TeaserToAvatar'
import useListCmsPaginatedContents from 'hooks/useListCmsPaginatedContents'
import useModule from 'hooks/useModule'
import useSessionContext from 'hooks/useSessionContext'
import { modules } from 'shared/constants'
import { CONTENT_TYPES } from 'types/cms'
import { isNative } from 'utils/platform'
import AbsenceRequestBlock from './Blocks/AbsenceRequestBlock'
import CostCentersBlock from './Blocks/CostCentersBlock'
import EventsBlock from './Blocks/EventsBlock'
import SettingsBlock from './Blocks/SettingsBlock'
import TeamsBlock from './Blocks/TeamsBlock'
import { useSideMenuContext } from './context'
import { classes } from './styles'

// eslint-disable-next-line no-undef
const addOption = (key: number, showElement: boolean, element: JSX.Element) => {
  if (!showElement) return []
  return [{ key, element }]
}

const DrawerContent: React.FC = () => {
  const { cmsActivated } = useSessionContext()
  const { hasAccessToModule } = useModule()

  const { teams, publicTeams, costCenters } = useSideMenuContext()
  const showTeamsBlock = teams.length > 0 || publicTeams.length > 0
  const showCostCenters = costCenters.length > 0 && !showTeamsBlock
  const showSettings = isNative()
  const { totalEntries } = useListCmsPaginatedContents({
    pageSize: 1,
    contentType: CONTENT_TYPES.event,
  })
  const showEvents = cmsActivated && totalEntries > 0
  const options = useMemo(
    () => [
      ...addOption(0, showEvents, <EventsBlock />),
      ...addOption(1, hasAccessToModule(modules.workflowAbsences), <AbsenceRequestBlock />),
      ...addOption(2, showTeamsBlock, <TeamsBlock />),
      ...addOption(3, showCostCenters, <CostCentersBlock />),
      ...addOption(4, showSettings, <SettingsBlock />),
    ],
    [hasAccessToModule, showCostCenters, showEvents, showSettings, showTeamsBlock],
  )

  return (
    <Box sx={classes.blocksContainer}>
      <TeaserToAvatar />
      {options.map(({ key, element }) => {
        return <Box key={key}>{element}</Box>
      })}
    </Box>
  )
}

export default DrawerContent
