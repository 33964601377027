import { Box, styled } from '@mui/material'

export const TabContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVertical',
})<{ isVertical?: boolean }>`
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? 'row' : 'column')};
  width: 100%;
`
export const TabBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
` as typeof Box
