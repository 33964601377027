import { Paper, Popper, styled } from '@mui/material'
import { PickersLayout } from '@mui/x-date-pickers/PickersLayout'
import type { PopperComponentProps } from './types'

export const CalendarHeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Container = styled('div')`
  display: flex;
  gap: 24px;
  align-items: center;
`

export const CustomLayout = styled(PickersLayout)`
  > div > div {
    height: 100%;
  }
  padding: ${({ theme }) => theme.spacing(2)};
`

export const CustomPaper = styled(Paper)`
  border-radius: ${({ theme }) => theme.spacing(2)};
`

export const PopperComponent = ({ top, left }: PopperComponentProps) => styled(Popper)`
  top: ${top}px !important;
  left: ${left}px !important;
  border-radius: 16px !important;
`
