import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const ChevronLeftMD: React.FC<IconProps> = ({
  color = 'currentColor',
  size = ICON_SIZE.medium,
  viewBox = ICON_VIEWBOX.medium,
}) => (
  <SvgWrapper aria-label="Chevron Left Md Icon" viewBox={viewBox} size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.707 7.293a1 1 0 0 1 0 1.414L11.414 12l3.293 3.293a1 1 0 0 1-1.414 1.414l-4-4a1 1 0 0 1 0-1.414l4-4a1 1 0 0 1 1.414 0z"
      fill={color}
    />
  </SvgWrapper>
)

export default ChevronLeftMD
