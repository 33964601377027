import React, { useCallback, useMemo } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import NewAbsenceRequestPage from 'pages/AbsencePlanner/components/TaskBar/AbsenceRequestTab/NewAbsenceRequest/NewAbsenceRequestPage'
import { SIDE_MENU_OPTIONS } from 'redesign/components/templates/SideMenu/constants'
import {
  AdminAbsences,
  AdminShiftUpdates,
  Events,
  MainMenu,
  PersonalAbsences,
  PersonalShiftUpdates,
  Settings,
} from 'redesign/components/templates/SideMenuPages'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'

export const useSideMenuContent = () => {
  const { breakpoints } = useTheme()
  const context = useSideMenuContext()
  const { t } = useFavurTranslation()
  const { page, pages, setPage, taskCount, open, setOpen } = context

  const isSmallScreen = useMediaQuery(breakpoints.down('sm'))
  const onClose = useCallback(() => {
    setOpen(false)
    setPage(SIDE_MENU_OPTIONS.mainMenu)
  }, [setOpen, setPage])
  const props = useMemo(() => ({ ...context, t, isDrawer: isSmallScreen, onClose }), [
    context,
    isSmallScreen,
    onClose,
    t,
  ])

  const PageContent = useMemo(() => {
    switch (page) {
      case SIDE_MENU_OPTIONS.personalAbsences:
        return <PersonalAbsences {...props} />
      case SIDE_MENU_OPTIONS.adminAbsences:
        return <AdminAbsences {...props} />
      case SIDE_MENU_OPTIONS.personalShiftUpdates:
        return <PersonalShiftUpdates {...props} />
      case SIDE_MENU_OPTIONS.adminShiftUpdates:
        return <AdminShiftUpdates {...props} />
      case SIDE_MENU_OPTIONS.events:
        return <Events {...props} />
      case SIDE_MENU_OPTIONS.settings:
        return <Settings {...props} />
      case SIDE_MENU_OPTIONS.newAbsenceRequest:
        return <NewAbsenceRequestPage refetchAll={() => {}} goBack={() => setPage(SIDE_MENU_OPTIONS.mainMenu)} />
      case SIDE_MENU_OPTIONS.mainMenu:
        return <MainMenu {...props} />
      default:
        return <></>
    }
  }, [page, props, setPage])

  return { PageContent, page, pages, setPage, taskCount, open, setOpen }
}
