import React from 'react'
import Headline from 'redesign/components/atoms/Headline'
import CheckboxFormGroup from 'redesign/components/molecules/CheckboxFormGroup'
import { Container } from './styles'
import { useGeneralSettings } from './useGeneralSettings'

const GeneralSettings: React.FC = () => {
  const { options, headlineTitle } = useGeneralSettings()

  // TODO: Replace checkboxes for switches
  return (
    <Container>
      <Headline text={headlineTitle} />
      <CheckboxFormGroup checkboxes={options} />
    </Container>
  )
}

export default GeneralSettings
