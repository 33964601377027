import React from 'react'
import { Button, IconButton, Typography, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ChevronLeft } from 'redesign/components/atoms/Icon'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import DrawerHeader from 'redesign/components/organisms/DrawerHeader'
import type { FiltersComponentsProps } from './types'

const FiltersHeaders: React.FC<FiltersComponentsProps> = ({
  isPersonsFilter,
  isSmallScreen,
  onClose,
  onClosePersonsFilters,
  onReset,
}) => {
  const { palette } = useTheme()
  const { t } = useFavurTranslation()

  return isPersonsFilter && onClosePersonsFilters ? (
    <DrawerHeader
      isSmallScreen={isSmallScreen}
      startContent={
        <IconButton onClick={onClosePersonsFilters}>
          <ChevronLeft size={ICON_SIZE.medium} color={palette.text.secondary} />
        </IconButton>
      }
      middleContent={<Typography variant="subtitle1">{t('requestEmployeeData.single.title')}</Typography>}
      closeIconOnClick={() => {
        onClosePersonsFilters()
        onClose()
      }}
    />
  ) : (
    <DrawerHeader
      isSmallScreen={isSmallScreen}
      startContent={<Typography variant="subtitle1">{t('filter.title.header')}</Typography>}
      endContent={
        <Button variant="text" onClick={onReset}>
          {t('filter.button.reset')}
        </Button>
      }
      closeIconOnClick={onClose}
    />
  )
}

export default FiltersHeaders
