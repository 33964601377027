import React from 'react'
import { Button, ButtonGroup } from '@mui/material'
import { ChevronLeft, ChevronRight } from 'redesign/components/atoms/Icon'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import { ChildContainer } from './styles'
import type { ArrowButtonGroupProps } from './types'

const ArrowButtonGroup: React.FC<ArrowButtonGroupProps> = ({
  handlePreviousButton,
  handleNextButton,
  hideArrows = false,
  children,
}) => (
  <ButtonGroup>
    {!hideArrows && (
      <Button variant="text" onClick={handlePreviousButton}>
        <ChevronLeft size={ICON_SIZE.extraSmall} />
      </Button>
    )}
    <ChildContainer>{children}</ChildContainer>
    {!hideArrows && (
      <Button variant="text" onClick={handleNextButton}>
        <ChevronRight size={ICON_SIZE.extraSmall} />
      </Button>
    )}
  </ButtonGroup>
)

export default ArrowButtonGroup
