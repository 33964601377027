import { useCallback, useMemo } from 'react'
import { orderAttendances } from 'components/ShiftsList/components/ShiftCard/TeamsSection/utils'
import { getTeamPlanDetail } from 'components/ShiftsList/components/utils'
import { groupBy } from 'lodash/fp'
import { useSwipeableView } from 'redesign/services/SwipeableView/useSwipeableView'
import type { TeamClickProps, UseSelectedTeamPlanContentProps } from './types'

const useSelectedTeamPlanContent = ({ teamPlanData, teamPlanLoading, currentDay }: UseSelectedTeamPlanContentProps) => {
  const { selectedTeamData, onSelectTeamData } = useSwipeableView()

  const selectedTeamPlanData = useMemo(() => {
    const teamPlan =
      selectedTeamData &&
      getTeamPlanDetail(teamPlanLoading, teamPlanData, currentDay, selectedTeamData.tenantId, selectedTeamData.teamId)

    const newAttendances = (teamPlan?.attendances ?? []).flatMap((attendance) => {
      const groupedShifts = Object.values(groupBy('shortDescription', attendance.shifts))
      return groupedShifts.map((shifts) => ({ ...attendance, shifts }))
    })
    const orderedAttendances = orderAttendances(newAttendances)
    const absences = [...(teamPlan?.vacationAbsences ?? []), ...(teamPlan?.otherAbsences ?? [])]

    return { attendances: orderedAttendances, absences }
  }, [currentDay, selectedTeamData, teamPlanData, teamPlanLoading])

  const onTeamClick = useCallback(
    ({ teamId, tenantId, teamName, showCostCenter }: TeamClickProps) => {
      onSelectTeamData({ teamId, tenantId, teamName, showCostCenter })
    },
    [onSelectTeamData],
  )

  return {
    isTeamModalOpen: Boolean(selectedTeamData),
    onTeamClick,
    selectedTeamData,
    selectedTeamPlanData,
  }
}

export default useSelectedTeamPlanContent
