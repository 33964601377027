import { css, styled } from '@mui/material'

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCurrentWeek',
})<{ isCurrentWeek: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.3;
  transition: opacity 0.8s ease-in-out;
  ${({ isCurrentWeek, theme }) =>
    isCurrentWeek
      ? css`
          opacity: 1;
        `
      : css`
          margin: ${theme.spacing(0, 3)};
        `}
`

export const DayContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ theme }) => theme.spacing(4.5)};
  gap: ${({ theme }) => theme.spacing(0.25)};
`

export const ShiftsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'displayStacks',
})<{ displayStacks: boolean }>`
  height: ${({ theme }) => theme.spacing(0.5)};
  width: 100%;
  opacity: 0;
  ${({ displayStacks, theme }) =>
    displayStacks
      ? css`
          opacity: 1;
        `
      : css`
          margin: ${theme.spacing(0, 3)};
        `}
  transition: opacity 0.8s ease-in-out;
`
