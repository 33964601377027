import React from 'react'
import { AccordionDetails, useTheme } from '@mui/material'
import { ChevronDown } from 'redesign/components/atoms/Icon'
import { AccordionSummary, MuiAccordion } from './styles'
import { AccordionProps } from './types'

const Accordion: React.FC<AccordionProps> = ({ children, header, defaultExpanded, testId }) => {
  const { palette } = useTheme()

  return (
    <MuiAccordion data-testid={testId} disableGutters defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ChevronDown color={palette.primary.main} />}>{header}</AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion
