import React from 'react'
import { ThemeProvider } from '@mui/material'
import usePushNotifications from 'hooks/usePushNotifications'
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom'
import redesignTheme from 'redesign/themes/main'
import routes from 'services/RoutesProvider/routes'
import { isNativeNoOverride } from 'utils/platform'

const ComponentWithPushNotification = ({
  component: Component,
  redirectTo,
  ...props
}: RouteProps &
  RouteComponentProps & {
    redirectTo: string
  }) => {
  // check push notifications on any protected route
  usePushNotifications({ refresh: true })
  return <>{Component ? <Component {...props} /> : <Redirect to={redirectTo} />}</>
}

const ProtectedRoute = ({
  component: Component,
  isAllowed,
  redirectTo = routes.login,
  noPushRegistration = false,
  moduleAccess,
  redirectLogin = false,
  isRedesignTheme = false,
  ...rest
}: RouteProps & {
  isAllowed: boolean
  redirectTo?: string
  noPushRegistration?: boolean
  moduleAccess?: boolean
  redirectLogin?: boolean
  isRedesignTheme?: boolean
}) => {
  const renderComponent = (props: RouteComponentProps) => {
    if (redirectLogin && !isAllowed) return <Route {...rest} render={renderComponent} />
    if (!isAllowed || !Component) return <Redirect to={redirectTo} />
    if (moduleAccess === false) return <Redirect to={routes.dashboard} />
    if (noPushRegistration || !isNativeNoOverride()) return <Component {...props} />

    return <ComponentWithPushNotification {...props} component={Component} redirectTo={redirectTo} />
  }

  return isRedesignTheme ? (
    <ThemeProvider theme={redesignTheme}>
      <Route {...rest} render={renderComponent} />
    </ThemeProvider>
  ) : (
    <Route {...rest} render={renderComponent} />
  )
}

export default ProtectedRoute
