import { Box, styled } from '@mui/material'

export const Container = styled(Box)`
  padding: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
` as typeof Box

export const ButtonContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(0, 1)};
`
