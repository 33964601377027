import React, { useCallback } from 'react'
import { Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { useHistory } from 'react-router-dom'
import BackButton from 'redesign/components/atoms/Buttons/BackButton'
import type { BackButtonProps } from 'redesign/components/atoms/Buttons/BackButton/types'
import Header from '../../Header'

const ShiftUpdateDetailHeader: React.FC<BackButtonProps> = ({ onGoBack }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { pushBackLinkOrDefault } = useStateBackLink()

  const gotoBackLink = useCallback(() => {
    if (onGoBack) onGoBack()
    pushBackLinkOrDefault(() => {
      history.goBack()
    })
  }, [onGoBack, pushBackLinkOrDefault, history])

  return (
    <Header
      startContent={<BackButton onGoBack={gotoBackLink} />}
      middleContent={
        <Typography variant="subtitle1" data-testid="header__title">
          {t('page.tasks.shiftUpdate.detail.title.one')}
        </Typography>
      }
    />
  )
}

export default ShiftUpdateDetailHeader
