import { createContext, Dispatch, SetStateAction, useContext } from 'react'

export enum AppUnavailableStatus {
  HIDE = 'HIDE',
  LOADING = 'LOADING',
  RETRY = 'RETRY',
}

export type AppUnavailableContextT = {
  status: AppUnavailableStatus
  setStatus: Dispatch<SetStateAction<AppUnavailableStatus>>
}

const AppUnavailableContext = createContext({
  status: AppUnavailableStatus.HIDE,
  setStatus: (_status: AppUnavailableStatus) => {},
} as AppUnavailableContextT)

export const useAppUnavailableContext = () => {
  const context = useContext(AppUnavailableContext)
  return context
}

export default AppUnavailableContext
