import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const Task: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
  isFilled,
}) => {
  const path = isFilled ? (
    <path
      d="M12 22c5.523 0 10-4.477 10-10 0-1.694-.421-3.29-1.164-4.687a.78.78 0 0 1-.09.106l-8.048 7.942a2.18 2.18 0 0 1-3.424-.472L7.229 11.3a.77.77 0 1 1 1.337-.762l2.044 3.588a.641.641 0 0 0 1.008.138l8.047-7.94a.765.765 0 0 1 .419-.213A9.986 9.986 0 0 0 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10Z"
      fill={color}
    />
  ) : (
    <path
      d="M3.788 12.25a8.462 8.462 0 0 1 13.24-6.984.77.77 0 1 0 .87-1.269A9.957 9.957 0 0 0 12.25 2.25c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10c0-.413-.025-.82-.074-1.22a.77.77 0 0 0-1.527.186A8.461 8.461 0 1 1 3.79 12.25Zm17.207-4.58a.77.77 0 1 0-1.08-1.096l-8.047 7.942a.641.641 0 0 1-1.008-.14L8.816 10.79a.77.77 0 1 0-1.337.761l2.045 3.588a2.18 2.18 0 0 0 3.424.472l8.047-7.942Z"
      fill={color}
    />
  )
  return (
    <SvgWrapper viewBox={viewBox} size={size}>
      {path}
    </SvgWrapper>
  )
}

export default Task
