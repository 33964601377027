export const AVATAR_MENU_OPEN_WIDTH = 248
export const AVATAR_MENU_CLOSED_WIDTH = 67
export const TASK_MENU_OPEN_WIDTH = 468
export const TASK_MENU_OPEN_WIDTH_REDESIGN = 407
export const TASK_MENU_CLOSED_WIDTH = 68
export const TEAM_NAME_ROW_HEIGHT = 62
export const CELL_WIDTH = 45
export const CELL_HEIGHT = 52
export const HEADER_CELL_HEIGHT = 72
export const PLACEHOLDER_ROWS_COUNT = 7
export const SEPARATOR_WIDTH = 16
