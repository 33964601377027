import React from 'react'
import { Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import CalendarButton from 'redesign/components/atoms/Buttons/CalendarButton'
import DatePickerDrawer from 'redesign/components/organisms/DatePickerDrawer'
import DrawerHeader from 'redesign/components/organisms/DrawerHeader'
import DatePickerInput from '../../atoms/MonthPickerInput'
import type { MobileMonthPickerProps } from './types'
import { useMobileMonthPicker } from './useMobileMonthPicker'

const MobileMonthPicker: React.FC<MobileMonthPickerProps> = ({
  label,
  testId = 'date-picker',
  disabled,
  error,
  fullWidth,
  onChange,
  defaultValue,
  minDate,
  maxDate,
  setDisplayDateValue,
  displayDateValue,
  isButton = false,
}) => {
  const {
    displayedFieldValue,
    monthOptionProps,
    onChangeValue,
    onClick,
    onClose,
    open,
    yearOptionProps,
    handleCalendarButtonAction,
  } = useMobileMonthPicker({ onChange, defaultValue, minDate, maxDate })
  const { t } = useFavurTranslation()

  return (
    <>
      {isButton ? (
        <CalendarButton label={label} handleCalendarAction={handleCalendarButtonAction} />
      ) : (
        <DatePickerInput
          disabled={disabled}
          error={error}
          focused={open}
          label={label}
          onClick={() => {
            setDisplayDateValue(true)
            onClick()
          }}
          testId={testId}
          value={displayDateValue ? displayedFieldValue : ''}
          fullWidth={fullWidth}
        />
      )}
      <DatePickerDrawer
        header={
          <DrawerHeader
            middleContent={<Typography variant="subtitle1">{t('filter.task_time_frame.date_picker_title')}</Typography>}
            closeIconOnClick={onClose}
          />
        }
        monthOptionProps={monthOptionProps}
        onClose={onClose}
        onChange={onChangeValue}
        open={open}
        yearOptionProps={yearOptionProps}
      />
    </>
  )
}
export default MobileMonthPicker
