import React from 'react'
import AppStatesService from './AppStates'
import AppUnavailable from './AppUnavailable'
import BannerMessagesService from './BannerMessages'
import DialogsService from './Dialogs'
import FeatureFlagService from './FeatureFlags'
import FlashMessagesService from './FlashMessages'
import OfficeViewService from './OfficeView'
import PinService from './Pin'
import PSNService from './PushNotifications'
import SessionService from './Session'
import ShiftsService from './Shifts'

const Services = ({ children }: { children?: React.ReactNode }) => {
  return (
    <FlashMessagesService>
      <AppUnavailable>
        <SessionService>
          <AppStatesService>
            <ShiftsService>
              <PinService>
                <FeatureFlagService>
                  <BannerMessagesService>
                    <DialogsService>
                      <OfficeViewService>
                        <PSNService>{children}</PSNService>
                      </OfficeViewService>
                    </DialogsService>
                  </BannerMessagesService>
                </FeatureFlagService>
              </PinService>
            </ShiftsService>
          </AppStatesService>
        </SessionService>
      </AppUnavailable>
    </FlashMessagesService>
  )
}

export default Services
