import React, { useMemo } from 'react'
import useFilterContext from 'components/Filter/context'
import RemovableChipGroup from '../../ChipGroups/RemovableChipGroup'
import { ChipContainer, Container } from './styles'

const SubheaderFilters: React.FC = () => {
  const { getChips, removeChip, openFilterPage } = useFilterContext()

  const chips = useMemo(() => getChips(), [getChips])

  const options = useMemo(
    () =>
      chips.map((chip) => ({
        label: chip.label,
        value: chip.filterName,
      })),
    [chips],
  )

  const onDelete = (value: string | number) => {
    const chip = chips.find((foundChip) => foundChip.filterName === value)
    if (chip) {
      removeChip(chip)
    }
  }

  if (!options.length) return null

  return (
    <Container>
      <ChipContainer>
        <RemovableChipGroup options={options} onDelete={onDelete} onClick={() => openFilterPage()} />
      </ChipContainer>
    </Container>
  )
}

export default SubheaderFilters
