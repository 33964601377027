import React, { useMemo } from 'react'
import Headline from 'redesign/components/atoms/Headline'
import { HEADLINE_LEVELS } from 'redesign/components/atoms/Headline/constants'
import EventsSection from 'redesign/components/organisms/ShiftPlanSections/EventsSection'
import NoShiftTenantSection from 'redesign/components/organisms/ShiftPlanSections/NoShiftTenantSection'
import ShiftSection from 'redesign/components/organisms/ShiftPlanSections/ShiftSection'
import TeamSection from 'redesign/components/organisms/ShiftPlanSections/TeamSection/TeamSection'
import { getTodayFormatted } from 'utils/date'
import { Container } from './styles'
import type { ShiftPlanContentProps } from './types'
import useShiftPlanContent from './useShiftPlanContent'

const ShiftPlanContent: React.FC<ShiftPlanContentProps> = ({
  sliderParams,
  sortedTenantIds,
  showTenantHeadline,
  tenantList,
  groupedTenants,
  getAbsenceRequestsByTenantId,
  effectiveShifts,
  effectiveShiftsLoading,
  effectiveShiftsError,
  shiftUpdates,
  onTaskClick,
  getEventsForTenantShiftCard,
  date,
  shiftsSectionLoading,
  teamPlanData,
  teamPlanLoading,
  onTeamClick,
  eventsLoading,
  showNoShiftTenant,
  filteredPersonsWithTenant,
}) => {
  const isPast = useMemo(() => Boolean(date && date < getTodayFormatted()), [date])
  const shiftPlanData = useShiftPlanContent({
    sortedTenantIds,
    tenantList,
    groupedTenants,
    getAbsenceRequestsByTenantId,
    effectiveShifts,
    effectiveShiftsLoading,
    shiftUpdates,
    getEventsForTenantShiftCard,
    isPast,
    date,
    teamPlanData,
  })

  return showNoShiftTenant ? (
    <NoShiftTenantSection
      date={date}
      isPast={isPast}
      getAbsenceRequestsByTenantId={getAbsenceRequestsByTenantId}
      onTaskClick={onTaskClick}
      filteredPersonsWithTenant={filteredPersonsWithTenant}
    />
  ) : (
    <>
      {shiftPlanData.map(
        ({
          tenant,
          shiftData,
          events,
          showEvents,
          absenceRequests,
          groupedShifts,
          filteredEffectiveShifts,
          canShowEffectiveTimes,
          showShiftUpdateAlert,
          updateTask,
          shiftUpdateDaysCount,
          teamPlan,
        }) => (
          <Container key={`${tenant.id}-${date}-${sliderParams.index}`}>
            {showTenantHeadline && (
              <Headline testId={`${tenant.name}-headline`} level={HEADLINE_LEVELS.first} text={tenant.name} />
            )}
            {showEvents && <EventsSection eventsLoading={eventsLoading} events={events} date={date} />}
            <ShiftSection
              date={date}
              isPast={isPast}
              shiftData={shiftData}
              tenant={tenant}
              absenceRequests={absenceRequests}
              groupedShifts={groupedShifts}
              onTaskClick={onTaskClick}
              effectiveShifts={filteredEffectiveShifts}
              effectiveShiftsError={Boolean(effectiveShiftsError)}
              canShowEffectiveTimes={canShowEffectiveTimes}
              showShiftUpdateAlert={showShiftUpdateAlert}
              updateTask={updateTask}
              shiftsSectionLoading={shiftsSectionLoading}
              shiftUpdateDaysCount={shiftUpdateDaysCount}
            />
            {!showShiftUpdateAlert && (
              <TeamSection
                loading={teamPlanLoading || shiftsSectionLoading}
                teamPlan={teamPlan}
                onTeamClick={onTeamClick}
              />
            )}
          </Container>
        ),
      )}
    </>
  )
}
export default ShiftPlanContent
