import { styled } from '@mui/material'
import { TAG_SIZE_VALUES, TAG_SIZE } from '../constants'

export const MoreEntries = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: ${({ theme }) => theme.spacing(TAG_SIZE_VALUES[TAG_SIZE.small])};
  box-sizing: border-box;
`
