import React from 'react'
import MonthlyCalendarGrid from '../MonthlyCalendarGrid'
import type { ShiftPlanMonthlyCalendarContentProps } from './types'
import useMonthlyCalendar from './useMonthlyCalendar'

const ShiftPlanMonthlyCalendarContent: React.FC<ShiftPlanMonthlyCalendarContentProps> = ({
  index,
  referenceDay,
  onClickDay,
  onClose,
  calendarTitleDay,
}) => {
  const { loadingMonth, monthTaskList, eventsByDate, monthShifts, weekCalendarDates } = useMonthlyCalendar({
    index,
    referenceDay,
  })

  return (
    <MonthlyCalendarGrid
      onClickDay={onClickDay}
      onClose={onClose}
      shifts={monthShifts}
      key={index}
      weekCalendarDates={weekCalendarDates}
      eventsByDate={eventsByDate}
      tasks={monthTaskList}
      loading={loadingMonth}
      referenceDay={calendarTitleDay}
    />
  )
}

export default ShiftPlanMonthlyCalendarContent
