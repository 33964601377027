import React, { useCallback, useMemo, useState } from 'react'
import { TextField } from '@mui/material'
import DatePicker from 'components/DatePickers/UncontrolledDatePicker'
import type { FamilyFormValuesKeysT, PersonFieldKeysT, PersonFieldT, PersonKeysT } from 'components/Forms/Family/types'
import { genders } from 'components/Forms/PersonalData/constants'
import InfoAdornment from 'components/Forms/components/InfoAdornment'
import InfoDialog from 'components/Forms/components/InfoDialog'
import UncontrolledSelect from 'components/Forms/components/UncontrolledSelect'
import { classes } from 'components/Forms/styles'
import type { FormHandlersT, FormValuesKeysT } from 'components/Forms/types'
import Grid from 'components/Grid'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { newDateWithoutTime } from 'utils/date'

interface IPersonFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  favurForm: Record<string, any>
  formHandlers: FormHandlersT
  namePrefix: string
  inputKeys: PersonKeysT
  iterator?: {
    index: number
    item: PersonFieldT & { id: string }
  }
}

const PersonForm: React.FC<IPersonFormProps> = ({ formHandlers, inputKeys, iterator, namePrefix, favurForm }) => {
  const { getValues } = favurForm
  const { t } = useFavurTranslation()
  const [openSocialSecurityDialog, setOpenSocialSecurityDialog] = useState(false)

  const getFullName = useCallback(
    (nestedInputName: string) =>
      iterator ? `${namePrefix}.${iterator.index}.${nestedInputName}` : `${namePrefix}${nestedInputName}`,
    [iterator, namePrefix],
  )

  const formHandlersWithFullName = useCallback(
    (nestedInputName: string) => {
      const fullName = getFullName(nestedInputName)
      if (iterator) {
        const handlers = formHandlers(fullName as FormValuesKeysT)

        return {
          defaultValue: iterator?.item?.[`${nestedInputName}` as PersonFieldKeysT] || '',
          ...handlers,
        }
      }
      return formHandlers(fullName as FormValuesKeysT)
    },
    [formHandlers, getFullName, iterator],
  )

  const getDefaultValue = useCallback(
    (type: 'gender' | 'birthDate') => {
      if (iterator) {
        return iterator.item[type]
      }

      return getValues(
        getFullName(type === 'birthDate' ? `${inputKeys.birthdate}` : `${inputKeys[type]}`) as FamilyFormValuesKeysT,
      ) as string
    },
    [getFullName, getValues, inputKeys, iterator],
  )

  const defaultGender = useMemo(() => getDefaultValue('gender'), [getDefaultValue])

  return (
    <>
      <Grid sx={classes.gridForFormField} col={4}>
        <TextField
          variant="standard"
          required
          fullWidth
          {...formHandlersWithFullName(`${inputKeys.firstname}`)}
          slotProps={{
            htmlInput: { 'data-testid': `person-block-form_${namePrefix}-first-name` },
          }}
        />
      </Grid>
      <Grid sx={classes.gridForFormField} col={4}>
        <TextField
          variant="standard"
          required
          fullWidth
          {...formHandlersWithFullName(`${inputKeys.lastname}`)}
          slotProps={{
            htmlInput: { 'data-testid': `person-block-form_${namePrefix}-last-name` },
          }}
        />
      </Grid>
      <Grid sx={classes.gridForFormField} col={4}>
        <UncontrolledSelect
          sortOptions
          translate
          required
          defaultValue={defaultGender ?? ''}
          name={getFullName(`${inputKeys.gender}`)}
          formHandlers={formHandlers(getFullName(`${inputKeys.gender}`) as FormValuesKeysT)}
          options={genders}
        />
      </Grid>
      <Grid sx={classes.gridForFormField} col={4}>
        <DatePicker
          required={true}
          formField={getFullName(`${inputKeys.birthdate}`)}
          dataTestId={`person-block-form_${namePrefix}-birthdate`}
          favurForm={favurForm}
          formHandlers={formHandlersWithFullName(`${inputKeys.birthdate}`)}
          minDate={newDateWithoutTime('1900-01-01')}
          useDefaultValue={false}
        />
      </Grid>
      <Grid sx={classes.gridForFormField} col={4}>
        <TextField
          variant="standard"
          fullWidth
          {...formHandlersWithFullName(`${inputKeys.insurance}`)}
          slotProps={{
            htmlInput: { 'data-testid': `person-block-form_${namePrefix}-health-insurance` },
          }}
        />
        <InfoAdornment type="title" open={openSocialSecurityDialog} setOpen={setOpenSocialSecurityDialog} />
        <InfoDialog
          open={openSocialSecurityDialog}
          action={() => setOpenSocialSecurityDialog(false)}
          text={t('personalData.socialInsuranceNumber.dialog')}
        />
      </Grid>
    </>
  )
}

export default PersonForm
