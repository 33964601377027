import React, { useCallback } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStoredFilters from 'pages/Shifts/useStoredFilters'
import { useSwipeableView } from 'redesign/services/SwipeableView/useSwipeableView'

export const useGeneralSettings = () => {
  const { t } = useFavurTranslation()
  const { sideMenuData } = useSwipeableView()
  const { showTopCalendarShifts, setShowTopCalendarShifts } = sideMenuData
  const { updateStoredFilter } = useStoredFilters()

  const onCheckChange = useCallback(
    (key: string, checked: boolean) => {
      updateStoredFilter(key, checked)
      setShowTopCalendarShifts(checked)
    },
    [updateStoredFilter, setShowTopCalendarShifts],
  )

  const options = [
    {
      checked: showTopCalendarShifts,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target
        onCheckChange('showTopCalendarShifts', checked)
      },
      key: t('page.shifts.sideMenu.showShiftChips'),
      label: t('page.shifts.sideMenu.showShiftChips'),
    },
  ]

  return {
    options,
    headlineTitle: t('navigation.sideMenu.settings'),
  }
}
