import React, { useState } from 'react'
import { IconButton, Tooltip, Typography, Menu, MenuItem, Fade, Box } from '@mui/material'
import ApplicationInformation from 'components/ApplicationInformation'
import Logo from 'components/Logo'
import OldNativeVersionDialog from 'components/OldNativeVersionDialog'
import Page from 'components/Page'
import ResizingPaper from 'components/ResizingPaper'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { InfoL } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { isNativeNoOverride } from 'utils/platform'
import { Browser } from '@capacitor/browser'
import LanguageSwitcher from './components/LanguageSwitcher'
import MirusLogo from './components/MirusLogo'
import { classes } from './styles'

interface Props {
  children?: React.ReactNode
}

const EntryPointWithMirusLogo: React.FC<Props> = ({ children }) => {
  const [showInfo, setShowInfo] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const history = useHistory()
  const closeInfo = () => setShowInfo(false)
  const { t } = useFavurTranslation()
  const infoLabel = t('navigation.main.info.application') as string
  const privacyLabel = t('navigation.main.info.privacy') as string
  const contactLabel = t('page.contact.page.title') as string
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Page
      center
      footer={
        <Box sx={classes.footer}>
          <Tooltip title={infoLabel}>
            <IconButton color="default" aria-label={infoLabel} onClick={handleClick} size="large">
              <InfoL sx={classes.icon} />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            slots={{ transition: Fade }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            slotProps={{ paper: { sx: classes.paperMenu } }}
          >
            <MenuItem onClick={() => history.push(routes.contact)} sx={classes.listItem}>
              <Typography variant="button" sx={classes.itemTypography}>
                {contactLabel}
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={async () => {
                await Browser.open({ url: t('page.termsAndConditionsUpdate.url.dataProtection') })
              }}
              sx={classes.listItem}
            >
              <Typography variant="button" sx={classes.itemTypography}>
                {privacyLabel}
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose()
                setShowInfo(true)
              }}
              sx={classes.listItem}
            >
              <Typography variant="button" sx={classes.itemTypography}>
                {infoLabel}
              </Typography>
            </MenuItem>
          </Menu>
          {showInfo && <ApplicationInformation label={infoLabel} onClose={closeInfo} />}
        </Box>
      }
    >
      <Box sx={classes.centeredBox} component={ResizingPaper} p={2}>
        <Box sx={classes.header}>
          <Box sx={classes.actions}>
            <LanguageSwitcher />
          </Box>
          <Box sx={classes.imageContainer}>
            <Logo />
          </Box>
        </Box>

        {children}
      </Box>
      <Box sx={classes.outsideCentered}>
        <Box sx={classes.collaborationText}>
          <Typography variant="caption">{t('login.common.collaboration.text')}</Typography>
        </Box>
        <MirusLogo />
      </Box>
      {isNativeNoOverride() && <OldNativeVersionDialog />}
    </Page>
  )
}

export default EntryPointWithMirusLogo
