import React from 'react'
import Chip from 'redesign/components/atoms/Chip'
import { Container } from './styles'
import type { RemovableChipGroupProps } from './types'

const RemovableChipGroup: React.FC<RemovableChipGroupProps> = ({ options, onDelete, onClick }) => (
  <Container>
    {options.map(({ label, value }) => (
      <Chip key={value} label={label} onDelete={() => onDelete(value)} selected onClick={onClick} />
    ))}
  </Container>
)

export default RemovableChipGroup
