import React, { useCallback } from 'react'
import { Box, TextField } from '@mui/material'
import { FormPhoneInputT } from 'hooks/usePhoneNumber'
import { CheckMark } from 'icons'
import { ICON_SIZE_S } from 'icons/constants'
import { isMobile } from 'utils/platform'
import { iconClass, classes } from './styles'

interface IPhoneNumberInputParams {
  label: string
  phone: FormPhoneInputT
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  setPhone?: React.Dispatch<React.SetStateAction<FormPhoneInputT>>
  error?: boolean
  disabled?: boolean
  helperText?: string
  fullWidthNoMargin?: boolean
  required?: boolean
  readOnly?: boolean
}

const PhoneNumberInput: React.FC<IPhoneNumberInputParams> = ({
  label,
  phone,
  onChange,
  setPhone,
  error,
  disabled,
  helperText,
  fullWidthNoMargin,
  required,
  readOnly,
}) => {
  const useMobileComponent = isMobile() && !disabled
  const updateCountryCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPhone !== undefined &&
        setPhone({
          countryCode: e.target.value.replace('+', ''),
          phoneNumber: phone.phoneNumber,
          isValidNumber: phone.isValidNumber,
        })
    },
    [phone.phoneNumber, phone.isValidNumber, setPhone],
  )

  return (
    <Box sx={fullWidthNoMargin ? classes.formInputFullWidth : classes.formInput}>
      <TextField
        sx={useMobileComponent ? classes.formInputMainMobile : classes.formInputMain}
        variant="standard"
        required={required}
        type="tel"
        autoComplete="tel"
        id="login-start-phone_number"
        label={label}
        value={phone.phoneNumber}
        onChange={onChange}
        error={error}
        helperText={helperText}
        disabled={disabled}
        slotProps={{
          input: {
            readOnly,
            startAdornment: (
              <TextField
                sx={classes.countryCode}
                variant="standard"
                autoComplete="tel-country-code"
                value={phone.countryCode ? `+${phone.countryCode}` : ''}
                onChange={updateCountryCode}
                onFocus={(e) => e.target.blur()}
                disabled={disabled}
                slotProps={{
                  htmlInput: {
                    type: 'tel',
                    inputMode: 'none',
                  },
                }}
              />
            ),
            endAdornment: (
              <CheckMark fill={phone.isValidNumber ? undefined : 'transparent'} sx={iconClass} size={ICON_SIZE_S} />
            ),
          },

          htmlInput: {
            'aria-label': 'login-start-phone_number',
            'data-testid': 'login-start-phone-number',
            inputMode: 'tel',
          },
        }}
      />
    </Box>
  )
}

export default PhoneNumberInput
