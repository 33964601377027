import React from 'react'
import { Button, useTheme } from '@mui/material'
import CloseButton from 'redesign/components/atoms/Buttons/CloseButton/CloseButton'
import { ICON_SIZE } from 'redesign/components/atoms/Icon/constants'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import ContentCard from '../../../organisms/ContentCard'
import { FooterContainer } from '../../../organisms/FullScreenModal/styles'
import Header from '../../../organisms/Header'
import { SIDE_MENU_TRANSLATIONS } from '../../SideMenu/constants'
import type { MenuOptions, MenuPageProps } from '../../SideMenu/types'
import { getMainMenuTitle } from '../../SideMenu/utils'
import { sideMenuIcons } from '../../SideMenuIcons/constants'

const MainMenu: React.FC<MenuPageProps> = ({ onClose, pages, parentPage, setPage, t }) => {
  const { palette } = useTheme()

  return (
    <>
      <Header
        middleContent={
          <HeaderLabel mainText={t('navigation.sideMenu.header')} subText={getMainMenuTitle(parentPage, t)} />
        }
        endContent={<CloseButton onClick={onClose} />}
      />
      <div>
        {pages.map((page) => {
          const Icon = sideMenuIcons[page]

          return (
            <ContentCard
              key={page}
              header={{
                icon: <Icon color={palette.primary.main} size={ICON_SIZE.small} />,
                title: t(SIDE_MENU_TRANSLATIONS[page as MenuOptions]),
                showChevron: true,
              }}
              onClick={() => setPage(page)}
            />
          )
        })}
      </div>
      <FooterContainer removeBottomPadding>
        <Button variant="contained" fullWidth onClick={() => setPage('newAbsenceRequest')}>
          {t('absencePlanner.taskbar.newAbsenceRequest.header')}
        </Button>
      </FooterContainer>
    </>
  )
}

export default MainMenu
