import { shiftTypes } from 'constants/shift'
import { groupBy, sortBy } from 'lodash/fp'
import type { PlannedShiftT } from 'types'
import { groupShiftsByShortDescription } from 'utils/shifts'
import type { CostCenterT } from './types'

export const groupShiftsByDate = (shifts: PlannedShiftT[]): { [key: string]: PlannedShiftT[] } =>
  Object.entries(groupBy('date', shifts)).reduce((acc, [key, value]) => {
    return { ...acc, [key]: value }
  }, {})

export const groupShiftsByCostCenter = (shifts: PlannedShiftT[]) => {
  const shiftsByCostCenter = shifts.reduce((acc, shift) => {
    const costCenterId = shift.costCenterUuid as string

    const currentCostCenterAndShifts = !acc[costCenterId]
      ? ({ costCenter: { uuid: shift.costCenterUuid, name: shift.costCenterName }, shifts: [] } as {
          costCenter: CostCenterT
          shifts: PlannedShiftT[]
        })
      : acc[costCenterId]

    const currentShifts = sortBy('from', currentCostCenterAndShifts.shifts)
    const currentCostCenter = currentCostCenterAndShifts.costCenter
    const earliestTime = currentShifts.length > 0 ? currentShifts[0].from : shift.from

    return {
      ...acc,
      [costCenterId]: { costCenter: currentCostCenter, shifts: [...currentShifts, shift], earliestTime },
    }
  }, {} as { [key: string]: { costCenter: CostCenterT; shifts: PlannedShiftT[]; earliestTime: string | null } })

  const costCenterGroupedShifts = Object.values(shiftsByCostCenter).map((costCenterAndShifts) => {
    const groupedShifts = groupShiftsByShortDescription(costCenterAndShifts.shifts as PlannedShiftT[])
    const absences = groupedShifts.filter((shiftGroup) => shiftGroup[0].type === shiftTypes.absence)
    const rest = groupedShifts.filter((shiftGroup) => shiftGroup[0].type !== shiftTypes.absence)
    return {
      ...costCenterAndShifts,
      shifts: [...absences, ...rest],
    }
  })

  return sortBy('earliestTime', costCenterGroupedShifts)
}
