import { styled } from '@mui/material'
import { IonRefresher, IonRefresherContent } from '@ionic/react'

export const PullIndicator = styled(IonRefresherContent)`
  z-index: 100000;
  height: 0 !important;
`

export const Refresher = styled(IonRefresher)`
  display: contents !important;
`
