import { useState } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { UserAnnouncement } from 'shared/graphql/graphql'
import { announcementsQuery } from 'shared/queries'

export const useAnnouncements = () => {
  const [announcements, setAnnouncements] = useState<UserAnnouncement[]>()
  const { loading, refetch } = useJamesApolloQuery<{ announcements: UserAnnouncement[] }>(announcementsQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setAnnouncements(data.announcements)
    },
  })

  return { announcements, loading, refetch }
}
