import { styled } from '@mui/material'

export const SectionContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

export const ShiftsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const TeamPlanContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`
