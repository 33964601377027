import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { MoreEntries } from './styles'

const MoreEntriesTag: React.FC<{ overflowVal: number }> = ({ overflowVal }) => {
  const { palette } = useTheme()

  return (
    <MoreEntries>
      <Typography variant="caption" color={palette.text.secondary}>
        +{overflowVal}
      </Typography>
    </MoreEntries>
  )
}

export default MoreEntriesTag
