import React, { memo } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ArrowLeftThinS, ArrowRightThinS, CalendarDayM, SideMenuL } from 'icons'
import theme from 'utils/theme'
import MonthSelector from './MonthSelector'
import { monthSwitcherBarClasses } from './styles'
import type { MonthSwitcherBarProps } from './types'

const MonthSwitcherBar: React.FC<MonthSwitcherBarProps> = ({
  shownMonthIndex,
  toNextMonth,
  toPrevMonth,
  toToday,
  baseDate,
  onMonthSelect,
  onMenuClick,
}) => {
  const { t } = useFavurTranslation()

  return (
    <Box sx={monthSwitcherBarClasses.container}>
      <Box sx={monthSwitcherBarClasses.flexContainer}>
        <Box sx={monthSwitcherBarClasses.dateContainer}>
          <IconButton
            disableRipple
            disableFocusRipple
            sx={monthSwitcherBarClasses.iconButtons}
            onClick={toPrevMonth}
            data-testid="absence-planner-to_prev_month"
          >
            <ArrowLeftThinS fill={theme.palette.secondary.main} />
          </IconButton>
          <MonthSelector shownMonthIndex={shownMonthIndex} baseDate={baseDate} onMonthSelect={onMonthSelect} />
          <IconButton
            disableRipple
            disableFocusRipple
            sx={monthSwitcherBarClasses.iconButtons}
            onClick={toNextMonth}
            data-testid="absence-planner-to_next_month"
          >
            <ArrowRightThinS fill={theme.palette.secondary.main} />
          </IconButton>
        </Box>
        <IconButton disableRipple disableFocusRipple sx={monthSwitcherBarClasses.iconButtons} onClick={toToday}>
          <CalendarDayM fill={theme.palette.primary.main} />
          <Typography variant="body2" sx={monthSwitcherBarClasses.textToday}>
            {t('page.shifts.content.today')}
          </Typography>
        </IconButton>
      </Box>
      <Box sx={monthSwitcherBarClasses.emptyHeaderBlock}>
        <IconButton
          sx={monthSwitcherBarClasses.sideMenuIcon}
          disableRipple
          data-testid="header__button--side-menu"
          size="large"
          onClick={onMenuClick}
        >
          <SideMenuL />
        </IconButton>
      </Box>
    </Box>
  )
}

export default memo(MonthSwitcherBar)
