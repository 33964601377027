import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const ChevronRight: React.FC<IconProps> = ({
  color = 'currentColor',
  fillOpacity,
  size = ICON_SIZE.medium,
  viewBox = ICON_VIEWBOX.medium,
}) => (
  <SvgWrapper aria-label="Chevron Right Icon" viewBox={viewBox} size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.293 4.293a1 1 0 0 1 1.414 0l7 7a1 1 0 0 1 0 1.414l-7 7a1 1 0 0 1-1.414-1.414L14.586 12 8.293 5.707a1 1 0 0 1 0-1.414z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  </SvgWrapper>
)

export default ChevronRight
