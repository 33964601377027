import type { ComponentType } from 'react'
import { ManagerTasks, EventIcon, SettingsIcon, PlaceholderIcon, Task } from 'redesign/components/atoms/Icon'
import type { IconProps } from 'redesign/components/atoms/Icon/types'
import { SIDE_MENU_OPTIONS } from '../SideMenu/constants'
import type { MenuOptions } from '../SideMenu/types'

export const sideMenuIcons: Record<MenuOptions, ComponentType<IconProps>> = {
  [SIDE_MENU_OPTIONS.adminAbsences]: ManagerTasks,
  [SIDE_MENU_OPTIONS.personalAbsences]: Task,
  [SIDE_MENU_OPTIONS.events]: EventIcon,
  [SIDE_MENU_OPTIONS.personalShiftUpdates]: Task,
  [SIDE_MENU_OPTIONS.adminShiftUpdates]: ManagerTasks,
  [SIDE_MENU_OPTIONS.settings]: SettingsIcon,
  [SIDE_MENU_OPTIONS.mainMenu]: PlaceholderIcon,
  [SIDE_MENU_OPTIONS.newAbsenceRequest]: PlaceholderIcon,
}
