import React from 'react'
import { motion } from 'framer-motion'
import NavigationButtons from 'redesign/components/molecules/NavigationButtons'
import NavigationGroup from 'redesign/components/molecules/NavigationGroup'
import { MENU_CONTAINER_MOTION, PRIMARY_GROUP_MOTION, SECONDARY_GROUP_MOTION, TERTIARY_GROUP_MOTION } from './motions'
import { MenuContainer, PrimaryNavigation, TertiaryNavigation } from './styles'
import type { TNavigationMenuOptions } from './types'

const NavigationMenuOptions: React.FC<TNavigationMenuOptions> = ({
  appInfoData,
  showAppInfo,
  toggleAppInfoDialog,
  navigationLinks,
  onNavigationGroupClick,
  navigationButtonCallbacks,
}) => {
  const { primaryNavigation, secondaryNavigation, tertiaryNavigation } = { ...navigationLinks }

  return (
    <MenuContainer data-testid="navigation-menu">
      <motion.div initial="hidden" animate="visible" exit="hidden" variants={MENU_CONTAINER_MOTION}>
        {primaryNavigation && (
          <PrimaryNavigation>
            <motion.div variants={PRIMARY_GROUP_MOTION}>
              <NavigationGroup navPoints={primaryNavigation} onNavigationGroupClick={onNavigationGroupClick} />
            </motion.div>
          </PrimaryNavigation>
        )}
        {secondaryNavigation && (
          <>
            <motion.div variants={SECONDARY_GROUP_MOTION}>
              <NavigationGroup navPoints={secondaryNavigation} onNavigationGroupClick={onNavigationGroupClick} />
            </motion.div>
          </>
        )}

        {tertiaryNavigation && (
          <TertiaryNavigation>
            <motion.div variants={TERTIARY_GROUP_MOTION}>
              <NavigationButtons
                appInfoData={appInfoData}
                showAppInfo={showAppInfo}
                toggleAppInfoDialog={toggleAppInfoDialog}
                navPoints={tertiaryNavigation}
                navigationButtonCallbacks={navigationButtonCallbacks}
              />
            </motion.div>
          </TertiaryNavigation>
        )}
      </motion.div>
    </MenuContainer>
  )
}

export default NavigationMenuOptions
