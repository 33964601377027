import React from 'react'
import { Typography } from '@mui/material'
import { CloseIcon } from '../Icon'
import { ICON_SIZE } from '../Icon/constants'
import { Chip as MuiChip } from './styles'
import type { ChipProps } from './types'

const Chip: React.FC<ChipProps> = ({ selected, label, onClick, ...props }) => (
  <MuiChip
    selected={selected}
    size="medium"
    label={<Typography variant="body1">{label}</Typography>}
    variant={selected ? 'filled' : 'outlined'}
    deleteIcon={<CloseIcon size={ICON_SIZE.small} />}
    onClick={onClick}
    {...props}
  />
)

export default Chip
