// IMPORTANT: AVOID USING THESE FUNCTIONS
// Could potentially bring scalability issues later

export const convertPxToMuiSpacing = (pxValue: number) => (pxValue ?? 0) / 8

export const convertMuiSpacingToPx = (pxValue: number) => (pxValue ?? 0) * 8

export const getFontSizeRem = () => {
  const fontSizePx = getComputedStyle(document.documentElement).fontSize

  return parseFloat(fontSizePx) / 16
}
