import React from 'react'
import { Box, TextField } from '@mui/material'
import Card from 'components/Basics/Card'
import ActionButtons from 'components/Buttons/ActionButtons'
import { BoxedLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TeamTabs } from 'pages/Teams/TeamPage/constants'
import { useForm, SubmitHandler } from 'react-hook-form'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import type { MutationResult } from 'types'
import { useMutation } from '@apollo/client'
import { classes } from '../../../../styles'
import { useTeam } from '../../../useTeam'
import { updateTeamNameMutation } from '../queries'

type NameInputs = {
  nameDe: string
  nameFr: string
  nameIt: string
  nameEn: string
}

const Edit: React.FC<RouteComponentProps<{ uuid: string }>> = ({ match }) => {
  const { uuid: teamUuid } = match.params
  const { team, loading } = useTeam(teamUuid)

  const { t } = useFavurTranslation()
  const requiredFieldText = t('personalData.error.requiredField')
  const history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NameInputs>()

  const [mutate] = useMutation<{ updateTeamName: MutationResult }>(updateTeamNameMutation)

  const onSubmit: SubmitHandler<NameInputs> = async (data) => {
    const result = await mutate({ variables: { ...data, id: teamUuid } })
    if (result.data?.updateTeamName.success) {
      history.push(`${routes.teams}/${teamUuid}/${TeamTabs.settings}`)
    }
  }

  return (
    <Page
      header={
        <WithBackwardLink
          closeIcon
          route={`${routes.teams}/${teamUuid}/${TeamTabs.settings}`}
          title={t(`teams.tabLabel.settings`)}
        />
      }
      hideNativeNavigation
      hasFloatingButtons
    >
      {loading || !team ? (
        <BoxedLoading />
      ) : (
        <Box>
          <Card marginBottom="16px">
            <Box sx={classes.settingsCard}>
              <TextField
                variant="standard"
                {...register('nameDe', { required: true })}
                label={t('teams.createDialog.germanInputName')}
                defaultValue={team?.nameDe}
                error={Boolean(errors.nameDe)}
                helperText={errors?.nameDe ? requiredFieldText : ' '}
                fullWidth
                required
                slotProps={{
                  htmlInput: { 'data-testid': 'new-team-name-de' },
                }}
              />
              <TextField
                variant="standard"
                {...register('nameFr')}
                label={t('teams.createDialog.frenchInputName')}
                defaultValue={team?.nameFr}
                error={Boolean(errors.nameFr)}
                helperText={errors?.nameFr ? requiredFieldText : ' '}
                fullWidth
                required
              />
              <TextField
                variant="standard"
                {...register('nameIt')}
                label={t('teams.createDialog.italianInputName')}
                defaultValue={team?.nameIt}
                error={Boolean(errors.nameIt)}
                helperText={errors?.nameIt ? requiredFieldText : ' '}
                fullWidth
                required
              />
              <TextField
                variant="standard"
                {...register('nameEn')}
                label={t('teams.createDialog.englishInputName')}
                defaultValue={team?.nameEn}
                error={Boolean(errors.nameEn)}
                helperText={errors?.nameEn ? requiredFieldText : ' '}
                fullWidth
                required
              />
            </Box>
          </Card>
          <ActionButtons
            isFloating
            noNavigation
            cancelAction={() => history.push(`${routes.teams}/${teamUuid}/${TeamTabs.settings}`)}
            cancelLabel={t('common.cancel')}
            validateLabel={t('common.confirm')}
            validateAction={handleSubmit(onSubmit)}
          />
        </Box>
      )}
    </Page>
  )
}

export default Edit
