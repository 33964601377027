import React from 'react'
import { CheckBoxRounded, IndeterminateCheckBoxRounded } from '@mui/icons-material'
import { FormControlLabel } from '@mui/material'
import { Checkbox as MuiCheckbox } from '@mui/material'
import { Label } from './styles'
import type { CheckboxProps } from './types'

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { value, label, labelPlacement } = props

  return (
    <FormControlLabel
      value={value}
      control={
        <MuiCheckbox
          {...props}
          indeterminateIcon={<IndeterminateCheckBoxRounded />}
          checkedIcon={<CheckBoxRounded />}
        />
      }
      label={<Label variant="body1">{label}</Label>}
      labelPlacement={labelPlacement}
    />
  )
}

export default Checkbox
