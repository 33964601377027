import { Chip, css, styled } from '@mui/material'
import { TAG_SIZE, TAG_SIZE_VALUES } from './constants'

export const MainContainer = styled(Chip, {
  shouldForwardProp: (prop) =>
    prop !== 'borderColor' &&
    prop !== 'backgroundColor' &&
    prop !== 'tagSize' &&
    prop !== 'fullWidth' &&
    prop !== 'isTransparent' &&
    prop !== 'minWidthRem',
})<{
  backgroundColor?: string
  borderColor?: string
  fullWidth?: boolean
  isTransparent?: boolean
  minWidthRem?: number
  tagSize: keyof typeof TAG_SIZE
}>`
  ${({ minWidthRem, theme }) =>
    minWidthRem &&
    css`
      min-width: ${theme.spacing(minWidthRem)};
    `}
  .MuiChip-label {
    display: flex;
    padding: ${({ theme }) => theme.spacing(0.375, 0.75)};
  }
  background-color: ${({ theme, backgroundColor, isTransparent }) =>
    isTransparent ? theme.palette.common.transparent : backgroundColor};
  border-radius: ${({ theme }) => theme.spacing(0.625)};
  border: ${({ theme, borderColor }) =>
    `${theme.spacing(0.125)} solid ${borderColor ?? theme.palette.common.cardBorder}`};
  height: ${({ tagSize, theme }) => theme.spacing(TAG_SIZE_VALUES[tagSize])};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`

export const TagContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{
  fullWidth?: boolean
}>`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  justify-content: space-between;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`
