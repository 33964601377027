import React from 'react'
import { ButtonProps, IconButton, useTheme } from '@mui/material'
import { CloseIcon } from '../../Icon'

const CloseButton: React.FC<ButtonProps> = (props) => {
  const theme = useTheme()

  return (
    <IconButton {...props}>
      <CloseIcon color={theme.palette.text.secondary} />
    </IconButton>
  )
}

export default CloseButton
