import React from 'react'
import { TextFieldProps } from '@mui/material'
import { Search } from '../Icon'
import { SearchField } from './styles'

const SearchInput: React.FC<TextFieldProps> = ({ onChange, label }) => {
  return (
    <SearchField
      variant="outlined"
      label={label}
      InputProps={{
        endAdornment: <Search fillOpacity="0.6" />,
      }}
      onChange={onChange}
    />
  )
}

export default SearchInput
