import { styled, TextField as MuiTextField } from '@mui/material'

export const TextField = styled(MuiTextField)`
  & .MuiOutlinedInput-root {
    :hover {
      background-color: transparent;

      // media query to check if the user uses a touch device to avoid passing isNative
      @media (pointer: fine) {
        ${({ theme, disabled }) => !disabled && `background-color: ${theme.palette.common.hoverCard}`};
      }
    }
  }

  & .MuiInputBase-input {
    pointer-events: none;
  }

  & .MuiInputBase-root {
    ${({ disabled }) => (disabled ? 'pointer-events: none' : 'cursor: pointer')};
  }

  & .MuiOutlinedInput-notchedOutline {
    ${({ disabled }) => (disabled ? 'pointer-events: none' : 'cursor: pointer')};
  }
`
