import React, { useMemo, useState } from 'react'
import useSessionContext from 'hooks/useSessionContext'
import { SIDE_MENU_OPTIONS } from 'redesign/components/templates/SideMenu/constants'
import type { MenuOptions } from 'redesign/components/templates/SideMenu/types'
import { getSideMenuConfiguration } from 'redesign/components/templates/SideMenu/utils'
import SideMenuContext from './context'
import type { SideMenuProviderProps } from './types'

const SideMenuProvider: React.FC<SideMenuProviderProps> = ({ children, parentPage, persons }) => {
  const [page, setPage] = useState<MenuOptions | undefined>(SIDE_MENU_OPTIONS.mainMenu)
  const [open, setOpen] = useState(false)
  const [taskCount, setTaskCount] = useState<number | undefined>()
  const isAbsenceManager = useMemo(() => (persons ?? []).some((person) => person.permissions.workflowAbsences), [
    persons,
  ])
  const { cmsActivated } = useSessionContext()

  const pages = getSideMenuConfiguration({ parentPage, isAbsenceManager, hasCmsAccess: cmsActivated })

  return (
    <SideMenuContext.Provider
      value={{
        page,
        setPage,
        pages,
        open,
        setOpen,
        parentPage,
        isAbsenceManager,
        taskCount,
        setTaskCount,
      }}
    >
      {children}
    </SideMenuContext.Provider>
  )
}

export default SideMenuProvider
