import React from 'react'
import { DateView, PickersLayoutProps } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { addYears, subYears } from 'date-fns'
import CalendarButton from 'redesign/components/atoms/Buttons/CalendarButton'
import CalendarTodayButton from 'redesign/components/molecules/CalendarTodayButton'
import { newDateWithoutTime } from 'utils/date'
import MonthlyCalendarHeader from './MonthCalendarHeader'
import { Container, CustomLayout, CustomPaper } from './styles'
import type { DesktopMonthPickerProps } from './types'
import { useDesktopMonthPicker } from './useDesktopMonthPicker'

const DesktopMonthPicker: React.FC<DesktopMonthPickerProps> = ({
  label,
  datePickerDate,
  onChange,
  handleToday,
  todayLabel = '',
}) => {
  const { ref, open, placeholderDate, setOpen, setPlaceholderDate, Popper } = useDesktopMonthPicker({ datePickerDate })

  return (
    <Container ref={ref}>
      <DatePicker
        open={open}
        value={datePickerDate}
        slots={{
          desktopPaper: CustomPaper,
          calendarHeader: () => (
            <MonthlyCalendarHeader
              addYear={() => setPlaceholderDate(addYears(placeholderDate, 1))}
              subtractYear={() => setPlaceholderDate(subYears(placeholderDate, 1))}
              year={placeholderDate.getFullYear()}
            />
          ),
          field: () => <CalendarButton handleCalendarAction={() => setOpen(true)} label={label} />,
          layout: CustomLayout as React.FC<PickersLayoutProps<Date | null, Date, DateView>>,
          popper: Popper,
        }}
        views={['month']}
        onClose={() => {
          setPlaceholderDate(datePickerDate)
          setOpen(false)
        }}
        onMonthChange={(date) => {
          const currentYear = date.getFullYear()
          const updatedYear = placeholderDate.getFullYear()
          const updatedDate = addYears(date, updatedYear - currentYear)

          onChange(updatedDate)
          setPlaceholderDate(updatedDate)
        }}
      />
      {handleToday && (
        <CalendarTodayButton
          hideArrows
          label={todayLabel}
          handleToday={() => {
            handleToday()
            setPlaceholderDate(newDateWithoutTime())
          }}
        />
      )}
    </Container>
  )
}
export default DesktopMonthPicker
