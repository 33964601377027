import { Divider, styled, Typography } from '@mui/material'

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'lineThrough',
})<{ gap?: number; lineThrough?: boolean; color?: string }>`
  display: flex;
  gap: ${({ theme, gap }) => theme.spacing(gap ?? 1)};
  align-items: center;
  width: fit-content;
  color: ${({ color }) => color};
  position: relative;
`
export const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'overflowSingleLine',
})<{ overflowSingleLine: boolean }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  justify-content: start;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  line-clamp: ${({ overflowSingleLine }) => (overflowSingleLine ? 1 : 2)};
  -webkit-line-clamp: ${({ overflowSingleLine }) => (overflowSingleLine ? 1 : 2)};
`

export const StyledDivider = styled(Divider)<{ color?: string }>`
  position: absolute !important;
  top: 50%;
  border-bottom-width: ${({ theme }) => theme.spacing(0.125)};
  width: 100%;
  height: 0;
  background-color: ${({ color }) => color};
  margin: 0;
`
