import React from 'react'
import { FilterType } from 'components/Filter/types'
import CheckListFilter from '../CheckListFilter/MultipleChoiceFilter'
import DateRangeFilter from '../DateRangeFilter'
import PersonsFilter from '../PersonsFilter/PersonsFilter'
import type { FilterSectionProps } from './types'

export const getConfigurationComponent = ({
  configuration,
  displayDateValue,
  setDisplayDateValue,
}: FilterSectionProps) => {
  switch (configuration.type) {
    case FilterType.MULTIPLE_CHOICE:
      return <CheckListFilter configuration={configuration} />
    case FilterType.PERSONS:
      return <PersonsFilter configuration={configuration} />
    case FilterType.DATE_RANGE:
      return (
        <DateRangeFilter
          displayDateValue={displayDateValue}
          setDisplayDateValue={setDisplayDateValue}
          configuration={configuration}
        />
      )
    default:
      return <></>
  }
}
