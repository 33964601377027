import { Link, Table, TableRow, css, styled } from '@mui/material'

export const TableLayout = styled(Table)`
  table-layout: fixed;
`

export const Row = styled(TableRow)`
  & .MuiTableCell-root {
    padding: ${({ theme }) => theme.spacing(0.75)};
  }

  &:first-of-type .MuiTableCell-root {
    border-top: ${({ theme }) => `${theme.spacing(0.125)} solid ${theme.palette.grey[100]}`};
  }
  & .MuiTableCell-root:first-of-type {
    padding-left: 0;
  }
  & .MuiTableCell-root:last-of-type {
    padding-right: 0;
    word-break: 'break-word';
  }
`

export const SettingsLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isLive',
})<{ isLive?: boolean }>`
  text-decoration: none;

  ${({ isLive }) =>
    isLive &&
    css`
      color: transparent;
    `}
`
