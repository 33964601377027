import React, { useMemo } from 'react'
import { Typography, useTheme } from '@mui/material'
import { shiftTypes } from 'constants/shift'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Avatar from '../Avatar'
import IconAndTextBlock from '../IconAndTextBlock'
import ShiftPlanCardHeaderIcon from '../ShiftPlanHeaderIcon'
import { ABSENCES_TYPES } from './constants'
import { HeaderContainer, NameAndShiftContainer, Profile } from './styles'
import type { ShiftPlanCardHeaderProps } from './types'

const ShiftPlanCardHeader: React.FC<ShiftPlanCardHeaderProps> = ({ shift, isOld, user, hideAbsenceType = false }) => {
  const { t } = useFavurTranslation()
  const { palette } = useTheme()
  const { description, timeType } = shift
  const title = useMemo(() => {
    if (shift.type === shiftTypes.absence) return hideAbsenceType ? undefined : t(ABSENCES_TYPES[timeType])
    return description
  }, [description, hideAbsenceType, shift.type, t, timeType])

  return (
    <HeaderContainer>
      {Boolean(user) && (
        <Profile>
          <Avatar src={user?.avatarUrl} text={user?.avatarName} />
        </Profile>
      )}
      <NameAndShiftContainer>
        {Boolean(user?.avatarName) && (
          <Typography variant="body1">
            {user?.firstName} {user?.lastName}
          </Typography>
        )}
        <IconAndTextBlock
          color={palette.text.secondary}
          icon={<ShiftPlanCardHeaderIcon shift={shift} />}
          variant="body2"
          title={title}
          lineThrough={isOld}
        />
      </NameAndShiftContainer>
    </HeaderContainer>
  )
}

export default ShiftPlanCardHeader
