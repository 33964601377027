import { newDateWithoutTime } from 'utils/date'
import type { SwipeableViewContextValues } from './types'

export const SWIPEABLE_VIEW_INITIAL_VALUES: SwipeableViewContextValues = {
  finishLoadingGoToDay: () => {},
  loading: false,
  handleIndexChange: () => {},
  handleIndexWeekChange: () => {},
  handleIndexMonthChange: () => {},
  toggleCalendarOpen: () => {},
  closeCalendar: () => {},
  goToDay: () => {},
  goToToday: () => {},
  selectedTeamData: undefined,
  onSelectTeamData: () => {},
  sideMenuData: {
    teams: [],
    publicTeams: [],
    selectedTeams: [],
    setSelectedTeams: () => {},
    selectedPublicTeams: [],
    setSelectedPublicTeams: () => {},
    showTopCalendarShifts: false,
    setShowTopCalendarShifts: () => {},
    isMultiTenant: false,
    costCenters: [],
    setCostCenters: () => {},
    loading: false,
  },
  state: {
    currentDay: newDateWithoutTime(),
    calendarTitleDay: newDateWithoutTime(),
    referenceDay: newDateWithoutTime(),
    index: 0,
    weekIndex: 0,
    monthIndex: 0,
    start: '',
    end: '',
    animateTransition: false,
    showDelay: false,
    calendarOpen: false,
  },
  weeklyShifts: {},
  loadingWeeklyShifts: false,
  canShowShifts: () => false,
}
