import theme from 'utils/theme'
import {
  AVATAR_MENU_CLOSED_WIDTH,
  AVATAR_MENU_OPEN_WIDTH,
  CELL_HEIGHT,
  CELL_WIDTH,
  SEPARATOR_WIDTH,
  TASK_MENU_CLOSED_WIDTH,
  TASK_MENU_OPEN_WIDTH,
  TASK_MENU_OPEN_WIDTH_REDESIGN,
  TEAM_NAME_ROW_HEIGHT,
} from './constants'

const getFullDynamicWidth = (avatarBarExpanded: boolean, taskBarExpanded: boolean) =>
  `calc(100% - ${avatarBarExpanded ? AVATAR_MENU_OPEN_WIDTH : AVATAR_MENU_CLOSED_WIDTH}px - ${
    taskBarExpanded ? TASK_MENU_OPEN_WIDTH : TASK_MENU_CLOSED_WIDTH
  }px - ${SEPARATOR_WIDTH}px)`

export const gridClasses = {
  gridContainer: {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    background: theme.palette.common.white,
  },
  plannerContainer: {
    height: '100%',
    display: 'flex',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'auto !important',
    zIndex: 30,
  },
  tableBackground: (avatarBarExpanded: boolean, taskBarExpanded: boolean, isSmallScreen: boolean) => ({
    position: 'sticky',
    left: 0,
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    width: isSmallScreen ? '100%' : getFullDynamicWidth(avatarBarExpanded, taskBarExpanded),
  }),
  monthScroller: (
    taskBarExpanded: boolean,
    avatarBarExpanded: boolean,
    isSmallScreen: boolean,
    isRedesign: boolean,
  ) => ({
    display: 'flex',
    position: 'relative',
    width: `calc(100% -  ${
      taskBarExpanded ? (isRedesign ? TASK_MENU_OPEN_WIDTH_REDESIGN : TASK_MENU_OPEN_WIDTH) : TASK_MENU_CLOSED_WIDTH
    }px - ${SEPARATOR_WIDTH}px ${isRedesign ? '+ 7px' : ''})`,
    height: 'calc(100% - 49px)',
    overflowX: 'scroll',
    overscrollBehavior: 'none',
    ...(!avatarBarExpanded || !isSmallScreen ? { transform: 'translate3d(0, 0, 0)' } : {}),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
  selectedBgRow: {
    // @ts-ignore
    background: theme.palette.primary[50],
  },
  selectedBgCell: {
    // @ts-ignore
    background: theme.palette.secondary[25],
  },
  selectedBgColumn: {
    // @ts-ignore
    background: theme.palette.primary[25],
  },
  selectedBgTask: {
    // @ts-ignore
    background: theme.palette.primary[100],
  },
  selectedBgToday: {
    // @ts-ignore
    background: theme.palette.secondary[10],
  },
  selectedBgFirstDayOfMonth: {
    // @ts-ignore
    background: theme.palette.grey[25],
  },
  monthContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
  },
  openDrawerMargin: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: `${AVATAR_MENU_OPEN_WIDTH}px`,
      // keep transition syncronized with avatar side bar transition
      transition: theme.transitions.create(['margin-left', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
  closedDrawerMargin: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: `${AVATAR_MENU_CLOSED_WIDTH}px`,
      // keep transition syncronized with avatar side bar transition
      transition: theme.transitions.create(['margin-left', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shortest,
      }),
    },
  },
  stickyLeft: {
    position: 'sticky',
    left: 0,
    paddingLeft: 0,
  },
  gridRow: {
    position: 'relative',
    width: 'fit-content',
    display: 'flex',
    zIndex: 11,
  },
  aloneRow: {
    height: CELL_HEIGHT,
    flex: 0,
    borderBottom: '1px solid',
    // @ts-ignore
    borderColor: theme.palette.primary[50],
  },
  stickyContainer: {
    position: 'sticky',
    width: 'fit-content',
    top: '72.5px',
    zIndex: 20,
    '&::before': {
      position: 'absolute',
      top: 30,
      bottom: 0,
      left: 0,
      right: 0,
      content: '""',
      zIndex: -1,
      boxShadow: theme.shadows[10],
    },
  },
  floatingLoading: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
  },
  monthlyBarContainer: {
    display: 'flex',
    position: 'sticky',
    top: '-0.5px',
    zIndex: 12,
    [theme.breakpoints.down('sm')]: {
      zIndex: 30,
    },
  },
  avatarBarSeparator: {
    width: '15px',
    // @ts-ignore
    backgroundColor: theme.palette.grey[25],
    borderLeft: '1px solid',
    borderRight: '1px solid',
    //@ts-ignore
    borderColor: theme.palette.grey[200],
  },
  toggleBarButtonContainer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    zIndex: 50,
    borderRight: '1px solid',
    borderColor: theme.palette.grey[200],
  },
  gridBodyContainer: {
    marginLeft: '-501px',
    width: '100%',
    height: 'fit-content',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  smallScreenGridBodyContainer: (avatarBarExpanded: boolean) => ({
    marginLeft: avatarBarExpanded ? '-482px' : '-301px',
    transition: theme.transitions.create(['margin-left'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
  backgroundTeamName: () => ({
    height: `${TEAM_NAME_ROW_HEIGHT}px`,
    width: 'fit-content',
    boxSizing: 'border-box',
    display: 'flex',
    backgroundSize: `${CELL_WIDTH}px`,
    borderTop: '1px solid',
    borderBottom: '1px solid',
    // @ts-ignore
    borderColor: theme.palette.primary[50],
    zIndex: 9,
  }),
  smallScreenGradient: (isPreview?: boolean) => ({
    minHeight: '72px',
    width: '68px',
    zIndex: 35,
    position: 'absolute',
    top: isPreview ? 0 : 41,
    left: 0,
  }),
}
