import { Typography, css, styled } from '@mui/material'
import { convertPxToMuiSpacing } from 'redesign/themes/main/utils'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: ${({ theme }) => theme.spacing(6)};
  max-width: ${({ theme }) => `${theme.spacing(convertPxToMuiSpacing(theme.breakpoints.values.sm))}`};
  width: 100%;
  margin-bottom: ${({ theme }) => `${theme.spacing(0.25)}`};
`

export const DaysContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: ${({ theme }) => `${theme.spacing(1, 2)}`};
  gap: ${({ theme }) => `${theme.spacing(1)}`};
`

export const Text = styled(Typography)`
  text-align: center;
`

export const HeaderContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSmallScreen',
})<{ isSmallScreen?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => `${theme.spacing(2)}`};
  ${({ isSmallScreen, theme }) =>
    isSmallScreen &&
    css`
      padding: ${theme.spacing(1, 2)};
    `}
`
