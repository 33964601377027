import React, { memo } from 'react'
import { Box, Typography } from '@mui/material'
import { classes } from 'components/ShiftsList/components/ShiftCard/Placeholders/styles'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFeatureFlag from 'hooks/useFeatureFlag'
import RedesignThemeWrapper from 'redesign/components/atoms/RedesignThemeWrapper'
import { TeamRow as RedesignTeamRow } from 'redesign/components/atoms/TeamRow'
import { TeamRowTitle } from 'redesign/components/atoms/TeamRowTitle'
import { TeamT } from 'types'
import { getFullTeamLabel } from 'utils/team'
import { teamNameRowClasses } from './styles'

export interface ITeamNameRownProps {
  team?: TeamT
  teamsLoading: boolean
  isMultiTenant?: boolean
  isSmallScreen?: boolean
}

const TeamNameRow: React.FC<ITeamNameRownProps> = ({ team, teamsLoading, isMultiTenant }) => {
  const { language } = useFavurTranslation()
  const redesignFF = useFeatureFlag(FF_FAVUR_REDESIGN)

  if (redesignFF) {
    return (
      <RedesignThemeWrapper>
        <RedesignTeamRow>
          <TeamRowTitle teamsLoading={teamsLoading} language={language} team={team} isMultiTenant={isMultiTenant} />
        </RedesignTeamRow>
      </RedesignThemeWrapper>
    )
  }

  return (
    <Box sx={teamNameRowClasses.container}>
      <Box sx={teamNameRowClasses.contentWrapper}>
        {teamsLoading || !team ? (
          <Box sx={classes.teamTitlePlaceholder} />
        ) : (
          <Typography variant="body2" sx={teamNameRowClasses.text} data-testid={`title-${team.id}`}>
            {getFullTeamLabel(team, language, Boolean(isMultiTenant))}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default memo(TeamNameRow)
