import React from 'react'
import { FilterProvider } from 'components/Filter/context'
import useFilterConfiguration from 'pages/Documents/DocumentList/useFilterConfiguration'
import DocumentsPageContent from './DocumentsPageContent'

const DocumentsPage: React.FC = () => {
  const { configuration } = useFilterConfiguration()

  return (
    <FilterProvider configuration={configuration} name="documents">
      <DocumentsPageContent />
    </FilterProvider>
  )
}

export default DocumentsPage
