import { Accordion, AccordionSummary as MuiAccordionSummary, styled } from '@mui/material'

export const MuiAccordion = styled(Accordion)`
  ::before {
    display: none;
  }

  border-radius: 0 !important;

  & .MuiCollapse-wrapper,
  .MuiCollapse-root {
    margin: 0 !important;
  }
`

export const AccordionSummary = styled(MuiAccordionSummary)`
  & .MuiAccordionSummary-expandIconWrapper {
    margin: 0 !important;
  }

  & .MuiAccordionSummary-content {
    margin: ${({ theme }) => theme.spacing(2, 0)};
  }
`
