import React, { useMemo } from 'react'
import { Typography } from '@mui/material'
import { orderAbsences, orderAttendances } from 'components/ShiftsList/components/ShiftCard/TeamsSection/utils'
import useFavurTranslation from 'hooks/useFavurTranslation'
import AvatarGroup from '../AvatarGroup'
import ContentCard from '../ContentCard'
import { MAX_ABSENCES_ROWS, MAX_ATTENDANCES_ROWS } from './constants'
import { AbsenceContainer, Container } from './styles'
import type { TeamCardProps } from './types'

const TeamCard: React.FC<TeamCardProps> = ({
  hideAbsences,
  hideAttendances,
  attendances,
  absences,
  title,
  onCardClick,
}) => {
  const { t } = useFavurTranslation()
  const onlyVacations = useMemo(() => hideAttendances && !hideAbsences, [hideAbsences, hideAttendances])
  const teamTitle = useMemo(
    () =>
      onlyVacations
        ? t('component.shiftCard.teams.title.vacations', { teamName: title })
        : t('component.shiftCard.teams.title.attendances', { teamName: title }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onlyVacations, t],
  )

  if (hideAttendances && hideAbsences) {
    return null
  }

  return (
    <ContentCard
      onClick={onCardClick}
      header={{
        title: teamTitle,
        showChevron: true,
        overflowSingleLine: true,
      }}
      dataTestId="team-card"
      hasTopPaddingBody
    >
      <Container>
        {!hideAttendances && (
          <AvatarGroup showShifts maxRows={MAX_ATTENDANCES_ROWS} avatars={orderAttendances(attendances)} />
        )}
        {!hideAbsences && (
          <AbsenceContainer data-testid="team-card-absences">
            {!onlyVacations && (
              <Typography variant="body1">{t('component.shiftCard.teams.vacationsSection.title')}</Typography>
            )}
            <AvatarGroup maxRows={MAX_ABSENCES_ROWS} avatars={orderAbsences(absences)} />
          </AbsenceContainer>
        )}
      </Container>
    </ContentCard>
  )
}

export default TeamCard
