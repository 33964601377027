import React, { useMemo } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ShiftUpdatedValues } from 'pages/Tasks/types'
import Headline from 'redesign/components/atoms/Headline'
import { HEADLINE_LEVELS } from 'redesign/components/atoms/Headline/constants'
import { formatDate } from 'redesign/shared/date'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { newDateWithoutTime } from 'utils/date'
import ShiftUpdateGroup from './ShiftUpdateGroup'
import { DailyContainer, Wrapper } from './styles'
import type { ShiftUpdateDetailDayProps } from './types'

const ShiftUpdateDetailDay: React.FC<ShiftUpdateDetailDayProps> = ({ day, shiftsUpdated, shiftsUpdatedHistory }) => {
  const { t, locale } = useFavurTranslation()
  const date = newDateWithoutTime(day)

  const formatedDate = formatDate({
    date,
    dateFormat: DATE_FORMAT_VARIANTS.longWithWeekday,
    locale,
    t,
  })

  const deleted = useMemo(() => {
    const noNewShifts = !shiftsUpdated || shiftsUpdated.length === 0
    const haveDeletedShifts =
      shiftsUpdatedHistory &&
      shiftsUpdatedHistory.filter((shift) => shift.updated === ShiftUpdatedValues.deleted).length > 0

    const changeType = noNewShifts && haveDeletedShifts ? ShiftUpdatedValues.deleted : ShiftUpdatedValues.updated

    return changeType === ShiftUpdatedValues.deleted
  }, [shiftsUpdated, shiftsUpdatedHistory])

  return (
    <Wrapper>
      <Headline testId={`${day}-headline`} level={HEADLINE_LEVELS.first} text={formatedDate} />
      <DailyContainer>
        <ShiftUpdateGroup shifts={shiftsUpdated} deleted={deleted} />
        <ShiftUpdateGroup isOld shifts={shiftsUpdatedHistory} />
      </DailyContainer>
    </Wrapper>
  )
}

export default ShiftUpdateDetailDay
