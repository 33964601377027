export const successDrawerClasses = {
  bodyContainer: {
    padding: '16px 12px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  },
  paper: {
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    maxWidth: '600px',
    margin: '0 auto',
  },
}
