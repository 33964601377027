import React from 'react'
import { Breadcrumbs, Link } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { CmsFolderT } from 'types'

interface IFoldersBreadcrumbProps {
  folder?: CmsFolderT
  ownerId?: number
}

const FoldersBreadcrumb: React.FC<IFoldersBreadcrumbProps> = ({ folder }) => {
  const { t } = useFavurTranslation()
  const path = folder?.path

  if (!path || path.length < 2) return <></>

  return (
    <>
      <Breadcrumbs maxItems={3} aria-label="breadcrumb">
        {path.map((currentPath, index) => {
          return (
            <Link underline="none" color="inherit" key={currentPath.uuid}>
              {index === 0 ? t('navigation.main.menu.content') : currentPath.name}
            </Link>
          )
        })}
      </Breadcrumbs>
    </>
  )
}

export default FoldersBreadcrumb
