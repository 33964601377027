import React from 'react'
import { Container } from './styles'
import { Stack } from './styles'
import { ShiftStackProps } from './types'

const ShiftStack: React.FC<ShiftStackProps> = ({ shifts = [], testId }) => (
  <Container data-testid={testId}>
    {shifts.map(({ hexBackgroundColor, id }) => (
      <Stack
        data-testid={`shift-stack-${id}-${hexBackgroundColor}`}
        backgroundColor={hexBackgroundColor}
        key={`shift-stack-${id}`}
      />
    ))}
  </Container>
)

export default ShiftStack
