import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import DialogService from 'components/Dialog/DialogService'
import OldNativeVersionDialog from 'components/OldNativeVersionDialog'
import Page from 'components/Page'
import UpdateApp from 'components/Splash/UpdateApp'
import useModule from 'hooks/useModule'
import usePSN from 'hooks/usePushNotifications'
import useRolesViews from 'hooks/useRolesViews'
import useSessionContext from 'hooks/useSessionContext'
import { modules } from 'shared/constants'
import { isNativeNoOverride } from 'utils/platform'
import { PushNotifications } from '@capacitor/push-notifications'
import DashboardHeader from './DashboardHeader'
import EmployeeCardsSection from './EmployeeCardsSection'
import NextShiftSection from './NextShiftSection'
import DashboardTasks from './Tasks'
import { classes } from './styles'

const DashboardPage: React.FC = () => {
  const { hasAccessToModule } = useModule()
  const { pushActiveOnDevice } = usePSN({ refresh: false })
  const { refresh } = useSessionContext()
  const { hasActiveAndResignedPersons } = useRolesViews()

  useEffect(() => {
    if (isNativeNoOverride() && pushActiveOnDevice) {
      PushNotifications.removeAllDeliveredNotifications()
    }
  }, [pushActiveOnDevice])

  useEffect(() => {
    refresh()
  }, [refresh])

  const renderActiveConnectionsContent = () => (
    <>
      {hasAccessToModule(modules.tasks) && (
        <Box sx={classes.section}>
          <DashboardTasks />
        </Box>
      )}
      {hasAccessToModule(modules.shiftPlan) && <NextShiftSection />}
      <EmployeeCardsSection />
    </>
  )

  return (
    <UpdateApp>
      <Page header={<DashboardHeader />}>
        <DialogService subscribed={['securityTimeout', 'securitySetup', 'pinReset', 'pinChange']} />
        {hasActiveAndResignedPersons && renderActiveConnectionsContent()}
      </Page>
      {isNativeNoOverride() && <OldNativeVersionDialog showOnlyOnce />}
    </UpdateApp>
  )
}

export default DashboardPage
