import { useEffect } from 'react'
import { FilterStateT } from 'components/Filter/types'
import useRolesViews from 'hooks/useRolesViews'
import useTasksQueryPaginated from 'redesign/shared/hooks/useTasksQueryPaginated'
import { TASK_LIMIT } from '../../components/templates/ContentLists/TaskList/constants'

export const useTaskList = () => {
  const { isOffice } = useRolesViews()
  const {
    updateFilters,
    updateOffice,
    hasMoreElements,
    list: tasks,
    loading: tasksLoading,
    loadMore,
    reload,
    totalCount,
  } = useTasksQueryPaginated({ initialOffice: isOffice, limit: TASK_LIMIT, initialFilters: {} })
  const handleFilterChange = (filters: FilterStateT) => updateFilters(filters)

  useEffect(() => {
    updateOffice(isOffice)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOffice])

  return {
    isOffice,
    updateFilters,
    updateOffice,
    hasMoreElements,
    handleFilterChange,
    list: tasks ?? [],
    loading: tasksLoading,
    loadMore,
    reload,
    showLoadMoreButton: (totalCount === undefined || hasMoreElements) && Boolean(tasks.length),
  }
}
