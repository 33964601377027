import { omit } from 'lodash/fp'
import { ApolloError, QueryHookOptions } from '@apollo/client'

export type JamesApolloArgsT = {
  query: string
  queryEncapsulation?: boolean
  queryName?: string
  queryOptions?: QueryHookOptions
}

export const removeTypenameFromObject = (object: Record<string, unknown>) => omit(['__typename'], object)

const REGEX = /.*code: "(.*?)"/

export const getFirstErrorCode = (e: ApolloError): string => {
  if (e.graphQLErrors.length < 1) {
    return ''
  }
  const error = e.graphQLErrors[0].message
  const parsed = REGEX.exec(error) ?? []
  return parsed[1] ?? ''
}

// Checks if apollo error is a server error (4XX or 5XX)
export const isServerError = (error: ApolloError): boolean => {
  if (error.networkError === null || !('statusCode' in error.networkError)) {
    return false
  }

  const statusCode = error.networkError.statusCode
  if (typeof statusCode !== 'number' || !Number.isInteger(statusCode)) {
    return false
  }
  return statusCode >= 400 && statusCode < 600
}

// Checks if apollo error is a network error (CORS ERROR, User with no network, etc)
export const isNetworkError = (error: ApolloError): boolean => {
  const errMessage = error.message
  if (typeof errMessage !== 'string') {
    return false
  }
  return (
    errMessage.includes('Failed to fetch') || errMessage.includes('NetworkError') || errMessage.includes('Load failed')
  )
}
