import React from 'react'
import Svg from 'components/Svg'
import { ICON_SIZE_M } from './constants'
import { IconPropsT } from './types'

const FolderClosedM: React.FC<IconPropsT> = ({ fill = 'none' }) => {
  return (
    <Svg size={ICON_SIZE_M} strokeWidth={1.5} stroke="currentColor" fill={fill}>
      <path d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
    </Svg>
  )
}

export default FolderClosedM
