import React, { useCallback, useMemo } from 'react'
import { ROLE_VIEWS } from 'constants/roles'
import he from 'he'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import routes from 'services/RoutesProvider/routes'
import { absencePeriods, absencePeriodsTranslationMap } from 'shared/constants'
import { NotificationT, ResolvedAbsenceRequestNotificationT } from 'types'
import { absenceRequestDateFormat } from 'utils/constants'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { getShortNameFromName } from 'utils/person'
import NotificationTemplate from '../Templates/Notification'
import { ResolvedAbsenceRequestStates } from '../constants'

interface IResolvedAbsenceRequestProps {
  notification: ResolvedAbsenceRequestNotificationT
  dismiss: (notification: NotificationT) => void
}

const ResolvedAbsenceRequest: React.FC<IResolvedAbsenceRequestProps> = (props) => {
  const { t, locale } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()
  const { notification, dismiss } = props
  const title = useMemo(() => {
    const titleKey =
      notification.data.state === ResolvedAbsenceRequestStates.approved
        ? 'notification.new.absence.accepted.title'
        : 'notification.new.absence.declined.title'

    return he.decode(
      t(titleKey, {
        managerNameShort: notification.data.managerNameShort ?? getShortNameFromName(notification.data.managerName),
      }),
    )
  }, [notification.data, t])

  const dateFrom = getFormattedFromDate(
    newDateWithoutTime(notification.data.dateFrom),
    absenceRequestDateFormat,
    locale,
  )
  const dateTo = getFormattedFromDate(newDateWithoutTime(notification.data.dateTo), absenceRequestDateFormat, locale)
  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(`${routes.absenceRequestDetail}/${notification.data.taskUuid}`, ROLE_VIEWS.frontliner)
  }, [dismiss, notification, pushWithRole])

  const multiday = notification.data.dateFrom !== notification.data.dateTo
  const { period } = notification.data

  const absencePeriod = useMemo(() => {
    if (multiday || !period || period.trim() === '') {
      return ''
    }
    const translatedPeriod = absencePeriodsTranslationMap[period]
    return t(absencePeriods[translatedPeriod])
  }, [multiday, period, t])

  const templateProps = {
    ...props,
    title,
    body: multiday
      ? t('notification.new.absence.dates_multiday.body', {
          dateFrom,
          dateTo,
        })
      : t('notification.new.absence_singleday.body', {
          dateFrom,
          absencePeriod,
        }),
    dismissText: t('component.notification.button.dismiss'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
  }

  return <NotificationTemplate {...templateProps} />
}

export default ResolvedAbsenceRequest
