import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { BoxedLoading } from 'components/LoadingIcon'
import { useAnnouncementsContext } from 'services/AnnouncementsService/context'
import { UserAnnouncement } from 'shared/graphql/graphql'
import { UserAnnouncementT } from 'types'
import AnnouncementCard from './AnnouncementCard'
import { classes } from './styles'
import { useAnnouncements } from './useAnnouncements'

const AnnouncementList: React.FC = () => {
  const { loading, announcements, refetch } = useAnnouncements()
  const { openAnnouncement, markAnnouncementSeen } = useAnnouncementsContext()

  const handleClick = useCallback(
    (announcement: UserAnnouncement) => {
      openAnnouncement(announcement as UserAnnouncementT)
      if (!announcement.seen) {
        markAnnouncementSeen(announcement as UserAnnouncementT)
        refetch()
      }
    },
    [markAnnouncementSeen, openAnnouncement, refetch],
  )

  if (loading) {
    return <BoxedLoading />
  }

  return (
    <Box sx={classes.cardList}>
      {announcements?.map((announcement) => (
        <AnnouncementCard
          key={`announcement_${announcement.id}`}
          announcement={announcement}
          onClick={() => handleClick(announcement)}
        />
      ))}
    </Box>
  )
}

export default AnnouncementList
