import React, { useMemo } from 'react'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useSessionContext from 'hooks/useSessionContext'
import useStateBackLink from 'hooks/useStateBackLink'
import { headerClasses } from 'pages/UserSettings/styles'
import routes from 'services/RoutesProvider/routes'
import PersonalDataForm from './PersonalDataForm'

const PersonalDataPage: React.FC = () => {
  const { t } = useFavurTranslation()
  const { pushOriginLinkOrDefault } = useStateBackLink()

  const { user } = useSessionContext()

  const dataObject = useMemo(() => {
    if (!user) return undefined
    const { phoneNumber: phone, firstName, lastName, birthDate } = user
    return { phone, firstName, lastName, birthDate }
  }, [user])

  return (
    <Page
      header={
        <BackwardLink
          onClickIcon={() => {
            pushOriginLinkOrDefault(routes.userSettings)
          }}
          title={t('page.userSettings.personalData.header')}
        />
      }
      sxHeader={headerClasses.subHeader}
      noPadding
      hideNativeNavigation
    >
      {!dataObject ? <SimpleLoading /> : <PersonalDataForm {...dataObject} />}
    </Page>
  )
}

export default PersonalDataPage
