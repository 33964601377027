import React from 'react'
import EffectiveShiftCard from 'redesign/components/molecules/EffectiveShiftCard/EffectiveShiftCard'
import EffectiveShiftErrorCard from 'redesign/components/molecules/EffectiveShiftErrorCard'
import { Container } from './styles'
import type { EffectiveShiftSectionProps } from './types'

const EffectiveShiftSection: React.FC<EffectiveShiftSectionProps> = ({ effectiveShifts, effectiveShiftsError }) => {
  if (effectiveShiftsError) return <EffectiveShiftErrorCard />

  return (
    <Container>
      <EffectiveShiftCard effectiveShifts={effectiveShifts} />
    </Container>
  )
}

export default EffectiveShiftSection
