import { authWithPersonsFields } from 'shared/queries'
import { gql } from '@apollo/client'

export const logoutMutationParams = gql`
  mutation logout {
    logout {
      auth
    }
  }
`

export const logoutDeviceMutationParams = (token: string) => gql`
  mutation logoutWithToken {
    logout(token: "${token}") {
      auth
    }
  }
`

export const RememberMeParams = `
  authRefreshToken{
    ${authWithPersonsFields}
  }
`

export const sessionQuery = gql`
query getMe($buildVersionNumber: String!) {
  me{
    ${authWithPersonsFields}
  },
  versionNumber(buildVersionNumber: $buildVersionNumber)
}`
