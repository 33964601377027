import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TabList, Tab } from './styles'
import { TabBarProps } from './types'

const TabBar: React.FC<TabBarProps> = ({ orientation, color, iconPosition, pages, onClick, variant }) => {
  const { t } = useFavurTranslation()

  return (
    <TabList
      orientation={orientation}
      textColor={color}
      indicatorColor={color}
      onChange={(_, selectedTab) => onClick(selectedTab)}
      aria-label="Tabs"
      variant={variant}
    >
      {pages?.map(({ name, label, isDisabled, icon }) => (
        <Tab
          icon={icon}
          iconPosition={iconPosition}
          disabled={isDisabled}
          disableRipple
          label={label && t(label)}
          value={name}
          key={name}
        />
      ))}
    </TabList>
  )
}
export default TabBar
