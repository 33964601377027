import { ROLE_VIEWS } from 'constants/roles'
import he from 'he'
import routes from 'services/RoutesProvider/routes'
import {
  AbsenceRequestCommentForFrontlinerNotification,
  AbsenceRequestCommentForManagerNotification,
} from 'shared/graphql/graphql'
import { NotificationWithManagerProps } from '../types'
import { getBadgeGlossaryKey } from '../utils'
import { notificationGlosssaryKeys } from './constants'

export const getAbsenceRequestComment = ({ notification, t, isManager = false }: NotificationWithManagerProps) => {
  const data = notification.data as
    | AbsenceRequestCommentForFrontlinerNotification
    | AbsenceRequestCommentForManagerNotification
  const { commentShort, badge, taskUuid } = data
  const { title, body } = isManager
    ? notificationGlosssaryKeys.absenceRequestCommentForManager
    : notificationGlosssaryKeys.absenceRequestCommentForFrontliner

  return {
    title: he.decode(t(title, { ...data })),
    body: `${t(body)} ${commentShort ?? t(getBadgeGlossaryKey(badge as string))}`,
    route: `${routes.absenceRequestDetail}/${taskUuid}`,
    role: isManager ? ROLE_VIEWS.office : ROLE_VIEWS.frontliner,
  }
}
