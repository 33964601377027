import React, { memo, useMemo } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ChevronRightMD } from 'redesign/components/atoms/Icon'
import Avatar from 'redesign/components/molecules/Avatar'
import { AVATAR_SIZE } from 'redesign/components/molecules/Avatar/constants'
import { getFullAvatarUrl } from 'utils/avatar'
import { getAvatarNameFromPerson, getUserName } from 'utils/person'
import AvatarTooltip from '../Tooltips/AvatarTooltip'
import { CollapsableContent, MainContainer, TextContainer } from './styles'
import type { AbsencePlannerAvatarCellProps } from './types'

const AbsencePlannerAvatarCell: React.FC<AbsencePlannerAvatarCellProps> = ({
  onClick,
  isSelected,
  onTooltipActionClick,
  tooltipOpen,
  onTooltipClose,
  person,
  taskCount = 0,
  isExpanded,
}) => {
  const theme = useTheme()
  const name = useMemo(() => getUserName(person, true), [person])
  const avatarName = useMemo(() => getAvatarNameFromPerson(person), [person])
  const avatarImage = useMemo(() => (person.user?.avatarUrl ? getFullAvatarUrl(person.user.avatarUrl) : undefined), [
    person,
  ])
  const { t } = useFavurTranslation()

  return (
    <MainContainer isExpanded={isExpanded} id={`${person.id}_sidebar-avatar`} onClick={onClick} isSelected={isSelected}>
      <AvatarTooltip
        text={name}
        actionButton={
          taskCount > 0 ? (
            <Button variant="contained" onClick={onTooltipActionClick}>
              {t('absencePlanner.avatars.showTasks')}
            </Button>
          ) : undefined
        }
        open={tooltipOpen}
        onClose={onTooltipClose}
      >
        <div>
          <Avatar
            badgeVariant="standard"
            text={avatarName}
            badgeContent={taskCount}
            src={avatarImage}
            size={AVATAR_SIZE.extraLarge}
          />
        </div>
      </AvatarTooltip>

      <CollapsableContent isExpanded={isExpanded}>
        <TextContainer>
          <Typography variant="body2" noWrap>
            {name}
          </Typography>
        </TextContainer>
        {Boolean(taskCount) && <ChevronRightMD color={theme.palette.primary.main} />}
      </CollapsableContent>
    </MainContainer>
  )
}

export default memo(AbsencePlannerAvatarCell)
