import React from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import ShiftUpdateDetailHeader from 'redesign/components/organisms/Headers/ShfitUpdateDetailHeader'
import Page from 'redesign/components/templates/Page'
import ShiftUpdateDetailsTemplate from 'redesign/components/templates/ShiftUpdateDetails/ShiftUpdateDetails'
import type { TUseShiftUpdateDetailPage } from './types'
import { useShiftUpdateDetailPage } from './useShiftUpdateDetailPage'

const ShiftUpdateDetails: React.FC<RouteComponentProps<TUseShiftUpdateDetailPage>> = ({ match }) => {
  const {
    params: { favurUuid, status },
  } = match
  const { updatedDays, shiftsUpdatedByDate, shiftsUpdatedHistoryByDate, onGoBack } = useShiftUpdateDetailPage({
    favurUuid,
    status,
  })

  return (
    <Page header={<ShiftUpdateDetailHeader onGoBack={onGoBack} />} paddingX={0} removeLastMargin>
      <ShiftUpdateDetailsTemplate
        updatedDays={updatedDays}
        shiftsUpdatedByDate={shiftsUpdatedByDate}
        shiftsUpdatedHistoryByDate={shiftsUpdatedHistoryByDate}
      />
    </Page>
  )
}
export default ShiftUpdateDetails
