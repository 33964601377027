import { styled } from '@mui/material'
import { convertPxToMuiSpacing } from 'redesign/themes/main/utils'

export const Container = styled(`div`)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  min-height: ${({ theme }) => theme.spacing(6)};
  max-width: ${({ theme }) => `${theme.spacing(convertPxToMuiSpacing(theme.breakpoints.values.sm))}`};
  width: 100%;
  margin-bottom: ${({ theme }) => `${theme.spacing(0.25)}`};
`

export const FlexContainer = styled(`div`)`
  display: flex;
  justify-content: center;
  overflow: hidden;
`
