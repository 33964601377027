import React from 'react'
import DrawerPuller from 'redesign/components/atoms/DrawerPuller'
import { ContentContainer, FooterContainer, SwipeableDrawer } from './styles'
import type { DrawerProps } from './types'

const Drawer: React.FC<DrawerProps> = ({
  children,
  disableBackdrop = false,
  fullHeight = false,
  header,
  onClose,
  open,
  showPuller,
  testId,
  heightSpacing,
  footer,
}) => {
  const handleClose = (event: React.SyntheticEvent) => {
    // Prevents the drawer closes when backdrop is clicked if disableBackdrop
    if (event && disableBackdrop && (event.target as HTMLElement).classList.contains('MuiBackdrop-root')) {
      return
    }
    onClose()
  }

  return (
    <SwipeableDrawer
      disableDiscovery
      disableAutoFocus
      onOpen={() => {}}
      onClose={handleClose}
      open={open}
      anchor="bottom"
      fullHeight={fullHeight}
      swipeAreaWidth={0}
      data-testid={testId}
      heightSpacing={heightSpacing}
    >
      {showPuller && <DrawerPuller />}
      {header}
      <ContentContainer>{children}</ContentContainer>
      {footer && <FooterContainer>{footer}</FooterContainer>}
    </SwipeableDrawer>
  )
}

export default Drawer
