import { Avatar, Badge, BadgePropsVariantOverrides, css, styled, Theme } from '@mui/material'
import type { OverridableStringUnion } from '@mui/types'
import { AVATAR_GAP, AVATAR_SIZE } from './constants'

const getBadgePosition = ({
  size,
  theme,
  variant,
}: {
  size: number
  theme: Theme
  variant: OverridableStringUnion<'standard' | 'dot', BadgePropsVariantOverrides> | undefined
}) => {
  switch (size) {
    case AVATAR_SIZE.extraLarge:
      return variant === 'dot'
        ? css`
            bottom: ${theme.spacing(0.75)};
            right: ${theme.spacing(0.75)};
          `
        : css`
            bottom: ${theme.spacing(1.5)};
            right: ${theme.spacing(1.5)};
          `
    case AVATAR_SIZE.large:
      return variant === 'dot'
        ? css`
            bottom: ${theme.spacing(0.75)};
            right: ${theme.spacing(0.75)};
          `
        : css`
            bottom: ${theme.spacing(1.5)};
            right: ${theme.spacing(1.5)};
          `
    case AVATAR_SIZE.medium:
      return css`
        bottom: ${theme.spacing(0.5)};
        right: ${theme.spacing(0.5)};
      `

    default:
      return css`
        bottom: ${theme.spacing(0.5)};
        right: ${theme.spacing(0.5)};
      `
  }
}

export const MuiAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'removeBackground',
})<{ size: number; removeBackground: boolean }>`
  width: ${({ theme, size }) => theme.spacing(size)};
  height: ${({ theme, size }) => theme.spacing(size)};
  background-color: ${({ removeBackground, theme }) =>
    removeBackground ? theme.palette.common.transparent : theme.palette.common.greyMid};
`

export const MuiBadge = styled(Badge)<{ size: number }>`
  & .MuiBadge-badge {
    ${({ theme, size, variant }) => getBadgePosition({ size, theme, variant })}
    ${({ theme, variant }) =>
      variant === 'dot' &&
      css`
        box-shadow: ${theme.spacing(0, 0, 0, 0.125)} ${theme.palette.background.paper};
      `}
  }
`

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(AVATAR_GAP)};
  width: fit-content;
`
