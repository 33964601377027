import React from 'react'
import { isNative } from 'utils/platform'
import Link from '../../atoms/Link'
import { Content, MuiCard } from './styles'
import type { CardProps } from './types'

const Card: React.FC<CardProps> = ({ children, download, isNew = false, url, onClick, dataTestId }) => (
  <Link url={url} download={download}>
    <MuiCard
      data-testid={dataTestId}
      aria-label="card"
      isNew={isNew}
      onClick={onClick}
      isClickable={Boolean(onClick)}
      isNative={isNative()}
    >
      <Content>{children}</Content>
    </MuiCard>
  </Link>
)

export default Card
