import { useCallback } from 'react'
import useFilterContext from 'components/Filter/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getISOFirstDayOfMonth, getISOLastDayOfMonth, newDateWithoutTime } from 'utils/date'
import type { DateRangeFilterProps } from './types'

export const useDateRangeFilter = ({ configuration }: Pick<DateRangeFilterProps, 'configuration'>) => {
  const { changeValue, getValue, validateFilters } = useFilterContext()
  const { t } = useFavurTranslation()
  const {
    maxDate: maxDateString,
    minDate: minDateString,
    fromDate: { name: fromDateName },
    toDate: { name: toDateName },
  } = configuration
  const fromDateValueString = getValue(fromDateName)
  const toDateValueString = getValue(toDateName)
  const fromDateValue = fromDateValueString ? newDateWithoutTime(fromDateValueString as string) : null
  const toDateValue = toDateValueString ? newDateWithoutTime(toDateValueString as string) : null
  const maxDate = newDateWithoutTime(maxDateString)
  const minDate = newDateWithoutTime(minDateString)
  const error = !validateFilters() ? t('filter.task_time_frame.error_label') : undefined
  const startDateOnChange = useCallback(
    (updatedValue: Date) => {
      changeValue(fromDateName, getISOFirstDayOfMonth(updatedValue))
    },
    [changeValue, fromDateName],
  )
  const endDateOnChange = useCallback(
    (updatedValue: Date) => {
      changeValue(toDateName, getISOLastDayOfMonth(updatedValue))
    },
    [changeValue, toDateName],
  )

  return {
    endDateOnChange,
    error,
    fromDateName,
    fromDateValue,
    minDate,
    maxDate,
    startDateOnChange,
    toDateName,
    toDateValue,
  }
}
