import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

export const initializeDataDogDatadogUser = (userId: number | undefined) => {
  if (!userId) return
  const user = {
    id: `${userId}`,
  }

  datadogRum.setUser(user)
  datadogLogs.setUser(user)
}
