import React from 'react'
import TeamDetailsContent from 'redesign/components/organisms/TeamDetailsContent'
import { useShiftPlanPage } from 'redesign/pages/ShiftPlan/useShiftPlanPage'
import ShiftPlanContent from './ShiftPlanContent'
import type { ShiftPlanDataProps } from './types'
import useSelectedTeamPlanContent from './useSelectedTeamPlanContent'

const ShiftPlanData: React.FC<ShiftPlanDataProps> = ({ sliderParams }) => {
  const {
    sortedTenantIds,
    showTenantHeadline,
    tenantList,
    groupedTenants,
    getAbsenceRequestByTenantId,
    getEventsForTenantShiftCard,
    onTaskClick,
    currentDay,
    effectiveShifts,
    effectiveShiftsLoading,
    effectiveShiftsError,
    shiftUpdates,
    shiftsSectionLoading,
    teamPlanData,
    teamPlanLoading,
    eventsLoading,
    showNoShiftTenant,
    filteredPersonsWithTenant,
  } = useShiftPlanPage({
    index: sliderParams.index,
  })
  const { isTeamModalOpen, onTeamClick, selectedTeamPlanData, selectedTeamData } = useSelectedTeamPlanContent({
    teamPlanData,
    teamPlanLoading,
    currentDay,
  })

  return isTeamModalOpen ? (
    <TeamDetailsContent
      selectedTeamPlanData={selectedTeamPlanData}
      showCostCenter={selectedTeamData?.showCostCenter}
      isLoading={teamPlanLoading}
      date={currentDay}
    />
  ) : (
    <ShiftPlanContent
      sliderParams={sliderParams}
      sortedTenantIds={sortedTenantIds}
      showTenantHeadline={showTenantHeadline}
      tenantList={tenantList}
      groupedTenants={groupedTenants}
      getAbsenceRequestsByTenantId={getAbsenceRequestByTenantId}
      getEventsForTenantShiftCard={getEventsForTenantShiftCard}
      date={currentDay}
      onTaskClick={onTaskClick}
      effectiveShifts={effectiveShifts}
      effectiveShiftsError={effectiveShiftsError}
      effectiveShiftsLoading={effectiveShiftsLoading}
      shiftUpdates={shiftUpdates}
      shiftsSectionLoading={shiftsSectionLoading}
      teamPlanData={teamPlanData}
      teamPlanLoading={teamPlanLoading}
      onTeamClick={onTeamClick}
      eventsLoading={eventsLoading}
      showNoShiftTenant={showNoShiftTenant}
      filteredPersonsWithTenant={filteredPersonsWithTenant}
    />
  )
}

export default ShiftPlanData
