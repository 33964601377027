import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { HEADLINE_LEVELS } from './constants'
import { Container } from './styles'
import { HeadlineProps } from './types'
import { getSpacingByLevel, getVariantByLevel } from './utils'

const Headline: React.FC<HeadlineProps> = ({
  level = HEADLINE_LEVELS.second,
  testId,
  noBottomSpacing = false,
  text,
  horizontalSpacing = 2,
  endContent,
}) => {
  const theme = useTheme()

  return (
    <Container bottomSpacing={getSpacingByLevel(level, noBottomSpacing)} horizontalSpacing={horizontalSpacing}>
      <Typography variant={getVariantByLevel(level)} data-testid={testId} color={theme.palette.text.primary}>
        {text}
      </Typography>
      {endContent}
    </Container>
  )
}

export default Headline
