import { useCallback, useMemo } from 'react'
import useConnections from 'pages/UserSettings/hooks/useConnections'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { dateToISOStringWithoutTime, newDateWithoutTime } from 'utils/date'

const currentIsoDate = dateToISOStringWithoutTime(newDateWithoutTime())

const useEmployer = () => {
  const { persons, invitations, loading } = useConnections()
  const history = useHistory()

  const currentPersons = useMemo(() => {
    if (loading || !persons) return []
    return persons.filter((person) => !person.workWithdrawal || person.workWithdrawal > currentIsoDate)
  }, [loading, persons])

  const historicalPersons = useMemo(() => {
    if (loading || !persons) return []
    return persons.filter((person) => person.workWithdrawal && person.workWithdrawal <= currentIsoDate)
  }, [loading, persons])

  const redirectCondition = useMemo(
    () => !loading && currentPersons.length === 1 && !historicalPersons.length && !invitations.length,
    [currentPersons.length, historicalPersons.length, invitations.length, loading],
  )
  const handleEmployerRedirect = useCallback(() => {
    if (redirectCondition) {
      history.push(`${routes.employer}/${currentPersons[0].favurUuid}`, { origin: `${routes.userSettings}` })
    }
  }, [redirectCondition, history, currentPersons])

  return { currentPersons, handleEmployerRedirect, historicalPersons, redirectCondition }
}

export default useEmployer
