import { useRef, useState, useCallback, useEffect } from 'react'
import type { TeamMemberT } from 'components/ShiftsList/components/ShiftCard/TeamsSection/types'
import { AVATAR_GAP, AVATAR_SIZE } from 'redesign/components/molecules/Avatar/constants'
import { convertMuiSpacingToPx, getFontSizeRem } from 'redesign/themes/main/utils'
import type { AvatarGroupProps } from './types'

export const useAvatarGroup = ({
  avatars,
  displayAllAvatars,
  maxRows = 1,
  size = AVATAR_SIZE.extraLarge,
}: AvatarGroupProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [visibleAvatars, setVisibleAvatars] = useState<TeamMemberT[]>([])
  const [extraAvatarsCount, setExtraAvatarsCount] = useState(0)

  const calculateVisibleAvatars = useCallback(() => {
    if (containerRef.current) {
      const fontSizePx = getFontSizeRem()
      const containerWidth = containerRef.current.offsetWidth
      // @TODO: Investigate if we can enhance this logic for better scalability
      const totalAvatarWidth = convertMuiSpacingToPx((size + AVATAR_GAP) * fontSizePx) // Avatar width + gap
      const maxAvatarsPerRow = Math.floor(containerWidth / totalAvatarWidth)
      const maxVisibleAvatars = maxAvatarsPerRow * maxRows

      setVisibleAvatars(displayAllAvatars ? avatars : avatars.slice(0, maxVisibleAvatars - 1))
      setExtraAvatarsCount(displayAllAvatars ? 0 : avatars.length - maxVisibleAvatars + 1)
    }
  }, [avatars, displayAllAvatars, maxRows, size])

  useEffect(() => {
    const observer = new ResizeObserver(() => calculateVisibleAvatars())
    const mutationObserver = new MutationObserver(() => calculateVisibleAvatars())

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    mutationObserver.observe(document.documentElement, { attributes: true, attributeFilter: ['style'] })

    return () => {
      observer.disconnect()
      mutationObserver.disconnect()
    }
  }, [avatars, calculateVisibleAvatars, displayAllAvatars, maxRows, size])

  return {
    containerRef,
    extraAvatarsCount,
    visibleAvatars,
  }
}
