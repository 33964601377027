import React from 'react'
import { Button, TableBody, TableCell, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import info from 'info.json'
import { getNumberOfCommits } from 'redesign/components/molecules/AppInformation/utils'
import Dialog from 'redesign/components/organisms/Dialog'
import routes from 'services/RoutesProvider/routes'
import { ENV_LIST } from './constants'
import { Row, SettingsLink, TableLayout } from './styles'
import type { AppInfoProps } from './types'

const AppInformation: React.FC<AppInfoProps> = ({ label, onClose, appInfoData }) => {
  const { t } = useFavurTranslation()

  return (
    <Dialog
      open
      onClose={onClose}
      title={label}
      actions={
        <Button onClick={onClose} color="secondary" size="large">
          {t('common.dialog.button.close')}
        </Button>
      }
    >
      <TableLayout>
        <TableBody>
          <Row>
            <TableCell>
              <Typography>Build Version</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>{`${info.version}${getNumberOfCommits(info)}`}</Typography>
            </TableCell>
          </Row>
          <Row>
            <TableCell>
              <Typography>Built</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography>{info.build_date}</Typography>
            </TableCell>
          </Row>
          {ENV_LIST.includes(appInfoData?.info?.applicationEnv as string) ? (
            <Row>
              <TableCell>
                <Typography>Features</Typography>
              </TableCell>
              <TableCell align="right">
                <SettingsLink href={routes.settings} isLive={appInfoData?.info?.applicationEnv === 'LIVE'}>
                  <Typography>settings</Typography>
                </SettingsLink>
              </TableCell>
            </Row>
          ) : null}
        </TableBody>
      </TableLayout>
    </Dialog>
  )
}

export default AppInformation
