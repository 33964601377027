import React, { useState } from 'react'
import useBannerHighSecurityConnectionEnable from 'hooks/useBannerHighSecurityConnectionEnable'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { MonthlySheetDialogDataT } from 'pages/Documents/DocumentList/types'
import ListWrapper from 'redesign/components/organisms/ListWrapper'
import DocumentCard from './DocumentCard'
import DocumentDialog from './DocumentDialog'
import { DEFAULT_DIALOG_STATE } from './constants'
import { DocumentListProps } from './types'

const DocumentsList: React.FC<DocumentListProps> = ({ isLoading, documents, loadMore, showLoadMoreButton }) => {
  const { loading: loadingBanner } = useBannerHighSecurityConnectionEnable()
  const [dialog, setDialog] = useState<MonthlySheetDialogDataT>(DEFAULT_DIALOG_STATE)
  const { t } = useFavurTranslation()

  return (
    <>
      <ListWrapper
        hasContent={Boolean(documents?.length)}
        message={t('page.documents.content.noDocuments')}
        isLoading={isLoading || loadingBanner}
        showLoadMoreButton={showLoadMoreButton}
        loadMore={loadMore}
        button-test-id="documents-load-more"
      >
        {documents?.map((document) => (
          <DocumentCard key={document.favurUuid} document={document} openMonthlySheetDialog={setDialog} />
        ))}
      </ListWrapper>
      <DocumentDialog dialog={dialog} onClose={() => setDialog(DEFAULT_DIALOG_STATE)} />
    </>
  )
}

export default DocumentsList
