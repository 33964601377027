import React from 'react'
import { SideMenuBodyWrapper } from './styles'
import { useSideMenuContent } from './useSideMenuContent'

const SideMenuContent: React.FC = () => {
  const { PageContent } = useSideMenuContent()

  return <SideMenuBodyWrapper>{PageContent}</SideMenuBodyWrapper>
}

export default SideMenuContent
