import { FormControl, styled } from '@mui/material'

export const Container = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  margin: ${({ theme }) => theme.spacing(0, -2)};
  gap: ${({ theme }) => theme.spacing(1)};
`
