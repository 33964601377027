import { useCallback, useMemo } from 'react'
import { getShowNoShiftTenant } from 'components/ShiftsList/components/DailySlideRenderer'
import {
  getCurrentTeamPlan,
  getTaskIds,
  getTenantsList,
  groupCostCentersByTenant,
  sortTenantsListToIds,
} from 'components/ShiftsList/components/utils'
import useAbsenceRequests from 'hooks/useAbsenceRequests'
import useListCmsPaginatedContents from 'hooks/useListCmsPaginatedContents'
import useSessionContext from 'hooks/useSessionContext'
import useShifts from 'hooks/useShifts'
import useStateBackLink from 'hooks/useStateBackLink'
import useTeamPlan from 'hooks/useTeamPlan'
import useShiftUpdates from 'pages/Shifts/useShiftUpdates'
import { absenceRequestStatus } from 'pages/Tasks/AbsenceRequest/constants'
import { useHistory } from 'react-router'
import { useSwipeableView } from 'redesign/services/SwipeableView/useSwipeableView'
import { getDatetimeRangeWithTimezone } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import type { ShiftsByTenantCCT } from 'types'
import { CONTENT_TYPES } from 'types/cms'
import { getEventsFromTenantAndOwner } from 'utils/cms'
import { dateToISOStringWithoutTime, newDateWithoutTime } from 'utils/date'
import { getDayFromIndex } from 'utils/shifts'
import type { TUseShiftPlanPage } from './types'

export const useShiftPlanPage = ({ index = 0 }: TUseShiftPlanPage) => {
  const baseDate = dateToISOStringWithoutTime(newDateWithoutTime())
  const { personsWithTenant } = useSwipeableView()
  const currentDay = getDayFromIndex(baseDate, index)
  const dates = {
    start: currentDay,
    end: currentDay,
  }

  const history = useHistory()
  const { setBackLink } = useStateBackLink()
  const { personIds } = useSessionContext()
  const { shifts, loading: shiftsLoading } = useShifts({ dates, displayFull: true, personIds })

  const dailyShiftUpdateTaskIds = useMemo(() => getTaskIds(shifts), [shifts])
  const { tasks: shiftUpdates, loading: shiftUpdatesLoading } = useShiftUpdates({ taskIds: dailyShiftUpdateTaskIds })
  const { shifts: effectiveShifts, loading: effectiveShiftsLoading, error: effectiveShiftsError } = useShifts({
    dates,
    displayFull: true,
    personIds,
    isEffective: true,
  })
  const { tenants: teamPlanTenants, data: teamPlanData, loading: teamPlanLoading } = useTeamPlan({
    dates,
    teamsLoading: shiftsLoading,
  })
  const groupedTenants = useMemo(
    () => (shifts && shifts[0] ? groupCostCentersByTenant(shifts[0].tenants) : new Map<string, ShiftsByTenantCCT[]>()),
    [shifts],
  )

  const showNoShiftTenant = useMemo(() => getShowNoShiftTenant(shifts, teamPlanData, effectiveShifts), [
    effectiveShifts,
    shifts,
    teamPlanData,
  ])

  const tenantList = useMemo(() => getTenantsList(shifts, teamPlanTenants, effectiveShifts), [
    shifts,
    teamPlanTenants,
    effectiveShifts,
  ])

  const filteredPersonsWithTenant = useMemo(
    () =>
      personsWithTenant?.filter(
        (person) =>
          !person.workWithdrawal || newDateWithoutTime(person.workWithdrawal) > newDateWithoutTime(currentDay),
      ),
    [currentDay, personsWithTenant],
  )

  const { tasks: absenceRequests, loading: absenceRequestsLoading } = useAbsenceRequests({
    personIds: !filteredPersonsWithTenant ? [] : filteredPersonsWithTenant.map((person) => Number(person.id) as number),
    date: currentDay,
    statuses: [
      absenceRequestStatus.reviewOfficeSeen,
      absenceRequestStatus.reviewOfficeUnseen,
      absenceRequestStatus.approvedOffice,
      absenceRequestStatus.rejectedDeletionRequest,
      absenceRequestStatus.acceptedDeletionRequest,
      absenceRequestStatus.rejectedDeletionRequest,
    ],
  })

  const shiftsSectionLoading = shiftsLoading || shiftUpdatesLoading || effectiveShiftsLoading || absenceRequestsLoading
  const getAbsenceRequestByTenantId = useCallback(
    (tenantId: string) => {
      if (!filteredPersonsWithTenant || absenceRequests.length === 0) return []
      const person = filteredPersonsWithTenant.find((p) => {
        return p.tenant?.id === tenantId
      })
      if (!person) return []
      return absenceRequests.filter((task) => task.person?.id === person.id)
    },
    [absenceRequests, filteredPersonsWithTenant],
  )

  const onTaskClick = (taskUrl: string) => {
    setBackLink(routes.shifts)
    history.push(`${taskUrl}?dateref=${currentDay}`)
  }

  const eventsDateRange = useMemo(() => getDatetimeRangeWithTimezone({ start: currentDay, end: currentDay }), [
    currentDay,
  ])

  const { list: events, loading: eventsLoading } = useListCmsPaginatedContents({
    contentType: CONTENT_TYPES.event,
    ...eventsDateRange,
  })

  const getEventsForTenantShiftCard = useCallback(
    ({ tenantShifts = [] }: { tenantShifts: ShiftsByTenantCCT[] }) =>
      tenantShifts.length > 0
        ? getEventsFromTenantAndOwner(events, {
            tenantId: tenantShifts[0].tenantId,
            ownerId: tenantShifts[0].ownerId,
          })
        : events,
    [events],
  )

  const sortedTenantIds = useMemo(() => sortTenantsListToIds(tenantList, groupedTenants), [groupedTenants, tenantList])
  const tenantsWithContentCount = useMemo(
    () =>
      sortedTenantIds.reduce((count, tenantId) => {
        if (groupedTenants.get(tenantId)) return count + 1

        if (getAbsenceRequestByTenantId(tenantId).length) return count + 1

        if (getCurrentTeamPlan(teamPlanLoading, teamPlanData, currentDay, tenantId)?.length) return count + 1

        return count
      }, 0),
    [currentDay, getAbsenceRequestByTenantId, groupedTenants, sortedTenantIds, teamPlanData, teamPlanLoading],
  )

  return {
    getAbsenceRequestByTenantId,
    getEventsForTenantShiftCard,
    groupedTenants,
    sortedTenantIds,
    tenantList,
    showTenantHeadline: tenantsWithContentCount > 1,
    onTaskClick,
    currentDay,
    effectiveShifts,
    effectiveShiftsLoading,
    effectiveShiftsError,
    shiftUpdates,
    shiftsSectionLoading,
    teamPlanData,
    teamPlanLoading,
    eventsLoading,
    showNoShiftTenant,
    filteredPersonsWithTenant,
  }
}
