import { createContext, Dispatch, SetStateAction, Context } from 'react'
import { AuthPersonIdAndPermissions, MeObject } from 'types'

export type ApiMeResponseT = Pick<MeObject, 'auth' | 'cmsActivated'> &
  Partial<Pick<MeObject, 'phone' | 'personIds' | 'personsAndPermissions' | 'user'>> & {
    name?: string
  }

export type SessionContextT = ApiMeResponseT & {
  cleanSession: (newSession?: ApiMeResponseT) => void
  logout: () => void
  persons?: AuthPersonIdAndPermissions[]
  refresh: (options?: { force: boolean; onComplete?: () => void }) => Promise<void>
  registeredToken: null | string
  setRegisteredToken: Dispatch<SetStateAction<string | null>>
  update: Dispatch<SetStateAction<ApiMeResponseT>>
}

const initialState: SessionContextT = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  auth: -1 as any,
  cleanSession: (_newSession?: ApiMeResponseT) => {},
  cmsActivated: false,
  logout: () => {},
  persons: undefined,
  refresh: async () => {},
  registeredToken: null,
  setRegisteredToken: () => {},
  update: () => {},
}

const SessionContext: Context<SessionContextT> = createContext(initialState)

export default SessionContext
