import React from 'react'
import { Box, Card, Typography } from '@mui/material'
import { ArrowRightThinS, FolderClosedM } from 'icons'
import { Link } from 'react-router-dom'
import { CmsFolderT } from 'types'
import { styles } from '../styles'
import { getFolderLink } from './utils'

interface IFolderCardProps {
  folder: CmsFolderT
  ownerId?: number
  onClick?: (_folder: CmsFolderT) => void
}

const FolderCard: React.FC<IFolderCardProps> = ({ folder, ownerId, onClick = (_folder: CmsFolderT) => {} }) => {
  return (
    <Box
      component={Link}
      sx={styles.contentCardLink}
      to={getFolderLink(folder, ownerId)}
      onClick={() => onClick(folder)}
    >
      <Card variant="outlined" sx={styles.contentCard}>
        <Box sx={styles.iconText}>
          <FolderClosedM />
          <Box sx={styles.contentCardText}>
            <Typography variant="body1" sx={styles.titleText}>
              {folder.name ?? ''}
            </Typography>
          </Box>
        </Box>
        <ArrowRightThinS />
      </Card>
    </Box>
  )
}

export default FolderCard
