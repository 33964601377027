import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import FullPageDrawer from 'redesign/components/atoms/FullPageDrawer'
import DesktopSideMenu from 'redesign/components/organisms/DesktopSideMenu'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'
import SideMenuContent from '../../organisms/SideMenuContent/SideMenuContent'
import { SIDE_MENU_OPTIONS } from './constants'
import type { SideMenuProps } from './types'

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { open, setOpen, setPage } = useSideMenuContext()

  return isSmallScreen ? (
    <FullPageDrawer
      {...props}
      open={open}
      onClose={() => {
        setOpen(false)
        setPage(SIDE_MENU_OPTIONS.mainMenu)
      }}
    >
      <SideMenuContent />
    </FullPageDrawer>
  ) : (
    <DesktopSideMenu />
  )
}

export default SideMenu
