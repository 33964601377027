import { styled, Typography } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export const Text = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
