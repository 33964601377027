import { Badge as MuiBadge, styled } from '@mui/material'

export const Badge = styled(MuiBadge)`
  margin: 0 !important;

  & .MuiBadge-badge {
    position: relative;
    transform: none;
  }
`
