import React from 'react'
import { shiftTypes } from 'constants/shift'
import ShiftComment from 'redesign/components/atoms/ShiftComment'
import AccordionWrapper from 'redesign/components/molecules/AccordionWrapper'
import AbsenceShiftData from 'redesign/components/molecules/ShiftData/AbsenceShiftData'
import PlannedShiftData from 'redesign/components/molecules/ShiftData/PlannedShiftData'
import ShiftPlanCardHeader from 'redesign/components/molecules/ShiftPlanCardHeader'
import { DataContainer } from './styles'
import type { ShiftPlanCardProps } from './types'

const ShiftPlanCard: React.FC<ShiftPlanCardProps> = ({
  shifts,
  isAccordion,
  date,
  isOld,
  user,
  showCostCenter = true,
  defaultExpanded = false,
  hideAbsenceType = false,
  testId,
}) => {
  const firstShift = shifts?.[0]
  const uniqueDifferentComments = Array.from(new Set(shifts.flatMap((s) => (s.note ? [s.note] : []))))
  const singleComment = uniqueDifferentComments.length === 1
  const comment = singleComment ? uniqueDifferentComments[0] : undefined
  const ShiftData = firstShift.type === shiftTypes.absence ? AbsenceShiftData : PlannedShiftData

  return (
    <AccordionWrapper
      testId={`shift-plan-card${testId ? `_${testId}` : ''}`}
      isAccordion={isAccordion}
      header={<ShiftPlanCardHeader shift={firstShift} isOld={isOld} user={user} hideAbsenceType={hideAbsenceType} />}
      defaultExpanded={defaultExpanded}
    >
      <DataContainer>
        <ShiftData date={date} firstShift={firstShift} shifts={shifts} isOld={isOld} showCostCenter={showCostCenter} />
        {comment && <ShiftComment isOld={isOld} note={comment} />}
      </DataContainer>
    </AccordionWrapper>
  )
}

export default ShiftPlanCard
