import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import AcknowledgeAllButton from 'redesign/components/atoms/Buttons/AcknowledgeAllButton'
import ListWrapper from 'redesign/components/organisms/ListWrapper'
import Notification from 'redesign/components/organisms/Notifications'
import { NotificationsListProps } from './types'

const NotificationsList: React.FC<NotificationsListProps> = ({
  isLoading,
  notifications,
  showLoadMoreButton,
  loadMore,
  dismiss,
  handleAcknowledgeAll,
  showAcknowledgeAll,
}) => {
  const { t } = useFavurTranslation()

  return (
    <>
      {showAcknowledgeAll ? <AcknowledgeAllButton onClick={handleAcknowledgeAll} /> : null}
      <ListWrapper
        hasContent={Boolean(notifications?.length)}
        message={t('page.notifications.list.noNotifications')}
        isLoading={isLoading}
        showLoadMoreButton={showLoadMoreButton}
        loadMore={loadMore}
      >
        {notifications?.map((notification) => (
          <Notification key={notification.id} dismiss={dismiss} notification={notification} />
        ))}
      </ListWrapper>
    </>
  )
}

export default NotificationsList
