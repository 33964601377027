import React, { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import Avatar from 'components/Avatar'
import useSessionContext from 'hooks/useSessionContext'
import { getAvatarShifts } from 'redesign/components/organisms/AvatarGroup/getAvatarShifts'
import { getShiftKey } from 'types/utils'
import { classes, shiftCardAvatarClasses } from '../styles'
import AvatarShiftChip from './AvatarShiftChip'
import { TeamMemberT } from './types'
import { getTeamMemberKey } from './utils'

interface ITeamAvatarsProps {
  avatars: TeamMemberT[]
  max: number
  showShifts?: boolean
}

const isLastElement = (idx: number, max: number) => idx === max - 1

const TeamAvatars: React.FC<ITeamAvatarsProps> = ({ avatars, max, showShifts = false }) => {
  const { persons } = useSessionContext()
  const personIds = useMemo(() => persons?.map((person) => `${person.id}`), [persons])

  const avatarsToShow = avatars.length > max ? avatars.slice(0, max) : avatars
  const isShowAvatar = useCallback((avatar: TeamMemberT) => !personIds?.includes(avatar.personId), [personIds])

  return (
    <Box sx={classes.avatarContainer}>
      {avatarsToShow.map(
        (avatar, idx) =>
          isShowAvatar(avatar) && (
            <Box key={getTeamMemberKey(avatar)} sx={classes.avatarElementContainer}>
              <Avatar
                sx={isLastElement(idx, max) ? shiftCardAvatarClasses.avatarLastElem : []}
                avatar={avatar}
                size={40}
              >
                <Box sx={isLastElement(idx, max) ? classes.avatarLastElemText : undefined}>
                  {isLastElement(idx, max) ? '...' : avatar.avatarName}
                </Box>
              </Avatar>
              {!isLastElement(idx, max) && showShifts && (
                <Box sx={classes.avatarColorsContainer}>
                  {getAvatarShifts(avatar).map((shift) => (
                    <AvatarShiftChip key={getShiftKey(shift, 'avatar')} shift={shift} />
                  ))}
                </Box>
              )}
            </Box>
          ),
      )}
    </Box>
  )
}

export default TeamAvatars
