import { ShiftTimeOfDayT, shiftTimesOfDay } from 'types'

export const getAbsencePeriodKey = ({ percent = 0, timeOfDay }: { percent?: number; timeOfDay?: ShiftTimeOfDayT }) => {
  if (percent < 100) {
    if (!timeOfDay) return 'absencePeriod.halfDay'

    if (timeOfDay === shiftTimesOfDay.pm) return 'absencePeriod.afternoon'

    return 'absencePeriod.morning'
  }

  return 'absencePeriod.fullDay'
}
