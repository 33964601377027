export const cleanupModalSheet = (rootId: string) => {
  const root = document.querySelector(`#${rootId}`) as HTMLDivElement
  const body = document.querySelector('body') as HTMLBodyElement
  if (!root) return

  body.style.removeProperty('background-color')
  root.style.removeProperty('overflow')
  root.style.removeProperty('transition-timing-function')
  root.style.removeProperty('transition-property')
  root.style.removeProperty('transition-duration')
  root.style.removeProperty('transform-origin')
  root.style.removeProperty('transform')
  root.style.removeProperty('border-top-right-radius')
  root.style.removeProperty('border-top-left-radius')
}
