import { ShiftsByDateT } from 'components/ShiftsList/types'
import { groupBy } from 'lodash/fp'
import type { PlannedShiftT } from 'types'
import { prepareShowingShifts } from '../utils'

export const preProcessShifts = (shifts: ShiftsByDateT[]) => {
  const groupedByDay = groupBy(
    'date',
    shifts.map((day) => day.tenants.flatMap((tenant) => tenant.shifts as PlannedShiftT[])).flat(),
  )
  return Object.entries(groupedByDay).reduce((object: { [key: string]: PlannedShiftT[] }, pair) => {
    const [key, value] = pair
    return { ...object, [key]: prepareShowingShifts(value) }
  }, {})
}
