import React from 'react'
import { addYears } from 'date-fns'
import RedesignThemeWrapper from 'redesign/components/atoms/RedesignThemeWrapper'
import CalendarTodayButton from 'redesign/components/molecules/CalendarTodayButton'
import { newDateWithoutTime } from 'utils/date'
import DesktopMonthPicker from '../DesktopMonthPicker'
import MobileMonthPicker from '../MobileMonthPicker'
import { MIN_DATE_STRING } from './constants'
import { Container } from './styles'
import { MonthSwitcherProps } from './types'
import { useMonthSwitcher } from './useMonthSwitcher'

const MonthSwitcher: React.FC<MonthSwitcherProps> = ({ baseDate, onChange, monthIndex, handleToday }) => {
  const { monthLabel, onMonthChange, todayLabel, isNative, datePickerDate } = useMonthSwitcher({
    baseDate,
    onChange,
    monthIndex,
  })

  return (
    <RedesignThemeWrapper>
      <Container>
        {isNative ? (
          <MobileMonthPicker
            displayDateValue
            setDisplayDateValue={() => {}}
            minDate={newDateWithoutTime(MIN_DATE_STRING)}
            maxDate={addYears(newDateWithoutTime(), 10)}
            onChange={onMonthChange}
            label={monthLabel}
            defaultValue={datePickerDate}
            isButton
          />
        ) : (
          <DesktopMonthPicker
            handleToday={handleToday}
            todayLabel={todayLabel}
            label={monthLabel}
            onChange={onMonthChange}
            datePickerDate={datePickerDate}
          />
        )}
        {isNative && <CalendarTodayButton hideArrows label={todayLabel} handleToday={handleToday} />}
      </Container>
    </RedesignThemeWrapper>
  )
}

export default MonthSwitcher
