import React, { useEffect } from 'react'
import { GlobalStyles } from '@mui/material'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { isNativeNoOverride, isAndroidNative } from 'utils/platform'
import theme from 'utils/theme'
import { StatusBar, Style } from '@capacitor/status-bar'
import '@ionic/react/css/core.css'
import '@ionic/react/css/normalize.css'
import '../../assets/fonts/index.css'

interface Props {
  children?: React.ReactNode
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    if (isNativeNoOverride()) {
      // setBackgroundColor and setOverlaysWebView are currently not supported on iOS devices.
      // https://capacitorjs.com/docs/apis/status-bar
      if (isAndroidNative()) {
        StatusBar.setBackgroundColor({ color: theme.palette.primary.dark })
      }
      StatusBar.setStyle({ style: Style.Default })
    }
  }, [])
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        {/* This removes the annoying error overlay from react */}
        <GlobalStyles
          styles={{
            'iframe#webpack-dev-server-client-overlay': {
              display: 'none',
            },
          }}
        />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider
