import { css, styled, Typography } from '@mui/material'

export const Container = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.greyLight};
  border-radius: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
`
export const Comment = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isOld',
})<{ isOld?: boolean }>`
  display: inline;
  ${({ isOld }) =>
    isOld &&
    css`
      text-decoration: line-through;
    `}
`
