import { modules } from 'shared/constants'
import type {
  SettingsInAppNotificationsOptionsT,
  SettingsInAppNotificationsT,
  SettingsPushNotificationsOptionsT,
  SettingsPushNotificationsT,
} from 'types'

export const adminSettings: {
  key: SettingsPushNotificationsOptionsT | SettingsInAppNotificationsOptionsT
  module: string
}[] = [
  { key: 'absenceRequest', module: modules.workflowAbsences },
  { key: 'officeMonthlySheetApproved', module: modules.workflowMonthlySheets },
  { key: 'receivedUserData', module: modules.workflowShareUserData },
  { key: 'secureConnectionManager', module: modules.workflowSecureConnection },
  { key: 'shiftUpdateSeen', module: modules.workflowShiftUpdate },
]

export const cmsSettings: { key: SettingsPushNotificationsOptionsT; module: string }[] = [
  { key: 'cmsEvent', module: modules.cms },
  { key: 'cmsPage', module: modules.cms },
  { key: 'cmsNewsletter', module: modules.cms },
]

export const communicationSettings: { key: SettingsPushNotificationsOptionsT; module: string }[] = [
  { key: 'shareUserData', module: modules.workflowShareUserData },
  { key: 'secureConnectionFrontliner', module: modules.workflowSecureConnection },
  { key: 'frontlinerAbsenceRequest', module: modules.workflowAbsences },
]

export const documentsSettings: { key: SettingsPushNotificationsOptionsT; module: string }[] = [
  { key: 'documents', module: modules.documents },
]

export const workingHoursSettings: { key: SettingsPushNotificationsOptionsT; module: string }[] = [
  { key: 'newShifts', module: modules.shiftPlan },
  { key: 'shiftsUpdate', module: modules.shiftPlan },
  { key: 'monthlySheetReminder', module: modules.workflowMonthlySheets },
]

export const defaultSettings: SettingsPushNotificationsT = {
  absenceRequest: true,
  documents: true,
  frontlinerAbsenceRequest: true,
  invitations: true,
  monthlySheetReminder: true,
  newShifts: true,
  officeMonthlySheetApproved: true,
  receivedUserData: true,
  shareUserData: true,
  shiftsUpdate: true,
  shiftUpdateSeen: false,
  secureConnectionFrontliner: true,
  secureConnectionManager: true,
}

export const defaultInAppSettings: SettingsInAppNotificationsT = {
  absenceRequest: true,
  officeMonthlySheetApproved: true,
  receivedUserData: true,
  shiftUpdateSeen: false,
  secureConnectionManager: true,
}
