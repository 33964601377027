import React from 'react'
import { useTheme } from '@mui/material'
import { CalendarIcon } from 'redesign/components/atoms/Icon'
import { TextField } from './styles'
import type { MonthPickerInputProps } from './types'

const MonthPickerInput: React.FC<MonthPickerInputProps> = ({
  label,
  onClick,
  testId,
  value,
  focused,
  disabled,
  error,
  fullWidth,
}) => {
  const theme = useTheme()

  return (
    <TextField
      fullWidth={fullWidth}
      helperText={error}
      error={Boolean(error)}
      disabled={disabled}
      focused={focused}
      data-testid={testId}
      onClick={() => {
        if (disabled) return

        onClick()
      }}
      label={label}
      slotProps={{
        input: {
          endAdornment: (
            <CalendarIcon color={focused ? theme.palette.primary.main : 'currentColor'} fillOpacity="0.6" />
          ),
          readOnly: true,
        },
        inputLabel: { shrink: true },
      }}
      value={value}
    />
  )
}
export default MonthPickerInput
