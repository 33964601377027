import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Headline from 'redesign/components/atoms/Headline'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import EventCard from '../../EventCard'
import { CardsContainer, Container } from '../styles'
import type { EventsSectionProps } from './types'

const EventsSection: React.FC<EventsSectionProps> = ({ events, date, eventsLoading }) => {
  const { t } = useFavurTranslation()

  return (
    <Container>
      <Headline text={t('shiftPlan.headline.eventSection')} testId="my-events-headline" />
      <CardsContainer>
        {eventsLoading ? (
          <LoadingSpinner />
        ) : (
          events?.map((event) => <EventCard event={event} date={date} key={event?.uuid} />)
        )}
      </CardsContainer>
    </Container>
  )
}

export default EventsSection
