import React, { memo, useMemo } from 'react'
import { Backdrop, Box } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useAvatarSidebarStateContext, {
  useAvatarSidebarStateDispatchContext,
} from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { TaskCountsT } from 'pages/AbsencePlanner/types'
import Divider from 'redesign/components/atoms/Divider'
import RedesignThemeWrapper from 'redesign/components/atoms/RedesignThemeWrapper'
import { PersonT, TeamT } from 'types'
import { getUserName } from 'utils/person'
import AbsencePlannerAvatar from '../Avatar'
import LoadingAbsencePlannerAvatar from '../Avatar/LoadingAvatar'
import { PLACEHOLDER_ROWS_COUNT } from '../Grid/constants'
import { avatarSideBarClasses } from './styles'

const getUserTaskCount = (taskCounts: TaskCountsT, personIds: string[]) => {
  const filteredTaskCounts = Object.entries(taskCounts).filter(([personId]) => personIds.includes(personId))
  return filteredTaskCounts.reduce((acc, personTaskCount) => acc + personTaskCount[1], 0)
}

interface IAvatarSideBarProps {
  teams: TeamT[]
  taskCounts: TaskCountsT
  loading: boolean
  persons?: PersonT[]
  firstPersonIds: number[] | undefined
  isPreview?: boolean
}

const AvatarSideBar: React.FC<IAvatarSideBarProps> = ({
  teams,
  taskCounts,
  loading,
  persons,
  firstPersonIds,
  isPreview,
}) => {
  const { t } = useFavurTranslation()
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { avatarBarExpanded: open } = useAvatarSidebarStateContext()
  const setAvatarBarExpanded = useAvatarSidebarStateDispatchContext()
  const { selectedPerson } = useSelectedStatesContext()
  const userPersonTaskCount = useMemo(
    () =>
      getUserTaskCount(
        taskCounts,
        (persons ?? []).map((person) => `${person.id}`),
      ),
    [persons, taskCounts],
  )
  const redesignFF = useFeatureFlag(FF_FAVUR_REDESIGN)
  const dividerComponent = useMemo(
    () =>
      redesignFF ? (
        <RedesignThemeWrapper>
          <Divider orientation="horizontal" fullSize fullWidth />
        </RedesignThemeWrapper>
      ) : (
        <DivisionLine />
      ),
    [redesignFF],
  )

  const firstUserPerson = useMemo(() => {
    if (!persons || persons.length === 0) return undefined
    return persons[0]
  }, [persons])

  const loadingAvatarBar = loading || !firstUserPerson

  return (
    <Box sx={avatarSideBarClasses.container(isSmallScreen, open, Boolean(isPreview))}>
      <Backdrop
        open={open && isSmallScreen}
        sx={avatarSideBarClasses.backdrop}
        onClick={() => setAvatarBarExpanded(false)}
      />
      {loadingAvatarBar ? (
        <>
          <Box
            display="flex"
            sx={[avatarSideBarClasses.borderContainer, ...(isPreview ? [avatarSideBarClasses.stickyContainer] : [])]}
          >
            <LoadingAbsencePlannerAvatar isExpanded={open} key="placeholder-user_sidebar-avatar" />
          </Box>
          <Box key="placeholder_team-avatars" sx={avatarSideBarClasses.borderContainer}>
            <Box>
              <Box sx={avatarSideBarClasses.divisionLineContainer}>{dividerComponent}</Box>
              {Array.from({ length: PLACEHOLDER_ROWS_COUNT }, (_, index) => (
                <LoadingAbsencePlannerAvatar isExpanded={open} key={`${index}-placeholder_sidebar-avatar`} />
              ))}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            display="flex"
            sx={[avatarSideBarClasses.borderContainer, ...(isPreview ? [avatarSideBarClasses.stickyContainer] : [])]}
          >
            <AbsencePlannerAvatar
              name={isPreview ? getUserName(firstUserPerson, true) : t('timeline.avatar.you')}
              key={`${firstUserPerson.id}_sidebar-avatar`}
              tasks={userPersonTaskCount}
              showBorder
              selected={selectedPerson?.id === firstUserPerson.id}
              person={firstUserPerson}
              openUserTasks
            />
          </Box>
          {teams.map((team) => (
            <Box key={`${team.id}_team-avatars`} sx={avatarSideBarClasses.borderContainer}>
              <Box display="flex">
                <Box sx={avatarSideBarClasses.divisionLineContainer}>{dividerComponent}</Box>
              </Box>
              {(team.teamPermissions ?? []).map((ptp) => {
                if (firstPersonIds?.includes(parseInt(`${ptp.person?.id}`))) return null
                const person = ptp.person as PersonT
                return (
                  <Box sx={avatarSideBarClasses.avatarContainer} key={`${person.id}_sidebar-avatar`}>
                    <AbsencePlannerAvatar
                      name={getUserName(person, true)}
                      selected={selectedPerson?.id === person.id}
                      tasks={taskCounts[`${person.id}`] ?? 0}
                      person={person}
                    />
                  </Box>
                )
              })}
            </Box>
          ))}
        </>
      )}
    </Box>
  )
}

export default memo(AvatarSideBar)
