import he from 'he'
import { taskStates } from 'pages/Tasks/constants'
import routes from 'services/RoutesProvider/routes'
import { ReminderShiftUpdateManualNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getShiftUpdateManualReminder = ({ notification, t }: GetNotificationData) => {
  const { taskUuid, managerNameShort } = notification.data as ReminderShiftUpdateManualNotification
  const { title, body } = notificationGlosssaryKeys.reminderShiftUpdateManual

  return {
    title: he.decode(t(title, { managerNameShort })),
    body: t(body),
    route: `${routes.shiftUpdateDetail}/${taskUuid}/${taskStates.todo}`,
  }
}
