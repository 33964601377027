import React, { useCallback, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import { SimpleLoading } from 'components/LoadingIcon'
import useAbsenceRequests from 'hooks/useAbsenceRequests'
import useListCmsPaginatedContents from 'hooks/useListCmsPaginatedContents'
import useSessionContext from 'hooks/useSessionContext'
import useShifts from 'hooks/useShifts'
import useTeamPlan from 'hooks/useTeamPlan'
import TeamplanDetailPageContent from 'pages/Shifts/TeamplanDetailPage/Content'
import useTeamplanDetailContext from 'pages/Shifts/TeamplanDetailPage/context'
import useSideMenuContext from 'pages/Shifts/components/SideMenu/context'
import useShiftUpdates from 'pages/Shifts/useShiftUpdates'
import { absenceRequestStatus } from 'pages/Tasks/AbsenceRequest/constants'
import { PersonT, ShiftsByTenantCCT } from 'types'
import { CONTENT_TYPES } from 'types/cms'
import { TeamplanDayT } from 'types/teamplan'
import { getFullAvatarUrl } from 'utils/avatar'
import { getEventsFromTenantAndOwner } from 'utils/cms'
import { getDatetimeRangeWithTimezone, newDateWithoutTime } from 'utils/date'
import { getAvatarName } from 'utils/person'
import { getDayFromIndex } from 'utils/shifts'
import { ShiftsByDateT } from '../types'
import NoShiftTenant from './NoShiftTenant'
import ShiftCard from './ShiftCard'
import ShiftCardLoading from './ShiftCard/Loading'
import {
  anyEffectiveShifts,
  anyTeamplanData,
  findEffectiveShiftListByTenant,
  getAllCostCenterFromTeamplan,
  getCurrentTeamPlan,
  getTaskIds,
  getTeamPlanDetail,
  getTenantsList,
  groupCostCentersByTenant,
  sortTenantsListToIds,
} from './utils'

const getCardLoading = (loading: boolean, loadingShiftUpdates: boolean, filteredPersonsWithTenant?: PersonT[]) =>
  loading || loadingShiftUpdates || !filteredPersonsWithTenant

export const getShowNoShiftTenant = (
  shifts: ShiftsByDateT[],
  teamplanData: TeamplanDayT,
  effectiveShifts: ShiftsByDateT[],
) => (!shifts || shifts.length === 0) && !anyTeamplanData(teamplanData) && !anyEffectiveShifts(effectiveShifts)

const getDates = (baseDate: string, index: number) => {
  const currentDay = getDayFromIndex(baseDate, index)
  const dates = {
    start: currentDay,
    end: currentDay,
  }
  return { currentDay, dates }
}

export interface ISlideRendererProps {
  baseDate: string
  displayFull: boolean
  params: {
    index: number
    key: number
  }
  personIds?: number[] | null
  personsWithTenant: PersonT[] | undefined
}

const DailySlideRenderer: React.FC<ISlideRendererProps> = ({
  baseDate,
  displayFull,
  params,
  personIds,
  personsWithTenant,
}: ISlideRendererProps) => {
  const { user } = useSessionContext()
  const { selectedTeams, selectedPublicTeams, setCostCenters, loading: teamsLoading } = useSideMenuContext()
  const { selectedTeamData } = useTeamplanDetailContext()
  const avatarData = useMemo(() => {
    const avatarName = getAvatarName(user?.firstName, user?.lastName)
    return { avatarName, avatarUrl: user?.avatarUrl ? getFullAvatarUrl(user.avatarUrl) : undefined }
  }, [user?.avatarUrl, user?.firstName, user?.lastName])
  const { key, index } = params
  const { currentDay, dates } = useMemo(() => getDates(baseDate, index), [baseDate, index])

  const filteredPersonsWithTenant = useMemo(
    () =>
      personsWithTenant?.filter(
        (person) =>
          !person.workWithdrawal || newDateWithoutTime(person.workWithdrawal) > newDateWithoutTime(currentDay),
      ),
    [currentDay, personsWithTenant],
  )
  const { shifts, loading: shiftsLoading } = useShifts({ dates, displayFull, personIds: personIds as number[] })
  const taskIds = useMemo(() => getTaskIds(shifts), [shifts])
  const { tasks: shiftUpdates, loading: loadingShiftUpdates } = useShiftUpdates({ taskIds })

  const teamIds = useMemo(() => {
    if (!selectedTeams && !selectedPublicTeams) return undefined
    return [...(selectedTeams ?? []), ...(selectedPublicTeams ?? [])]
  }, [selectedPublicTeams, selectedTeams])

  const { shifts: effectiveShifts, loading: effectiveShiftsLoading, error: effectiveShiftsError } = useShifts({
    dates,
    displayFull,
    personIds: personIds as number[],
    isEffective: true,
  })

  const cardLoading = useMemo(() => getCardLoading(shiftsLoading, loadingShiftUpdates, filteredPersonsWithTenant), [
    filteredPersonsWithTenant,
    loadingShiftUpdates,
    shiftsLoading,
  ])

  const { data: teamplanData, loading, tenants: teamplanTenants } = useTeamPlan({
    dates,
    teamIds,
    teamsLoading: teamsLoading || cardLoading,
  })

  const tenantList = useMemo(() => getTenantsList(shifts, teamplanTenants, effectiveShifts), [
    shifts,
    teamplanTenants,
    effectiveShifts,
  ])

  const { tasks: absenceRequests } = useAbsenceRequests({
    personIds: !filteredPersonsWithTenant ? [] : filteredPersonsWithTenant.map((person) => Number(person.id) as number),
    date: currentDay,
    statuses: [
      absenceRequestStatus.reviewOfficeSeen,
      absenceRequestStatus.reviewOfficeUnseen,
      absenceRequestStatus.approvedOffice,
      absenceRequestStatus.rejectedDeletionRequest,
    ],
  })

  const getAbsenceRequestByTenantId = useCallback(
    (tenantId: string) => {
      if (!filteredPersonsWithTenant || absenceRequests.length === 0) return []
      const person = filteredPersonsWithTenant.find((p) => {
        return p.tenant?.id === tenantId
      })
      if (!person) return []
      return absenceRequests.filter((task) => task.person?.id === person.id)
    },
    [absenceRequests, filteredPersonsWithTenant],
  )

  useEffect(() => {
    const costCenters = getAllCostCenterFromTeamplan(teamplanData)
    setCostCenters(costCenters)
  }, [setCostCenters, teamplanData])

  const groupedTenants = useMemo(
    () => (shifts && shifts[0] ? groupCostCentersByTenant(shifts[0].tenants) : new Map<string, ShiftsByTenantCCT[]>()),
    [shifts],
  )

  const sortedTenantIds = useMemo(() => sortTenantsListToIds(tenantList, groupedTenants), [groupedTenants, tenantList])

  const selectedTeamPlanDetail = useMemo(
    () =>
      selectedTeamData &&
      getTeamPlanDetail(loading, teamplanData, currentDay, selectedTeamData.tenantId, selectedTeamData.id),
    [currentDay, loading, selectedTeamData, teamplanData],
  )

  const showNoShiftTenant = useMemo(() => getShowNoShiftTenant(shifts, teamplanData, effectiveShifts), [
    effectiveShifts,
    shifts,
    teamplanData,
  ])

  const eventsDateRange = useMemo(() => getDatetimeRangeWithTimezone({ start: currentDay, end: currentDay }), [
    currentDay,
  ])

  const { list: events } = useListCmsPaginatedContents({
    contentType: CONTENT_TYPES.event,
    ...eventsDateRange,
  })
  const getEventsForTenantShiftCard = useCallback(
    ({ tenantShifts = [] }: { tenantShifts: ShiftsByTenantCCT[] }) =>
      tenantShifts.length > 0
        ? getEventsFromTenantAndOwner(events, {
            tenantId: tenantShifts[0].tenantId,
            ownerId: tenantShifts[0].ownerId,
          })
        : events,
    [events],
  )

  if (selectedTeamPlanDetail) {
    return loading ? (
      <SimpleLoading />
    ) : (
      <TeamplanDetailPageContent
        teamplanDetail={selectedTeamPlanDetail}
        hideCostCenter={!selectedTeamData?.showTeamplanCostCenters}
      />
    )
  }

  if (cardLoading) {
    return <ShiftCardLoading />
  }

  return (
    <>
      {showNoShiftTenant
        ? (filteredPersonsWithTenant ?? []).map((person) => (
            <Box key={`${key}-${person.tenant?.name}`}>
              <NoShiftTenant
                displayFull={displayFull}
                events={getEventsFromTenantAndOwner(events, {
                  tenantId: person.tenant?.id,
                  ownerId: person.owner?.id.toString(),
                })}
                tenantName={person.tenant?.name as string}
                date={currentDay}
                absenceRequests={absenceRequests.filter((task) => task.person?.id === person.id)}
              />
            </Box>
          ))
        : sortedTenantIds.map((tenantId) => {
            const filteredEffectiveShifts = findEffectiveShiftListByTenant(
              effectiveShifts,
              effectiveShiftsLoading,
              tenantId,
            )
            const tenantShifts = groupedTenants.get(tenantId) ?? []

            return (
              <ShiftCard
                key={`${currentDay}-${tenantId}`}
                events={getEventsForTenantShiftCard({ tenantShifts })}
                date={currentDay}
                tenant={tenantList[tenantId]}
                shiftData={tenantShifts}
                avatarData={avatarData}
                teamplan={getCurrentTeamPlan(loading, teamplanData, currentDay, tenantId)}
                effectiveShifts={filteredEffectiveShifts}
                effectiveShiftsError={Boolean(effectiveShiftsError)}
                shiftUpdates={shiftUpdates ?? []}
                absenceRequests={getAbsenceRequestByTenantId(tenantId)}
              />
            )
          })}
    </>
  )
}

export default DailySlideRenderer
