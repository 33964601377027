import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const CalendarActionIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
  fillOpacity,
}) => (
  <SvgWrapper aria-label="Calendar Action Icon" viewBox={viewBox} size={size}>
    <path
      d="M20.07 4.48h-3.026V2.693a.703.703 0 0 0-.698-.698.703.703 0 0 0-.698.698V4.48H8.361V2.693a.703.703 0 0 0-.698-.698.703.703 0 0 0-.698.698V4.48H3.941c-1.424 0-1.936 1.164-1.936 1.936v13.653c0 1.424 1.163 1.936 1.936 1.936h16.128c1.424 0 1.936-1.164 1.936-1.936V6.416c0-1.424-1.163-1.936-1.936-1.936zM3.93 5.876h3.025V7.04c0 .381.316.698.698.698a.703.703 0 0 0 .698-.698V5.876h7.287V7.04c0 .381.317.698.698.698a.703.703 0 0 0 .698-.698V5.876h3.015c.55.019.55.41.55.54v3.025H3.392V6.425c.018-.549.41-.549.54-.549zM20.07 20.61H3.941c-.55-.019-.55-.41-.55-.54v-9.232H20.61v9.223c-.019.549-.41.549-.54.549z"
      fill={color}
      fillOpacity={fillOpacity}
    />
    <path
      d="M8 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM8 18a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM13 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM13 18a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM18 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM18 18a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
      fill={color}
      fillOpacity={fillOpacity}
    />
  </SvgWrapper>
)

export default CalendarActionIcon
