import React, { useState } from 'react'
import { useTheme } from '@mui/material'
import { CloseIcon } from 'redesign/components/atoms/Icon'
import type { NavigationMenuProps } from '../NavigationMenu/types'
import { Container, ExitButton, Footer, NavbarElement } from './styles'

const NavigationFooter: React.FC<Pick<NavigationMenuProps, 'toggleDrawer'>> = ({ toggleDrawer }) => {
  const theme = useTheme()
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Container>
      <Footer>
        <NavbarElement />
        <NavbarElement />
        <NavbarElement />
        <NavbarElement />
        <NavbarElement>
          <ExitButton
            disableRipple
            onClick={toggleDrawer}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            data-testid="close-side-menu"
          >
            <CloseIcon color={isHovered ? theme.palette.common.white : theme.palette.common.navItemBase} />
          </ExitButton>
        </NavbarElement>
      </Footer>
    </Container>
  )
}

export default NavigationFooter
