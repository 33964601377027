import React from 'react'
import { Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import NewAbsenceRequest from '.'
import SmallScreenPageHeader from '../SmallScreenPageHeader'
import { absenceRequestListClasses as classes } from '../styles'

interface INewAbsenceRequestPageProps {
  refetchAll: () => void
  goBack: () => void
}

const NewAbsenceRequestPage: React.FC<INewAbsenceRequestPageProps> = ({ refetchAll, goBack }) => {
  const { t } = useFavurTranslation()

  return (
    <Box sx={classes.page}>
      <SmallScreenPageHeader title={t('absencePlanner.taskbar.newAbsenceRequest.header')} onClick={goBack} />
      <NewAbsenceRequest goBack={goBack} refetchAll={refetchAll} subtitle="" title="" hideHeader drawerFullScreen />
    </Box>
  )
}

export default NewAbsenceRequestPage
