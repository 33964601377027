import React from 'react'
import { Frame } from './styles'
import { FramedIllustrationProps } from './types'

const FramedIllustration: React.FC<FramedIllustrationProps> = ({ illustration: Illustration, width, height }) => (
  <Frame>
    <Illustration width={width} height={height} />
  </Frame>
)

export default FramedIllustration
