import { css, Divider, styled } from '@mui/material'
import theme from 'redesign/themes/main'
import { DividerProps, DotDividerProps } from './types'

export const DividerComponent = styled(Divider, {
  shouldForwardProp: (prop) => prop !== 'fullSize' && prop !== 'fullWidth',
})<{ orientation?: Pick<DividerProps, 'orientation'>; fullSize?: boolean; fullWidth?: boolean }>`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ orientation, fullSize }) =>
    orientation === 'vertical'
      ? `
      display: flex;
      align-self: center;
      padding: ${fullSize ? theme.spacing(2, 0) : theme.spacing(0)};
        `
      : `
      margin: ${fullSize ? theme.spacing(0) : theme.spacing(0, 2)};
      `}
`
export const DotContainer = styled('div')`
  display: flex;
  justify-content: center;
`
export const Dot = styled('div')<{ size?: DotDividerProps['size'] }>`
  width: ${({ size }) => (size === 'large' ? theme.spacing(1) : theme.spacing(0.5))};
  height: ${({ size }) => (size === 'large' ? theme.spacing(1) : theme.spacing(0.5))};
  background-color: ${theme.palette.primary.main};
  border-radius: 50%;
`
export const SpaceDivider = styled('div')`
  width: ${theme.spacing(1)};
  height: ${theme.spacing(1)};
`

export const SeparatorDivider = styled('div')`
  width: 15px;
  background-color: ${({ theme: redesignTheme }) => redesignTheme.palette.common.backgroundApp};
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: ${({ theme: redesignTheme }) => redesignTheme.palette.common.cardBorder};
`
