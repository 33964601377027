import { PlannedShiftT } from 'types'

export const getCanShowEffectiveTimes = (
  isPast: boolean,
  areThereShifts: boolean,
  areThereEffectiveShifts: boolean,
  hasAcknowledgedShiftUpdate: boolean,
  hideEffectiveTimes: boolean,
) => !hideEffectiveTimes && isPast && (areThereShifts || areThereEffectiveShifts) && hasAcknowledgedShiftUpdate

export const getAreThereShifts = (
  groupedShifts: {
    costCenterName: string
    costCenterUuid: string
    shifts: PlannedShiftT[][]
  }[],
): boolean => {
  if (!groupedShifts || groupedShifts.length === 0) {
    return false
  }

  const numShifts = groupedShifts.reduce((acc, gs) => acc + gs.shifts.length, 0)
  return numShifts > 0
}
