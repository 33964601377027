import React, { useCallback, useMemo } from 'react'
import { shiftDateFormat } from 'constants/dateFormats'
import { shiftUpdateTypes } from 'constants/shift'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import useShitfsContext from 'hooks/useShiftsContext'
import useStateBackLink from 'hooks/useStateBackLink'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDate } from 'redesign/shared/date/utils'
import routes from 'services/RoutesProvider/routes'
import { Notification as NotificationProp, ShiftNotification } from 'shared/graphql/graphql'
import { notificationStatuses, RoleViewT } from 'types'
import { getDateFromISOString, getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import ContentCard from '../ContentCard'
import type { NotificationProps } from './types'
import { normalizeNotificationData } from './utils/normalizeNotificationData'

const Notification: React.FC<NotificationProps<NotificationProp>> = ({ dismiss, notification }) => {
  const { setBackLink } = useStateBackLink()
  const { t, locale } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()
  const { setShiftsContext } = useShitfsContext()
  const { status, updatedAt, dataType, data } = notification
  const setBackLinkAndDismiss = useCallback(
    (funcNotification: NotificationProp) => {
      setBackLink(routes.notifications)
      dismiss(funcNotification)
    },
    [dismiss, setBackLink],
  )

  const onClick = useCallback(
    (route: string, role?: RoleViewT, outsideLink?: boolean) => {
      setBackLinkAndDismiss(notification)

      if (outsideLink) {
        window.location.href = route
      }

      if (dataType === shiftUpdateTypes.inserted) {
        const { dateFrom } = data as ShiftNotification
        const fromDate = getDateFromISOString(dateFrom ?? '')
        const start = getFormattedFromDate(fromDate, shiftDateFormat)
        const end = getFormattedFromDate(fromDate, shiftDateFormat)
        setShiftsContext({
          start,
          end,
        })
      }

      pushWithRole(route, role)
    },
    [data, dataType, notification, pushWithRole, setBackLinkAndDismiss, setShiftsContext],
  )

  const { title, body, route, role, outsideLink } = useMemo(
    () =>
      normalizeNotificationData({
        notification,
        t,
        locale,
      }),
    [locale, notification, t],
  )

  return (
    <ContentCard
      isNew={status === notificationStatuses.new}
      header={{
        title,
      }}
      footerProps={{
        startText: body,
        endText: formatDate({
          date: newDateWithoutTime(updatedAt),
          dateFormat: DATE_FORMAT_VARIANTS.shortWithoutWeekday,
          locale,
          t,
        }),
      }}
      onClick={() => onClick(route, role, outsideLink)}
    />
  )
}

export default Notification
