import React from 'react'
import { Typography, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Headline from 'redesign/components/atoms/Headline'
import { HEADLINE_LEVELS } from 'redesign/components/atoms/Headline/constants'
import { CheckMark } from 'redesign/components/atoms/Icon'
import type { EffectiveShiftT } from 'types'
import { getFromUntil } from 'utils/shifts'
import AccordionWrapper from '../AccordionWrapper'
import { CardContent, EffectiveTimeContainer, IconHeader } from './styles'
import type { EffectiveShiftCardProps } from './types'

const EffectiveShiftCard: React.FC<EffectiveShiftCardProps> = ({ effectiveShifts }) => {
  const { t } = useFavurTranslation()
  const { palette } = useTheme()

  const validatedComponent = (
    <IconHeader>
      <CheckMark isFilled color={palette.success.main} />
      <Typography color={palette.success.main} variant="body2">
        {t('page.shifts.shiftCard.validated')}
      </Typography>
    </IconHeader>
  )

  const hideHeader = (
    <IconHeader>
      <Typography variant="body2">{t('page.shifts.shiftCard.pending')}</Typography>
    </IconHeader>
  )

  const getHeadlineComponent = (isValidated: boolean, hideTimes: boolean) => {
    if (hideTimes) return hideHeader
    if (isValidated) return validatedComponent

    return null
  }

  if (!effectiveShifts.length) {
    return <Headline level={HEADLINE_LEVELS.third} text={t('page.shifts.shiftCard.noEffectiveShifts')} />
  }

  return (
    <>
      {effectiveShifts?.map((effectiveShift) => {
        const { hideUnverifiedEffectiveTimes, shifts, tenantName, costCenterName } = effectiveShift
        const isValidated = shifts.length > 0 && (shifts[0] as EffectiveShiftT).validated
        const hideTimes = hideUnverifiedEffectiveTimes && !isValidated

        return (
          <>
            <Headline
              noBottomSpacing
              level={HEADLINE_LEVELS.third}
              text={t('page.shifts.shiftCard.effectiveTime')}
              testId="shift-card__effective-shifts"
              endContent={<>{getHeadlineComponent(isValidated, Boolean(hideTimes))}</>}
            />
            <AccordionWrapper testId="effective-shift-card">
              <CardContent key={`content-card-${tenantName}`}>
                {shifts.map((shift) => (
                  <EffectiveTimeContainer key={`effective-time-shift-${shift.id}`}>
                    <Typography variant="body1" color={palette.text.secondary}>
                      {t(`page.shifts.content.${shift.timeType}`)}
                    </Typography>
                    <Typography variant="body1">{hideTimes ? '-' : getFromUntil(shift, t, true)}</Typography>
                  </EffectiveTimeContainer>
                ))}
                <EffectiveTimeContainer>
                  <Typography variant="body1" color={palette.text.secondary}>
                    {t('component.shifthistory.costcenter')}
                  </Typography>
                  <Typography variant="body1">{hideTimes ? '-' : costCenterName}</Typography>
                </EffectiveTimeContainer>
              </CardContent>
            </AccordionWrapper>
          </>
        )
      })}
    </>
  )
}

export default EffectiveShiftCard
