import { styled } from '@mui/material'

export const MenuContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: ${({ theme }) => theme.spacing(72)};
  padding: ${({ theme }) => theme.spacing(0, 2)};
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  outline: none;
  color: ${({ theme }) => theme.palette.common.white};
  box-sizing: border-box;
`

export const PrimaryNavigation = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(1)};
`

export const TertiaryNavigation = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(3)};
`
