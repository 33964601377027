import React from 'react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import AbsencePlannerGrid from 'pages/AbsencePlanner/components/Grid'
import { SelectedStatesProvider } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import AbsencePlannerHeader from 'redesign/components/organisms/Headers/AbsencePlannerHeader'
import Page from 'redesign/components/templates/Page'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'
import theme from 'utils/theme'

const AbsencePlannerPage: React.FC = () => {
  const { setTaskCount } = useSideMenuContext()

  return (
    <Page header={<AbsencePlannerHeader />} fullContentWidth noPadding removeLastMargin fullContentHeight>
      <SelectedStatesProvider>
        <MuiThemeProvider theme={theme}>
          <AbsencePlannerGrid setTaskCount={setTaskCount} />
        </MuiThemeProvider>
      </SelectedStatesProvider>
    </Page>
  )
}

export default AbsencePlannerPage
