import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const MoreVertical: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
}) => (
  <SvgWrapper viewBox={viewBox} size={size} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0 6a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm0 6a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"
      fill={color}
      fillOpacity=".87"
    />
  </SvgWrapper>
)

export default MoreVertical
