import React from 'react'
import { FormLabel, FormGroup, FormHelperText } from '@mui/material'
import Checkbox from 'redesign/components/atoms/Checkbox'
import { Container } from './styles'
import { CheckboxFormGroupProps } from './types'

const CheckboxFormGroup: React.FC<CheckboxFormGroupProps> = ({
  checkboxes = [],
  disabled,
  error,
  helperText,
  legend,
}) => {
  return (
    <Container disabled={disabled} error={error}>
      {legend && <FormLabel component="legend">{legend}</FormLabel>}
      <FormGroup>
        {checkboxes.map((props) => (
          <Checkbox {...props} key={`${props?.value}`} />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Container>
  )
}

export default CheckboxFormGroup
