import type { PersonT } from 'types'
import { getUserName } from 'utils/person'
import { TASK_TYPES } from '../constants'
import { HEADER_FRONTLINER_TRANSLATIONS, HEADER_MANAGER_TRANSLATIONS } from './constants'
import { NormalizeTaskHeader } from './types'

export const normalizeTaskHeader = ({ task, t, isOffice }: NormalizeTaskHeader) => {
  if (!task) return ''

  const { taskType, person } = task

  if (!person) return ''

  const typeOfTask = taskType as typeof TASK_TYPES[keyof typeof TASK_TYPES]

  if (!Object.values(TASK_TYPES).includes(typeOfTask)) return ''

  const translationKeys = isOffice ? HEADER_MANAGER_TRANSLATIONS : HEADER_FRONTLINER_TRANSLATIONS

  const frontlinerNameShort = isOffice ? getUserName(person as PersonT, true) : ''

  return t(translationKeys[typeOfTask], { frontlinerNameShort })
}
