import React from 'react'
import BackButton from 'redesign/components/atoms/Buttons/BackButton'
import CloseButton from 'redesign/components/atoms/Buttons/CloseButton/CloseButton'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import { GeneralSettings } from 'redesign/components/organisms/ShiftPlanSettings/GeneralSettings'
import { TeamSettingsBlock } from 'redesign/components/organisms/ShiftPlanSettings/TeamSettingsBlock'
import { useSwipeableView } from 'redesign/services/SwipeableView/useSwipeableView'
import Header from '../../../organisms/Header'
import { SIDE_MENU_OPTIONS, SIDE_MENU_TRANSLATIONS } from '../../SideMenu/constants'
import type { MenuPageProps } from '../../SideMenu/types'
import { ContentWrapper } from '../styles'
import { FiltersContainer } from './styles'

const Settings: React.FC<MenuPageProps> = ({ onClose, t, setPage, isDrawer }) => {
  const { sideMenuData } = useSwipeableView()
  const {
    teams,
    publicTeams,
    selectedPublicTeams,
    selectedTeams,
    setSelectedPublicTeams,
    setSelectedTeams,
  } = sideMenuData

  return (
    <ContentWrapper>
      <Header
        startContent={isDrawer && <BackButton onGoBack={() => setPage(SIDE_MENU_OPTIONS.mainMenu)} />}
        middleContent={<HeaderLabel mainText={t(SIDE_MENU_TRANSLATIONS[SIDE_MENU_OPTIONS.settings])} />}
        endContent={isDrawer && <CloseButton onClick={onClose} />}
      />
      <FiltersContainer>
        <GeneralSettings />
        <TeamSettingsBlock
          teams={teams}
          selectedTeams={selectedTeams}
          setSelectedTeams={setSelectedTeams}
          headlineLabel={t('page.shifts.sideMenu.myTeams')}
          filterKey="selectedTeams"
        />
        <TeamSettingsBlock
          teams={publicTeams}
          selectedTeams={selectedPublicTeams}
          setSelectedTeams={setSelectedPublicTeams}
          headlineLabel={t('page.shifts.sideMenu.otherTeams')}
          filterKey="selectedPublicTeams"
        />
      </FiltersContainer>
    </ContentWrapper>
  )
}
export default Settings
