import { ROLE_VIEWS } from 'constants/roles'
import he from 'he'
import routes from 'services/RoutesProvider/routes'
import { ReminderSecureConnectionManualForFrontlinerNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getSecureConnectionManualReminderData = ({ notification, t }: GetNotificationData) => {
  const { taskUuid, managerNameShort } = notification.data as ReminderSecureConnectionManualForFrontlinerNotification
  const { title, body } = notificationGlosssaryKeys.reminderSecureConnection

  return {
    title: he.decode(t(title, { managerNameShort })),
    body: t(body),
    route: routes.secureConnectionWithTask(taskUuid ?? ''),
    role: ROLE_VIEWS.frontliner,
  }
}
