import { useContext, useMemo } from 'react'
import { FF_VIEW_ROLE } from 'constants/featureFlags'
import { ROLE_VIEWS } from 'constants/roles'
import useModule from 'hooks/useModule'
import OfficeViewContext from 'services/OfficeView/context'
import { RoleViewT } from 'types'
import { newDateWithoutTime } from 'utils/date'
import {
  hasFrontlinerView as userHasFrontlinerView,
  hasOfficeView as userHasOfficeView,
  userCanManageTeams,
  userCanManageAbsences,
  userCanManageTenants,
} from 'utils/roles'
import useFeatureFlag from './useFeatureFlag'
import useSessionContext from './useSessionContext'

const activeViewSessionStorageKey = 'activeView'

/**
 * Permissions view hook
 * @returns personIds: persons ids array
 * @returns persons: array with id and permissions
 * @returns activeView: 'frontliner' | 'office'
 * @returns setActiveView: to set activeView
 * @returns hasOfficeView: if the user has access to office view
 * @returns hasFrontlinerView: if the user has access to frontline view
 * @returns roles: View roles
 */
const useRolesViews = () => {
  const { persons } = useSessionContext()
  const { hasAccessToModule } = useModule()
  const { activeView: contextActiveView, setActiveView: setContextActiveView } = useContext(OfficeViewContext)
  const roleViewFF = useFeatureFlag(FF_VIEW_ROLE)
  const hasOfficeView = useMemo(() => (persons ? userHasOfficeView(persons, roleViewFF) : false), [persons, roleViewFF])
  const hasFrontlinerView = useMemo(() => (persons ? userHasFrontlinerView(persons, roleViewFF) : true), [
    persons,
    roleViewFF,
  ])
  const personIds = useMemo(() => (persons ? persons.map((p) => p.id) : []), [persons])
  const canManageTeams = useMemo(() => userCanManageTeams(persons, hasAccessToModule), [hasAccessToModule, persons])
  const canManageTenants = useMemo(() => userCanManageTenants(persons, hasAccessToModule), [hasAccessToModule, persons])
  const canManageAbsences = useMemo(() => userCanManageAbsences(persons), [persons])
  const setActiveView = (roleView: RoleViewT) => {
    setContextActiveView(roleView)
    sessionStorage.setItem(activeViewSessionStorageKey, roleView)
  }
  const activeView = (sessionStorage.getItem(activeViewSessionStorageKey) || contextActiveView) as RoleViewT
  const clearActiveView = () => sessionStorage.removeItem(activeViewSessionStorageKey)
  const hasActivePersons = useMemo(
    () =>
      persons !== undefined &&
      persons.length > 0 &&
      persons.some(
        (person) => !person.workWithdrawal || newDateWithoutTime(person.workWithdrawal) >= newDateWithoutTime(),
      ),
    [persons],
  )
  const hasActiveAndResignedPersons = useMemo(() => persons !== undefined && persons.length > 0, [persons])

  return {
    activeView,
    hasFrontlinerView,
    hasOfficeView,
    personIds,
    persons,
    roles: ROLE_VIEWS,
    setActiveView,
    canManageTeams,
    canManageAbsences,
    clearActiveView,
    hasActivePersons,
    hasActiveAndResignedPersons,
    canManageTenants,
    isOffice: activeView === ROLE_VIEWS.office,
  }
}

export default useRolesViews
