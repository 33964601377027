import theme from 'utils/theme'

export const classes = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  cardList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  headerContainer: {
    position: 'relative',
  },
  headerIcon: {
    color: theme.palette.common.white,
    position: 'absolute',
    left: 0,
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  titleContainer: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    height: '24px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  textBodyContainer: {
    width: '100%',
    display: 'grid',
  },
  dotContainer: {
    minWidth: '8px',
    width: '8px',
  },
  newDot: {
    minHeight: '8px',
    width: '8px',
    height: '8px',
    background: theme.palette.secondary.main,
    borderRadius: '50%',
  },
  title: {
    lineHeight: '22px',
  },
  date: {
    color: theme.palette.grey[600],
    letterSpacing: 0.3,
  },
  root: {
    cursor: 'pointer',
  },
  body: {
    color: theme.palette.grey[600],
    lineHeight: '22px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}
