import React from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { SHIFT_PLAN_PAGE_ROOT_ID } from 'redesign/pages/ShiftPlan/constants'
import { useSwipeableView } from 'redesign/services/SwipeableView/useSwipeableView'
import { isNative } from 'utils/platform'
import FullScreenModal from '../FullScreenModal'
import SubheaderCalendarMonth from '../Subheaders/SubheaderCalendarMonth'
import ShiftPlanMonthlyCalendarContent from './ShiftPlanMonthlyCalendarContent'
import { ModalContent, SwipeContainer } from './styles'
import type { ShiftPlanMonthlyCalendarProps } from './types'

const ShiftPlanMonthlyCalendar: React.FC<ShiftPlanMonthlyCalendarProps> = ({ open, onClose, onClickDay }) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { state, handleIndexMonthChange, goToToday } = useSwipeableView()
  const { calendarTitleDay, monthIndex, referenceDay } = state

  return (
    <FullScreenModal
      disableDrag
      isOpen={open}
      onClose={onClose}
      rootId={SHIFT_PLAN_PAGE_ROOT_ID}
      isSmallScreen={isSmallScreen}
      noPadding
      header={
        <SubheaderCalendarMonth
          handleToday={goToToday}
          selectedDay={calendarTitleDay}
          handleNextButton={() => handleIndexMonthChange(1)}
          handlePreviousButton={() => handleIndexMonthChange(-1)}
          onClose={onClose}
          isSmallScreen={isSmallScreen}
          isNative={isNative()}
        />
      }
    >
      <ModalContent isSmallScreen={isSmallScreen}>
        <SwipeContainer
          enableMouseEvents={isNative() && isSmallScreen}
          index={monthIndex}
          onChangeIndex={(newIndex, indexLatest) => handleIndexMonthChange(newIndex < indexLatest ? -1 : 1)}
          overscanSlideAfter={1}
          overscanSlideBefore={1}
          slideRenderer={(params) => (
            <ShiftPlanMonthlyCalendarContent
              key={params.index}
              onClickDay={onClickDay}
              onClose={onClose}
              referenceDay={referenceDay}
              calendarTitleDay={calendarTitleDay}
              index={params.index}
            />
          )}
        />
      </ModalContent>
    </FullScreenModal>
  )
}

export default ShiftPlanMonthlyCalendar
