import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const NewsIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
  isFilled,
}) => {
  const path = isFilled ? (
    <path
      d="M19.45 9.045c.135 0 .28-.038.396-.116l1.071-.71a.728.728 0 0 0 .203-1.012.715.715 0 0 0-1.004-.204l-1.072.71a.728.728 0 0 0-.202 1.012c.135.213.367.33.608.33v-.01ZM16.294 6.74a.84.84 0 0 0 .154.02.73.73 0 0 0 .705-.574l.28-1.303a.73.73 0 0 0-.55-.866.725.725 0 0 0-.86.555l-.28 1.303a.73.73 0 0 0 .55.866ZM21.448 11.788l-1.226-.301a.73.73 0 0 0-.878.534.735.735 0 0 0 .53.886l1.226.301a.732.732 0 0 0 .878-.535.735.735 0 0 0-.53-.885ZM16.42 10.242c-2.173-3.793-3.292-5.038-3.33-5.087a.725.725 0 0 0-1.024-.048.726.726 0 0 0-.077.982c-3.851 3.433-7.847 7.527-9.42 9.171a2.004 2.004 0 0 0-.406 2.188c.126.282.27.584.415.846.155.263.338.545.521.788.377.516.966.808 1.583.808.164 0 .338-.02.502-.059.348-.087.801-.204 1.303-.33l.106.408c.097.36.261.69.483.992.232.292.502.535.82.72a2.77 2.77 0 0 0 1.033.35 2.647 2.647 0 0 0 1.09-.068c.358-.098.686-.263.976-.486.299-.224.54-.506.724-.827a2.838 2.838 0 0 0 .29-2.14l-.117-.428a126.362 126.362 0 0 0 5.878-1.828.72.72 0 0 0 .666.457c.068 0 .145-.01.222-.039a.73.73 0 0 0 .473-.914c-.019-.068-.53-1.663-2.702-5.456h-.01Zm-5.821 8.578c.048.175.058.35.039.525-.02.175-.078.35-.174.506a1.248 1.248 0 0 1-.348.398c-.144.107-.299.185-.473.234a1.29 1.29 0 0 1-1.023-.136 1.372 1.372 0 0 1-.395-.35 1.456 1.456 0 0 1-.232-.477l-.106-.399c.791-.214 1.67-.447 2.615-.71l.107.409h-.01Z"
      fill={color}
    />
  ) : (
    <path
      d="M19.45 9.045c.135 0 .28-.038.396-.116l1.071-.71a.728.728 0 0 0 .203-1.012.715.715 0 0 0-1.004-.204l-1.072.71a.728.728 0 0 0-.202 1.012c.135.213.367.33.608.33v-.01ZM16.294 6.74a.84.84 0 0 0 .154.02.73.73 0 0 0 .705-.574l.28-1.303a.73.73 0 0 0-.55-.866.725.725 0 0 0-.86.555l-.28 1.303a.73.73 0 0 0 .55.866ZM21.448 11.788l-1.226-.301a.73.73 0 0 0-.878.534.735.735 0 0 0 .53.886l1.226.301a.732.732 0 0 0 .878-.535.735.735 0 0 0-.53-.885ZM16.42 10.242c-2.173-3.793-3.292-5.038-3.33-5.087a.725.725 0 0 0-1.024-.048.726.726 0 0 0-.077.982c-3.851 3.433-7.847 7.527-9.42 9.171a2.004 2.004 0 0 0-.406 2.188c.126.282.27.584.415.846.155.263.338.545.521.788.377.516.966.808 1.583.808.164 0 .338-.02.502-.059.348-.087.801-.204 1.303-.33l.106.408c.097.36.261.69.483.992.232.292.502.535.82.72a2.77 2.77 0 0 0 1.033.35 2.647 2.647 0 0 0 1.09-.068c.358-.098.686-.263.976-.486.299-.224.54-.506.724-.827a2.838 2.838 0 0 0 .29-2.14l-.117-.428a126.362 126.362 0 0 0 5.878-1.828.72.72 0 0 0 .666.457c.068 0 .145-.01.222-.039a.73.73 0 0 0 .473-.914c-.019-.068-.53-1.663-2.702-5.456h-.01Zm-11.593 8.17a.526.526 0 0 1-.57-.195 6.929 6.929 0 0 1-.434-.652c-.125-.214-.241-.476-.347-.71a.534.534 0 0 1 .125-.593c1.554-1.624 5.463-5.631 9.257-9.016.53.759 1.293 1.945 2.306 3.715 1.014 1.77 1.65 3.035 2.037 3.871-4.787 1.605-10.202 3.015-12.374 3.57v.01Zm5.772.408c.048.175.058.35.039.525-.02.175-.078.35-.174.506a1.248 1.248 0 0 1-.348.398c-.144.107-.299.185-.473.234a1.29 1.29 0 0 1-1.023-.136 1.372 1.372 0 0 1-.395-.35 1.456 1.456 0 0 1-.232-.477l-.106-.399c.791-.214 1.67-.447 2.615-.71l.107.409h-.01Z"
      fill={color}
    />
  )

  return (
    <SvgWrapper viewBox={viewBox} size={size}>
      {path}
    </SvgWrapper>
  )
}

export default NewsIcon
