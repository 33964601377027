import React from 'react'
import { Typography, useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import CalendarButton from 'redesign/components/atoms/Buttons/CalendarButton'
import CalendarTodayButton from 'redesign/components/molecules/CalendarTodayButton'
import { formatDate } from 'redesign/shared/date'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { Container } from './styles'
import type { CalendarActionProps } from './types'

const CalendarActions: React.FC<CalendarActionProps> = ({
  handleNextButton,
  handlePreviousButton,
  referenceDay,
  handleToday,
  handleCalendarAction,
  isMonthCalendarOpen = false,
  isNative,
  isSmallScreen = false,
  showMonthCTA = true,
}) => {
  const { t, locale } = useFavurTranslation()
  const { palette } = useTheme()
  const displayTodayButton =
    !isMonthCalendarOpen || (!isNative && !isSmallScreen) || (isMonthCalendarOpen && isNative && !isSmallScreen)

  return (
    <Container>
      {showMonthCTA && !isMonthCalendarOpen ? (
        <CalendarButton
          label={formatDate({ date: referenceDay, locale, t, dateFormat: DATE_FORMAT_VARIANTS.monthOnly })}
          handleCalendarAction={handleCalendarAction}
        />
      ) : (
        <Typography color={palette.text.primary} variant="subtitle1">
          {formatDate({ date: referenceDay, locale, t, dateFormat: DATE_FORMAT_VARIANTS.monthOnly })}
        </Typography>
      )}
      {displayTodayButton && (
        <CalendarTodayButton
          hideArrows={isNative && isSmallScreen}
          label={t('page.shifts.content.today')}
          handlePreviousButton={handlePreviousButton}
          handleToday={handleToday}
          handleNextButton={handleNextButton}
        />
      )}
    </Container>
  )
}

export default CalendarActions
