import React, { memo, useCallback, useEffect, useState } from 'react'
import { Box, Button, ClickAwayListener, Tooltip, Typography } from '@mui/material'
import { tooltipClasses } from 'components/Badge/styles'
import { FF_FAVUR_REDESIGN } from 'constants/featureFlags'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { ArrowRightThinS } from 'icons'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import useAvatarSidebarStateContext from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { getAvatarFromPerson } from 'pages/Tasks/utils/timeline'
import RedesignThemeWrapper from 'redesign/components/atoms/RedesignThemeWrapper'
import AbsencePlannerAvatarCell from 'redesign/components/organisms/AbsencePlannerAvatarCell'
import { SIDE_MENU_OPTIONS } from 'redesign/components/templates/SideMenu/constants'
import { useSideMenuContext } from 'redesign/services/SideMenu/useSideMenuContext'
import { PersonT } from 'types'
import AbsencePlannerAvatarIcon from '../AvatarIcon'
import { scrollerDivId } from '../Grid'
import { taskBarStates } from '../TaskBar/types'
import { avatarClasses } from './styles'

interface IAbsencePlannerAvatarProps {
  name: string
  tasks?: number
  showBorder?: boolean
  selected?: boolean
  person: PersonT
  openUserTasks?: boolean
}

const AbsencePlannerAvatar: React.FC<IAbsencePlannerAvatarProps> = ({
  name,
  tasks = 0,
  showBorder,
  selected,
  person,
  openUserTasks = false,
}) => {
  const { t } = useFavurTranslation()
  const avatarIconData = getAvatarFromPerson(person, t, false)
  const { deselectPerson, selectNewPerson } = useSelectedStatesActionsContext()
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const setTaskBarState = useTaskBarStateDispatchContext()
  const { avatarBarExpanded: isExpanded } = useAvatarSidebarStateContext()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { setOpen, setPage } = useSideMenuContext()
  const redesignFF = useFeatureFlag(FF_FAVUR_REDESIGN)

  const onClickTaskBarState = useCallback(() => {
    if (redesignFF) {
      setPage(openUserTasks ? SIDE_MENU_OPTIONS.personalAbsences : SIDE_MENU_OPTIONS.adminAbsences)
      setTaskBarState(openUserTasks ? taskBarStates.userTasks : taskBarStates.managerTasks)
      setOpen(true)
    } else {
      setTaskBarState(openUserTasks ? taskBarStates.userTasks : taskBarStates.managerTasks)
    }
  }, [redesignFF, setPage, openUserTasks, setOpen, setTaskBarState])

  const onClick = useCallback(() => {
    // Deselecting takes priority. Does nothing but deselecting
    if (selected) {
      deselectPerson()
      return
    }

    // Small screen
    if (isSmallScreen) {
      if (!isExpanded) {
        setTooltipOpen((value) => !value)
      } else {
        if (tasks > 0) {
          selectNewPerson(person)
          onClickTaskBarState()
        }
      }

      return
    }

    // Big screen, allows selections
    selectNewPerson(person)
    if (tasks > 0) {
      onClickTaskBarState()
    }
  }, [selected, isSmallScreen, selectNewPerson, person, tasks, deselectPerson, isExpanded, onClickTaskBarState])

  const onTooltipActionClick = useCallback(() => {
    selectNewPerson(person)
    setTaskBarState(openUserTasks ? taskBarStates.userTasks : taskBarStates.managerTasks)
  }, [openUserTasks, person, selectNewPerson, setTaskBarState])

  const closeTooltip = useCallback(() => setTooltipOpen(false), [])

  //This solution covers both touch scroll and mouse wheel scroll.
  //If we only want to do this with touch scroll, it's way easier,
  //because the ClickAwayListener component has an onTouch prop that we can use there.
  useEffect(() => {
    if (!isSmallScreen || !tooltipOpen) return undefined
    const scroller = document.getElementById(scrollerDivId)
    if (!scroller) return undefined
    const callback = closeTooltip
    scroller.addEventListener('scroll', callback, { once: true })
    return () => {
      scroller.removeEventListener('scroll', callback)
    }
  }, [closeTooltip, isSmallScreen, tooltipOpen])

  return redesignFF ? (
    <RedesignThemeWrapper>
      <AbsencePlannerAvatarCell
        onTooltipActionClick={onTooltipActionClick}
        onClick={onClick}
        isSelected={selected}
        tooltipOpen={tooltipOpen}
        onTooltipClose={closeTooltip}
        person={person}
        taskCount={tasks}
        isExpanded={isExpanded}
      />
    </RedesignThemeWrapper>
  ) : (
    <Box
      id={`${person.id}_sidebar-avatar`}
      sx={[avatarClasses.container, ...(selected ? [avatarClasses.avatarSelected] : [])]}
      onClick={onClick}
    >
      <Tooltip
        arrow
        title={
          <ClickAwayListener onClickAway={closeTooltip}>
            <Box textAlign="start" display="flex" flexDirection="column">
              <Typography variant="body2" sx={avatarClasses.tooltipText}>
                {name}
              </Typography>
              {tasks && tasks > 0 ? (
                <Button
                  variant="text"
                  onClick={onTooltipActionClick}
                  color="secondary"
                  sx={avatarClasses.tooltipButton}
                >
                  {t(
                    tasks > 1
                      ? 'component.absenceRequestConflicts.conflicts.pendingRequests'
                      : 'component.absenceRequestConflicts.conflict.pendingRequests',
                    { numConflicts: tasks },
                  )}
                  <ArrowRightThinS />
                </Button>
              ) : null}
            </Box>
          </ClickAwayListener>
        }
        open={tooltipOpen}
        onClose={closeTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="right"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -8],
                },
              },
            ],
          },
          tooltip: {
            sx: tooltipClasses.tooltip,
          },
          arrow: {
            sx: tooltipClasses.arrow,
          },
        }}
      >
        <Box>
          <AbsencePlannerAvatarIcon avatarIconData={avatarIconData} showBorder={showBorder} tasks={tasks} />
        </Box>
      </Tooltip>

      <Box sx={avatarClasses.collapsableContent(isExpanded)}>
        <Box sx={avatarClasses.textContainer}>
          <Typography variant="body2" sx={avatarClasses.nameText}>
            {name}
          </Typography>
        </Box>
        {Boolean(tasks) && <ArrowRightThinS />}
      </Box>
    </Box>
  )
}

export default memo(AbsencePlannerAvatar)
