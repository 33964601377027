import { add, setDay } from 'date-fns'
import type { CalculateDifferentWeekMondayProps } from '../types'

export const calculateDifferentWeekMonday = ({
  selectedDay,
  currentWeekIndex,
  slideWeekIndex,
}: CalculateDifferentWeekMondayProps) => {
  const monthDifference = slideWeekIndex - currentWeekIndex

  if (monthDifference === 0) {
    return add(selectedDay, { weeks: monthDifference })
  }
  return setDay(add(selectedDay, { weeks: monthDifference }), 1)
}
