import React, { useRef } from 'react'
import DayCell from 'redesign/components/atoms/DayCell'
import CalendarDayShifts from './CalendarDayShifts'
import { DayFrame, CalendarDayButton, CalendarDayContainer } from './styles'
import type { CalendarDayProps } from './types'

const CalendarDay: React.FC<CalendarDayProps> = ({
  isToday,
  isCurrentMonth,
  day,
  sortedShifts,
  shiftUpdates,
  events,
  onClick,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <CalendarDayContainer ref={containerRef} isCurrentMonth={isCurrentMonth}>
      <CalendarDayButton onClick={onClick}>
        <DayFrame>
          <DayCell isToday={isToday} isInCalendar text={day} />
        </DayFrame>
        <CalendarDayShifts
          sortedShifts={sortedShifts}
          shiftUpdates={shiftUpdates}
          events={events}
          containerRef={containerRef}
          day={day}
        />
      </CalendarDayButton>
    </CalendarDayContainer>
  )
}

export default CalendarDay
