import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { SimpleLoading } from 'components/LoadingIcon'
import { DocumentStatuses } from 'constants/documents'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useFileDownload, useFileDisplay } from 'hooks/useFileDownload'
import { DocumentPDFL, DownloadS } from 'icons'
import FlashMessagesContext from 'services/FlashMessages/context'
import { PersonStatusType } from 'shared/constants'
import { formatDate, getCaption, getDocumentDownloadUrl, isClosed, isMonthlySheet } from 'utils/documents'
import { classes } from '../styles'
import type { DocumentPropsT } from '../types'
import { getDocumentTranlationLabel } from './utils'

export const testIds = {
  createdByMirus: 'document-created-by-mirus',
  document: 'document',
  documentTitle: 'document-title',
  statusReceived: 'status-received',
}

const Document: React.FC<DocumentPropsT> = ({ document, openMonthlySheetDialog }) => {
  const { t, locale } = useFavurTranslation()
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const documentLabel = t(getDocumentTranlationLabel(document.documentType))

  const [viewing, setViewing] = useState(false)
  const [isNew, setIsNew] = useState(document.status === DocumentStatuses.received)
  const { downloadFile, error: downloadError, downloading } = useFileDownload(() => {
    setIsNew(false)
    setViewing(false)
  })
  const { displayFile, error: displayError, displaying } = useFileDisplay(() => {
    setIsNew(false)
    setViewing(false)
  })
  const hasToOpenDialog = useMemo(() => {
    const isDocMonthlySheet = isMonthlySheet(document)
    const documentHasTask = Boolean(document.taskMonthlySheet)
    return isDocMonthlySheet && !isClosed(document) && documentHasTask
  }, [document])

  const isInactivePerson = useMemo(() => {
    return document.person?.status !== PersonStatusType.active
  }, [document])

  const openFile = useCallback(() => {
    setViewing(true)
    displayFile({ url: getDocumentDownloadUrl(document.favurUuid), filename: `${documentLabel}-${Date.now()}.pdf` })
  }, [displayFile, document.favurUuid, documentLabel])

  const displayFileCallback = useCallback(() => {
    if (hasToOpenDialog) {
      openMonthlySheetDialog({
        open: true,
        // It can be that there is no task, we'll lead to a blank page now
        taskUuid: document.taskMonthlySheet?.task?.favurUuid,
        isInactivePerson: isInactivePerson,
        openFile: openFile,
      })
    } else {
      openFile()
    }
  }, [document.taskMonthlySheet?.task?.favurUuid, hasToOpenDialog, openMonthlySheetDialog, openFile, isInactivePerson])

  useEffect(() => {
    removeAll()
    if (downloadError || displayError) {
      setFlashMessage(t('page.documents.download.error'))
      setViewing(false)
    }
  }, [displayError, downloadError, removeAll, setFlashMessage, t])

  return viewing ? (
    <Card key={document.favurUuid} marginBottom={1}>
      <Box sx={[classes.loading]}>
        <SimpleLoading small smallContainer />
      </Box>
    </Card>
  ) : (
    <Card key={document.favurUuid} marginBottom={1}>
      <Box sx={classes.container}>
        <Box sx={classes.fillColumn}>
          <IconButton
            sx={classes.displayButton}
            data-testid={testIds.document}
            onClick={() => {
              !displaying && displayFileCallback()
            }}
            type="button"
            size="large"
          >
            {isNew && <Box sx={classes.notificationIcon} data-testid={testIds.statusReceived} />}
            <Box sx={[classes.pdfIcon]}>
              <DocumentPDFL />
            </Box>
            <Box sx={[classes.content]}>
              <Typography data-testid={testIds.documentTitle} variant="body2">
                <strong>
                  {documentLabel} {getCaption(document, locale)}
                </strong>
              </Typography>
              <Typography sx={classes.caption} variant="caption">
                {document.tenant ? document.tenant.name : document.person?.tenant?.name}
              </Typography>
              <Typography data-testid={testIds.createdByMirus} variant="caption">
                {formatDate(document.createdByMirus, locale)}
              </Typography>
            </Box>
          </IconButton>
        </Box>
        {hasToOpenDialog ? (
          <Box sx={classes.download}>
            <Typography variant="overline">{t('page.documents.ornament.pendent')}</Typography>
          </Box>
        ) : (
          <Box sx={classes.download}>
            <IconButton
              sx={classes.downloadButton}
              color="inherit"
              onClick={() => {
                setViewing(true)
                !downloading &&
                  downloadFile({
                    url: getDocumentDownloadUrl(document.favurUuid),
                    filename: `${documentLabel}-${Date.now()}.pdf`,
                  })
              }}
              size="large"
            >
              <DownloadS />
            </IconButton>
          </Box>
        )}
      </Box>
    </Card>
  )
}

export default Document
