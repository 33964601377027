import { css, styled } from '@mui/material'
import {
  AVATAR_MENU_CLOSED_WIDTH,
  AVATAR_MENU_OPEN_WIDTH,
  CELL_HEIGHT,
} from 'pages/AbsencePlanner/components/Grid/constants'
import { convertPxToMuiSpacing } from 'redesign/themes/main/utils'
import palette from 'utils/theme/palette'

export const MainContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isExpanded',
})<{ isSelected?: boolean; isExpanded: boolean }>`
  box-sizing: border-box;
  width: ${({ isExpanded, theme }) =>
    isExpanded
      ? theme.spacing(convertPxToMuiSpacing(AVATAR_MENU_OPEN_WIDTH))
      : theme.spacing(convertPxToMuiSpacing(AVATAR_MENU_CLOSED_WIDTH))};
  height: ${CELL_HEIGHT}px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-left: ${({ theme }) => theme.spacing(1.375)};
  padding-right: ${({ theme }) => theme.spacing(1.5)};
  cursor: pointer;
  background: ${({ theme }) => theme.palette.common.white};

  transition: ${({ theme }) =>
    theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    })};

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background: ${palette.primary[50]};
      border-left: ${`${theme.spacing(0.25)} solid ${palette.secondary.main}`};
      padding-left: ${theme.spacing(1.125)};
    `}
`
export const TextContainer = styled('div')`
  overflow: hidden;
  margin-left: ${({ theme }) => theme.spacing(1.75)};
  display: flex;
  align-items: center;
`
export const CollapsableContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})<{ isExpanded: boolean }>`
  width: ${({ theme, isExpanded }) => (isExpanded ? theme.spacing(23.125) : 0)};
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  justify-content: space-between;
  align-items: center;
  transition: ${({ theme }) =>
    theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    })};
`
