import { styled, Typography } from '@mui/material'

export const Label = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  justify-content: start;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  line-clamp: 1;
  -webkit-line-clamp: 1;
`
