import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { getBadgeLabel } from 'components/Badge/helpers'
import { shiftTypes } from 'constants/shift'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Tag from 'redesign/components/atoms/Tag'
import AbsenceTag from 'redesign/components/atoms/Tag/AbsenceTag'
import { getAbsenceType } from 'redesign/components/atoms/Tag/ShiftTag/getAbsenceType'
import type { PlannedShiftT } from 'types'
import { MIN_WIDTH_TAG } from './constants'
import { getTextColor } from './getTextColor'

const ShiftPlanCardHeaderIcon: React.FC<{ shift: PlannedShiftT }> = ({ shift }) => {
  const { t } = useFavurTranslation()
  const { palette } = useTheme()
  const { hexBackgroundColor, isManual, hexTextColor } = shift
  const shiftColor = hexBackgroundColor ?? palette.primary.main
  const borderColor = isManual ? shiftColor : undefined
  const textColor = getTextColor({ isManual, hexTextColor, palette })

  if (shift.type === shiftTypes.absence) {
    const absenceType = getAbsenceType({ shift })

    return <AbsenceTag variant="chipBig" text={t('page.shifts.content.absence')} absenceType={absenceType} />
  }

  return (
    <Tag minWidthRem={MIN_WIDTH_TAG} isTransparent={isManual} borderColor={borderColor} backgroundColor={shiftColor}>
      <Typography color={textColor} variant="chipBig">
        {t(getBadgeLabel(shift))}
      </Typography>
    </Tag>
  )
}

export default ShiftPlanCardHeaderIcon
