import React from 'react'
import DotAlert from 'redesign/components/atoms/DotAlert'
import ContentFooter from 'redesign/components/molecules/ContentFooter'
import HeaderBlock from 'redesign/components/molecules/HeaderBlock'
import Card from 'redesign/components/organisms/Card'
import { Body, BodyText, EmptyBody, Header } from './styles'
import { ContentCardProps } from './types'

const ContentCard: React.FC<ContentCardProps> = ({
  isNew,
  url,
  download,
  dataTestId,
  onClick,
  bodyText,
  footerProps,
  children,
  header,
  hasTopPaddingBody = false,
  overflowBodyText = false,
}) => {
  const { icon, component, title, showChevron, overflowSingleLine = false } = header ?? {}

  const hasBody = bodyText || children || footerProps

  return (
    <Card isNew={isNew} url={url} download={download} onClick={onClick} dataTestId={dataTestId}>
      {header && (
        <Header>
          <DotAlert isNew={isNew}>
            <HeaderBlock
              icon={icon}
              component={component}
              title={title}
              showChevron={Boolean(url || showChevron)}
              overflowSingleLine={overflowSingleLine}
            />
          </DotAlert>
        </Header>
      )}

      {hasBody ? (
        <Body hasTopPaddingBody={hasTopPaddingBody}>
          {bodyText && (
            <BodyText overflowBodyText={overflowBodyText} variant="body1">
              {bodyText}
            </BodyText>
          )}
          {children}
          {footerProps && <ContentFooter {...footerProps} />}
        </Body>
      ) : (
        <EmptyBody />
      )}
    </Card>
  )
}

export default ContentCard
