import { tasksArray } from 'constants/tasks'
import type { TaskStateT } from 'pages/Tasks/types'
import { taskTypes, TaskTypeT } from 'shared/constants'

export const getUrlTaskState = (taskStateUrl: TaskStateT | undefined) =>
  taskStateUrl && tasksArray.includes(taskStateUrl) ? taskStateUrl : tasksArray[0]

export const getUrlTaskType = (): TaskTypeT | undefined => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const taskType = urlParams.get('task_type')

  return taskType &&
    Object.values(taskTypes)
      .map((type) => type.toString())
      .includes(taskType)
    ? (taskType as TaskTypeT)
    : undefined
}
