import React from 'react'
import { useTheme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FramedIllustration from 'redesign/components/atoms/FramedIllustration'
import { ErrorIllustration } from 'redesign/components/atoms/Illustrations'
import ContentCard from 'redesign/components/organisms/ContentCard'

const EffectiveShiftErrorCard: React.FC = () => {
  const { palette } = useTheme()
  const { t } = useFavurTranslation()

  return (
    <ContentCard
      header={{
        icon: <FramedIllustration illustration={ErrorIllustration} />,
      }}
      footerProps={{
        startText: t('page.shifts.shiftCard.effectiveShiftsError'),
        primaryColorVariant: palette.text.secondary,
      }}
      bodyText={t('shifts.effectiveTimes.error')}
    />
  )
}

export default EffectiveShiftErrorCard
