import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import Tabs from 'redesign/components/organisms/Tabs'
import { PAGE_KEYS } from 'redesign/pages/Notifications/constants'
import SubheaderTabContext from 'redesign/services/SubheaderTabs/context'
import { Container } from './styles'

const SubheaderTabs: React.FC = () => {
  const { selectedTabName, setSelectedTabName, pages, counts } = useContext(SubheaderTabContext)
  const [value, setValue] = useState(PAGE_KEYS.supervisor)
  const onClick = (selectedTab: string) => {
    setSelectedTabName(selectedTab)
    setValue(selectedTab)
  }

  useEffect(() => {
    if (counts.personal && !counts.manager) {
      setSelectedTabName(PAGE_KEYS.personal)
      setValue(PAGE_KEYS.personal)
    }
  }, [counts, pages, setSelectedTabName])

  return (
    <Container>
      <Tabs
        value={value}
        onClick={onClick}
        variant="fullWidth"
        pages={pages}
        color="primary"
        showTabPage={false}
        selectedTabName={selectedTabName}
        setSelectedTabName={setSelectedTabName}
        iconPosition="end"
      />
    </Container>
  )
}

export default SubheaderTabs
