import React, { useState } from 'react'
import { Button, Typography, useTheme } from '@mui/material'
import { motion } from 'framer-motion'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { isNative } from 'utils/platform'
import AppInformation from '../AppInformation'
import { BUTTON_GROUP_MOTION, BUTTON_ITEM_MOTION } from './motions'
import { ButtonGroup, NavigationButtonsContainer } from './styles'
import type { NavigationButtonProps } from './types'

const NavigationButtons: React.FC<NavigationButtonProps> = ({
  appInfoData,
  showAppInfo,
  toggleAppInfoDialog,
  navPoints,
  navigationButtonCallbacks,
}) => {
  const { t } = useFavurTranslation()
  const theme = useTheme()
  const [hoverStates, setHoverStates] = useState(Array(navPoints.length).fill(false))

  const handleHoverStates = (index: number, state: boolean) =>
    setHoverStates((prevState) => {
      const newState = [...prevState]
      newState[index] = state

      return newState
    })

  return (
    <NavigationButtonsContainer isNative={isNative()}>
      <ButtonGroup initial="hidden" animate="visible" exit="hidden" variants={BUTTON_GROUP_MOTION}>
        {navPoints.map((navPoint, index) => (
          <motion.li
            variants={BUTTON_ITEM_MOTION(index)}
            onMouseEnter={() => handleHoverStates(index, true)}
            onMouseLeave={() => handleHoverStates(index, false)}
            key={navPoint.id}
          >
            <Button
              key={navPoint.label}
              data-testid={navPoint.testId}
              onClick={navigationButtonCallbacks[navPoint.id]}
              disableRipple
              variant="text"
              size="small"
            >
              <Typography
                variant="body2"
                color={hoverStates[index] ? theme.palette.common.white : theme.palette.common.navItemBase}
              >
                {t(navPoint.label)}
              </Typography>
            </Button>
          </motion.li>
        ))}
      </ButtonGroup>
      {showAppInfo && <AppInformation label="App Info" onClose={toggleAppInfoDialog} appInfoData={appInfoData} />}
    </NavigationButtonsContainer>
  )
}

export default NavigationButtons
