import { styled } from '@mui/material'

export const Stack = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor?: string }>`
  flex: 1;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ theme }) => `${theme.spacing(0.125)}`};
  border: ${({ theme }) => `${theme.spacing(0.0625)} solid ${theme.palette.common?.cardBorder}`};
  height: ${({ theme }) => theme.spacing(0.5)};
  box-sizing: border-box;
`

export const Container = styled('div')`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacing(0.125)};
  align-items: stretch;
  justify-content: space-between;
`
