import { ComponentsOverrides, Theme } from '@mui/material'

const styleOverrides: ComponentsOverrides<Theme>['MuiTextField'] = {
  root: ({ theme }) => ({
    backgroundColor: theme.palette.common.backgroundCard,
    borderRadius: theme.spacing(1),

    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.greyMid,
      },
    },

    '& .MuiOutlinedInput-root': {
      '&:hover': {
        backgroundColor: theme.palette.common.hoverCard,
      },

      '& fieldset': {
        borderColor: theme.palette.common.greyMid,
      },

      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },

      '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
      },

      '&.Mui-error.Mui-focused fieldset': {
        borderColor: theme.palette.error.main,
      },

      '&.Mui-focused .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  }),
}

const MuiTextField = {
  styleOverrides,
}

export default MuiTextField
