import { useCallback, useState } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useSessionContext from 'hooks/useSessionContext'
import { listPaginatedFolders } from 'shared/queries'
import { CmsPaginatedFoldersT, CmsFolderEntryT, CmsFolderT } from 'types'
import { CONTENT_TYPES, ContentTypeT } from 'types/cms'

const DEFAULT_PAGE_SIZE = 10

type PaginatedFoldersProps = {
  contentType: ContentTypeT
  uuid?: string
  skip?: boolean
  pageSize?: number
  date?: string
  ownerId?: number
  onLoad?: (_folderEntries: CmsFolderEntryT[]) => void
}

const getFolderEntryUuid = (folderEntry: CmsFolderEntryT) => {
  if (folderEntry.type === 'folder') return folderEntry.folder?.uuid

  return folderEntry.content?.uuid
}

const appendContent = (newEntries: CmsFolderEntryT[], currentEntries: CmsFolderEntryT[]) => {
  return newEntries.reduce((acc, newFolderEntry) => {
    const newFolderEntryUuid = getFolderEntryUuid(newFolderEntry)
    const existingFolderEntry = acc.find((folderEntry) => getFolderEntryUuid(folderEntry) === newFolderEntryUuid)
    if (!existingFolderEntry) return [...acc, newFolderEntry]
    return acc
  }, currentEntries)
}

const updateFolderEntries = (
  currentEntries: CmsFolderEntryT[],
  newEntries: CmsFolderEntryT[] | undefined,
  paginate: boolean,
) => {
  if (paginate && newEntries) {
    return appendContent(newEntries, currentEntries)
  }
  return newEntries ?? []
}

const getCurrentFolder = (paginatedFolder?: CmsPaginatedFoldersT) => {
  if (!paginatedFolder?.uuid) return undefined

  return { ...paginatedFolder } as CmsFolderT
}

const useListCmsPaginatedFolders = ({
  contentType = CONTENT_TYPES.page,
  skip = false,
  pageSize = DEFAULT_PAGE_SIZE,
  uuid,
  date,
  ownerId,
  onLoad,
}: PaginatedFoldersProps) => {
  const { cmsActivated } = useSessionContext()
  const [paginate, setPaginate] = useState(false)
  const [page, setPage] = useState(1)
  const [folderEntries, setFolderEntries] = useState<CmsFolderEntryT[]>([])
  const [currentFolder, setCurrentFolder] = useState<CmsFolderT>()

  const { data: foldersData, loading: loading, error: error } = useJamesApolloQuery<{
    cmsPaginatedFolderList: CmsPaginatedFoldersT
  }>(listPaginatedFolders, {
    variables: {
      contentType: contentType,
      uuid: uuid,
      page: page,
      pageSize: pageSize,
      date: date,
      ownerId: ownerId,
    },
    fetchPolicy: 'cache-and-network',
    skip: !cmsActivated || skip,
    onCompleted: (result) => {
      const newEntries = result.cmsPaginatedFolderList?.folderEntries?.entries
      const updatedFolderEntries = updateFolderEntries(folderEntries, newEntries, paginate)
      setFolderEntries(updatedFolderEntries)
      setPaginate(false)
      setCurrentFolder(getCurrentFolder(result.cmsPaginatedFolderList))
      onLoad && onLoad(updatedFolderEntries)
    },
  })

  const loadMore = useCallback(() => {
    setPaginate(true)
    setPage(page + 1)
  }, [page])

  const totalCount = foldersData?.cmsPaginatedFolderList?.folderEntries?.totalEntries || 0

  const hasMoreElements = Boolean(totalCount && folderEntries && totalCount > folderEntries.length)

  return {
    list: folderEntries,
    currentFolder: currentFolder,
    loadMore,
    hasMoreElements,
    totalEntries: totalCount,
    loading,
    error,
  }
}

export default useListCmsPaginatedFolders
