import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useModule from 'hooks/useModule'
import usePersonsOfUser from 'hooks/usePersonsOfUser'
import { headerClasses } from 'pages/UserSettings/styles'
import { useHistory, useParams } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { modules } from 'shared/constants'
import BasicInfo from './sections/BasicInfo'
import EmployeeCardSection from './sections/EmployeeCard'
import PersonalDataSection from './sections/PersonalData'
import { classes } from './styles'

const EmployerPage: React.FC = () => {
  const { favurUuid } = useParams<{ favurUuid: string }>()
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { persons, loading } = usePersonsOfUser()
  const { hasAccessToModule } = useModule()

  const person = useMemo(() => (persons ?? []).find((p) => p.favurUuid === favurUuid), [favurUuid, persons])

  const goBackPage = useMemo(() => {
    // If user has only one person, when go back go to the list of employers page. Otherwise to the settings page
    if (loading || persons?.length === 0 || persons?.length === 1) {
      return routes.userSettings
    }
    return routes.employers
  }, [loading, persons])

  return (
    <Page
      header={
        <BackwardLink
          onClickIcon={() => {
            history.push(goBackPage)
          }}
          title={t('page.userSettings.employer.header')}
        />
      }
      sxHeader={headerClasses.subHeader}
    >
      {loading || !person ? (
        <SimpleLoading />
      ) : (
        <Box sx={classes.sectionsContainer}>
          <BasicInfo person={person} displayTeamOverview={hasAccessToModule(modules.teams)} />
          {hasAccessToModule(modules.workflowShareUserData) && <PersonalDataSection person={person} />}
          {hasAccessToModule(modules.employeeCards) && <EmployeeCardSection person={person} />}
        </Box>
      )}
    </Page>
  )
}

export default EmployerPage
