import React from 'react'
import BackButton from 'redesign/components/atoms/Buttons/BackButton'
import CloseButton from 'redesign/components/atoms/Buttons/CloseButton/CloseButton'
import HeaderLabel from 'redesign/components/molecules/HeaderLabel'
import Header from '../../../organisms/Header'
import { SIDE_MENU_OPTIONS, SIDE_MENU_TRANSLATIONS } from '../../SideMenu/constants'
import type { MenuPageProps } from '../../SideMenu/types'
import { ContentWrapper } from '../styles'

const AdminAbsences: React.FC<MenuPageProps> = ({ onClose, t, setPage, isDrawer }) => (
  <ContentWrapper>
    <Header
      startContent={isDrawer && <BackButton onGoBack={() => setPage(SIDE_MENU_OPTIONS.mainMenu)} />}
      middleContent={<HeaderLabel mainText={t(SIDE_MENU_TRANSLATIONS[SIDE_MENU_OPTIONS.adminAbsences])} />}
      endContent={isDrawer && <CloseButton onClick={onClose} />}
    />
  </ContentWrapper>
)

export default AdminAbsences
