import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { TextField, SxProps, Theme, Box } from '@mui/material'
import Alert from 'components/Alert'
import BaseInputDrawer from 'components/BaseDrawer/BaseInputDrawer'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { inputDrawerClasses } from './styles'

interface ITextInputDrawerProps {
  children?: React.ReactNode
  isOpen: boolean
  onCancel: () => void
  successButtonText: string
  onSuccess: (inputValue: string) => void
  onChangeInput?: (newValue: string) => void
  successButtonDisabled?: boolean
  textFieldPlaceHolder?: string
  textFieldMaxLength?: number
  textFieldMinLength?: number
  textFieldLabel?: string
  required?: boolean
  showCharactersLeft?: boolean
  testIdName?: string
  paperSx?: SxProps<Theme>
  defaultValue?: string
  warningText?: string
}

const TextInputDrawer: React.FC<ITextInputDrawerProps> = ({
  isOpen,
  onCancel,
  children,
  successButtonText,
  successButtonDisabled = false,
  onSuccess,
  showCharactersLeft,
  textFieldPlaceHolder = '',
  textFieldMaxLength = 1000,
  textFieldMinLength = 0,
  required = false,
  testIdName = '',
  paperSx = {},
  textFieldLabel,
  defaultValue = '',
  warningText,
  onChangeInput,
}) => {
  const { t } = useFavurTranslation()
  const [inputValue, setInputValue] = useState(defaultValue)
  const [error, setError] = useState(false)
  const textDrawerInputId = 'input-drawer-input'
  const helperKeys = {
    default: showCharactersLeft ? 'commentDrawer.input.helper' : undefined,
    requiredError: 'commentDrawer.input.required',
    maxLengthError: 'commentDrawer.input.maxLengthError',
    minLengthError: 'commentDrawer.input.minLengthError',
  }
  const [helperTextKey, setHelperTextKey] = useState(helperKeys.default)
  const [focusingTextField, setFocusingTextField] = useState(false)

  useEffect(() => {
    if (!isOpen) return
    const input = document.getElementById(textDrawerInputId)
    if (!input) return
    input.focus()
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      setInputValue(defaultValue)
    }
  }, [defaultValue, isOpen])

  const onChangeInputValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      onChangeInput && onChangeInput(newValue)
      setHelperTextKey(helperKeys.default)
      setError(false)
      setInputValue(newValue)
    },
    [helperKeys.default, onChangeInput],
  )

  const onCancelDrawer = useCallback(() => {
    setInputValue('')
    onCancel()
  }, [onCancel])

  const sendOnSuccess = useCallback(() => {
    if (inputValue.length > textFieldMaxLength) {
      setHelperTextKey(helperKeys.maxLengthError)
      return setError(true)
    }

    if (inputValue.length < textFieldMinLength) {
      setHelperTextKey(helperKeys.minLengthError)
      return setError(true)
    }

    if (required && inputValue.length === 0) {
      setHelperTextKey(helperKeys.requiredError)
      return setError(true)
    }

    onSuccess(inputValue)

    return setInputValue('')
  }, [
    inputValue,
    textFieldMaxLength,
    textFieldMinLength,
    required,
    onSuccess,
    helperKeys.maxLengthError,
    helperKeys.minLengthError,
    helperKeys.requiredError,
  ])

  return (
    <BaseInputDrawer
      open={isOpen}
      onClose={onCancelDrawer}
      paperSx={paperSx}
      testIdName={testIdName}
      onSuccess={sendOnSuccess}
      successButtonText={successButtonText}
      successButtonDisabled={successButtonDisabled}
      focusingTextField={focusingTextField}
    >
      {children}
      <TextField
        placeholder={textFieldPlaceHolder}
        variant="standard"
        sx={inputDrawerClasses.input}
        value={inputValue}
        onChange={onChangeInputValue}
        helperText={
          helperTextKey ? t(helperTextKey, { maxLength: textFieldMaxLength, minLength: textFieldMinLength }) : undefined
        }
        error={error}
        multiline
        id={textDrawerInputId}
        onFocus={() => setFocusingTextField(true)}
        onBlur={() => setFocusingTextField(false)}
        label={textFieldLabel}
        slotProps={{
          htmlInput: {
            maxLength: textFieldMaxLength,
            sx: inputDrawerClasses.inputTextArea,
            'data-testid': `input-drawer_comment-${testIdName}`,
          },
        }}
      />
      {warningText && (
        <Box sx={inputDrawerClasses.alertContainer}>
          <Alert severity="warning" content={warningText} title={t('tasks.new.secureconnection.warning')} />
        </Box>
      )}
    </BaseInputDrawer>
  )
}

export default TextInputDrawer
