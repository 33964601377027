import React from 'react'
import { Typography, IconButton, Box } from '@mui/material'
import { ArrowLeftM } from 'icons'
import { NavLink } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { classes } from './styles'

const PageHeader: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Box sx={classes.headerContainer}>
      <IconButton sx={classes.headerIcon} component={NavLink} to={routes.dashboard} size="large">
        <ArrowLeftM />
      </IconButton>
      <Typography variant="h2">{title}</Typography>
    </Box>
  )
}

export default PageHeader
