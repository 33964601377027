import React from 'react'
import SafeSpace from '../SafeSpace'
import { StyledDrawer } from './styles'
import type { FullPageDrawerProps } from './types'

const FullPageDrawer: React.FC<FullPageDrawerProps> = ({ onClose, open, children }) => (
  <StyledDrawer anchor="right" open={open} onClose={onClose}>
    <SafeSpace>{children}</SafeSpace>
  </StyledDrawer>
)

export default FullPageDrawer
