import React from 'react'
import { Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import type { FiltersFooterProps } from './types'

const FiltersFooter: React.FC<FiltersFooterProps> = ({
  hasSelectedPersons,
  isPersonsFilter,
  onClick,
  validFilters,
}) => {
  const { t } = useFavurTranslation()
  const translationKey = isPersonsFilter ? 'filter.persons.list.confirm' : 'filter.button.view'

  return (
    <Button
      variant="contained"
      fullWidth
      onClick={onClick}
      disabled={(isPersonsFilter && !hasSelectedPersons) || !validFilters}
    >
      {t(translationKey)}
    </Button>
  )
}

export default FiltersFooter
