import React from 'react'
import { Typography, useTheme } from '@mui/material'
import { AlertTriangle } from '../../Icon'
import { ICON_SIZE } from '../../Icon/constants'
import Tag from '../Tag'
import { TAG_SIZE } from '../constants'
import { TagContent } from '../styles'
import { AlertTagProps } from './types'

const AlertTag: React.FC<AlertTagProps> = ({ tagSize = TAG_SIZE.large, text, fullWidth }) => {
  const theme = useTheme()

  return (
    <Tag
      noPadding={Boolean(text)}
      backgroundColor={theme.palette.background.default}
      borderColor={theme.palette.common?.cardBorder}
      fullWidth={fullWidth}
      tagSize={tagSize}
    >
      <TagContent fullWidth>
        <AlertTriangle color={theme.palette.secondary.main} size={ICON_SIZE.extraSmall} />
        {text && (
          <Typography color={theme.palette.secondary.main} variant="chipBig" noWrap>
            {text}
          </Typography>
        )}
      </TagContent>
    </Tag>
  )
}

export default AlertTag
